export class SearchComparablesRadiusOption {

    constructor(id: number, value: string, zoomLevel: number, metricValue: string, imperialValue: string, customRadius: boolean, hiddenOption: boolean) {
        this.id = id;
        this.value = value;
        this.zoomLevel = zoomLevel;
        this.metricValue = metricValue;
        this.imperialValue = imperialValue;
        this.customRadius = customRadius;
        this.hiddenOption = hiddenOption;
    }

    id: number;
    value: string;
    zoomLevel: number;
    metricValue: string;
    imperialValue: string;
    customRadius: boolean;
    hiddenOption: boolean;
}