import { FormGroup } from "@angular/forms";
import { SearchComparablesEnum } from "../../enum/search-comparables.enum";
import { PropertyCode } from "../mpac/property-code";
import { Municipality } from "../property/municipality";
import { Centroid } from "./comparable-sales-response";
import { ComparableSalesShape } from "./comparable-sales-shape";
import { ComparableSalesShapeBounds } from "./comparable-sales-shape-bounds";
import { SearchComparablesCriteriaCrumb } from "./search-comparables-criteria-crumb";
import { SearchComparablesRadiusOption } from "./search-comparables-radius-option";

export class SearchComparablesFormWrapper {
    selectedSearchBy: SearchComparablesEnum;
    selectedPropertyTypeMPS: number;
    selectedSalePeriodMethod: string;
    selectedSalePeriod: string;

    radiusOption: SearchComparablesRadiusOption;
    sameCondoSearch: boolean;
    sameCondoLocation: google.maps.LatLng;
    sameCondoSearchOption: boolean;
    circleCenter: Centroid | null;

    municipalities: Municipality[];
    selectedMunicipalities: Municipality[] = [];
    totalMunicipalitiesCount: number;
    propertyCodes: PropertyCode[];
    selectedPropertyCodes: PropertyCode[] = [];
    
    form: FormGroup;
    formValue: any;
    searchCriteriaCrumbs: SearchComparablesCriteriaCrumb[];
    searchShapes: ComparableSalesShape;
    searchShapeBounds: ComparableSalesShapeBounds;
}