<div #omnibarContainer id='omnibar-container'>

  <img id="ob-logo" class="ob-brand" src="{{ themeService.activeTheme === 'dark' ? lightLogo : logo }}"
    (click)="reloadGema3G(); resetControls(); closeOtherScreens();"/>
    <img id="ob-mini-logo" class="ob-brand-sm" src="{{ logoSm }}" (click)="reloadGema3G()"/>

    @if (searchEnabled) {
      <div class="absolute z-50 right-[1px] top-[31px] sm:right-[5px] sm:top-[0px] lg:hidden">
        <mat-icon id="ob-icon-filter" (click)="showInput = !showInput">filter_list</mat-icon>
      </div>
    }

    @if (searchEnabled) {
      <ng-container>
        <mat-form-field id="ob-ff-search-type" appearance="outline" class="search get-dense-3 mb-3 sm:mb-6 md:mb-0"
          [ngClass]="{'show-inputs': showInput}">
          <mat-label id="ob-label-search-lbl">Search by</mat-label>
          <mat-select id="ob-list-search" [(ngModel)]="selectedSearchType"
            (selectionChange)="onSearchTypeSelected($event)">
            @for (type of searchTypes; track type; let i = $index) {
              <mat-option id="ob-option-searchby-{{type.value}}"
                [value]="type.value"
                [satPopoverAnchor]="searchTypePopover"
                (mouseover)="getSearchTypeTooltip(type.display)"
                (mouseout)="searchTypePopover.close()">
                {{ type.display }}
              </mat-option>
            }
            <sat-popover #searchTypePopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
              <gema3g-tooltip [content]="searchTypeToolTip"></gema3g-tooltip>
            </sat-popover>
          </mat-select>
          @if (selectedSearchType == OSTE.ALL_VALUE) {
            <mat-hint id="ob-hint-all"></mat-hint>
          }
          @if (selectedSearchType == OSTE.OWNER_VALUE) {
            <mat-hint id="ob-hint-owner">Search by Owner</mat-hint>
          }
        </mat-form-field>

        @if (selectedSearchType == OSTE.ALL_VALUE) {
          <form id="ob-form-search" class="mb-3 sm:mb-6 md:mb-0" [formGroup]="wildCardSearchFormGroup"
            (keyup.enter)="onFormSubmitted()">

            <!-- This hidden button consumes the form's submission via the enter key so that the form submission does not inadvertently reset the form field values via the "X" clear button -->
            <button id="ob-btn-hidden-submit" class="hidden max-h-0"></button>

            <section id="ob-section-search-container" class="text-and-voice-search-container get-dense-3">
              <mat-form-field id="ob-ff-search" appearance="outline" floatLabel="always">
                <mat-label id="ob-label-search"
                  [satPopoverAnchor]="shortcutPopoverTooltip"
                  (mouseover)="shortcutPopoverTooltip.open()"
                  (mouseout)="shortcutPopoverTooltip.close()">
                  Search text
                  <span
                    class="ob-search-keyboard-shortcut">
                    <fa-icon class="fa-xs fa-duotone ob-search-keyboard-shortcut-key" [icon]="faS"></fa-icon>
                  </span>
                  <sat-popover #shortcutPopoverTooltip [autoFocus]="false" [restoreFocus]="false" verticalAlign="above"
                    openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="searchShortcutTooltip"></gema3g-tooltip>
                  </sat-popover>
                </mat-label>
                <input id="ob-tf-search-string" matInput type="text" #trigger="matAutocompleteTrigger"
                  #autoSuggestAll formControlName="autoSuggestAll"
                  placeholder="Address, Name, PIN, ARN, Instrument, Plan" (keyup)="onOmnibarInputKeyUp($event)"
                  [matAutocomplete]="auto" [errorStateMatcher]="omnibarErrorStateMatcher"
                  (focus)='onAutoSuggestInputFocus()'
                  (keyup.escape)="clearFormControlFieldValue('autoSuggestAll')">

                  <!-- commented code
                  <mat-hint *ngIf="!wildCardSearchFormGroup.controls['autoSuggestAll'].errors?.['minLength']">
                    Minimum 2 characters
                  </mat-hint>
                  -->

                  <!-- replaced with a snackbar message for consistency
                  <mat-error>{{getValidationErrorMessage(wildCardSearchFormGroup, 'autoSuggestAll', 'Search string', 2)}}</mat-error>
                  -->

                  @if (isInputFieldHasValue(autoSuggestAll)) {
                    <button id="ob-btn-input-clear" matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearFormControlFieldValue('autoSuggestAll')">
                      <mat-icon id="ob-icon-close">close</mat-icon>
                    </button>
                  }

                  <mat-autocomplete id="ob-autocomplete-search" #matAutocomplete #auto="matAutocomplete"
                    [displayWith]="displayWithSelectedEntry">

                    @if (sessionTimedOut()) {
                      <div class="omnibar-autosuggest-rows-container">
                        <div id="ob-div-loading" class="loading-container">
                          Please sign in back to GeoWarehouse
                        </div>
                      </div>
                    }

                    <!-- Recently searched and viewed properties -->
                    @if (currentAutoSuggestMode() == AGSM.SHOW_RECENT_RESULTS_ONLY && !sessionTimedOut()) {
                      <div>
                        <div class="omnibar-autosuggest-header-row">
                          <div id="ob-div-recent-searches" class="label">Recent Searches</div>
                        </div>
                        <div class="omnibar-autosuggest-rows-container">
                          @if (recentSearchesLoading()) {
                            <mat-option [disabled]="true">
                              <div class="loading-container">
                                <img id="ob-img-loading-rs" src="assets/img/icon-refresh.gif">
                                <span #recentSearchesDiv class="loading">Loading...</span>
                              </div>
                            </mat-option>
                          }
                          @if (!recentSearchesLoading()) {
                            @for (recentlySearched of recentSearches; track $index) {
                              <mat-option id="ob-option-{{recentlySearched.text}}"
                                [value]="recentlySearched"
                                (onSelectionChange)="onRecentlySearchedPropertySelected(recentlySearched)">

                                @if ('recentlySearched'; as usage) {
                                  <div class="recent-property">
                                    <!--
                                    <span class="result-location-fa-icon">
                                      <fa-icon id="ob-icon-location" class="fa-2sm" [icon]="faLocationDot"></fa-icon>
                                    </span>
                                    -->
                                    <gema3g-recent-result [usage]="usage" [result]="recentlySearched"></gema3g-recent-result>
                                  </div>
                                }
                              </mat-option>
                            }
                          }
                        </div>

                        @if (userAccessControls.propertyReportAccess) {
                          <div class="omnibar-autosuggest-header-row">
                            <div class="left">
                              <div id="ob-div-recently-viewed" class="label">Recently Viewed Properties</div>
                              @if (!recentSearchesLoading()) {
                                <a class="anchor view-all" routerLink="/account/report-history">View All</a>
                              }
                            </div>
                          </div>
                        }

                        @if (userAccessControls.propertyReportAccess) {
                          <div class="omnibar-autosuggest-rows-container">
                            @if (recentViewsLoading()) {
                              <mat-option [disabled]="true">
                                <div class="loading-container">
                                  <img id="ob-img-loading-rv" src="assets/img/icon-refresh.gif">
                                  <span #recentlyViewedDiv class="loading">Loading...</span>
                                </div>
                              </mat-option>
                            }
                            @if (!recentViewsLoading()) {
                              @for (recentlyViewed of recentlyViewedProperties; track $index) {
                                <mat-option id="ob-option-{{recentlyViewed.text}}"
                                  [value]="recentlyViewed"
                                  (onSelectionChange)="onRecentlyViewedPropertySelected(recentlyViewed)">

                                  @if ('recentlyViewed'; as usage) {
                                    <div class="recent-property">
                                      <!--
                                      <span class="result-location-fa-icon">
                                        <fa-icon id="ob-icon-location" class="fa-2sm" [icon]="faLocationDot"></fa-icon>
                                      </span>
                                      -->
                                      <gema3g-recent-result [usage]="usage" [result]="recentlyViewed"></gema3g-recent-result>
                                    </div>
                                  }
                                </mat-option>
                              }
                            }
                          </div>
                        }
                      </div>
                    }


                    <!-- Autosuggest search results -->
                    @if (currentAutoSuggestMode() == AGSM.SHOW_AUTOSUGGEST_RESULTS_ONLY && !sessionTimedOut()) {
                      <div>
                        @if (autoSuggestSearchResults?.searchResult.MUNICIPALITY.length > 0) {
                          <div class="omnibar-autosuggest-header-row"
                            >
                            <div class="left">
                              <div id="ob-div-municipality" class="label">MUNICIPALITY</div>
                            </div>
                            @if (autoSuggestSearchResults?.searchResult.MUNICIPALITY.length > 0) {
                              <div class="right"
                                >
                                <div class="data"><span id="ob-suggestions-results"
                                  class="bold">{{ autoSuggestSearchResults.searchResultCount.MUNICIPALITY }} of
                                {{ autoSuggestSearchResults.searchResultCount.MUNICIPALITY }}</span> matches
                              </div>
                            </div>
                          }
                        </div>
                      }
                      <div class="max-h-[200px] overflow-y-auto mx-2 pl-1">
                        @for (municipality of autoSuggestSearchResults?.searchResult.MUNICIPALITY; track municipality; let i = $index) {
                          <mat-option id="ob-option-{{municipality.text}}"
                            (onSelectionChange)="onAutoSuggestSearchResultSelected(municipality)"
                            [value]="municipality" [ngClass]="['recent-result-row']">
                            <img id="ob-img-freehold"
                              src="assets/img/svg/omnibar/icon_propertytype_freehold.svg" class="icon">
                              {{ municipality.text[0] }} {{ municipality.text[1] }} ({{ municipality.id }})
                            </mat-option>
                          }
                        </div>

                        @if (autoSuggestSearchResults?.searchResult.OWNER.length > 0) {
                          <div class="omnibar-autosuggest-header-row"
                            >
                            <div class="left">
                              <div id="ob-div-owner" class="label">OWNER</div>
                            </div>
                            @if (autoSuggestSearchResults?.searchResult.OWNER.length > 0) {
                              <div class="right">
                                <div class="data"><span id="ob-suggesstions-owner"
                                class="bold">{{ autoSuggestSearchResults.searchResultCount.OWNER }}</span>
                                matches
                              </div>
                            </div>
                          }
                        </div>
                      }

                      <div class="px-2">
                        @for (owner of autoSuggestSearchResults?.searchResult.OWNER; track owner; let i = $index) {
                          <mat-option id="ob-option-{{owner.text}}"
                            (onSelectionChange)="onAutoSuggestSearchResultSelected(owner)" [value]="owner"
                            [ngClass]="['recent-result-row']">
                            <img id="ob-img-single-owner"
                              src="assets/img/svg/omnibar/icon_propertytype_singleowner.svg" class="icon">
                              {{ owner.text }} ({{ owner.id }})
                            </mat-option>
                          }
                        </div>

                        @if (autoSuggestSearchResults?.searchResult.ADDRESS.length > 0) {
                          <div class="omnibar-autosuggest-header-row">
                            <div class="left">
                              <div id="ob-div-address" class="label">ADDRESS</div>
                            </div>
                            <div class="right">
                              <div class="data">
                                <span id="ob-suggestions-address" class="bold">
                                  {{ autoSuggestSearchResults.searchResultCount.ADDRESS }}
                                </span>
                                @if (autoSuggestSearchResults?.searchResult.ADDRESS.length == 1) {
                                  match
                                } @else {
                                  matches
                                }
                              </div>
                            </div>
                          </div>
                        }

                        <div class="px-2">
                          @for (address of autoSuggestSearchResults?.searchResult.ADDRESS; track address; let i = $index) {
                            <mat-option id="ob-option-{{address.text}}"
                              (onSelectionChange)="onAutoSuggestSearchResultSelected(address)" [value]="address"
                              [ngClass]="['recent-result-row']">
                              <img id="ob-img-property-type-condo"
                                src="assets/img/svg/omnibar/icon_propertytype_condo.svg" class="icon">
                                {{ address.text }} ({{ address.id }})
                              </mat-option>
                            }
                          </div>

                          @if (autoSuggestSearchResults?.searchResult.BLOCK.length > 0) {
                            <div class="omnibar-autosuggest-header-row"
                              >
                              <div class="left">
                                <div id="ob-div-block" class="label">BLOCK</div>
                              </div>
                              @if (autoSuggestSearchResults?.searchResult.BLOCK.length > 0) {
                                <div class="right">
                                  <div class="data"><span id="ob-suggestions-block"
                                  class="bold">{{ autoSuggestSearchResults.searchResultCount.BLOCK }}</span>
                                  matches
                                </div>
                              </div>
                            }
                          </div>
                        }

                        <div class="px-2">
                          @for (block of autoSuggestSearchResults?.searchResult.BLOCK; track block; let i = $index) {
                            <mat-option id="ob-option-{{block.text}}"
                              (onSelectionChange)="onAutoSuggestSearchResultSelected(block)" [value]="block"
                              [ngClass]="['recent-result-row']">
                              <img id="ob-img-property-type-property"
                                src="assets/img/svg/omnibar/icon_propertytype_properties.svg" class="icon">
                                {{ block.text }} ({{ block.id }})
                              </mat-option>
                            }
                          </div>

                          @if (autoSuggestSearchResults?.searchResult.PIN.length > 0) {
                            <div class="omnibar-autosuggest-header-row"
                              >
                              <div class="left">
                                <div id="ob-div-pin" class="label">PIN</div>
                              </div>
                              @if (autoSuggestSearchResults?.searchResult.PIN.length > 0) {
                                <div class="right">
                                  <div class="data"><span id="ob-suggestions-pin"
                                  class="bold">{{ autoSuggestSearchResults.searchResultCount.PIN }}</span>
                                  matches
                                </div>
                              </div>
                            }
                          </div>
                        }

                        <div class="px-2">
                          @for (pin of autoSuggestSearchResults?.searchResult.PIN; track pin; let i = $index) {
                            <mat-option id="ob-option-{{pin.text}}"
                              (onSelectionChange)="onAutoSuggestSearchResultSelected(pin)" [value]="pin"
                              [ngClass]="['recent-result-row']">
                              <img src="assets/img/svg/omnibar/icon_propertytype_properties.svg" class="icon">
                              {{ pin.text }} ({{ pin.id }})
                            </mat-option>
                          }
                        </div>

                        @if (autoSuggestSearchResults?.searchResult.ARN.length > 0) {
                          <div class="omnibar-autosuggest-header-row"
                            >
                            <div class="left">
                              <div id="ob-div-arn" class="label">ARN</div>
                            </div>
                            @if (autoSuggestSearchResults?.searchResult.ARN.length > 0) {
                              <div class="right">
                                <div class="data"><span id="ob-suggestions-arn"
                                class="bold">{{ autoSuggestSearchResults.searchResultCount.ARN }}</span>
                                matches
                              </div>
                            </div>
                          }
                        </div>
                      }

                      <div class="px-2">
                        @for (arn of autoSuggestSearchResults?.searchResult.ARN; track arn; let i = $index) {
                          <mat-option id="ob-option-{{arn.text}}"
                            (onSelectionChange)="onAutoSuggestSearchResultSelected(arn)" [value]="arn"
                            [ngClass]="['recent-result-row']">
                            <img src="assets/img/svg/omnibar/icon_propertytype_freehold.svg" class="icon">
                            {{ arn.text }} ({{ arn.id }})
                          </mat-option>
                        }
                      </div>
                    </div>
                  }

                </mat-autocomplete>
              </mat-form-field>

              <!-- todo: add product access check here if applicable -->
              @if (!isFirefoxBrowser) {
                <gema3g-voice-search (recognizedText)="voiceSearchRecognizedText($event)"></gema3g-voice-search>
              }
            </section>
          </form>
        }

        @if (selectedSearchType == OSTE.OWNER_VALUE) {
          <form id="ob-form-search-submit" class="lg:flex" [formGroup]="ownerSearchFormGroup"
            (keyup.enter)="onFormSubmitted()">

            <!-- This hidden button consumes the form's submission via the enter key so that the form submission does not inadvertently reset the form field values via the "X" clear button -->
            <button id="ob-btn-hidden-submit" class="hidden max-h-0"></button>

            <section id="ob-section-name-search" class="ob-form-name-search get-dense-3">
              <mat-form-field id="ob-ff-first-name" appearance="outline" class="mb-5 md:mb-2 md:mr-2">
                <mat-label id="ob-label-first-name">First name</mat-label>
                <input id="ob-tf-first-name" matInput #firstName formControlName="firstName"
                  placeholder="First name" (keyup.escape)="clearFormControlFieldValue('firstName')">
                  <mat-hint id="ob-hint-optional">Optional</mat-hint>
                  @if (isInputFieldHasValue(firstName)) {
                    <button id="ob-btn-input-clear" matSuffix mat-icon-button
                      aria-label="Clear" (click)="clearFormControlFieldValue('firstName')">
                      <mat-icon id="ob-icon-close">close</mat-icon>
                    </button>
                  }
                </mat-form-field>
                <mat-form-field id="ob-ff-last-name" appearance="outline" class="mb-5 md:mb-2">
                  <mat-label id="ob-label-last-or-corp-name">Last name OR Corp name</mat-label>
                  <input id="ob-tf-last-or-corp-name" matInput #lastName formControlName="lastName"
                    placeholder="Last name OR Corp name" [errorStateMatcher]="omnibarErrorStateMatcher"
                    (keyup.escape)="clearFormControlFieldValue('lastName')">
                    <mat-hint>
                      <!-- Minimum 2 characters -->
                    </mat-hint>
                    <!-- replaced with a snackbar message for consistency
                    <mat-error>{{getValidationErrorMessage(ownerSearchFormGroup, 'lastName', 'Last/Corp name',
                    2)}}</mat-error>
                    -->
                    @if (isInputFieldHasValue(lastName)) {
                      <button id="ob-btn-clear-last-name" matSuffix mat-icon-button
                        aria-label="Clear" (click)="clearFormControlFieldValue('lastName')">
                        <mat-icon id="ob-icon-close">close</mat-icon>
                      </button>
                    }
                  </mat-form-field>
                </section>
              </form>
            }

            @if (selectedSearchType == OSTE.INSTRUMENT_VALUE) {
              <form id="ob-form-instrument-search" class="ob-form" [formGroup]="instrumentPlanSearchFormGroup"
                (keyup.enter)="onFormSubmitted()">

                <!-- This hidden button consumes the form's submission via the enter key so that the form submission does not inadvertently reset the form field values via the "X" clear button -->
                <button id="ob-btn-hidden-submit" class="hidden max-h-0"></button>

                <section class="ob-instrument-plan-search get-dense-3">
                  <mat-form-field id="ob-ff-instrument" appearance="outline" class="mb-5 md:mb-0">
                    <mat-label id="ob-label-instrument">Instrument OR Plan number</mat-label>
                    <input id="ob-tf-instrument-plan" matInput #instrumentPlanNumber
                      formControlName="instrumentPlanNumber" placeholder="Instrument, Plan"
                      [errorStateMatcher]="omnibarErrorStateMatcher"
                      (keyup.escape)="clearFormControlFieldValue('instrumentPlanNumber')">

                      <!-- commentented out -->
                      <!-- <mat-hint>
                      Minimum 2 characters
                    </mat-hint> -->

                    <!-- replaced with a snackbar message for consistency
                    <mat-error>{{getValidationErrorMessage(instrumentPlanSearchFormGroup, 'instrumentPlanNumber',
                    'Instrument/Plan number', 2)}}</mat-error>
                    -->

                    @if (isInputFieldHasValue(instrumentPlanNumber)) {
                      <button id="ob-btn-clear-instrument" matSuffix mat-icon-button aria-label="Clear"
                        (click)="clearFormControlFieldValue('instrumentPlanNumber')">
                        <mat-icon id="ob-icon-close">close</mat-icon>
                      </button>
                    }
                  </mat-form-field>
                </section>
              </form>
            }

            @if (selectedSearchType == OSTE.LOT_CONCESSION_VALUE) {
              <form id="ob-form-concession" class="ob-form ob-form-concession" [formGroup]="lotConcessionSearchFormGroup"
                (keyup.enter)="onFormSubmitted()">
                <section class="ob-lotcon-search" class="get-dense-3 lg:flex lg:mt-3 w-full">
                  <mat-form-field id="ob-ff-township" appearance="outline" class="mb-5 md:mb-2 md:mr-2">
                    <mat-label id="ob-icon-township">Township</mat-label>
                    <mat-select id="ob-select-township" formControlName="lotConTownship"
                      [errorStateMatcher]="omnibarErrorStateMatcher">
                      @for (township of townships.result; track township; let i = $index) {
                        <mat-option id="ob-option-{{township}}"
                          [value]="township">
                          {{ township }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (lotConcessionSearchFormGroup.controls['lotConTownship'].errors?.['required']) {
                      <!-- replaced with a snackbar message for consistency
                      <mat-error>
                        Township is required
                      </mat-error>
                      -->
                    }
                  </mat-form-field>

                  <mat-form-field id="ob-ff-concession" appearance="outline" class="mb-5 md:mb-2 md:mr-2">
                    <mat-label id="ob-label-concession">Concession</mat-label>
                    <mat-select id="ob-select-concession" formControlName="lotConConcession"
                      [errorStateMatcher]="omnibarErrorStateMatcher">
                      @for (concession of concessions.result; track concession; let i = $index) {
                        <mat-option id="ob-option-{{concession}}"
                          [value]="concession">
                          {{ concession }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (lotConcessionSearchFormGroup.controls['lotConConcession'].errors?.['required']) {
                      <!-- replaced with a snackbar message for consistency
                      <mat-error>
                        Concession is required
                      </mat-error>
                      -->
                    }
                  </mat-form-field>

                  <mat-form-field id="ob-ff-lot" appearance="outline" class="mb-5 md:mb-2">
                    <mat-label id="ob-label-lot">Lot</mat-label>
                    <mat-select id="ob-select-lot" formControlName="lotConLot"
                      [errorStateMatcher]="omnibarErrorStateMatcher">
                      @for (lot of lots.result; track lot; let i = $index) {
                        <mat-option id="ob-option-lot-{{lot.lot}}"
                          [value]="lot.lotId" (onSelectionChange)="onTownshipLotSelected(lot, $event)">
                          {{ lot.lot }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (lotConcessionSearchFormGroup.controls['lotConLot'].errors?.['required']) {
                      <!-- replaced with a snackbar message for consistency
                      <mat-error>
                        Lot is required
                      </mat-error>
                      -->
                    }
                  </mat-form-field>
                </section>
              </form>
            }

            <!-- LRO dropdown -->
            <section class="ob-lr get-dense-3 mb-3 sm:mb-6 md:mb-0" [ngClass]="{'show-inputs': showInput}">
              <mat-form-field id="ob-ff-LRO" class="ob" appearance="outline">
                <mat-label id="ob-label-LRO"
                  [satPopoverAnchor]="lroLabelPopoverTooltip"
                  (mouseover)="lroLabelPopoverTooltip.open()"
                  (mouseout)="lroLabelPopoverTooltip.close()">
                  LRO
                  <sat-popover #lroLabelPopoverTooltip [autoFocus]="false" [restoreFocus]="false" verticalAlign="above"
                    openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip [content]="'Land Registry Office'"></gema3g-tooltip>
                  </sat-popover>
                </mat-label>
                <mat-select id="ob-select-LRO" #selectLro [(ngModel)]="selectedLro"
                  (selectionChange)="onLroSelected($event)">
                  <mat-select-trigger id="ob-select-trigger-LRO">
                    {{ getSelectedLroDisplay(selectLro.value) }}
                  </mat-select-trigger>
                  <section id="ob-section-LRO-note" class="ob-lro-note">
                    All Searches are conducted within the province of Ontario, however Owner Name Search is
                    confined to a Land Registry Office.
                  </section>
                  <section
                    id="ob-section-LRO-requirement"
                    class="ob-lro-note-req"
                    [satPopoverAnchor]="lroPopoverTooltip"
                    (mouseover)="lroPopoverTooltip.open()"
                    (mouseout)="lroPopoverTooltip.close()">
                    Why must I select a Land Registry Office?
                  </section>
                  <sat-popover #lroPopoverTooltip [autoFocus]="false" [restoreFocus]="false" verticalAlign="above"
                    openTransition="500ms ease-out" closeTransition="100ms ease-in">
                    <gema3g-tooltip class="ob-lro-note-tooltip" [content]="lroToolTip"></gema3g-tooltip>
                  </sat-popover>
                  @for (lro of lros; track lro; let i = $index) {
                    <mat-option id="ob-option-lro-{{lro.id}}" [value]="lro.id">
                      {{ lro.name }} ({{ lro.id }})
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </section>
          </ng-container>
        }

        @if (!userService.userHasAccessToEStoreOnly) {
          @if (searchEnabled) {
            <section class="ob-button get-dense-3" [ngClass]="{'show-inputs': showInput}">
              <button id="ob-btn-search" mat-raised-button [disabled]="isSearching"
                (click)="onFormSubmitted()">Search{{
                isSearching ? "ing"
                : ""
                }}
              </button>
            </section>
          } @else {
            <!--
            <section class="w-full text-right ob-button get-dense-3">
              <a id="ob-btn-reload" (click)="reloadGema3G()" class="anchor">Back to Search</a>
            </section>
            -->
            <div class="ob-button get-dense-3">
              <button id="ob-btn-back" mat-raised-button (click)="goBack()">
                <div class="go-back">
                  <fa-icon id="ob-back-icon" class="fa-lg text-white" [icon]="faCircleLeft"></fa-icon>
                  <span id="ob-back-label">Back</span>
                </div>
              </button>
            </div>
          }
        }

        @if (searchComparablesEnabled || renewNowButtonEnabled) {
          <section class="ob-sc flex-row ml-4 lg:ml-0 get-dense-3 mt-3 sm:mt-0" [ngClass]="{'show-inputs': showInput}">
            <div class="ob-search-comparables">
              @if(searchComparablesEnabled){
              <div id="ob-div-search-comparables" class="ob-search-comparables-text" (click)="toggleSearchComparablesForm()">
                Search Comparables
                <!-- <mat-icon style="font-size: 16px; margin-bottom: -12px;">search</mat-icon> -->
                @if (user?.hasLicensePackageId()) {
                  <div class="ob-search-comparables-counter">
                    <span id="ob-div-search-comparables-tooltip" class="text-xs"
                      >
                      Balance: <span class="text-os-bold">{{ comparableSearchCounter() }}</span>
                    </span>
                    <fa-icon
                      id="ob-div-search-comparables-tooltip-icon"
                      class="fa-sm text-[#428BCA] pl-1"
                      [icon]="faCircleInfo"
                      [satPopoverAnchor]="comparableSearchCounterTooltip"
                      (mouseover)="comparableSearchCounterTooltip.open()"
                      (mouseout)="comparableSearchCounterTooltip.close()">
                    </fa-icon>
                    <sat-popover #comparableSearchCounterTooltip [autoFocus]="false"
                      [restoreFocus]="false"
                      verticalAlign="below"
                      openTransition="500ms ease-out"
                      closeTransition="100ms ease-in">
                      <gema3g-tooltip class="ob-lro-note-tooltip" [content]="comparableSearchCounterTooltipText"></gema3g-tooltip>
                    </sat-popover>
                  </div>
                }
              </div>
              }
              <div class="ob-search-comparables-far-right">
                @if(renewNowButtonEnabled){
                  <div>
                    <button id="ob-btn-renew" mat-raised-button (click)="onRenewNow()">Renew Now
                    </button>
                  </div>
                } @else if (searchComparablesEnabled) {
                  <div class="ob-search-comparables-img" (click)="toggleSearchComparablesForm()">
                    <img id="ob-img-compare-property"  src="assets/img/icon_compare_property.png"/>
                  </div>
                }
              </div>

            </div>
          </section>
        }
      </div>
