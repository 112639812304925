import { inject, Pipe, PipeTransform } from "@angular/core";
import { MeasurementUnitService } from "../service/measurement-unit.service";
import { DataService } from "../service/data.service";

@Pipe({
  name: 'squareMetersToAcresWithUom'
})
export class SquareMetersToAcersWithUomPipe implements PipeTransform {

  constructor() {
  }

  private measurementUnitService: MeasurementUnitService = inject(MeasurementUnitService);
  
  transform(input: any, numofdecimals: any) {

    if(input == null) {
      return DataService.NOT_APPLICABLE;
    }

    if (!isNaN(input)) {
      let nStr = (parseFloat(input) * this.measurementUnitService.rate_squareMetersToAcres).toFixed(numofdecimals);
      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      var out = x1 + x2;
      return out + " " + this.measurementUnitService.symbol_squareMetersToAcres;
    } else {
      return input;
    }
  }


}
