import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { AdBannerRotationService } from '../../../../shared/service/ad-banner-rotation.service';
import { AdBanner } from '../../../../core/model/banner/ad-banner';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { AdBannerAction } from "../../../../core/model/banner/ad-banner-action";
import { AdBannerService } from "../../../../shared/service/ad-banner.service";
import { BaseUnsubscribe } from "../../../../core/component/base-unsubscribe/base-unsubscribe";
import { takeUntil } from "rxjs";
import { AdBannerActionEnum } from "../../../../core/enum/ad-banner-action-enum";
import { RouteMappingUtility } from "../../../../shared/utility/route-mapping-utility";
import { EstoreProductCategoryEnum } from "../../../../core/enum/estore-product-category-enum";
import { DataService } from "../../../../shared/service/data.service";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { CommonModule } from '@angular/common';
import { GA_Button } from '../../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-right-sidebar',
  standalone: true,
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  imports: [CommonModule]
})
export class RightSidebarComponent extends BaseUnsubscribe{
  constructor() {
    super();
  }

  private gaService = inject(GoogleAnalyticsService);
  private adBannerRotationService = inject(AdBannerRotationService);
  private adBannerService = inject(AdBannerService);

  @Input() propertyReportSection: string;
  adBannerKeySuffix: string = '-RIGHT';
  newAdBanners: AdBanner[] = [];
  newBottomAdBanners: AdBanner[] = [];
  condoBanner: AdBanner;
  viewStatReportsAction = new AdBannerAction(AdBannerActionEnum.NAVIGATE_TO_ROUTE, ['../catalogue',
    RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.STAT) + '-' + RouteMappingUtility.eStoreCategoryToRoute(EstoreProductCategoryEnum.PROPERTY_SURVEY_IMAGE)]);

  isPropertySummary : boolean  = false;

  ngOnInit(): void {
    this.adBannerRotationService.adBanners
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(adBanners => {

      let adBannerKeys = Array.from(adBanners.keys());
      this.newAdBanners = [];
      this.newBottomAdBanners = [];

      switch (this.propertyReportSection) {
        case PropertyReportSectionEnum.REGISTRY:
          this.isPropertySummary = true;
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.REGISTRY + this.adBannerKeySuffix)) {
              let banner = adBanners.get(key)!;
              if (banner.imageSource.includes('conduit')) {
                this.condoBanner = banner;
              } else if (banner.imageSource.includes('/B_')) {
                this.newBottomAdBanners.push(banner);
              } else {
                this.newAdBanners.push(banner);
              }
            }
          });
          break;

        case PropertyReportSectionEnum.VALUATION_AND_SALES:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.VALUATION_AND_SALES + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          });
          break;

        case PropertyReportSectionEnum.PLANS_AND_SURVEYS:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.PLANS_AND_SURVEYS + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          });
          break;

        case PropertyReportSectionEnum.CONDO_DETAILS:
          adBannerKeys.forEach(key => {
            if (key.startsWith(PropertyReportSectionEnum.CONDO_DETAILS + this.adBannerKeySuffix)) {
              this.newAdBanners.push(adBanners.get(key)!);
            }
          });
          break;

      }

    })
  }

  ngAfterViewInit(): void {
  }

  invokeAction(action: AdBannerAction, index: number) {
    if(index == 0){
      this.gaService.buttonClicked(GA_Button.FIRST_RIGHT_SIDE_AD, GA_Button.FIRST_RIGHT_SIDE_AD_LABEL);
    }
    this.adBannerService.invokeAction(action);
  }
}
