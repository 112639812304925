import { ComparableSalesResultPayload } from "../comparables/comparable-sales-result-payload";
import { PropertyDetail } from "../property/property-detail";
import { SortColumn } from "./sort-column";
import { ScreenOrientation } from "../../enum/screen-orientation-enum";
import { ScreenDisplay } from "../../enum/screen-display-enum";

export class SearchComparablesResultSnapshot {
  new: boolean = false; //true when the snapshot is created for the first time from a search operation
  timestamp: number = 0; //when the snapshot was taken

  pin: string | null = null; //refers to the last property report pin which was opened from the search results
  pinElementId: string | null = null; //refers to the ui element id of the last property report pin
  pinRowElementId: string | null = null; //refers to the ui element id of the last property report pin row

  originalSubjectProperty: PropertyDetail | null = null;
  results: ComparableSalesResultPayload;
  sortColumn: SortColumn | null = null;
  screenOrientation: ScreenOrientation | null = null;
  screenDisplay: ScreenDisplay | null = null;
  autoPanMapForPropertyMarkers: boolean = false;  //auto panning the map refers to making each search result marker info bubble fully visible on the map, by default, turn this off to minimize map movements
  selectedProperties: string[] | null = [];
}
