import { AfterViewInit, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MapLayerVisibility } from '../../../core/model/interface/map-layer-visibility';
import { UserService } from "../../../shared/service/user.service";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { User } from "../../../core/model/user/user";
import { OnInit } from '@angular/core';
import { MainMapService } from '../main-map/main-map.service';
import { HeatmapBlockTypeEnum } from '../../../core/enum/heatmap-block-type.enum';
import { DataService } from '../../../shared/service/data.service';
import { HeatMapPropertyType } from '../../../core/model/interface/heatmap-property-type';
import { HeatMapDateRange } from '../../../core/model/interface/heatmap-date-range';
import { MapTypeEnum } from '../../../core/enum/map-type-enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { lastValueFrom, takeUntil } from 'rxjs';
import { LocationService } from '../../../shared/service/location.service';
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { StreetViewService } from '../../../shared/service/google-maps/street-view.service';
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { GenericPreference } from '../../../core/model/user/preference/generic-preference';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { OwnerTile } from '../main-map/tiles/owner-tile';
import { AssessmentTile } from '../main-map/tiles/assessment-tile';
import { AddressTile } from '../main-map/tiles/address-tile';
import { LotConcessionTile } from '../main-map/tiles/lot-concession-tile';
import { ThematicTile } from '../main-map/tiles/thematic-tile';
import { MunicipalityLroTile } from '../main-map/tiles/municipality-lro-tile';
import { MapTileEnum } from "../../../core/enum/map-tile-enum";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { HeatmapNhoodTypeEnum } from "../../../core/enum/heatmap-neighbourhood-type.enum";
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { FirstBaseSolutionService } from "../../../shared/service/first-base-solution.service";
import { GA_Button } from '../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-main-map-controls',
  templateUrl: './main-map-controls.component.html',
  styleUrls: ['./main-map-controls.component.scss']
})
export class MainMapControlsComponent extends BaseUnsubscribe implements OnInit {

  constructor() {
    super();
    this.userAccessControls = this.userService.getUserAccessControl();
    this.user = this.userService.user;
  }

  private gaService = inject(GoogleAnalyticsService);
  private fbsService = inject(FirstBaseSolutionService);
  private userService = inject(UserService);
  private mainMapService = inject(MainMapService);
  private dataService = inject(DataService);
  private locationService = inject(LocationService);
  private streetViewService = inject(StreetViewService);
  private screenManager = inject(ScreenManager);

  @Input() mapLayerVisibility: MapLayerVisibility;
  @Input() isMapControlsMoved = false;
  @Output() hoodQMapOpened = new EventEmitter();
  @Output() mainMapZoomIn = new EventEmitter();
  @Output() mainMapZoomOut = new EventEmitter();
  @Output() layerToggled = new EventEmitter<MatSlideToggleChange>();
  @Output() thematicLayerOpacityChanged = new EventEmitter<number>();
  @ViewChild('mapLayerMenuTrigger') mapLayerMenuTrigger: MatMenuTrigger;
  @ViewChild('mapTypeMenuTrigger') mapTypeMenuTrigger: MatMenuTrigger;
  userAccessControls: UserAccessControl;
  user: User;
  readyToDropStreetViewPegman: boolean = false;
  opacityValue: number = 0.5;
  hmPropertyType: HeatMapPropertyType[] = [];
  heatMapDateRanges: HeatMapDateRange[] = [];
  public MTE = MapTypeEnum;
  public DT = DataService;
  public OT = OwnerTile;
  public AT = AssessmentTile;
  public ADDT = AddressTile;
  public LCT = LotConcessionTile;
  public TT = ThematicTile;
  public MLT = MunicipalityLroTile;
  mapType: MapTypeEnum = MapTypeEnum.STANDARD;
  mapTypeAerialViewTilted: boolean = true;
  mapTypeAerialViewLabeled: boolean = true;
  schoolMapToolTip: string = "School Map";
  mapLayersToolTip: string = "Map Layers";
  mapViewsToolTip: string = "Map Views";
  streetViewOnToolTip: string = "Turn on Streetview";
  streetViewOffToolTip: string = "Turn off Streetview";
  userLocationToolTip: string = "Get user location";
  faCircleXmark = faCircleXmark;
  hasAccesstoHoodQSchoolCatchmentMap: boolean;
  maxZoomLevel: number = DataService.MAIN_MAP_MAX_ZOOM_LEVEL;
  minZoomLevel: number = DataService.MAIN_MAP_MIN_ZOOM_LEVEL;
  // zoomInToolTip: string;
  // zoomOutToolTip: string;
  // currentZoomLevelToolTip: string;

  onHoodQMapOpened = (event: any) => {
    this.hoodQMapOpened.emit();
  }

  onInitiateStreetView = (event: any) => {
    this.readyToDropStreetViewPegman = !this.readyToDropStreetViewPegman;
    this.streetViewService.initiateMainMapStreetView(this.readyToDropStreetViewPegman);
  }

  onMapZoomIn = (event: any) => {
    this.mainMapZoomIn.emit();
  }

  onMapZoomOut = (event: any) => {
    this.mainMapZoomOut.emit();
  }

  toggleFullScreen = (event: any) => {
    this.mainMapService.enterFullScreenMap(this.mainMapService.getMap());
  }

  onPropertyTypeSelected = (event: any, name: string) => {
    switch (name) {
      case "HM_AVERAGE_SALES_PT":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapAverageSalesPropertyType = event.source.value;
        this.heatMapLayerState.heatMapAverageSalesPropertyTypeIsCondo = (event.source.value == 'freehold') ? 'N' : 'Y';
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.AVERAGE_SALES, this.heatMapLayerState.heatMapAverageSalesDateRangeInMonths, this.heatMapLayerState.heatMapAverageSalesPropertyTypeIsCondo, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_GROWTH_RATE_PT":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapGrowthRatePropertyType = event.source.value;
        this.heatMapLayerState.heatMapGrowthRatePropertyTypeIsCondo = (event.source.value == 'freehold') ? 'N' : 'Y';
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.GROWTH_RATE, this.heatMapLayerState.heatMapGrowthRateDateRangeInMonths, this.heatMapLayerState.heatMapGrowthRatePropertyTypeIsCondo, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_MARKET_TURNOVER_PT":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapMarketTurnoverPropertyType = event.source.value;
        this.heatMapLayerState.heatMapMarketTurnoverPropertyTypeIsCondo = (event.source.value == 'freehold') ? 'N' : 'Y';
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.MARKET_TURNOVER, this.heatMapLayerState.heatMapMarketTurnoverDateRangeInMonths, this.heatMapLayerState.heatMapMarketTurnoverPropertyTypeIsCondo, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_RENTAL_PERCENTAGE":
        this.mainMapService.clearAllHeatmapNhood();
        this.mainMapService.renderNhoodHeatmapLayer(HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);
    }
  }

  onDateRangeSelected = (event: any, name: string) => {
    switch (name) {
      case "HM_AVERAGE_SALES_DR":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapAverageSalesDateRange = event.source.value;
        this.heatMapLayerState.heatMapAverageSalesDateRangeInMonths = this.getHeatmapDateRangeInMonths(event.source.value);
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.AVERAGE_SALES, this.heatMapLayerState.heatMapAverageSalesDateRangeInMonths, this.heatMapLayerState.heatMapAverageSalesPropertyTypeIsCondo, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_GROWTH_RATE_DR":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapGrowthRateDateRange = event.source.value;
        this.heatMapLayerState.heatMapGrowthRateDateRangeInMonths = this.getHeatmapDateRangeInMonths(event.source.value);
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.GROWTH_RATE, this.heatMapLayerState.heatMapGrowthRateDateRangeInMonths, this.heatMapLayerState.heatMapGrowthRatePropertyTypeIsCondo, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_MARKET_TURNOVER_DR":
        this.mainMapService.clearAllHeatmapBlocks();
        this.heatMapLayerState.heatMapMarketTurnoverDateRange = event.source.value;
        this.heatMapLayerState.heatMapMarketTurnoverDateRangeInMonths = this.getHeatmapDateRangeInMonths(event.source.value);
        this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.MARKET_TURNOVER, this.heatMapLayerState.heatMapMarketTurnoverDateRangeInMonths, this.heatMapLayerState.heatMapMarketTurnoverPropertyTypeIsCondo, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);
        break;

      case "HM_RENTAL_PERCENTAGE":
        this.mainMapService.clearAllHeatmapNhood();
        this.mainMapService.renderNhoodHeatmapLayer(HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);
        break;
    }
  }

  onMapTypeSelected = (event: any, mapName: string) => {

    event.stopPropagation();  //stop the mat-menu from auto closing

    switch (mapName) {
      case MapTypeEnum.STANDARD:
        this.gaService?.buttonClicked(GA_Button.MAP_VIEW_STD_ROAD_MAP, GA_Button.MAP_VIEW_STD_ROAD_MAP_LABEL);
        this.mapType = MapTypeEnum.STANDARD;
        setTimeout(() => {
          this.mainMapService.setMapType(MapTypeEnum.STANDARD);
        }, 200);
        this.mainMapService.applyFBSLayerVisibility(false);
        break;

      case MapTypeEnum.AERIAL:
        this.gaService?.buttonClicked(GA_Button.AERIAL_VIEW, GA_Button.AERIAL_VIEW_LABEL);
        this.mapType = MapTypeEnum.AERIAL;
        setTimeout(() => {
          this.mainMapService.setMapType(MapTypeEnum.AERIAL);
        }, 200);
        this.mainMapService.applyFBSLayerVisibility(false);
        break;

      case MapTypeEnum.ENHANCED_AERIAL:
        this.gaService?.buttonClicked(GA_Button.ENHANCED_AERIAL, GA_Button.ENHANCED_AERIAL_LABEL);
        this.mapType = MapTypeEnum.ENHANCED_AERIAL;
        //this.mapTypeAerialViewTilted = false;
        this.mainMapService.setMapTilt(0);

        this.mainMapService.initFBSLayer();
        setTimeout(() => {
          this.mainMapService.setMapType(MapTypeEnum.ENHANCED_AERIAL);
        }, 200);
        this.mainMapService.applyFBSLayerVisibility(true);
        this.mainMapService.refreshMapLayer(MapTileEnum.FIRST_BASE_SOLUTION_TILE, true);
        break;

      case MapTypeEnum.SATELLITE:
        this.mainMapService.setMapType(MapTypeEnum.SATELLITE);
        break;
    }
  }

  onAerialViewTiltChange(event: MatCheckboxChange) {
    this.mapTypeAerialViewTilted = event.checked;
    setTimeout(() => {
      this.mainMapService.setMapTilt((this.mapTypeAerialViewTilted) ? 45 : 0);
    }, 200);
  }

  onAerialViewLabelChange(event: MatCheckboxChange) {
    this.mapTypeAerialViewLabeled = event.checked;
    setTimeout(() => {
      this.mainMapService.setMapType((this.mapTypeAerialViewLabeled) ? MapTypeEnum.AERIAL : MapTypeEnum.SATELLITE);
    }, 200);
  }

  isFBSZoomLevelAllowed = () => {
    if (this.user?.roles?.lppadmins) {
      return true;
    } else {
      return this.mainMapService.getMapZoomLevel()! >= this.fbsService.minimumFBSAerialViewZoomLevel;
    }
  }

  toggleMapLayerVisibility = (event: MatSlideToggleChange) => {

    switch (event.source.name) {
      case "HM_AVERAGE_SALES":
        this.gaService?.buttonClicked(GA_Button.HEATMAP_AVG_SALES_ON, GA_Button.HEATMAP_AVG_SALES_ON_LABEL);
        this.mainMapService.clearAllHeatmapBlocks();

        if (event.checked) {
          this.heatMapLayerState.heatMapAverageSales = true;
          this.heatMapLayerState.heatMapGrowthRate = false;
          this.heatMapLayerState.heatMapMarketTurnover = false;
          this.heatMapLayerState.heatMapAverageRentalPercentage =false;

          this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.AVERAGE_SALES, this.heatMapLayerState.heatMapAverageSalesDateRangeInMonths, this.heatMapLayerState.heatMapAverageSalesPropertyTypeIsCondo, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);

        } else {
          this.heatMapLayerState.heatMapAverageSales = false;
        }
        break;

      case "HM_GROWTH_RATE":
        this.gaService?.buttonClicked(GA_Button.HEATMAP_GROWTH_RATE_ON, GA_Button.HEATMAP_GROWTH_RATE_ON_LABEL);
        this.mainMapService.clearAllHeatmapBlocks();

        if (event.checked) {
          this.heatMapLayerState.heatMapAverageSales = false;
          this.heatMapLayerState.heatMapGrowthRate = true;
          this.heatMapLayerState.heatMapMarketTurnover = false;
          this.heatMapLayerState.heatMapAverageRentalPercentage =false;

          this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.GROWTH_RATE, this.heatMapLayerState.heatMapGrowthRateDateRangeInMonths, this.heatMapLayerState.heatMapGrowthRatePropertyTypeIsCondo, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);

        } else {
          this.heatMapLayerState.heatMapGrowthRate = false;
        }
        break;

      case "HM_MARKET_TURNOVER":
        this.gaService?.buttonClicked(GA_Button.HEATMAP_MARKET_TURNOVER_ON, GA_Button.HEATMAP_MARKET_TURNOVER_ON_LABEL);
        this.mainMapService.clearAllHeatmapBlocks();

        if (event.checked) {
          this.heatMapLayerState.heatMapAverageSales = false;
          this.heatMapLayerState.heatMapGrowthRate = false;
          this.heatMapLayerState.heatMapMarketTurnover = true;
          this.heatMapLayerState.heatMapAverageRentalPercentage =false;

          this.mainMapService.renderHeatmapLayer(HeatmapBlockTypeEnum.MARKET_TURNOVER, this.heatMapLayerState.heatMapMarketTurnoverDateRangeInMonths, this.heatMapLayerState.heatMapMarketTurnoverPropertyTypeIsCondo, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);

        } else {
          this.heatMapLayerState.heatMapMarketTurnover = false;
        }
        break;

      case "HM_RENTAL_PERCENTAGE":
        this.mainMapService.clearAllHeatmapNhood();

        if (event.checked) {
          this.heatMapLayerState.heatMapAverageSales = false;
          this.heatMapLayerState.heatMapGrowthRate = false;
          this.heatMapLayerState.heatMapMarketTurnover = false;
          this.heatMapLayerState.heatMapAverageRentalPercentage =true;

          this.mainMapService.renderNhoodHeatmapLayer(HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE, this.mainMapService.getMap().getBounds(), this.mainMapService.getMapZoomLevel()!);

        } else {
          this.heatMapLayerState.heatMapAverageRentalPercentage = false;
        }
        break;

      default:
        this.layerToggled.emit(event);
    }

  }

  getHeatmapDateRangeInMonths = (key: string): number => {
    return this.heatMapDateRanges.find(range => { return range.value === key })!.months;
  }

  get heatMapLayerState() {
    return this.dataService.getMapLayerVisibility();
  }

  thematicOpacityLabel = (value: number): string => {
    this.thematicLayerOpacityChanged.emit(value);
    return `${value}`;
  }

  getUserLocation = () => {
    this.locationService.getPosition();
  }

  get zoomInToolTip() {
    return `Zoom In to Level ${this.nextZoomLevel}`;
  }

  get zoomOutToolTip() {
    return `Zoom Out to Level ${this.previousZoomLevel}`;
  }

  get currentZoomLevelToolTip() {
    return `Current map zoom level`;
  }

  get fullScreenMapToolTip() {
    return `Full screen`;
  }

  get fullScreenMapAvailable() {
    return this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
  }

  getGenericPreference = (): GenericPreference => {
    let genericPreference: GenericPreference = this.userService.getUserPreferencesFromLocalStorage()?.genericPreference;
    return genericPreference;
  }

  get currentZoomLevel(): string {
    return (this.mainMapService.getMapZoomLevel() !== undefined? (this.mainMapService.getMapZoomLevel()!) + '' : '?');
  }

  get nextZoomLevel(): string {
    return (this.mainMapService.getMapZoomLevel() !== undefined? (this.mainMapService.getMapZoomLevel()! + 1) + '' : '?');
  }

  get previousZoomLevel(): string {
    return (this.mainMapService.getMapZoomLevel() !== undefined? (this.mainMapService.getMapZoomLevel()! - 1) + '' : '?');
  }

  get isZoomMax(): boolean {
    return this.mainMapService.getMapZoomLevel() == this.maxZoomLevel;
  }

  get izZoomMin(): boolean {
    return this.mainMapService.getMapZoomLevel() == this.minZoomLevel;
  }

  async ngOnInit(): Promise<void> {
    this.hmPropertyType = [
      { value: 'freehold', viewValue: 'Freehold' },
      { value: 'condo', viewValue: 'Condo' },
    ];

    this.heatMapDateRanges = [
      { months: 3, value: '3mos', viewValue: '3 months' },
      { months: 6, value: '6mos', viewValue: '6 months' },
      { months: 12, value: '1yr', viewValue: '1 year' },
      { months: 60, value: '5yrs', viewValue: '5 years' }
    ];

    switch (this.getGenericPreference().mapType) {
      case 'MAP':
        this.mapType = MapTypeEnum.STANDARD;
        setTimeout(() => {
          this.mainMapService?.setMapType(MapTypeEnum.STANDARD);
        }, 100);
        break;

      case 'AERIAL':
        this.mapType = MapTypeEnum.AERIAL;
        setTimeout(() => {
          this.mainMapService?.setMapType(MapTypeEnum.AERIAL);
        }, 100);
        break;
    }

    setTimeout(async () => {
      await lastValueFrom(this.fbsService.getMinimumFBSAerialViewZoomLevel());
    }, 100);

    try {

      this.screenManager.getObservableScreen(ScreenNameEnum.MAP_LAYER_POPUP)!
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(visible => {
          if (visible) {
            this.mapTypeMenuTrigger?.closeMenu();
            this.screenManager.hideScreen(ScreenNameEnum.MAP_TYPE_POPUP);  //keep track of the screen's closed state
          } else {
            this.mapLayerMenuTrigger?.closeMenu();
          }
        })

      this.screenManager.getObservableScreen(ScreenNameEnum.MAP_TYPE_POPUP)!
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(visible => {
          if (visible) {
            this.mapLayerMenuTrigger?.closeMenu();
            this.screenManager.hideScreen(ScreenNameEnum.MAP_LAYER_POPUP);  //keep track of the screen's closed state
          } else {
            this.mapTypeMenuTrigger?.closeMenu();
          }
        })

      this.streetViewService.mainMapStreetViewInitiated$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((initiated: any) => {
          if (!initiated) this.readyToDropStreetViewPegman = false;
        });
    } finally {
    }

    this.hasAccesstoHoodQSchoolCatchmentMap = this.userAccessControls.hoodqSchoolCatchmentMap && !this.userAccessControls.restrictedAccess;
  }

  closedMenu(menuClose: string) {
    if (menuClose == 'mapLayerMenu') {
      this.screenManager.hideScreen(ScreenNameEnum.MAP_LAYER_POPUP);  //keep track of the screen's closed state
    }

    if (menuClose == 'mapTypeMenu') {
      this.screenManager.hideScreen(ScreenNameEnum.MAP_TYPE_POPUP);  //keep track of the screen's closed state
    }
  }

  openedMenu(menuOpen: string) {
    this.gaService?.buttonClicked(GA_Button.MAP_LAYERS, GA_Button.MAP_LAYERS_LABEL);

    if (menuOpen == 'mapLayerMenu' && this.mapTypeMenuTrigger?.menuOpen) {
      this.screenManager.showScreen(ScreenNameEnum.MAP_LAYER_POPUP);  //keep track of the screen's open state
    }

    if (menuOpen == 'mapTypeMenu' && this.mapLayerMenuTrigger?.menuOpen) {
      this.screenManager.showScreen(ScreenNameEnum.MAP_TYPE_POPUP);  //keep track of the screen's open state
    }
  }

}
