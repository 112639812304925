import { Component, inject } from '@angular/core';
import { DataService } from '../../../shared/service/data.service';
import { UrlService } from '../../../shared/service/url.service';

@Component({
  selector: 'gema3g-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  constructor() {
  }

  private urlService: UrlService = inject(UrlService);
  
  pageNotFoundText: string = DataService.PAGE_NOT_FOUND_TEXT;
  pageNotFoundTextDescription: string = DataService.PAGE_NOT_FOUND_TEXT_DESCRIPTION;

  home = () => {
    //We will let the auth guard configured for the login component
    //decide whether to redirect the user to the login page or to the home page
    //depending on whether the user is logged in or not.
    this.urlService.goToLoginPage();
  }
}
