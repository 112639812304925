<section class="user-messages-container">
  <gema3g-header></gema3g-header>
  <gema3g-omnibar-search [searchEnabled]='false'></gema3g-omnibar-search>

  <div class="main-container">
    <div class="header">
      <div class="left">
        <div id="msgs-header-label" class="label">Messages</div>
      </div>

      <div class="right">
        <!--
        <span id="msgs-btn-close" class="flex flex-row items-center" (click)="closeMessages()">
          <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
        </span>
        -->
      </div>
    </div>

    <div class="body">
      <mat-tab-group dynamicHeight class="tab-group">
        <!-- Banner Messages -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span matBadge="{{bannerMessagesCount}}" matBadgeOverlap="false" matBadgeSize="medium" [ngStyle]="{'color':'#004D94'}">In-App Notifications</span>
          </ng-template>

          <mat-sidenav-container #bannerMessagesSideNavContainer class="sidenav-container" [hasBackdrop]="false">

            <mat-sidenav-content #bannerMessagesSideNavContentContainer class="sidenav-data-container">
              <div class="sidenav-data-content">
                @if (hasBannerMessages) {
                  <table id="msgs-tbl" class="mat-elevation-z2 standard-table" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                    <ng-container matColumnDef="messageId">
                      <th id="msgs-tbl-hdr-msg-id" mat-header-cell *matHeaderCellDef mat-sort-header>Message ID</th>
                      <td id="msgs-tbl-data-msg-id-{{row.messageId}}-{{row.messageId}}" mat-cell *matCellDef="let row">
                        {{row.messageId}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createDate">
                      <th id="msgs-tbl-hdr-cd" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Create Date</th>
                      <td id="msgs-tbl-data-cd-{{row.messageId}}-{{row.createDate}}" mat-cell *matCellDef="let row" class="centered">
                      {{row.createDate | date : 'mediumDate'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <th id="msgs-tbl-hdr-msg-id" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                      <td id="msgs-tbl-data-msg-id-{{row.messageId}}-{{row.status}}" mat-cell *matCellDef="let row">
                        @if (row.status == 'A') {
                          <span class="active">Active</span>
                        }
                        @if (row.status == 'E') {
                          <span class="expired">Expired</span>
                        }
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="subject">
                      <th id="msgs-tbl-hdr-msg-id" mat-header-cell *matHeaderCellDef mat-sort-header class="subject-col-width">Subject</th>
                      <td id="msgs-tbl-data-msg-id-{{row.messageId}}-{{row.subject}}" mat-cell *matCellDef="let row">
                        {{row.subject}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="expiryDate">
                      <th id="msgs-tbl-hdr-cd" mat-header-cell *matHeaderCellDef mat-sort-header class="center-header">Expiry Date</th>
                      <td id="msgs-tbl-data-cd-{{row.messageId}}-{{row.expiryDate}}" mat-cell *matCellDef="let row" class="centered">
                      {{row.expiryDate | date : 'mediumDate'}}</td>
                    </ng-container>

                    <tr id="msgs-tbl-row-hdr" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr id="msgs-tbl-row-{{row.messageId}}" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openBannerMessage(row)"></tr>

                  </table>
                }
              </div>
            </mat-sidenav-content>

            <mat-sidenav #bannerMessageSideNavMessageContainer class="sidenav-message-container" position="end" disableClose="false">
              <div class="sidenav-message-content">
                <div class="header">
                  <div class="left">
                    <div id="msg-detail-label" class="label">Message Detail</div>
                  </div>

                  <div class="right">
                    <mat-slide-toggle id="msg-toggle-overlap" [(ngModel)]="isOverlapDisplay" (change)="toggleMessageDisplay($event)" color="primary">Overlap</mat-slide-toggle>

                    <span id="msg-detail-close-button" (click)="closeMessage()">
                      <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
                    </span>
                  </div>
                </div>

                <div class="message-body-container">
                  <h3>{{messageSubject}} @if (messageStatus == 'E') {
                    <span class="expired">(Expired)</span>
                  }</h3>
                  <div id="banner-message-body"></div>
                </div>
              </div>
            </mat-sidenav>

          </mat-sidenav-container>

        </mat-tab>

        <!-- Priority Messages -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span matBadge="{{priorityMessagesCount}}" matBadgeOverlap="false" matBadgeSize="medium" [ngStyle]="{'color':'#004D94'}">Notifications</span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>
</section>
