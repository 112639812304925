import MapTypeId = google.maps.MapTypeId;
import { EventEmitter, inject, Injectable, OnDestroy, OnInit, Output } from "@angular/core";
import { AddressTile } from "./tiles/address-tile";
import { AssessmentTile } from "./tiles/assessment-tile";
import { OwnerTile } from "./tiles/owner-tile";
import * as _ from "lodash";
import dayjs from "dayjs";
import { ThematicTile } from "./tiles/thematic-tile";
import { LroPolygonsService } from "../../../shared/service/lro-polygons.service";
import { LotConcessionTile } from "./tiles/lot-concession-tile";
import { MunicipalityLroTile } from "./tiles/municipality-lro-tile";
import { MapLayerVisibility } from "../../../core/model/interface/map-layer-visibility";
import { MapTileEnum } from "../../../core/enum/map-tile-enum";
import { AutoSuggestSearchMode } from "../../../core/enum/autosuggest-search-mode";
import { MainMapInfoBubbleContentService } from "./main-map-info-bubble-content.service";
import { SpatialSearchService } from "../../../shared/service/search/spatial-search.service";
import { BehaviorSubject, lastValueFrom, Observable, of, Subject, takeUntil } from 'rxjs';
import { ComparablesService } from "../../../shared/service/comparables.service";
import { PushPinMarkerBubble } from "../../../core/model/map/pushpin-marker-bubble-id";
import { PropertyReportSearchService } from "../../../shared/service/search/property-report-search.service";
import { PropertyDetail } from "../../../core/model/property/property-detail";
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { UserPreference } from "../../../core/model/user/preference/user-preference";
import { UserService } from "../../../shared/service/user.service";
import { UserAccessControl } from '../../../core/model/user/user-access-control';
import { GoogleMapState } from "../../../core/model/map/google-map-state";
import { ComparableSale, Condo } from "../../../core/model/comparables/comparable-sales-response";
import { Pii } from "../../../core/model/property/pii";
import { HeatmapSearchService } from "../../../shared/service/search/heatmap-search.service";
import { HeatmapBlockTypeEnum } from "../../../core/enum/heatmap-block-type.enum";
import { HeatmapBlockContainer } from "../../../core/model/map/heatmap-block-container";
import { DataService } from "../../../shared/service/data.service";
import { CurrencyPipe } from '@angular/common'
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { defaultErrorMatSnackBarConfig, colourPalette, LocalStorageKey } from "../../../shared/constant/constants";
import { MapTypeEnum } from "../../../core/enum/map-type-enum";
import { Centroid } from "../../../core/model/spatial/centroid";
import { Polygon } from "../../../core/model/property/polygon";
import { StreetViewPoint } from "../../../core/model/spatial/street-view-point";
import { StreetViewService } from "../../../shared/service/google-maps/street-view.service";
import { User } from "../../../core/model/user/user";
import { MunicipalityService } from "../../../shared/service/municipality.service";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { ScreenManager } from "../../../shared/service/screen-manager.service";
import { ScreenNameEnum } from "../../../core/enum/screen-name.enum";
import { SearchComparablesFormService } from "../../../shared/service/search/search-comparables-form.service";
import { Spatial } from "../../../core/model/spatial/spatial";
import { PinXY } from "../../../core/model/property/pin-x-y";
import { SearchResult } from "../../../core/model/search-result/search-result";
import { OmnibarSearchService } from "../../../shared/service/search/omnibar-search.service";
import { OwnerToPropertyMap } from "../../../core/model/search-result/owner-to-property-map";
import { OwnerToPropertyMapItem } from "../../../core/model/search-result/owner-to-property-map-item";
import { FirstBaseSolutionTile } from "./tiles/first-base-solution-tile";
import { FirstBaseSolutionService } from "../../../shared/service/first-base-solution.service";
import { CondoSummary } from "../../../core/model/property/condo-summary";
import { DecimalPipe } from '@angular/common';
import { MapService } from "../../../shared/service/map-service";
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { PIIRequest } from "../../../core/model/property/pii.request";
import { SearchComparablesResultService } from "../../../shared/service/search/search-comparables-result.service";
import { ErrorUtil } from "../../../shared/service/error.util";
import { PIIService } from "../../../shared/service/pii.service";
import { HeatmapNhoodTypeEnum } from "../../../core/enum/heatmap-neighbourhood-type.enum";
import { MainMapConstants } from "./main-map-constants";
import { GA_Event, GA_Feature, GA_Page } from "../../../shared/constant/google-analytics-constants";

declare var $: any;
declare var InfoBox: any;

@Injectable({
  providedIn: 'root'
})
export class MainMapService extends MainMapConstants {
  constructor() {
    super();

    this.userAccessControls = this.userService.getUserAccessControl();
    this.initializeUser();
  }

  private lroPolygonsService = inject(LroPolygonsService);
  private userService = inject(UserService);
  private infoBubbleContentService = inject(MainMapInfoBubbleContentService);
  private comparablesService = inject(ComparablesService);
  private spatialServiceService = inject(SpatialSearchService);
  private propertyReportService = inject(PropertyReportService);
  private piiService = inject(PIIService);
  private heatmapSearchService = inject(HeatmapSearchService);
  private currencyPipe = inject(CurrencyPipe);
  private _snackBar = inject(MatSnackBar);
  private streetViewService = inject(StreetViewService);
  private municipalityService = inject(MunicipalityService);
  private loggerService = inject(LoggerService);
  private decimalPipe = inject(DecimalPipe);
  private screenManager = inject(ScreenManager);
  private searchComparablesFormService = inject(SearchComparablesFormService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private omnibarSearchService = inject(OmnibarSearchService);
  private fbsService = inject(FirstBaseSolutionService);
  private mapService = inject(MapService);
  private gaService = inject(GoogleAnalyticsService);
  private dataService = inject(DataService);

  private destroyed$ = new Subject();
  @Output() newSubjectProperty = new EventEmitter<PropertyDetail>();
  private gMap: google.maps.Map;
  private lroPolygons: any[] = [];
  private renderedLroPolygons: google.maps.Polygon[] = [];
  private renderedOmnibarSearchBlockPolygons: google.maps.Polygon[] = [];
  private renderedMarkers: google.maps.Marker[] = [];
  private searchComparableMarkers: google.maps.Marker[] = [];
  private clickMarkers: google.maps.Marker[] = [];
  private clickPolygons: google.maps.Polygon[] = [];
  private renderedOmnibarSearchResultsPolygons: google.maps.Polygon[] = [];
  private renderedTownshipLotPolygons: google.maps.Polygon[] = [];
  private renderedMarkerInfoBubbles = new Map<string, PushPinMarkerBubble>();
  private userLocationMarker: google.maps.Marker = new google.maps.Marker({});
  private maximumSearchPolygonsAllowed: number;
  private searchResultDefaultMarkerIcon: string = 'assets/img/svg/map/icon-map-orange-push-pin.svg';
  private searchResultSelectedMarkerIcon: string = 'assets/img/svg/map/icon-map-orange-push-pin.svg';
  private tempMarkerIcon: string = 'assets/img/svg/map/pushpin-progress.gif';
  private userLocationMarkerIcon: string = 'assets/img/svg/map/icon-user-location.png';
  private userLocationMarkerSize: google.maps.Size = new google.maps.Size(30, 30);
  private searchResultDefaultMarkerSize: google.maps.Size = new google.maps.Size(30, 30);
  private searchResultSelectedMarkerSize: google.maps.Size = new google.maps.Size(36, 36);
  private streetViewMarkerSize: google.maps.Size = new google.maps.Size(40, 40);
  private currentMarkerInfoBubble: PushPinMarkerBubble;
  private userAccessControls: UserAccessControl;
  private user: User;
  private searchComparablesDrawingManager: google.maps.drawing.DrawingManager;
  private renderMarkerProgressInfoBox: any;
  private streetViewMapMarker: google.maps.Marker;
  private panoramaMarker: google.maps.Marker;
  private pinBounds = new google.maps.LatLngBounds();

  //cma -> Comparable Market Analysis (aka comparable sales)
  private cmaCircularBufferObjects: any[] = [];
  private cmaPolygonOverlays: any[] = [];
  private cmaMunicipalityPolygons: google.maps.Polygon[] = [];
  private cmaMultiPolygonCloseMarkers: any[] = [];
  private cmaMultiPolygonCoordinates: any[] = [];
  private cmaMultiPolygonBounds = new google.maps.LatLngBounds();
  private cmaMultiMunicipalityBounds = new google.maps.LatLngBounds();
  private cmaMultiSnapshotPolygons: google.maps.Polygon[] = [];
  public cmaPolygonStartPath: google.maps.LatLng | null;
  private cmaPolygonInfoBoxInProgressInstructions: any;
  private cmaPolygonInfoBoxStartInstructions: any;
  private cmaCancelPolygonDrawing: boolean = false;

  //observables
  private _drawAssessmentRequest = new BehaviorSubject<string>("");
  drawAssessmentRequest$ = this._drawAssessmentRequest.asObservable();
  private _mainMapClickedForSearchComparables = new BehaviorSubject<google.maps.LatLng>(new google.maps.LatLng(0, 0));
  mainMapClickedForSearchComparablesObservable$ = this._mainMapClickedForSearchComparables.asObservable();
  private _markerMouseOverEvent = new BehaviorSubject<google.maps.Marker>(new google.maps.Marker({}));
  markerMouseOverEvent$ = this._markerMouseOverEvent.asObservable();

  //heatmap layer variables
  private lastNELat: number;
  private lastNELng: number;
  private lastSWLat: number;
  private lastSWLng: number;
  private lastHeatmapDateRange: number;
  private lastIsCondoFlag: string;
  private lastHeatmapResponsePolygons = null;
  private lastHeatmapResponseData = null;
  private lastHeatmapNhoodRentalPercentageData = null;

  getLroPolygonService = () => {
    return this.lroPolygonsService;
  }

  renderLroPolygon = (lroId: string, focusLroOnMap: boolean) => {
    this.clearLroPolygon();

    this.lroPolygons = this.lroPolygonsService.getLroById(lroId);
    if (this.lroPolygons.length == 0) {
      return;
    }

    var lroCentroid = this.lroPolygons[0].centroid;
    var lroBounds = new google.maps.LatLngBounds();

    this.lroPolygons.forEach(lroPolygon => {

      //TODO: change styling
      const lroPolygonToRender = new google.maps.Polygon({
        paths: lroPolygon.polygon,
        strokeColor: "#489BAE",
        strokeWeight: 3,
        fillColor: "#489BAE",
        fillOpacity: 0.0,
        clickable: false
      });

      lroPolygonToRender.setMap(this.gMap);
      this.renderedLroPolygons.push(lroPolygonToRender);

      lroPolygon.polygon.forEach((point: any) => {
        var latlng = new google.maps.LatLng(point.lat, point.lng);
        lroBounds.extend(latlng);
      })
    })

    if (this.gMap && focusLroOnMap) {
      this.gMap.setCenter(new google.maps.LatLng(lroCentroid.lat, lroCentroid.lng));
      this.gMap.fitBounds(lroBounds);
      this.gMap.panTo(this.getMapCenter());
    }
  }

  renderSubjectPropertyMapMarker = (subjectProperty: PropertyDetail) => {
    this.renderOmnibarSearchResultsMarkers(AutoSuggestSearchMode.GET_RECENTLY_VIEWED_SUGGESTED_RESULT, subjectProperty);
  }

  renderOmnibarSearchResultsMarkers(searchMode: AutoSuggestSearchMode, propertyDetail: PropertyDetail) {
    if (searchMode == AutoSuggestSearchMode.WILDCARD_SEARCH || searchMode == AutoSuggestSearchMode.GET_RECENTLY_SEARCHED_SUGGESTED_RESULT) {
      this.renderWildCardPropertyMarkers(propertyDetail);
    }

    if (searchMode == AutoSuggestSearchMode.GET_RECENTLY_VIEWED_SUGGESTED_RESULT) {
      this.renderRecentlyViewedPropertyMarkers(propertyDetail);
    }
  }

  private renderComparableSalePIIMarker = async (pii: Pii, markerInitiallyVisible: boolean, markerBounds: google.maps.LatLngBounds) => {
    try {
      if (this.piiService.isCondoUnit(pii) || this.piiService.isCondoBlock(pii)) {  //note: for comparables sales results, the condo block is not returned by the backend
        if (this.isCondoBlockMarkerRendered(pii, this.getSearchComparableMarkers())) {
          this.loggerService.logDebug(`ignoring search comparable condo pin ${pii.pin} as the condo block marker is already on the map`);
          return;
        } else {
          let block: string = this.piiService.getCondoBlockNumber(pii.pin);
          let results: SearchResult = await lastValueFrom(this.omnibarSearchService.getPropertiesByBlock(block, 20, 0), {defaultValue: new SearchResult()});
          if (results) {
            //overwrite the condo unit pii with the condo block pii
            pii = results.searchResult[0];
            this.loggerService.logDebug(`rendering condo block marker for search comparable condo pin ${pii.pin}, block pin ${pii.pinXy?.spatialPin}`);
          }
        }
      }

      let piiMarker = this.renderPIIPropertyMarker(pii, false, false, undefined, undefined, undefined, false, true, markerInitiallyVisible);

      if (piiMarker && piiMarker.getPosition()) {
        markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
      }
    } catch (e) {
      this.loggerService.logError(`error in rendering search comparables marker for ${(this.piiService.isCondoUnit(pii)? 'block pin ' + this.piiService.getCondoBlockPin(pii.pin) : 'pin ' + pii.pin)}`, e);
    }
  }

  private showRenderMarkerProgress = (latLng: google.maps.LatLng, progressPercentage: string) => {

    this.renderMarkerProgressInfoBox = new InfoBox({
      content: this.getRenderMarkerProgressHtml(progressPercentage),
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(15, -30),
      zIndex: null,
      boxStyle: {
        background: null,
        opacity: 1,
        width: "0px"
      },
      position: latLng,
      closeBoxMargin: "10px 2px 2px 2px",
      closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: "floatPane",
      enableEventPropagation: false
    });
    this.renderMarkerProgressInfoBox.open(this.getMap());
  }

  private getRenderMarkerProgressHtml = (progressPercentage: string) => {
    let htmlContent =
    '<div class="search-comps-polygon-in-progress-instruction-box">' +
      '<span class="left"></span>' +
      '<span class="right">Loading&nbsp;property&nbsp;markers...&nbsp;' + progressPercentage + '</span>' +
    '</div>' +
    '<div class="trianglestylearn" style="display:none">' +
      '<img src="/assets/img/down-arrow-box.png" />' +
    '</div>';

    return htmlContent;
  }

  private startRenderMarkerProgress = (searchCenter: google.maps.LatLng, progressPercentage: string) => {
    this.showRenderMarkerProgress(searchCenter, progressPercentage);
  }

  private incrementRenderMarkerProgress = (current: number, total: number) => {
    let progressPercentage = `${Math.floor((current! / total!) * 100)}%`;
    this.renderMarkerProgressInfoBox.setContent(this.getRenderMarkerProgressHtml(progressPercentage));
  }

  private endRenderMarkerProgress = () => {
    setTimeout(() => {
      this.renderMarkerProgressInfoBox.close();
      this.renderMarkerProgressInfoBox.setMap(null);
    }, 1500);
  }

  private interruptRenderMarkerProgress = (current: number, total: number) => {
    let progressPercentage = `${Math.floor((current! / total!) * 100)}% (interrupted)`;
    this.renderMarkerProgressInfoBox.setContent(this.getRenderMarkerProgressHtml(progressPercentage));
  }

  private isAbortRenderComparableSalesMarkers = () => {
    let abort: string | null = localStorage.getItem(LocalStorageKey.comparablesSalesAbortRenderMarkers);
    return (abort && abort === 'true');
  }

  private renderComparableSalesPIIMarkers = async (piiList: Pii[], selectedSalesPins: string[], searchCenter: google.maps.LatLng) => {
    let markerBounds = new google.maps.LatLngBounds();

    try {
      this.loggerService.logDebug(`creating ${piiList?.length} search comparables markers`);
      this.loggerService.logDebug(`displaying markers for ${selectedSalesPins?.length} search comparables properties`);

      if (piiList?.length > 0) {
        this.startRenderMarkerProgress(searchCenter, '0%');
      }

      localStorage.setItem(LocalStorageKey.comparablesSalesAbortRenderMarkers, 'false');
      
      //we cannot use forEach or map to iterate the pii list here since we need to make extra synchronous calls inside the loop
      let ctr: number = 0;
      for await (const pii of piiList) {

        if (this.isAbortRenderComparableSalesMarkers()) {
          this.loggerService.logDebug(`search comparables markers rendering aborted`);
          //this.interruptRenderMarkerProgress(++ctr, piiList.length);
          this.clearSearchComparableMarkers();
          break;
        }

        let pin: string = pii.pin;
        if (this.piiService.isCondoBlock(pii) || this.piiService.isCondoUnit(pii)) {
          if (pii.pinXy?.spatialPin) {
            pin = pii.pinXy?.spatialPin;
          } else {
            pin = this.piiService.getCondoBlockPin(pii.pin);
          }
        }

        let markerInitiallyVisible: boolean = selectedSalesPins.includes(pin);

        await this.renderComparableSalePIIMarker(pii, markerInitiallyVisible, markerBounds).then(() => {
          this.incrementRenderMarkerProgress(++ctr, piiList.length);
        });
      }

    } catch (e) {
      this.loggerService.logError(`error displaying the search comparables pii markers`, e);
      throw e;

    } finally {
      this.endRenderMarkerProgress();
    }
  }

  async renderComparableSalesMarkers(sales: ComparableSale[], searchCenter: google.maps.LatLng) {
    this.clearSearchComparableMarkers();

    try {
      //bulk retrieve pii
      let piiRequest: PIIRequest = new PIIRequest();
      piiRequest.pins = sales.map(sale => sale.pin);

      let selectedSalesPins: string[] = sales.filter(sale => sale.selected).map(sale => this.searchComparablesResultService.getSaleMarkerPin(sale));

      let piiList: Pii[] = await lastValueFrom(this.piiService.getPiiList(piiRequest));
      this.renderComparableSalesPIIMarkers(piiList, selectedSalesPins, searchCenter);

    } catch (e) {
      this.loggerService.logError(`error displaying the search comparables markers`, e);
      this.openSnackBarError(ErrorUtil.SEARCH_COMPARABLES_RESULT_MARKERS_ERROR);
    }
  }

  renderOmnibarSearchBlockPolygon = async (block: string) => {
    this.clearOmnibarSearchBlockPolygons();

    this.loggerService.logDebug(`rendering polygon for condo block ${block}`);
    let blockPolygons: Spatial[] = await lastValueFrom(this.spatialServiceService.getPolygonsByBlock(block));

    let polygonBounds = new google.maps.LatLngBounds();

    blockPolygons.forEach(blockPolygon => {

      let polygonPaths: google.maps.LatLng[] = [];
      blockPolygon.polygon.forEach((polygon: any) => {
        var latlng = new google.maps.LatLng(polygon.latitude, polygon.longitude);
        polygonPaths.push(latlng);
        polygonBounds.extend(latlng);
      })

      //TODO: change styling
      const blockPolygonToRender = new google.maps.Polygon({
        paths: polygonPaths,
        strokeColor: "#61A5F2",
        strokeWeight: 3,
        fillColor: "#61A5F2",
        fillOpacity: 0.0,
        clickable: false
      });

      blockPolygonToRender.setMap(this.gMap);
      this.renderedOmnibarSearchBlockPolygons.push(blockPolygonToRender);
    })

    if (!polygonBounds.isEmpty()) {
      this.fitBounds(polygonBounds, true);
      this.gMap?.panTo(this.getMapCenter());
    }
  }

  isCondoBlockSearchResults = (searchResults: any): boolean | undefined => {
    let isCondo: boolean = searchResults?.searchResult?.[0]?.pinXy?.condo;
    this.loggerService.logDebug(`block ${searchResults?.searchResult?.[0]?.pin} condo? ${isCondo}`);
    return (isCondo);
  }

  isCondoBlockMarkerRendered = (pii: any, markerStorage: google.maps.Marker[]) => {
    let condoBlockPin: string = this.piiService.getCondoBlockPin(pii.pin);
    let condoBlockMarkerFound: boolean = this.isMarkerRenderedForPin(condoBlockPin, markerStorage);

    this.loggerService.logDebug(`marker for condo block ${condoBlockPin} found? ${condoBlockMarkerFound}`);
    return condoBlockMarkerFound;
  }

  private renderWildCardPIIMarker = async (pii: Pii, markerBounds: google.maps.LatLngBounds, condoUnitSearch: boolean) => {
    if (this.piiService.isCondoPin(pii) || this.piiService.isCondoBlock(pii)) {
      if (this.isCondoBlockMarkerRendered(pii, this.getRenderedMarkers())) {
        this.loggerService.logDebug(`ignoring condo pin ${pii.pin} as the condo block marker is already on the map`);
        return;
      } else {
        if (!condoUnitSearch) {
          //the backend will always return the block pii before the unit piis
          let block: string = this.piiService.getCondoBlockNumber(pii.pin);
          let results: SearchResult = await lastValueFrom(this.omnibarSearchService.getPropertiesByBlock(block, 20, 0), {defaultValue: new SearchResult()});
          if (results) {
            //overwrite the condo unit pii with the condo block pii
            pii = results.searchResult[0];
            this.loggerService.logDebug(`rendering block marker and polygon for condo pin ${pii.pin}, block pin ${pii.pinXy?.spatialPin}`);
          }
        }
      }

      setTimeout(() => {
        this.renderOmnibarSearchBlockPolygon(this.piiService.getCondoBlockPin(pii.pin));
      }, 200);
    }

    let piiMarker = this.renderPIIPropertyMarker(pii, false, false);

    if (piiMarker && piiMarker.getPosition()) {
      markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
    }
  }

  private renderWildCardPropertyMarkers = async (searchResults: any) => {

    this.clearAllRenderedMapObjects();

    switch (searchResults.activityType) {
      case "NAME_SEARCH":
        this.renderOwnerPropertyMarkers(searchResults, true);
        break;

      default:

        let markerBounds = new google.maps.LatLngBounds();

        let condoUnitSearch: boolean = false;
        if (!_.isEmpty(searchResults?.searchResult) && searchResults?.searchResult.length == 1) {
          let pii: Pii = searchResults?.searchResult[0];
          let isCondoUnit: boolean | undefined = this.piiService.isCondoPin(pii);
          if (isCondoUnit) {
            condoUnitSearch = true;
          }
        }
        this.loggerService.logDebug(`condo unit search? ${condoUnitSearch}`);

        //we cannot use forEach or map to iterate the pii list here since we need to make extra synchronous calls inside the loop
        for await (const pii of searchResults?.searchResult) {
          await this.renderWildCardPIIMarker(pii, markerBounds, condoUnitSearch);
        }

        if (!markerBounds.isEmpty()) {
          this.gMap.fitBounds(markerBounds);

          if (this.getRenderedMarkers().length == 1 && this.renderedOmnibarSearchBlockPolygons.length == 0) {
            this.gMap.setZoom(DataService.AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
          }
        }

        break;
    }

  }

  public fitLastPinBounds = () => {
    this.fitBounds(this.pinBounds, false);
  }

  private renderRecentlyViewedPropertyMarkers = (propertyDetail: PropertyDetail) => {

    this.clearAllExceptRenderedMapObjectsForScreen(ScreenNameEnum.PROPERTY_REPORT);

    let markerBounds = new google.maps.LatLngBounds();
    if (propertyDetail?.pii) {
      const spatialDetails: Spatial[] = propertyDetail.pii?.spatial?.length ? propertyDetail.pii.spatial : propertyDetail.assessments[0]?.spatial;
      spatialDetails?.forEach((spatial: any) => {
        this.pinBounds = new google.maps.LatLngBounds();  //reset

        let polygonPaths: google.maps.LatLng[] = [];
        spatial.polygon.forEach((polygon: any) => {
          var latlng = new google.maps.LatLng(polygon.latitude, polygon.longitude);
          polygonPaths.push(latlng);
          this.pinBounds.extend(latlng);
        })

        setTimeout(() => {
          const pinPolygonToRender = new google.maps.Polygon({
            paths: polygonPaths,
            strokeColor: "#61A5F2",
            strokeWeight: 3,
            fillColor: "#61A5F2",
            fillOpacity: 0.0,
            clickable: false
          });
          pinPolygonToRender.setMap(this.gMap);
          this.renderedOmnibarSearchResultsPolygons.push(pinPolygonToRender);

          this.fitLastPinBounds();
          this.setMapZoomLevel(DataService.RECENT_PROPERTY_DEFAULT_ZOOM_LEVEL);
        }, 200);

        setTimeout(() => {
          let piiMarker = this.renderPIIPropertyMarker(propertyDetail.pii, false, false);
          if (piiMarker) {
            if (piiMarker.getPosition()) {
              markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
              this.gMap.setCenter(<google.maps.LatLng>piiMarker.getPosition());
            }
          }
        }, 200);

      });
    }
  }

  async getSignedStaticStreetViewThumbnailImageUrl(pin: string, width: number, height: number) {
    let signedUrl: string = '';

    let response = await lastValueFrom(this.comparablesService.getStaticStreetView(pin, width, height, 'STREET_VIEW', 240, DataService.NO_STREETVIEW_IMAGE));
    if (response) {
        signedUrl = response;
      }
    return signedUrl;
  }

  displayPropertyInfoBubbleByClickLatLng = async (latLng: google.maps.LatLng) => {
    let tempMarker = null;

    try {
      tempMarker = this.renderTempPIIPropertyMarker(latLng);

      var pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(latLng.lat(), latLng.lng()), {defaultValue: new Pii()});

      //get and display the condo building info if the user happened to click on a condo unit
      //if (pii && this.piiService.isCondo(pii) && this.piiService.isCondoPin(pii)) {
      if (pii) {
        this.loggerService.logDebug(`user clicked on a condo? ${this.piiService.isCondo(pii)} lat[${latLng.lat()}] lng[${latLng.lng()}]`);

        if (this.piiService.isCondoBlock(pii) || this.piiService.isCondoUnit(pii)) {
          let block: string = this.piiService.getCondoBlockNumber(pii.pin);
          let results: SearchResult = await lastValueFrom(this.omnibarSearchService.getPropertiesByBlock(block, 20, 0), {defaultValue: new SearchResult()});
          if (results) {
            pii = results.searchResult[0];
            this.loggerService.logDebug(`found condo building info? ${pii != null}`);
          }
        }
      }

      let arnFromLatLng: string | undefined;
      if (pii?.arns?.length == 0) {
        try {
          arnFromLatLng = await lastValueFrom(this.spatialServiceService.getARNByLatLng(latLng.lat(), latLng.lng()), {defaultValue: {}});
        } catch (e) {
          //a missing ARN is not a critical error when displaying the property info bubble
        }
      }

      let staticStreetViewThumbnailImageUrl: string = await this.getSignedStaticStreetViewThumbnailImageUrl(pii.pin, 130, 100);
      this.loggerService.logDebug(`static streetview image url for pin ${pii.pin}: ${staticStreetViewThumbnailImageUrl}`);

      //close the info bubble that might already be opened at this location
      this.closeMarkerBubbleByPin(pii.pin);

      this.renderPIIPropertyMarker(pii, false, true, latLng, arnFromLatLng, staticStreetViewThumbnailImageUrl, true);

      if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
        this.handleMapClickedForSearchComparables(latLng);
      }

    } catch (e) {
      this.loggerService.logWarning(`error displaying property info bubble at latitude ${latLng.lat()}, longitude ${latLng.lng()}`, e);

    } finally {
      tempMarker?.setMap(null);
    }
  }

  /**
   * Provides a fast visual cue to the user that the map has been clicked and data is being fetched
   *
   * @param latLng
   * @returns
   */
  renderTempPIIPropertyMarker = (latLng: google.maps.LatLng): google.maps.Marker => {
    let marker: google.maps.Marker = new google.maps.Marker({});
    let defaultMarkerIcon = {
      url: this.tempMarkerIcon,
      scaledSize: this.searchResultDefaultMarkerSize
    }

    marker = new google.maps.Marker({
      map: this.gMap,
      position: latLng,
      icon: defaultMarkerIcon
    });

    return marker;
  }

  defaultMarkerIcon = {
    url: this.searchResultDefaultMarkerIcon,
    scaledSize: this.searchResultDefaultMarkerSize
  }

  selectedMarkerIcon = {
    url: this.searchResultSelectedMarkerIcon,
    scaledSize: this.searchResultSelectedMarkerSize
  }

  /**
   * Draws a property map marker on the map.
   *
   * @param pii
   * @param clearMap
   * @param showInfoBubble
   * @param mapLatLng
   * @param arnFromLatLng
   * @param staticStreetViewThumbnailImageUrl
   * @param clickMarker True when drawing a map marker from a map click event, as opposed to drawing markers from the search result; otherwise false.
   * @param searchComparableMarker True when drawing a marker from search comparables; otherwise false.
   * @param searchComparableMarkerVisible True when the search comparables marker is initially visible; otherwise false.
   * @returns
   */
  renderPIIPropertyMarker = (pii: Pii, clearMap: boolean, showInfoBubble: boolean, mapLatLng?: google.maps.LatLng, arnFromLatLng?: string, staticStreetViewThumbnailImageUrl?: string, clickMarker?: boolean, searchComparableMarker?: boolean, searchComparableMarkerVisible?: boolean) => {

    if (typeof pii == 'undefined') {
      this.loggerService.logWarning(`failed to render property marker due to missing pii`);
      return null;
    }

    if (clearMap) {
      this.clearAllRenderedMapObjects();
    }

    let marker: google.maps.Marker = new google.maps.Marker({});

    if (searchComparableMarker) {
      marker = new google.maps.Marker({
        map: (searchComparableMarkerVisible)? this.gMap : null,
        icon: this.defaultMarkerIcon
      });
    } else {
      marker = new google.maps.Marker({
        map: this.gMap,
        icon: this.defaultMarkerIcon
      });
    }

    let markerLocation: google.maps.LatLng;

    if (pii?.pinXy?.centroid) {
      markerLocation = new google.maps.LatLng(pii.pinXy.centroid.latitude, pii.pinXy.centroid.longitude);

    } else if (mapLatLng) {
      this.loggerService.logDebug(`pii spatial info is missing, taking marker location from provided lat/lng ${mapLatLng.toString()}`);
      markerLocation = mapLatLng;
    }

    //@ts-ignore
    if (typeof markerLocation == 'undefined') {
      this.loggerService.logWarning(`unable to render ${searchComparableMarker? 'search comparable' : ''} marker (${pii?.address?.fullAddress}) due to missing lat/lng`);
      return null;
    }

    let pinIdentifier: string | undefined = pii?.pin;

    if (this.piiService.isCondoBlock(pii) || this.piiService.isCondoUnit(pii)) {
      if (pii.pinXy?.spatialPin) {
        pinIdentifier = pii.pinXy?.spatialPin;
      } else {
        pinIdentifier = this.piiService.getCondoBlockPin(pii.pin);
      }
    }

    let markerId: string = markerLocation.toString() + '-' + pinIdentifier;

    let existingMarker: google.maps.Marker | undefined = this.getRenderedMarkers().find(m => {
      return m.getPosition()?.toString() == markerLocation.toString();
    });

    if (!existingMarker) {
      marker.setPosition(markerLocation);
    } else {
      this.loggerService.logDebug(`marker at location ${markerLocation} already rendered`);
    }

    marker.set('markerId', markerId);
    marker.set('markerPin', pinIdentifier);
    marker.set('markerPosition', markerLocation);

    //for condos, pii?.pin is either the 5-digit block number for condo buildings or lots ('isCondoBuilding' attribute) or
    //the 9-digits pin for condo units ('isCondoUnit' attribute)
    marker.set('pin', pii?.pin);
    marker.set('isPiiOnDemand', (pii.onDemandPii !== undefined && pii.onDemandPii)? true : false);
    marker.set('isCondo', this.piiService.isCondo(pii));
    marker.set('isCondoBuilding', this.piiService.isCondoBuilding(pii));
    marker.set('isCondoUnit', this.piiService.isCondoUnit(pii));
    marker.set('condoUnitPin', pii?.pin);
    marker.set('condoBuildingBlockNumber', this.piiService.getCondoBlockNumber(pii?.pin));
    marker.set('isSearchComparableMarker', searchComparableMarker);
    marker.set('isMouseOverRequestFromSearchResults', false);

    if (this.piiService.isCondo(pii)) {
      if (clickMarker) {
        marker.set('address', pii?.address?.streetAddress);
      } else {
        marker.set('address', pii?.address?.fullAddress? pii.address.fullAddress : '');
      }
    } else {
      marker.set('address', pii?.address?.fullAddress? pii.address.fullAddress : '');
    }

    this.loggerService.logDebug(`address at marker location ${markerLocation}: ${marker.get('address')}`);

    let markerBubble: google.maps.InfoWindow = new google.maps.InfoWindow();
    if (pii?.pinXy?.centroid) {
      markerBubble = this.infoBubbleContentService.getMarkerBubble(marker, pii, new google.maps.LatLng(pii?.pinXy?.centroid?.latitude, pii?.pinXy?.centroid?.longitude), arnFromLatLng, staticStreetViewThumbnailImageUrl);
    } else if (mapLatLng) {
      markerBubble = this.infoBubbleContentService.getMarkerBubble(marker, pii, mapLatLng, arnFromLatLng, staticStreetViewThumbnailImageUrl);
    }
    
    this.loggerService.logDebug(`saving info bubble for marker id ${markerId}`);
    
    markerBubble.set('isSearchComparableMarkerBubble', searchComparableMarker);
    this.renderedMarkerInfoBubbles.set(markerId, new PushPinMarkerBubble(pinIdentifier!, markerBubble));

    marker.addListener("mouseover", async () => {
      //todo: just a workaround to remove the ugly black border appearing around the info bubble, but this isn't always working
      //var bubbleCloseBtn = $(".gm-ui-hover-effect");
      //bubbleCloseBtn.focus();

      setTimeout(() => {
        this.PIIPropertyMarkerActionHandler(marker);
      }, 200);
    });

    marker.addListener("click", async () => {
      this.PIIPropertyMarkerActionHandler(marker);
    });

    marker.addListener("mouseout", () => {
      marker.setIcon(this.defaultMarkerIcon);
    });

    if (clickMarker) {
      this.clearClickMarkers();
      this.clearClickPolygons();
      this.clickMarkers.push(marker);

      setTimeout(() => {
        this.renderClickPolygon(marker.getPosition());
      }, 100);
    } else {
      if (searchComparableMarker) {
        this.getSearchComparableMarkers().push(marker);
      } else {
        this.getRenderedMarkers().push(marker);
      }
    }

    if (showInfoBubble) {
      //@ts-ignore
      let pushPinMarkerBubble: PushPinMarkerBubble = <PushPinMarkerBubble>this.renderedMarkerInfoBubbles.get(markerId);
      let markerBubble: google.maps.InfoWindow = pushPinMarkerBubble.bubble;
      if (markerBubble) {
        //@ts-ignore
        this.loggerService.logDebug(`opening info bubble at ${markerLocation.toString()}`);
        google.maps.event.trigger(marker, 'mouseover');
      }
    }

    return marker;
  }

  private PIIPropertyMarkerActionHandler(marker: google.maps.Marker) {
    marker.setIcon(this.selectedMarkerIcon);

    let markerPosition: google.maps.LatLng = marker.get('markerPosition');
    let pushPinMarkerBubble: PushPinMarkerBubble = <PushPinMarkerBubble>this.renderedMarkerInfoBubbles.get(marker.get('markerId'));

    let markerBubble: google.maps.InfoWindow = pushPinMarkerBubble.bubble;
    let markerBubbleIdentifier = pushPinMarkerBubble.identifier;

    if (!this.currentMarkerInfoBubble) {
      this.currentMarkerInfoBubble = new PushPinMarkerBubble(markerBubbleIdentifier, markerBubble);
    }

    //this.loggerService.logDebug('this.currentMarkerInfoBubble:'+this.currentMarkerInfoBubble + "  and markerBubbleIdentifier="+markerBubbleIdentifier);
    if (this.currentMarkerInfoBubble?.bubble && (this.currentMarkerInfoBubble?.bubble.getPosition()?.toString() != markerPosition.toString() || this.currentMarkerInfoBubble?.identifier != markerBubbleIdentifier)) {
      this.currentMarkerInfoBubble.bubble.close();   //close the bubble that belongs to the previous marker
    }

    this.currentMarkerInfoBubble.bubble = markerBubble;
    this.currentMarkerInfoBubble.identifier = markerBubbleIdentifier;
    this.currentMarkerInfoBubble.bubble.setPosition(markerPosition);
    //this.loggerService.logDebug('after this.currentMarkerInfoBubble='+this.currentMarkerInfoBubble);

    this.displayMarkerBubbleOnMouseOver(markerBubbleIdentifier);

    //todo - to complete this implementation
    //this._markerMouseOverEvent.next(marker);  //any other component can subscribe to this event to get the marker that is being hovered over
  }

  getAlternativeMarkerCentroid = (block: string): Spatial | null => {
    this.getBlockCentroid(block).then((spatial) => {
      return spatial;
    });

    return null;
  }

  private getBlockCentroid = async (block: string): Promise<Spatial> => {
    let spatial: Spatial = await lastValueFrom(this.spatialServiceService.getBlockCentroid(block));
    return spatial;
  }

  getAlternativePiiForMarkerControid = (spatial: Spatial): Pii | null => {
    this.getBlockCentroidPii(spatial).then((pii) => {
      return pii;
    });

    return null;
  }

  private getBlockCentroidPii = async (spatial: Spatial): Promise<Pii> => {
    let pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(spatial?.centroid?.latitude, spatial?.centroid?.longitude));
    return pii;
  }

  renderClickPolygon = async (markerPosition: google.maps.LatLng | null | undefined) => {
    if (!markerPosition) return;

    let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(markerPosition!.lat(), markerPosition!.lng()), {defaultValue: new Pii()});
    this.renderPIIPolygon(pii, this.clickPolygons);
  }

  renderPIIPolygon = (pii: Pii, polygonStorage: any[])=> {
    if (pii) {
      pii.spatial?.forEach((spatial: any) => {

        let polygonPaths: google.maps.LatLng[] = [];
        spatial.polygon.forEach((polygon: any) => {
          var latlng = new google.maps.LatLng(polygon.latitude, polygon.longitude);
          polygonPaths.push(latlng);
        })

        setTimeout(() => {
          const pinPolygonToRender = new google.maps.Polygon({
            paths: polygonPaths,
            strokeColor: colourPalette.sunrise,
            strokeWeight: 3,
            fillColor: colourPalette.sunrise,
            fillOpacity: 0.0,
            clickable: false
          });
          pinPolygonToRender.setMap(this.gMap);

          if (polygonStorage) {
            polygonStorage.push(pinPolygonToRender);
          }
        }, 200);

      });
    }
  }

  private displayMarkerBubbleOnMouseOver = async (markerBubbleIdentifier: string) => {
    let isSearchComparableMarkerBubble = this.currentMarkerInfoBubble?.bubble.get('isSearchComparableMarkerBubble');

    let contentDomElement: Node = this.currentMarkerInfoBubble?.bubble?.getContent() as Node;
    //@ts-ignore
    let bubbleContainer = contentDomElement.firstChild; //class='pii-bubble-container'
    let childNodes = bubbleContainer?.childNodes;
    let addressNode = childNodes?.[0].childNodes?.[1].firstChild; //class='address'
    let addressValue = addressNode?.textContent;
    let bodyNode = childNodes?.[1]; //class='body'
    let bodyChildren = bodyNode?.childNodes;
    //let streetViewThumbnailContainer = bodyNode?.firstChild;
    //let streetViewThumbnailImage = streetViewThumbnailContainer?.firstChild;
    let streetViewThumbnailImage = bodyChildren?.[0].firstChild;
    let propertyDetailsContainer = bodyChildren?.[1];  //class='property-details'
    let propertyDetailsChildren = propertyDetailsContainer?.childNodes;
    let pinArnContainer1 = propertyDetailsChildren?.[1];
    let pinContainer1 = pinArnContainer1?.firstChild;  //class='pin-container'
    let arnContainer1 = pinArnContainer1?.lastChild; //class='arn-container'

    let pin1 = pinContainer1?.childNodes?.[1];
    let arn1 = arnContainer1?.childNodes?.[1];

    //@ts-ignore
    let pin1Value = pin1?.innerHTML;
    //@ts-ignore
    let arn1Value = arn1?.innerHTML;

    let linksContainer1 = propertyDetailsChildren?.[2]; //class='links-container'
    let streetViewNode1 = linksContainer1?.firstChild?.lastChild;
    let propertyReportNode1 = linksContainer1?.lastChild?.lastChild;

    let pinArnContainer3 = propertyDetailsChildren?.[3];
    let arnContainer2 = pinArnContainer3?.lastChild; //class='arn-container'

    let arn2 = arnContainer2?.childNodes?.[1];

    //@ts-ignore
    let arn2Value = arn2?.innerHTML;

    let linksContainer2 = propertyDetailsChildren?.[4]; //class='links-container'
    let propertyReportNode2 = linksContainer2?.lastChild?.lastChild;

    streetViewThumbnailImage?.addEventListener('click',
      () => {
        if (pin1Value != null && this.dataService.isPin(pin1Value)) {
          this.openStreetViewForPin(pin1Value);
        }
      });

    streetViewNode1?.addEventListener('click',
      () => {
        if (pin1Value != null){
          if (this.dataService.isPin(pin1Value)) {
            this.openStreetViewForPin(pin1Value);
          } else if (this.dataService.isBlock(pin1Value)) {
            this.openStreetViewForPin(pin1Value + '0001');
          }
        }
      });

    propertyReportNode1?.addEventListener('click',
      () => {
        if (pin1Value != null && pin1Value != DataService.NOT_APPLICABLE) {
          if (this.dataService.isPin(pin1Value)) {
            this.loggerService.logDebug(`opening property report by pin ${pin1Value}`);

            if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT) && this.propertyReportService.getSubjectPropertyPin() == pin1Value) {
              this.loggerService.logDebug(`property report for pin ${pin1Value} is already open`);
              setTimeout(() => {
                this.propertyReportService.scrollToTopOfPage();
              }, 200);
              return;
            }

            this.openPropertyReportByPin(pin1Value, true);

          } else if (this.dataService.isBlock(pin1Value)) {
            this.screenManager.showScreen(ScreenNameEnum.OMNIBAR_CONDO_SEARCH_RESULTS);
            this.openCondoSearch(new CondoSummary(pin1Value, addressValue));

          } else {
            this.loggerService.logDebug(`map bubble pin ${pin1Value} is invalid`);
          }

        } else {
          if (arn1Value != null && this.dataService.isArn(arn1Value)) {
            this.loggerService.logDebug(`opening property report by arn ${arn1Value}`);
  
            if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT) && this.propertyReportService.getSubjectProperty().hasAssessment(arn1Value)) {
              this.loggerService.logDebug(`property report for arn ${arn1Value} is already open`);
              setTimeout(() => {
                this.propertyReportService.scrollToTopOfPage();
              }, 200);
              return;
            }
  
            this.openPropertyReportByArn(arn1Value, true);
          }
        }
      });

    propertyReportNode2?.addEventListener('click',
      () => {
        if (arn2Value != null && this.dataService.isArn(arn2Value)) {
          this.loggerService.logDebug(`opening property report by arn ${arn2Value}`);
          this.openPropertyReportByArn(arn2Value, true);
        }
      });

    //@ts-ignore
    streetViewThumbnailImage.src = DataService.NO_STREETVIEW_IMAGE;

    if (this.userAccessControls.googleStreetViewAccess) {
      let response = await lastValueFrom(this.comparablesService.getStaticStreetView(markerBubbleIdentifier, 130, 100, 'STREET_VIEW', 240));
      if (response) {
        //@ts-ignore
        streetViewThumbnailImage.src = response;
      }
    }

    //set auto pan map setting for the info bubble, but only for the comparables search results markers
    let autoPanMapForSearchComparablesResultStr: string | null = localStorage.getItem(LocalStorageKey.comparablesSalesResultsAutoPanMap);
    if (autoPanMapForSearchComparablesResultStr && isSearchComparableMarkerBubble) {
      if (autoPanMapForSearchComparablesResultStr == 'true') {
        this.currentMarkerInfoBubble.bubble.setOptions({disableAutoPan: false});
      } else {
        this.currentMarkerInfoBubble.bubble.setOptions({disableAutoPan: true});
      }
    } else {
      this.currentMarkerInfoBubble.bubble.setOptions({disableAutoPan: false});  //make the search result marker info bubble always fully visible
    }

    this.currentMarkerInfoBubble?.bubble?.open(this.gMap);
  }

  isStreetViewAvailableForPin = async (pin: string): Promise<boolean> => {
    let isStreetViewAvailable: boolean = false;
    const streetViewPoint: StreetViewPoint = await lastValueFrom(this.propertyReportService.getStreetViewPoint(pin));
    if (streetViewPoint?.centroid) {
      await this.streetViewService.isStreetViewServiceAvailable(streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude)
        .then(
          (isAvailable) => {
            isStreetViewAvailable = isAvailable;
          }
        )
        .catch(err => {
          this.loggerService.logError(`Could not retrieve streetview for lat=${streetViewPoint.centroid.latitude} and lng=${streetViewPoint.centroid.longitude}`);
        })
    }
    return isStreetViewAvailable;
  }

  openStreetViewForPin = async (pin: string) => {
    this.exitFullScreenMap();

    this.gaService.sendEvent(GA_Event.MAIN_MAP, 'Open Street View', 'By Pin');
    this.loggerService.logDebug(`switching map to streetview for pin ${pin}`);
    const streetViewPoint: StreetViewPoint = await lastValueFrom(this.propertyReportService.getStreetViewPoint(pin));
    if (streetViewPoint?.centroid) {
      await this.streetViewService.isStreetViewServiceAvailable(streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude)
        .then(
          (isAvailable) => {
            if (isAvailable) {
              this.currentMarkerInfoBubble?.bubble?.close();
              this.propertyReportService.requestPropertyStreetView(streetViewPoint);
            } else {
              this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
            }
          }
        )
        .catch(err => {
          this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
        })
    } else {
      this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
    }
  }

  openPropertyReportByPin = async (pin: string, clearMapObjectsWhenOpeningPropertyReport: boolean) => {
    this.gaService.openSection(GA_Page.PROPERTY_DETAILS_PAGE, GA_Page.PROPERTY_DETAILS_PAGE_LABEL + '-' + pin);

    if (clearMapObjectsWhenOpeningPropertyReport) {
      this.clearAllRenderedMapObjects();
    }
    this.propertyReportService.showPropertyReportByPinDeferData(pin);
  }

  openPropertyReportByArn = async (arn: string, clearMapObjectsWhenOpeningPropertyReport: boolean) => {
    this.gaService.openSection(GA_Page.PROPERTY_DETAILS_PAGE, GA_Page.PROPERTY_DETAILS_PAGE_LABEL + '-' + arn);
    
    if (clearMapObjectsWhenOpeningPropertyReport) {
      this.clearAllRenderedMapObjects();
    }
    this.propertyReportService.showPropertyReportByPinDeferData(arn);
  }

  openCondoSearch = async (condoSummary: CondoSummary) => {
    //this.clearAllRenderedMapObjects();
    await lastValueFrom(this.omnibarSearchService.getCondoLevelsCounterByBlock(condoSummary));
  }

  renderPiiMarkers = (piiList: Pii[], clearMap: boolean) => {
    if (clearMap) {
      this.clearRenderedMarkers();
      this.clearRenderedMarkerInfoBubbles();
    }
    let markerBounds = new google.maps.LatLngBounds();
    if (piiList) {
      piiList.forEach(pii => {
        const marker = this.renderPIIPropertyMarker(pii, false, false);
        if (marker?.getPosition()) {
          markerBounds.extend(<google.maps.LatLng>marker.getPosition());
        }
      });
      if (!markerBounds.isEmpty()) {
        this.gMap.fitBounds(markerBounds);
      }
    }
  }

  renderOwnerPropertyMarkers = (searchResults: SearchResult, clearMap: boolean) => {
    let owners: OwnerToPropertyMap[] | undefined = searchResults?.ownerToPropertyMap;
    this.renderOwnersPropertyMarkers(owners, clearMap);
  }

  renderOwnersPropertyMarkers = (owners: OwnerToPropertyMap[] , clearMap: boolean) => {

    if (_.isEmpty(owners)) {
      this.loggerService.logDebug('search by owner results has no owners');
    }

    if (clearMap) this.clearAllRenderedMapObjects();

    let markerBounds = new google.maps.LatLngBounds();

    if (owners instanceof Array) {
      owners.forEach(owner => {
        let ownerMapItems: OwnerToPropertyMapItem[] = owner.ownerToPropertyMapItems;
        if (ownerMapItems && ownerMapItems[0] && ownerMapItems[0]?.pii) {
          let piiMarker = this.renderPIIPropertyMarker(ownerMapItems[0].pii, false, false);
          if (piiMarker && piiMarker.getPosition()) {
            markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
          }
        } else {
          this.loggerService.logDebug(`unable to render property marker for ${owner.name}`);
        }
      })
    } else {
      let me = this;
      Object.keys(owners).forEach(function(ownerName) {
        //@ts-ignore
        let ownerMapItems: OwnerToPropertyMapItem[] = owners[ownerName];
        if (ownerMapItems && ownerMapItems[0] && ownerMapItems[0]?.pii) {
          let piiMarker = me.renderPIIPropertyMarker(ownerMapItems[0].pii, false, false);
          if (piiMarker && piiMarker.getPosition()) {
            markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
          }
        } else {
          me.loggerService.logDebug(`unable to render property marker for ${ownerMapItems[0].pin}`);
        }
      });
    }

    if (!markerBounds.isEmpty()) {
      this.gMap.fitBounds(markerBounds);

      if (this.getRenderedMarkers().length == 1) {
        this.gMap.setZoom(DataService.AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
      }
    }
  }

  renderOwnerItems = (ownerMapItems: OwnerToPropertyMapItem[], clearMap: boolean ) => {
    if (clearMap) {
      this.clearAllRenderedMapObjects();
    }
    let markerBounds = new google.maps.LatLngBounds();

    if (Array.isArray(ownerMapItems) && ownerMapItems.length) {
      ownerMapItems.filter(Boolean).forEach(omi => {
        let piiMarker = this.renderPIIPropertyMarker(omi.pii, false, false);
        if (piiMarker && piiMarker.getPosition()) {
          markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
        }
      });

      if (!markerBounds.isEmpty()) {
        this.gMap.fitBounds(markerBounds);
        if (this.getRenderedMarkers().length == 1) {
          this.gMap.setZoom(DataService.AUTOSUGGEST_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
        }
      }
    }
  }

  renderInstrumentPropertyMarkers = (searchResults: SearchResult) => {
    this.renderWildCardPropertyMarkers(searchResults);  //re-use
  }

  renderTownshipLotPolygonAndMarkers = async (polygon: any, searchResults: SearchResult) => {

    this.clearAllRenderedMapObjects();

    this.renderTownshipLotPolygon(polygon.spatialData?.[0]?.polygon);

    let markerBounds = new google.maps.LatLngBounds();

    searchResults?.spatialPropertyResult?.forEach((pii: any) => {

      let piiMarker = this.renderPIIPropertyMarker(pii, false, false);
      if (piiMarker && piiMarker.getPosition()) {
        markerBounds.extend(<google.maps.LatLng>piiMarker.getPosition());
      }
    })

    if (!markerBounds.isEmpty()) {
      this.fitBounds(markerBounds, false);
    }
  }

  renderTownshipLotPolygon = (polygon: any) => {

    this.clearAllRenderedMapObjects();

    let markerBounds = new google.maps.LatLngBounds();
    if (!_.isEmpty(polygon)) {
      var lotBounds = new google.maps.LatLngBounds();

      let polygonPaths: google.maps.LatLng[] = [];
      polygon.forEach((point: any) => {
        var latlng = new google.maps.LatLng(point.latitude, point.longitude);
        polygonPaths.push(latlng);
        lotBounds.extend(latlng);
      })

      //TODO: change styling
      const townshipPolygonToRender = new google.maps.Polygon({
        paths: polygonPaths,
        strokeColor: "#61A5F2",
        strokeWeight: 3,
        fillColor: "#61A5F2",
        fillOpacity: 0.0,
        clickable: false
      });
      townshipPolygonToRender.setMap(this.gMap);
      this.renderedTownshipLotPolygons.push(townshipPolygonToRender);

      this.fitBounds(lotBounds, false);
    }

  }

  renderMunicipalityPolygon = async (municipalityIds: string[]) => {
    let response: any = await lastValueFrom(this.municipalityService.getMunicipalityPolygons(municipalityIds));
    let responseValues: any[] = Object.values(response);

    this.clearSearchComparablesMunicipalityObjects();

    var municipalityBounds = new google.maps.LatLngBounds();
    responseValues.forEach((valueArray: any[]) => {
      valueArray.forEach(municipalitySpatial => {

        //TODO: change styling
        let polygonPaths: google.maps.LatLng[] = [];
        municipalitySpatial.polygon.forEach((point: {latitude: number; longitude: number;}) => {
          var latlng = new google.maps.LatLng(point.latitude, point.longitude);
          polygonPaths.push(latlng);
          municipalityBounds.extend(latlng);
        });
        const municipalityPolygon = new google.maps.Polygon({
          paths: polygonPaths,
          strokeColor: "#3A8FEF",
          strokeWeight: 2,
          fillColor: "#3A8FEF",
          fillOpacity: 0.25,
          clickable: false
        });

        municipalityPolygon.setMap(this.gMap);
        this.getRenderedMunicipalityPolygons().push(municipalityPolygon);
      })
    })

    if (!municipalityBounds.isEmpty()) {
      this.getSelectedMunicipalityBounds().union(municipalityBounds);
      this.fitBounds(this.getSelectedMunicipalityBounds(), false);
    }
  }

  closeAllMarkerBubbles = () => {
    let markerBubbles = Array.from(this.renderedMarkerInfoBubbles.values());
    markerBubbles.forEach((ppMarkerBubble) => {
      ppMarkerBubble.bubble?.close();
    })
  }

  isMarkerRenderedForPin = (pin: string, markerStorage: google.maps.Marker[]): boolean => {
    let marker: google.maps.Marker | undefined;

    marker = markerStorage.find(m => {
      return m.get('markerPin') == pin;
    });

    this.loggerService.logDebug(`marker exists for pin ${pin}? ${marker != undefined}`);
    return marker != undefined;
  }

  getMarkerBubbleAtPosition = (position: google.maps.LatLng) => {
    let marker: google.maps.Marker | undefined;

    marker = this.getRenderedMarkers().find(m => {
      return m.get('markerPosition').toString() == position.toString();
    });

    this.loggerService.logDebug(`marker at position ${position} found?`, marker);
    return marker;
  }

  getMarkerBubbleById = (markerId: string) => {
    let pinBubble: PushPinMarkerBubble | undefined = this.renderedMarkerInfoBubbles.get(markerId);
    return pinBubble;
  }

  closeMarkerBubbleById = (markerId: string) => {
    let pinBubble = this.getMarkerBubbleById(markerId);
    if (pinBubble) {
      let bubble: google.maps.InfoWindow = pinBubble.bubble;
      if (bubble) {
        bubble.close();
      }
    }
  }

  getMarkerBubbleByPin = (pin: string): google.maps.InfoWindow | undefined => {
    let pinIdentifier: string;

    if (this.piiService.isBlockNumber(pin)) {
      pinIdentifier = this.piiService.getCondoBlockPin(pin);
    } else {
      pinIdentifier = pin;
    }

    let markerBubbles = Array.from(this.renderedMarkerInfoBubbles.values());

    let pushPinMarkerBubble: PushPinMarkerBubble | undefined;
    let infoBubble: google.maps.InfoWindow | undefined;
    pushPinMarkerBubble = markerBubbles.find((mb) => {
      return mb.identifier == pinIdentifier;
    });

    if (pushPinMarkerBubble) {
      infoBubble = pushPinMarkerBubble.bubble;
    }

    this.loggerService.logDebug(`info bubble found for pin ${pin}? ${infoBubble != undefined}`);
    return infoBubble;
  }

  closeMarkerBubbleByPin = (pin: string) => {
    let markerBubble: google.maps.InfoWindow | undefined = this.getMarkerBubbleByPin(pin);

    if (markerBubble) {
      this.loggerService.logDebug(`closing info bubble for pin ${pin}`);
      markerBubble.close();
    }
  }

  getSearchComparableMarkerById = (markerId: string): google.maps.Marker | null => {
    let marker = this.getSearchComparableMarkers().find(m => m.get('markerId') == markerId);
    if (marker) {
      return marker;
    }

    return null;
  }

  getSearchComparableMarkerByPin = (pin: string): google.maps.Marker | null => {
    let marker = this.getSearchComparableMarkers().find(m => m.get('markerPin') == pin);
    if (marker) {
      return marker;
    }

    return null;
  }

  replaceMarkerBubble(markerId: string, newBubble: google.maps.InfoWindow | undefined) {
    if (markerId && newBubble) {
      let pinBubble = this.getMarkerBubbleById(markerId);
      if (pinBubble) {
        pinBubble.bubble = newBubble;
      }
    }
  }

  highlightSearchComparableMarker = (pin: string, uniqueRowId: string | null) => {
    let resultMarker: google.maps.Marker | null;

    resultMarker = this.getSearchComparableMarkerByPin(pin);

    if (resultMarker && this.isMarkerVisible(resultMarker)) {
      resultMarker.set('isMouseOverRequestFromSearchResults', true);
      if (uniqueRowId) {
        resultMarker.set('uniqueSearchResultRowId', uniqueRowId);
      }

      google.maps.event.trigger(resultMarker, 'mouseover');
    }
  }

  unhighlightSearchComparableMarker = (pin: string) => {
    let marker = this.getSearchComparableMarkerByPin(pin);
    if (marker) {
      google.maps.event.trigger(marker, 'mouseout');
    } else {
      this.loggerService.logDebug(`marker with marker pin ${pin} not found`);
    }
  }

  toggleSearchComparableMarker = (pin: string, visible: boolean) => {
    let resultMarker: google.maps.Marker | null;

    resultMarker = this.getSearchComparableMarkerByPin(pin);

    if (resultMarker) {
      if (!visible) {
        this.hideMarker(resultMarker);
        this.closeMarkerBubbleByPin(pin);
      } else {
        this.showMarker(resultMarker);
        this.highlightSearchComparableMarker(pin, null);
      }
    }
  }

  hideSearchComparableMarkers = () => {
    this.getSearchComparableMarkers().forEach(m => m.setMap(null));
    this.closeAllMarkerBubbles();
  }

  showSearchComparableMarkers = () => {
    this.getSearchComparableMarkers().forEach(m => m.setMap(this.gMap));
  }

  getMapBoundsForAllMarkers = (): google.maps.LatLngBounds => {
    let mapBounds = new google.maps.LatLngBounds();

    this.getRenderedMarkers().forEach(marker => {
      mapBounds.extend(marker.getPosition()!);
    })

    this.getClickedMarkers().forEach(marker => {
      mapBounds.extend(marker.getPosition()!);
    })

    return mapBounds;
  }

  extendMapBoundsByLatLngs = (points: google.maps.LatLng[]) => {
    if (!_.isEmpty(points)) {
      let mapBounds = this.getMapBoundsForAllMarkers();

      points.forEach(point => {
        mapBounds.extend(point);
      })

      this.gMap.fitBounds(mapBounds);
    }
  }

  extendMapBoundsByMarkers = (markers: google.maps.Marker[]) => {
    if (!_.isEmpty(markers)) {
      let mapBounds = this.getMapBoundsForAllMarkers();

      markers.forEach(marker => {
        mapBounds.extend(marker.getPosition()!);
      })

      this.gMap.fitBounds(mapBounds);
    }
  }

  /**
   * Add the user/browser's (user ISP) location marker to the map.
   * @param point
   */
  renderUserLocationMarker = (point: google.maps.LatLng) => {

    if (this.userLocationMarker) {
      this.userLocationMarker.setMap(null);
      //TODO: we should reduce the map bounds since the previous user location marker extended the map bounds.
      //TODO: optionally notify the user where the location is.
    }

    let markerIcon = {
      url: this.userLocationMarkerIcon,
      scaledSize: this.userLocationMarkerSize
    }

    this.userLocationMarker = new google.maps.Marker({
      map: this.gMap,
      position: point,
      icon: markerIcon
    });

    this.extendMapBoundsByLatLngs([point]);
  }

  //TODO: inject map instead when this service is instantiated
  setMap = (map: google.maps.Map) => {
    this.gMap = map;
  }

  getMap = (): google.maps.Map => {
    return this.gMap;
  }

  setMapCenter(center: google.maps.LatLng) {
    try {
      this.gMap?.setCenter(center);
    } catch (e) {
      this.loggerService.logError(`failed to center map on lat ${center.lat()}, lng ${center.lng()}`);
    }
  }

  getMapCenter = (): google.maps.LatLng => {
    return this.gMap?.getCenter()!;
  }

  getMapZoomLevel = () => {
    return this.gMap?.getZoom();
  }

  setMapZoomLevel = (level: number) => {
    this.gMap?.setZoom(level);
  }

  setMapTilt = (degrees: number) => {
    this.gMap?.setTilt(degrees);
  }

  panMapToCenter(center: google.maps.LatLng) {
    this.gMap?.panTo(center);
  }

  getMapBounds = () => {
    return this.gMap?.getBounds();
  }

  fitBounds = (bounds: any, reduceFit: boolean) => {
    if (reduceFit) {
      var offset = 0.002;
      var center = bounds?.getCenter();
      bounds?.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
      bounds?.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));
    } else {
      if (bounds) this.getMap()?.fitBounds(bounds);
    }
  }

  getMapState = () => {
    return new GoogleMapState(this.getMapCenter(), this.getMapZoomLevel()!);
  }

  setMapType = (type: MapTypeEnum) => {
    this.mapService.setMapType(this.getMap(), type);
  }

  getMapLayersCount = () => {
    let count: number;

    count = (this.getMap())? this.getMap().overlayMapTypes.getLength() : 0;
    return count;
  };

  enterFullScreenMap(map: google.maps.Map) {
    // see this https://developers.google.com/maps/documentation/javascript/examples/control-replacement#maps_control_replacement-typescript
    let elementToSendFullscreen = map?.getDiv().firstChild as HTMLElement;
    if (elementToSendFullscreen.requestFullscreen) {
      elementToSendFullscreen.requestFullscreen();
    }
  }

  exitFullScreenMap = () => {
    try {
      if (document && document.fullscreenElement) {
        document.exitFullscreen();
      }
    } catch (e) {
    }
  }

  //Set the map to ignore the users' swipe and pan actions so that users are not stuck on the map while scrolling the page.
  setMapTwoFingerTouchGesture = (map: google.maps.Map) => {
    map?.setOptions({
      gestureHandling: 'cooperative'
    });
  }

  //Set the map to always respond to the users' swipe and pan actions.
  setMapOneFingerTouchGesture = (map: google.maps.Map) => {
    map?.setOptions({
      gestureHandling: 'greedy'
    });
  }

  setAutoPanMapForSearchComparablesResultMarkers(autoPanMap: boolean) {
    autoPanMap? localStorage.setItem(LocalStorageKey.comparablesSalesResultsAutoPanMap, 'true') : localStorage.setItem(LocalStorageKey.comparablesSalesResultsAutoPanMap, 'false');

    //apply the change right away to the current marker bubble
    if (this.currentMarkerInfoBubble?.bubble) {
      if (autoPanMap) {
        this.currentMarkerInfoBubble.bubble.setOptions({disableAutoPan: false});
      } else {
        this.currentMarkerInfoBubble.bubble.setOptions({disableAutoPan: true});
      }

      //re-open info bubble window which would normally be done from the marker mouseover event
      this.currentMarkerInfoBubble.bubble.open(this.gMap);
    }
  }
  
  initializeMapLayers(layerVisibility: MapLayerVisibility): void {
    //clear the overlays
    while (this.gMap.overlayMapTypes.getLength() > 0) {
      this.gMap.overlayMapTypes.pop();
    }

    let currentMapZoomLevel: number = this.getMapZoomLevel()!;

    if (_.inRange(currentMapZoomLevel, AssessmentTile.MIN_ZOOM, AssessmentTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new AssessmentTile(layerVisibility.assessment, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
    }

    if (_.inRange(currentMapZoomLevel, OwnerTile.MIN_ZOOM, OwnerTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new OwnerTile(layerVisibility.ownership, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
    }

    if (_.inRange(currentMapZoomLevel, AddressTile.MIN_ZOOM, AddressTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new AddressTile(layerVisibility.address, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
    }

    if (_.inRange(currentMapZoomLevel, LotConcessionTile.MIN_ZOOM, LotConcessionTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new LotConcessionTile(layerVisibility.lotConcession, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
    }

    if (_.inRange(currentMapZoomLevel, ThematicTile.MIN_ZOOM, ThematicTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new ThematicTile(layerVisibility.thematic, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey, this._thematicLayerOpacityValue));
    }

    if (_.inRange(currentMapZoomLevel, MunicipalityLroTile.MIN_ZOOM, MunicipalityLroTile.MAX_ZOOM + 1)) {
      this.gMap.overlayMapTypes.setAt(this.gMap.overlayMapTypes.getLength(), new MunicipalityLroTile(layerVisibility.municipalityLro, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
    }

    this.applyFBSLayerVisibility(layerVisibility.firstBaseSolution);

    this.loggerService.logDebug(`initialized main map with ${this.gMap.overlayMapTypes.getLength()} ${(this.gMap.overlayMapTypes.getLength() > 1)? 'overlays' : 'overlay'}`, this.gMap.overlayMapTypes);
  }

  initFBSLayer(){
    this.fbsService.updateAuthenticationCookie();
  }

  applyFBSLayerVisibility(layerVisibility: boolean) {
    this.fbsService.setFBSMapSelected(layerVisibility);
    this.mapService.applyFBSLayerVisibility(this.getMap(), layerVisibility);
  }

  refreshMapLayer(layerName: string | null, layerVisibility: boolean): void {
    const overlayIndex = this.mapService.getLayerIndex(this.getMap(), layerName);

    if (overlayIndex > -1) {
      switch (layerName) {
        case MapTileEnum.ASSESSMENT_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new AssessmentTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
          break;
        case MapTileEnum.OWNER_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new OwnerTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
          break;
        case MapTileEnum.ADDRESS_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new AddressTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
          break;
        case MapTileEnum.THEMATIC_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new ThematicTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey, this._thematicLayerOpacityValue));
          break;
        case MapTileEnum.LOT_CONCESSION_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new LotConcessionTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
          break;
        case MapTileEnum.MUNICIPALITY_LRO_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new MunicipalityLroTile(layerVisibility, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
          break;
        case MapTileEnum.FIRST_BASE_SOLUTION_TILE:
          this.gMap.overlayMapTypes.setAt(overlayIndex, new FirstBaseSolutionTile(this.fbsService.isFBSMapSelected(), this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey, this.fbsService));
          break;
      }

      this.loggerService.logDebug(`refreshed map layer ${layerName} on overlay index ${overlayIndex}`);
    } else {
      this.loggerService.logError(`refreshing map layer ${layerName} failed on overlay index ${overlayIndex}`);
    }

  }

  addSubjectPropertyRegistryMapLayer = (map: google.maps.Map, layerName: MapTileEnum) => {
    switch (layerName) {
      case MapTileEnum.ASSESSMENT_TILE:
        map.overlayMapTypes.setAt(map.overlayMapTypes.getLength(), new AssessmentTile(true, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
        break;
      case MapTileEnum.OWNER_TILE:
        map.overlayMapTypes.setAt(map.overlayMapTypes.getLength(), new OwnerTile(true, this.tileProtocol, this.tileServerUrl, this.tileAuthenticationKey));
        break;
    }
  }

  get tileProtocol(): string | undefined {
    return this.mapService.tileProtocol;
  }

  get tileServerUrl(): string | undefined {
    return this.mapService.tileServerUrl;
  }

  get tileAuthenticationKey(): string | undefined {
    return this.mapService.tileAuthenticationKey;
  }

  private _thematicLayerOpacityValue: number = 0.5;
  get thematicLayerOpacityValue(): number{
    return this._thematicLayerOpacityValue;
  }
  set thematicLayerOpacityValue(val : number){
    this._thematicLayerOpacityValue = val;
  }

  //ToDo do we need a Subject/SubjectBehaviour ?
  private _mainMapTypeId: MapTypeId = google.maps.MapTypeId.ROADMAP;
  set mainMapTypeId(mapTypeId: MapTypeId) {
    this._mainMapTypeId = mapTypeId;
  }

  get mainMapTypeId(): MapTypeId {
    return this._mainMapTypeId;
  }

  updateMainMapType(userPreference: UserPreference) {
    if (userPreference && userPreference.genericPreference) {
      const mapType = userPreference.genericPreference.mapType;
      if (mapType == MapTypeEnum.AERIAL) {
        this.mainMapTypeId = google.maps.MapTypeId.SATELLITE;
      } else if (mapType == MapTypeEnum.STANDARD) {
        this.mainMapTypeId = google.maps.MapTypeId.ROADMAP;
      }
    }
  }

  /************ HEATMAP - START ************/
  hmBlockType: HeatmapBlockTypeEnum;
  hmAverageSalesBlockVO: HeatmapBlockContainer = new HeatmapBlockContainer();
  hmGrowthRateBlockVO: HeatmapBlockContainer = new HeatmapBlockContainer();
  hmMarketTurnoverBlockVO: HeatmapBlockContainer = new HeatmapBlockContainer();
  tempBlockVO: HeatmapBlockContainer = new HeatmapBlockContainer();

  hmNhoodType: HeatmapNhoodTypeEnum;
  hmAverageRentalNhoodVO: HeatmapBlockContainer = new HeatmapBlockContainer();

  clearAllHeatmapBlocks() {
    this.clearHeatmapBlocks(this.hmAverageSalesBlockVO);
    this.clearHeatmapBlocks(this.hmGrowthRateBlockVO);
    this.clearHeatmapBlocks(this.hmMarketTurnoverBlockVO);
    this.clearHeatmapBlocks(this.tempBlockVO);
    this.clearHeatmapNhood(this.hmAverageRentalNhoodVO);
  }

  clearAllHeatmapNhood() {
    this.clearHeatmapNhood(this.hmAverageRentalNhoodVO);
    this.clearHeatmapBlocks(this.tempBlockVO);
  }

  private clearHeatmapBlocks(blocks: any) {
    for (var i = 0; i < blocks?.polygons?.length; i++) {
      if (blocks.polygons[i]) blocks.polygons[i].setMap(null);
    }
    for (var i = 0; i < blocks?.markers?.length; i++) {
      if (blocks.markers[i]) blocks.markers[i].setMap(null);
    }
    while (blocks?.polygons?.length > 0) {
      blocks.polygons.pop();
    }
    while (blocks?.markers?.length > 0) {
      blocks.markers.pop();
    }
  }

  private clearHeatmapNhood(nhood: any) {
    for (var i = 0; i < nhood?.polygons?.length; i++) {
      if (nhood.polygons[i]) nhood.polygons[i].setMap(null);
    }
    for (var i = 0; i < nhood?.markers?.length; i++) {
      if (nhood.markers[i]) nhood.markers[i].setMap(null);
    }
    while (nhood?.polygons?.length > 0) {
      nhood.polygons.pop();
    }
    while (nhood?.markers?.length > 0) {
      nhood.markers.pop();
    }
  }

  private saveHeatmapBlock(blockType: HeatmapBlockTypeEnum | null, blockVO: any) {
    if (blockVO) {
      switch (blockType) {
        case HeatmapBlockTypeEnum.AVERAGE_SALES:
          if (blockVO.polygon) this.hmAverageSalesBlockVO.polygons.push(blockVO.polygon);
          if (blockVO.marker) this.hmAverageSalesBlockVO.markers.push(blockVO.marker);
          break;

        case HeatmapBlockTypeEnum.GROWTH_RATE:
          if (blockVO.polygon) this.hmGrowthRateBlockVO.polygons.push(blockVO.polygon);
          if (blockVO.marker) this.hmGrowthRateBlockVO.markers.push(blockVO.marker);
          break;

        case HeatmapBlockTypeEnum.MARKET_TURNOVER:
          if (blockVO.polygon) this.hmMarketTurnoverBlockVO.polygons.push(blockVO.polygon);
          if (blockVO.marker) this.hmMarketTurnoverBlockVO.markers.push(blockVO.marker);
          break;

        default:
          if (blockVO.polygon) this.tempBlockVO.polygons.push(blockVO.polygon);
          if (blockVO.marker) this.tempBlockVO.markers.push(blockVO.marker);
      }
    }
  }


  private saveHeatmapNhood(nhoodType: HeatmapNhoodTypeEnum | null, nhoodVO: any) {
    if (nhoodVO) {
      switch (nhoodType) {
        case HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE:
          if (nhoodVO.polygon) this.hmAverageRentalNhoodVO.polygons.push(nhoodVO.polygon);
          if (nhoodVO.marker) this.hmAverageRentalNhoodVO.markers.push(nhoodVO.marker);
          break;

        default:
          if (nhoodVO.polygon) this.tempBlockVO.polygons.push(nhoodVO.polygon);
          if (nhoodVO.marker) this.tempBlockVO.markers.push(nhoodVO.marker);
      }
    }
  }

  async renderHeatmapLayer(blockType: HeatmapBlockTypeEnum, dateRangeInMonths: number, isCondo: string, mapBounds: any, zoomLevel: number) {
    if (!this.hasAccessToHeatmapBlock(blockType)) return;

    if (zoomLevel >= DataService.HEATMAP_MIN_ZOOM_LEVEL && zoomLevel <= DataService.HEATMAP_MAX_ZOOM_LEVEL) {
      var nelat = mapBounds.getNorthEast().lat();
      var nelng = mapBounds.getNorthEast().lng();
      var swlat = mapBounds.getSouthWest().lat();
      var swlng = mapBounds.getSouthWest().lng();

      var dateStart = dayjs().subtract(dateRangeInMonths, 'month').valueOf();
      var dateEnd = dayjs().valueOf();

      var loadingCondo = 'Loading condo heatmap for last ' + dateRangeInMonths + ' months...';
      var loadingFreehold = 'Loading freehold heatmap for last ' + dateRangeInMonths + ' months...';
      let heatMapLoadingMarker = new google.maps.Marker({
        position: new google.maps.LatLng(this.getMapCenter().lat(), this.getMapCenter().lng()),
        map: this.getMap(),
        icon: this.loadingMarkerIcon((isCondo == 'Y') ? loadingCondo : loadingFreehold, zoomLevel)
      });

      if (this.isChangesInHeatmapRequestDetected(nelat, nelng, swlat, swlng, dateRangeInMonths, isCondo)) {

        let heatmapResponse: any = await lastValueFrom(this.heatmapSearchService.getHeatmapBlocks(nelat, nelng, swlat, swlng, dateStart, dateEnd, isCondo));

        heatMapLoadingMarker.setMap(null);

        if (heatmapResponse && heatmapResponse.heatmapAreas && heatmapResponse.heatmapAreas.length > 0) {
          this.loggerService.logDebug('This area has heatmap data');
          this.lastHeatmapResponseData = heatmapResponse.heatmapAreas;

          if (heatmapResponse && heatmapResponse.heatmapPolygons && heatmapResponse.heatmapPolygons.length > 0) {
            this.loggerService.logDebug('This area has heatmap blocks');
            this.lastHeatmapResponsePolygons = heatmapResponse.heatmapPolygons;
            this.renderBlockPolygonsAndData(blockType, heatmapResponse.heatmapPolygons, heatmapResponse.heatmapAreas);
          } else {
            this.openSnackBarError('No heatmap data available in this area.');
            this.clearAllHeatmapBlocks();
            this.lastHeatmapResponsePolygons = null;
          }
        } else {
          this.openSnackBarError('No heatmap data available in this area.');
          this.clearAllHeatmapBlocks();
          this.lastHeatmapResponseData = null;
        }

      } else {
        heatMapLoadingMarker.setMap(null);
        this.renderBlockPolygonsAndData(blockType, this.lastHeatmapResponsePolygons, this.lastHeatmapResponseData);
      }

    } else {
      this.clearAllHeatmapBlocks();
    }
  }

  private renderBlockPolygonsAndData(blockType: HeatmapBlockTypeEnum, blockPolygons: any, blockData: any) {
    switch (blockType) {
      case HeatmapBlockTypeEnum.AVERAGE_SALES:
        this.loggerService.logDebug('Rendering ' + blockType);
        this.renderAverageSalesBlockHeatmapObjects(blockPolygons, blockData);
        break;

      case HeatmapBlockTypeEnum.GROWTH_RATE:
        this.loggerService.logDebug('Rendering ' + blockType);
        this.renderGrowthRateBlockHeatmapObjects(blockPolygons, blockData);
        break;

      case HeatmapBlockTypeEnum.MARKET_TURNOVER:
        this.loggerService.logDebug('Rendering ' + blockType);
        this.renderMarketTurnoverBlockHeatmapObjects(blockPolygons, blockData);
        break;
    }
  }

  private renderNhoodPolygonsAndData(nhoodType: HeatmapNhoodTypeEnum, nhoodData: any) {
    switch (nhoodType) {
      case HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE:
        this.loggerService.logDebug('Rendering ' + nhoodType);
        this.renderAverageRentalNhoodHeatmapObjects( nhoodData);
        break;
    }
  }

  private renderAverageRentalNhoodHeatmapObjects(nhoodData: any) {
    this.clearAllHeatmapNhood();
    for (var i = 0; i < nhoodData.length; i++) {
      var polygonCentroid = nhoodData[i].disAreaPolygon[0].centroid;
      var polygonPoints = nhoodData[i].disAreaPolygon[0].polygon;
      var polygonText =  (nhoodData[i] && nhoodData[i].rentalPercentage != null) ? nhoodData[i].rentalPercentage : null
      const averageRank = (nhoodData[i] && nhoodData[i].rentalRank != null && nhoodData[i].rentalRank > -1) ? nhoodData[i].rentalRank : null;
      const fillColor = this.getPolygonFillColor(averageRank != null && averageRank > -1 && polygonText !=null ? averageRank : null);
      const fillColors = fillColor.split(":");
      var formattedPolygonText: string | null = null;
      if (averageRank != null && polygonText != null && !isNaN(polygonText)) {
        formattedPolygonText = polygonText.toFixed(polygonText === 0 ? 0 : 2) + "%";
      } else {
        formattedPolygonText = "Insufficient Data";
      }
      this.addHeatmapNhoodToMap(HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE, polygonPoints, polygonCentroid, formattedPolygonText, '#A9A9A9', 1, 2, fillColors[0], fillColors[1] as unknown as number, nhoodData);
    }
  }

  private renderAverageSalesBlockHeatmapObjects(blocks: any, data: any) {
    this.clearAllHeatmapBlocks();
    if (!blocks) return;
    for (var i = 0; i < blocks.length; i++) {
      var polygonCentroid = blocks[i].heatmapPolygon[0].centroid;
      var polygonPoints = blocks[i].heatmapPolygon[0].polygon;
      var blockNumber = blocks[i].name;
      var blockData = this.findBlockDataByBlockNumber(blockNumber, data);
      var polygonText = (blockData && blockData.average) ? blockData.average : null;
      const averageRank = (blockData && blockData.averageRank != null && blockData.averageRank > -1) ? blockData.averageRank : null;
      const fillColor = this.getPolygonFillColor(averageRank != null && averageRank > -1 && polygonText ? averageRank : null);
      const fillColors = fillColor.split(":");
      var formattedPolygonText: string | null = null;
      if (averageRank != null && polygonText && !isNaN(polygonText)) {
        if (polygonText >= 1000000) {
          var value = polygonText / 1000000;
          formattedPolygonText = this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2') + 'M';
        } else if (polygonText > 1000) {
          var value = polygonText / 1000;
          formattedPolygonText = this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0') + 'K';
        } else {
          formattedPolygonText = this.currencyPipe.transform(polygonText, 'USD', 'symbol', '1.0-0');
        }
      }
      this.addHeatmapBlockToMap(HeatmapBlockTypeEnum.AVERAGE_SALES, polygonPoints, polygonCentroid, formattedPolygonText, '#A9A9A9', 1, 2, fillColors[0], fillColors[1] as unknown as number, blockData);
    }
  }

  private renderGrowthRateBlockHeatmapObjects(blocks: any, data: any) {
    this.clearAllHeatmapBlocks();
    if (!blocks) return;
    for (var i = 0; i < blocks.length; i++) {
      var polygonCentroid = blocks[i].heatmapPolygon[0].centroid;
      var polygonPoints = blocks[i].heatmapPolygon[0].polygon;
      var blockNumber = blocks[i].name;
      var blockData = this.findBlockDataByBlockNumber(blockNumber, data);
      var polygonText = (blockData && blockData.growth) ? blockData.growth : null;
      const growthRank = (blockData && blockData.growthRank != null && blockData.growthRank > -1) ? blockData.growthRank : null;
      const fillColor = this.getPolygonFillColor(growthRank != null && growthRank > -1 && polygonText ? growthRank : null);
      const fillColors = fillColor.split(":");
      var formattedPolygonText: string | null = null;
      if (growthRank != null && polygonText && !isNaN(polygonText)) {
        formattedPolygonText = this.decimalPipe.transform(polygonText, '1.2-2') + "%";
        if (formattedPolygonText.startsWith(".") || formattedPolygonText.startsWith("-.")) {
          formattedPolygonText = formattedPolygonText.replace('.', '0.');
        }
      }
      this.addHeatmapBlockToMap(HeatmapBlockTypeEnum.GROWTH_RATE, polygonPoints, polygonCentroid, formattedPolygonText, '#A9A9A9', 1, 2, fillColors[0], fillColors[1] as unknown as number, blockData);
    }
  }

  private renderMarketTurnoverBlockHeatmapObjects(blocks: any, data: any) {
    this.clearAllHeatmapBlocks();
    if (!blocks) return;
    for (var i = 0; i < blocks.length; i++) {
      var polygonCentroid = blocks[i].heatmapPolygon[0].centroid;
      var polygonPoints = blocks[i].heatmapPolygon[0].polygon;
      var blockNumber = blocks[i].name;
      var blockData = this.findBlockDataByBlockNumber(blockNumber, data);
      var polygonText = (blockData && blockData.tor) ? blockData.tor : null;
      const torRank = (blockData && blockData.torRank != null && blockData.torRank > -1) ? blockData.torRank : null;
      const fillColor = this.getPolygonFillColor(torRank != null && torRank > -1 && polygonText ? torRank : null);
      const fillColors = fillColor.split(":");
      var formattedPolygonText: string | null = null;
      if (torRank != null && polygonText && !isNaN(polygonText)) {
        formattedPolygonText = polygonText + "%"; //format("#.#%", polygonText, {enforceMaskSign: false});
        if (formattedPolygonText.startsWith(".") || formattedPolygonText.startsWith("-.")) {
          formattedPolygonText = formattedPolygonText.replace('.', '0.');
        }
      }
      this.addHeatmapBlockToMap(HeatmapBlockTypeEnum.MARKET_TURNOVER, polygonPoints, polygonCentroid, formattedPolygonText, '#A9A9A9', 1, 2, fillColors[0], fillColors[1] as unknown as number, blockData);
    }
  }

  private addHeatmapBlockToMap(blockType: HeatmapBlockTypeEnum, polygonPoints: any, centroid: any, polygonText: string | null, strokeColor: string, strokeOpacity: number, strokeWeight: number, fillColor: string, fillOpacity: number, blockData: any) {
    let polygonCoords: any = [];
    let isWithinPolygon: boolean = false;

    polygonPoints.forEach((polygonCoordinate: { latitude: number; longitude: number; }) => {
      polygonCoords.push({
        "lat": polygonCoordinate.latitude,
        "lng": polygonCoordinate.longitude
      });
    })

    var heatmapPolygon = new google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: strokeColor,
      strokeOpacity: strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: fillColor,
      fillOpacity: fillOpacity
    });
    heatmapPolygon.setMap(this.getMap());

    let me = this;
    google.maps.event.addListener(heatmapPolygon, 'click', function (event: any) {
      me.loggerService.logDebug('Block Id ' + ((blockData) ? blockData.id : ''));
    });

    this.saveHeatmapBlock(null, {polygon: heatmapPolygon, marker: null});

    if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
      isWithinPolygon = google.maps.geometry.poly.containsLocation(this.propertyReportService.getSubjectPropertyLatLng(), heatmapPolygon);
    }

    if (polygonText != null && polygonText != '$ ' && polygonText != '%') {
      setTimeout(() => {
        var heatmapMarker = new google.maps.Marker({
          position: new google.maps.LatLng(centroid.latitude, centroid.longitude),
          map: this.getMap(),
          icon: this.createMarkerIcon(polygonText, isWithinPolygon, {'weight': 'bold', 'size': '12px', 'family': 'Verdana'})
        });
        this.saveHeatmapBlock(blockType, {polygon: heatmapPolygon, marker: heatmapMarker});
      }, 100);


      // for debugging
      // var dataStyle = '<span style="font-weight: bold; padding-left: 3px;">';
      // var blockInfoContent = '<div>' +
      // 						   '<div>Block:' +
      // 								dataStyle + blockData.id + '</span>' +
      // 						   '</div>' +
      // 						   '<div>Total Number of Sales:' +
      // 								dataStyle + blockData.totalNumberOfSales + '</span>' +
      // 						   '</div>';

      // if (blockType == this.hmBlockModel.hmBlockType.AVERAGE_SALES) {
      // 	blockInfoContent = blockInfoContent +
      // 						   '<div>Ranking:' +
      // 								dataStyle + blockData.averageRank + '</span>' +
      // 						   '</div>';
      // } else if (blockType == this.hmBlockModel.hmBlockType.GROWTH_RATE) {
      // 	blockInfoContent = blockInfoContent +
      // 						   '<div>Ranking:' +
      // 								dataStyle + blockData.growthRank + '</span>' +
      // 						   '</div>';
      // } else if (blockType == this.hmBlockModel.hmBlockType.MARKET_TURNOVER) {
      // 	blockInfoContent = blockInfoContent +
      // 						   '<div>Ranking:' +
      // 								dataStyle + blockData.torRank + '</span>' +
      // 						   '</div>';
      // }

      // blockInfoContent += '</div>';

      // var blockInfo = new google.maps.InfoWindow({
      // 	content: blockInfoContent
      // });
      // var blockInfoOpen = false;

      // let debugmode = false;  //TODO

      // heatmapMarker.addListener('click', function() {
      // 	if (debugmode && !blockInfoOpen) {
      // 		blockInfo.open({
      // 			anchor: heatmapMarker
      // 		});
      // 		blockInfoOpen = true;
      // 	}
      // 	else if (blockInfoOpen) {
      // 		blockInfo.close();
      // 		blockInfoOpen = false;
      // 	}
      // });
    } else if (blockType == HeatmapBlockTypeEnum.GROWTH_RATE && blockData && !isNaN(blockData.totalNumberOfSales) && blockData.totalNumberOfSales <= 5) {
      var heatmapMarker = new google.maps.Marker({
        position: new google.maps.LatLng(centroid.latitude, centroid.longitude),
        map: this.getMap(),
        icon: this.createMarkerIcon('Not enough sales', isWithinPolygon, {'weight': 'normal', 'size': '11px', 'family': 'Verdana'})
      });
      this.saveHeatmapBlock(blockType, {polygon: heatmapPolygon, marker: heatmapMarker});
    }
  }

  private addHeatmapNhoodToMap(nhoodType: HeatmapNhoodTypeEnum, polygonPoints: any, centroid: any, polygonText: string | null, strokeColor: string, strokeOpacity: number, strokeWeight: number, fillColor: string, fillOpacity: number, nhoodData: any) {
    let polygonCoords: any = [];
    let isWithinPolygon: boolean = false;

    polygonPoints.forEach((polygonCoordinate: { latitude: number; longitude: number; }) => {
      polygonCoords.push({
        "lat": polygonCoordinate.latitude,
        "lng": polygonCoordinate.longitude
      });
    })

    var heatmapPolygon = new google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: strokeColor,
      strokeOpacity: strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: fillColor,
      fillOpacity: fillOpacity
    });
    heatmapPolygon.setMap(this.getMap());

    let me = this;
    google.maps.event.addListener(heatmapPolygon, 'click', function (event: any) {
      me.loggerService.logDebug('Nhood Id ' + ((nhoodData) ? nhoodData.nhoodId : ''));
    });

    this.saveHeatmapNhood(null, {polygon: heatmapPolygon, marker: null});

    if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
      isWithinPolygon = google.maps.geometry.poly.containsLocation(this.propertyReportService.getSubjectPropertyLatLng(), heatmapPolygon);
    }

    if (polygonText != null && polygonText != '%') {
      setTimeout(() => {
        var heatmapMarker = new google.maps.Marker({
          position: new google.maps.LatLng(centroid.latitude, centroid.longitude),
          map: this.getMap(),
          icon: this.createMarkerIcon(polygonText, isWithinPolygon, {'weight': 'bold', 'size': '12px', 'family': 'Verdana'})
        });
        this.saveHeatmapNhood(nhoodType, {polygon: heatmapPolygon, marker: heatmapMarker});
      }, 100);


    }
  }

  private createMarkerIcon(message: string, isFound: boolean, font: any) {
    const width = 110;
    const height = 22;
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    var iconBlueImg = new Image();
    iconBlueImg.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAWCAYAAAAhKqlXAAADAElEQVRoQ+2aQUhUQRjHfwO7sNQSQodN6hIqCIoodFgvdjAFKQVlrSySMMMlZFcRNsiiXErWiFxRMTpoFrSCLzFKOnQKCkWEPSi4B7ukkESmxBKiwsRsZJbCPqHdd5k5vvf/vv/H/8/M+2bmCfYYUkrb8DCX3r/nWixG9vIyB79+xb62thdaP/tfCmRkgMvFhstFvKCA2ZMn6a+tFaN75Rf/PjQM2RCJ0P3hA4fOn4dTpyArC44cAZVYj9QpoCbG0hJ8/AivX8P4OFRUsFxfT2NZmZjYybxtnJplvb08DQapa2mBtjZwOFJXpM6cXAFlZEcHGAYEg9xvaBDXf0dtGxcOyxcPHlAzOgpud/KkGpE+BSYmoLEROjv/mJcwzjCk3+sl/OYNnDiRvoI0k3kFlHleLwwNcUYtm0JK6SgvZ+X0aQ74/eYTaWT6FWhthZUVlp89E5mip0cGe3u5NT8PNlv6i9GM5hVQ37ycHBgY4Kyoq5MLeXlktbebT6CR1ilw9WqiaXwncnPlRiSCvbDQumI0s3kFXr6Ehw/5JpxOKT9/BqfTfLBGWqfA3Bx4PGwIkFJK6wrRzPtTQH3njh8Hbdz+dLMcHY9DZiYIh0PK1VV9SmK5IyYLiMWgqootkZMjN8fGsOXnm4zUMEsVUBvxUIg1UVMjl0pKOKo335b6YZpcbcLjcWZEKCS7R0ZoiUZNx2qgRQqsr/9qTEIhGtSRV0ZxMV+am7FfvGhRRZrWlAL37kE0ynfD4HDikHlwUHYFAgQmJyE721QODUqzAlNTUFkJjx5xxeMRg9vXOnfuyMmREdyvXmnz0uxJUrqZGaiuhkCAiM8nLqiAnRepzo4O3vb14Q6HQS+bSfVMOWBrC/r74e5duHmTMb+fc0KIrb+M+12FWjYfP6Z1fR375ctQWvprBurb8JT7lCBQG+yFBVBt/5MniWbkR1MTNzwe0bOzgl3/nKiXqmHp6uL29DS1s7O4FhexqY5Gj9QroM6Mjx1js6iIT243z30+OoUQu9T/CUtE9xcWUxZlAAAAAElFTkSuQmCC";
    var iconYellowImg = new Image();
    iconYellowImg.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAAWCAYAAAAhKqlXAAADVElEQVRoQ2NkwAL+/2dgWbgwPvbIEZusGzc0VF68kOB+80aE9cMHAWzKR8WoFAICAh8YxMVf/hIXf/lFT+/SZXv7g1NDQ9esxmY8I7rgmjXBScuXR/YfPWrNFxGxgsHFZQ+DsvJdBgmJFwwgg0cB7UIAlDGePJFhuHtXmWHLFh+GDRsCGDw9t7+Ii1uU4uq6ZyuyzfCIA+WyyZNzFzU11UUWFExgKC7uZeDg+EE7V46aTDAEQBHZ2FjPsGZNCENTU11XUtL8cpgmeMRNmJC/tqenJGj16lAGC4sTBA0dVUC/ENi61ZshJWUOQ1tbFTzywBG3Zk1wfkbGjAnbt3symJicoZ+LRm0iOgRAkZeRMYNh/vxEH1Cxyfj/PwOHm9uut97eW7ny8ycSbdCoQvqHQGFhP8Pbt8IvFi+Ok2ScODGvafLk3Nrr1zUZWFj+0N81ozYSHQKgOk9V9TbD9OmZYYyRkcvuaGtfVa6ubiXagFGFAxcCqamzQY3Gg4waGtd/LV8eyWpgcGHgXDNqM9EhsHGjP0NfX9E7Rh6ez/+fP5dk4OH5QrTmUYUDFwJXrugwhISs+cXIwPD/////GP3wgXPZqM14QwBUzykq3mcYjbghllC+fOFhkJR8zsDIwfH9//v3gqOjJEMkAm/c0GDw89v0h1FV9dbvdeuCWHR0rgwRp49sZ4I64h0dFR8Yg4LWPrGzOyQ92vkeGgkC1An/8oXnDGNHR3n/ihURBefPGw4Nl49gV/74wQFumHR0VCSBhrwELC2Pv8rJmcIaHb10BAfL4Pd6a2s1w/nzhp/WrAkRBvcD5s1L7Cwr6yo7ftySQUXlzuD3wQh04YkTFgy+vpsZZszISA4JWTsP3oFraKg/vmJFhMXmzb6jkTfIEsaZMyYMgYHrGcrKupbn5U2OAjkPeSKVp7GxfveUKTkWEyYUMIwWmwMfe3/+sDBMnZrN0NJSw1BT07IuP39iOCMjA3gmAGPIBFRszpqVVvjjBwdrQsICBmfnveAcODobTp+IBHWw79xRYQA1+xcsSAA1Rr6lp8+sCglZizLnhnWsC9Rg6ewsrz91yiz08mVd8cePZVlALZpRQPsQAI0Zy8g8+W1oeP6RhcWJZXl5k9oYGRkw1pAAAEeZNSqQHk0hAAAAAElFTkSuQmCC";

    let context: any = canvas.getContext("2d");
    context.clearRect(0, 0, width, height);
    context.drawImage(isFound ? iconYellowImg : iconBlueImg, 0, 0, width, height);
    context.fillStyle = "rgb(0, 0, 255)";
    context.font = font.weight + " " + font.size + " " + font.family;
    context.textAlign = "center";
    context.fillText(message, width / 2, 15);
    return canvas.toDataURL();
  }

  private getPolygonFillColor(rank: any) {
    const colors = ["#0000e4:0.5", "#2020e4:0.3", "#ffc53f:0.4", "#ff7f3f:0.4", "#ff3f3f:0.3", "#ff2020:0.4", "#ff0000:0.5"];
    const noDataColor = "#7f7f7f:0";
    return rank == null ? noDataColor : colors[Math.min(colors.length - 1, rank)];
  }

  private loadingMarkerIcon(text: string, zoomLevel: number) {
    const width = 340;
    const height = 50;
    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let context: any = canvas.getContext("2d");
    context.clearRect(0, 0, width, height);
    context.strokeRect(0, 0, width, height);
    context.font = "bold 12px Verdana";
    context.textAlign = "center";
    context.fillText(text, width / 2, 30);
    return canvas.toDataURL();
  }

  private findBlockDataByBlockNumber(blockNum: any, data: any) {
    var blockData;
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id == blockNum) {
          //this.loggerService.logDebug('Block ' + blockNum + ' has data');
          blockData = data[i];
          break;
        }
      }
    }
    return blockData;
  }

  private findNhoodDataByNhoodNumber(nhoodNum: any, data: any) {
    var nhoodData;
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].nhoodId == nhoodNum) {
          //this.loggerService.logDebug('Block ' + blockNum + ' has data');
          nhoodData = data[i];
          break;
        }
      }
    }
    return nhoodData;
  }

  private getHeatmapPropertyType(propertyType: string) {
    if (propertyType == 'Condo') {
      return 'Y';
    } else if (propertyType == 'Freehold') {
      return 'N';
    }

    return '';
  }

  private getHeatmapDateRange(dateRange: string) {
    var months = 0;
    if (dateRange == '3 months') {
      months = 3;
    } else if (dateRange == '6 months') {
      months = 6;
    } else if (dateRange == '1 year') {
      months = 12;
    } else if (dateRange == '5 years') {
      months = 60;
    }
    return months;
  }

  private hasAccessToHeatmapBlock(blockType: HeatmapBlockTypeEnum): boolean {
    let access: boolean = false;

    switch (blockType) {
      case HeatmapBlockTypeEnum.AVERAGE_SALES:
        if (this.userAccessControls.showHeatmapAverageSales) {
          access = true;
        }
        break;
      case HeatmapBlockTypeEnum.GROWTH_RATE:
        if (this.userAccessControls.showHeatmapGrowthRate) {
          access = true;
        }
        break;
      case HeatmapBlockTypeEnum.MARKET_TURNOVER:
        if (this.userAccessControls.showHeatmapMarketTurnover) {
          access = true;
        }
    }

    return access;
  }

  private hasAccessToHeatmapNHood(nhoodType: HeatmapNhoodTypeEnum): boolean {
    let access: boolean = false;

    switch (nhoodType) {
      case HeatmapNhoodTypeEnum.AVERAGE_RENTAL_PERCENTAGE:
        if (this.userAccessControls.heatmapRentalPercentGeo) {
          access = true;
        }
        break;

    }

    return access;
  }


  private isChangesInHeatmapRequestDetected(nelat: number, nelng: number, swlat: number, swlng: number, dateRangeInMonths: number, isCondo: string) {
    var changesDetected;

    if (this.lastNELat != null && this.lastNELat == nelat &&
      this.lastNELng != null && this.lastNELng == nelng &&
      this.lastSWLat != null && this.lastSWLat == swlat &&
      this.lastSWLng != null && this.lastSWLng == swlng &&
      this.lastHeatmapDateRange != null && this.lastHeatmapDateRange == dateRangeInMonths &&
      this.lastIsCondoFlag != null && this.lastIsCondoFlag == isCondo) {

      //switching from Average Sales, Growth Rate and Turnover Rate, in any order,
      //without changing the map boundary, date range and property type does not require a call to the server
      //as all three sets of data were retrieved all at once
      changesDetected = false;

    } else {
      this.lastNELat = nelat;
      this.lastNELng = nelng;
      this.lastSWLat = swlat;
      this.lastSWLng = swlng;
      this.lastHeatmapDateRange = dateRangeInMonths;
      this.lastIsCondoFlag = isCondo;
      changesDetected = true;
    }

    this.loggerService.logDebug((changesDetected) ? 'Detected heatmap request changes' : 'No changes in heatmap request detected');
    return changesDetected;
  }


  private isChangesInHeatmapNhoodRequestDetected(nelat: number, nelng: number, swlat: number, swlng: number) {
    var changesDetected;

    if (this.lastNELat != null && this.lastNELat == nelat &&
      this.lastNELng != null && this.lastNELng == nelng &&
      this.lastSWLat != null && this.lastSWLat == swlat &&
      this.lastSWLng != null && this.lastSWLng == swlng) {

      changesDetected = false;

    } else {
      this.lastNELat = nelat;
      this.lastNELng = nelng;
      this.lastSWLat = swlat;
      this.lastSWLng = swlng;
      changesDetected = true;
    }

    this.loggerService.logDebug((changesDetected) ? 'Detected heatmap request changes' : 'No changes in heatmap request detected');
    return changesDetected;
  }

  /************ HEATMAP - END ************/

  openSnackBarError(msg: string) {
    this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
  }

  addArnInfoBox(arn: string, centroid: Centroid, pinLat: number, pinLong: number, map: google.maps.Map) {
    if(centroid) {
      var arnPixelOffset = new google.maps.Size(1, -40)
      var htmlContent =
        '<div class="arnboxstyle">' +
        '<span class="arnstyle">ARN</span>' +
        '<span class="arnnumberstyle">' + arn + '</span>' +
        '</div>' +
        '<div class="trianglestylearn" style="display:none">' +
        '<img src="/assets/img/down-arrow-box.png" />' +
        '</div>';
      if (pinLat && pinLong) {
        if (pinLat > centroid.latitude && pinLong > centroid.longitude) {
          arnPixelOffset = new google.maps.Size(-140, -40)
        } else if (pinLat < centroid.latitude && pinLong > centroid.longitude) {
          arnPixelOffset = new google.maps.Size(-140, -40)
        } else if (pinLat < centroid.latitude && pinLong < centroid.longitude) {
          arnPixelOffset = new google.maps.Size(-5, -40)
        } else {
          arnPixelOffset = new google.maps.Size(-5, -40)
        }
      }
      const infoBox = new InfoBox({
        content: htmlContent,
        disableAutoPan: false,
        maxWidth: 0,
        pixelOffset: arnPixelOffset,
        zIndex: null,
        boxStyle: {
          background: null,
          opacity: 1,
          width: "0px"
        },
        position: new google.maps.LatLng(centroid.latitude, centroid.longitude),
        closeBoxMargin: "10px 2px 2px 2px",
        closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
        infoBoxClearance: new google.maps.Size(1, 1),
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false
      });
      infoBox.open(map);
    }
  }

  addPinInfoBox(pin: string, map: google.maps.Map, lat: number, long: number) {
    var latLong = new google.maps.LatLng(lat, long);
    var htmlContent = "";
    if (pin != null && pin != "") {
      htmlContent =
        '<div class="pinboxstyle">' +
        '<span class="pinstyle">PIN</span>' +
        '<span class="pinnumberstyle">' + pin + '</span>' +
        '</div>' +
        '<div class="trianglestylepin" style="display:none">' +
        '<img src="/assets/img/down-arrow-box.png" />' +
        '</div>';
    }
    const infoBox = new InfoBox({
      content: htmlContent,
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(-104, 10),
      zIndex: null,
      boxStyle: {
        background: null,
        opacity: 1,
        width: "0px"

      },
      position: latLong,
      closeBoxMargin: "10px 2px 2px 2px",
      closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: "floatPane",
      enableEventPropagation: false
    });
    infoBox.open(map);
  }

  addNewDrawAssessmentRequest(arn: string) {
    this._drawAssessmentRequest.next(arn);
  }

  drawAssessment(polygons: Polygon[], map: google.maps.Map): google.maps.Polyline {
    let {bounds, polygonCoords} = this.calculateBounds(polygons);

    let lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      strokeWeight: 2,
      scale: 3
    };

    let line = new google.maps.Polyline({
      path: polygonCoords,
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: '50%',
        repeat: '15px'
      }],
      strokeColor: '#ff9c00',

    });
    line.setMap(map);
    this.fitBounds(bounds, false);
    return line;
  }

  private calculateBounds(polygons: Polygon[]) {
    let bounds = new google.maps.LatLngBounds();
    let polygonCoords: { lat: number; lng: number; }[] = [];
    polygons.forEach(polygon => {
      polygonCoords.push({
        "lat": polygon.latitude,
        "lng": polygon.longitude
      });
      bounds.extend(new google.maps.LatLng(polygon.latitude, polygon.longitude));
    });
    return {bounds, polygonCoords};
  }

  drawPropertyReportPolygon(polygons: Polygon[], strokeColor: string, strokeOpacity: number, strokeWeight: number, fillColor: string, fillOpacity: number, map: google.maps.Map): google.maps.Polygon {
    let {bounds, polygonCoords} = this.calculateBounds(polygons);

    let propertyReportPolygon = new google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: strokeColor,
      strokeOpacity: strokeOpacity,
      strokeWeight: strokeWeight,
      fillColor: fillColor,
      fillOpacity: fillOpacity
    });

    propertyReportPolygon.setMap(map);
    this.fitBounds(bounds, false);

    return propertyReportPolygon;
  }

  getGMapConfigs(centroid: Centroid) {
    let lat = 0;
    let long = 0;
    let mapOptions;
    let zoomControlOptions: google.maps.ZoomControlOptions = {
      position : google.maps.ControlPosition.TOP_RIGHT
    };
    if(centroid) {
      lat = centroid.latitude;
      long = centroid.longitude;
      let coordinates = new google.maps.LatLng(lat, long);

      mapOptions = {
        center: coordinates,
        zoom: 17,
        scrollwheel: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: true,
        fullscreenControl: true,
        zoomControl: true,
        zoomControlOptions: zoomControlOptions,
      };
    }
    return {lat, long, mapOptions};
  }

  toggleSearchComparablesDrawPolygonMode = (flag: boolean) => {
    if (flag) {
      this.searchComparablesDrawingManager?.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else {
      this.searchComparablesDrawingManager?.setDrawingMode(null);
      this.getMap()?.setOptions({
        draggableCursor: ''
      });
      this.cmaPolygonStartPath = null;
      this.closeSearchComparablesInProgressPolygonInstructions();
    }
  }

  isSearchComparablesInPolygonDrawingMode = (): boolean => {
    let polygonDrawMode: boolean = false;

    polygonDrawMode = this.searchComparablesDrawingManager?.getDrawingMode() == google.maps.drawing.OverlayType.POLYGON;
    return polygonDrawMode;
  }

  /**
   * Needed since google maps api exposes the "polygoncomplete" event but not the "polygonstart" or "overlaystart" event.
   * This "polygonstart" event has been requested by the developer community since 2011 but has not yet been provided.
   *
   * @param latLng
   */
  setSearchComparablesPolygonStartPath = (latLng: google.maps.LatLng) => {
    this.cmaPolygonStartPath = latLng;
    this.loggerService.logDebug(`polygon search area start path set to ${latLng.lat()}, ${latLng.lng()}`);

    var htmlContent =
    '<div class="search-comps-polygon-in-progress-instruction-box">' +
      '<span class="left"></span>' +
      '<span class="right">Press&nbsp;esc&nbsp;key&nbsp;to&nbsp;cancel&nbsp;this&nbsp;polygon,&nbsp;double&nbsp;click&nbsp;to&nbsp;close.</span>' +
    '</div>' +
      '<div class="trianglestylearn" style="display:none">' +
      '<img src="/assets/img/down-arrow-box.png" />' +
    '</div>';

    this.cmaPolygonInfoBoxInProgressInstructions = new InfoBox({
      content: htmlContent,
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(15, -30),
      zIndex: null,
      boxStyle: {
        background: null,
        opacity: 1,
        width: "0px"
      },
      position: latLng,
      closeBoxMargin: "10px 2px 2px 2px",
      closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: "floatPane",
      enableEventPropagation: false
    });
    this.cmaPolygonInfoBoxInProgressInstructions.open(this.getMap());
  }

  showSearchComparablesStartPolygonInstructions = (latLng: google.maps.LatLng) => {
    var htmlContent =
    '<div class="search-comps-polygon-in-progress-instruction-box">' +
      '<span class="left"></span>' +
      '<span class="right">Click&nbsp;to&nbsp;start&nbsp;drawing&nbsp;the&nbsp;polygon,&nbsp;press&nbsp;esc&nbsp;to&nbsp;cancel.</span>' +
    '</div>' +
      '<div class="trianglestylearn" style="display:none">' +
      '<img src="/assets/img/down-arrow-box.png" />' +
    '</div>';

    this.cmaPolygonInfoBoxStartInstructions = new InfoBox({
      content: htmlContent,
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(15, -30),
      zIndex: null,
      boxStyle: {
        background: null,
        opacity: 1,
        width: "0px"
      },
      position: latLng,
      closeBoxMargin: "10px 2px 2px 2px",
      closeBoxURL: "http://www.google.com/intl/en_us/mapfiles/close.gif",
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: "floatPane",
      enableEventPropagation: false
    });
    this.cmaPolygonInfoBoxStartInstructions.open(this.getMap());
  }

  isSearchComparablesPolygonStartPathSet = (): boolean => {
    return (this.cmaPolygonStartPath != null);
  }

  isSearchComparablesStartPolygonInstructionsVisible = (): boolean => {
    let visible: boolean = false;

    if (this.cmaPolygonInfoBoxStartInstructions && this.cmaPolygonInfoBoxStartInstructions.getMap()) {
      visible = true;
    }

    return visible;
  }

  closeSearchComparablesInProgressPolygonInstructions = () => {
    this.cmaPolygonInfoBoxInProgressInstructions?.close();
    this.cmaPolygonInfoBoxInProgressInstructions?.setMap(null);
  }

  closeSearchComparablesStartPolygonInstructions = () => {
    this.cmaPolygonInfoBoxStartInstructions?.close();
    this.cmaPolygonInfoBoxStartInstructions?.setMap(null);
  }

  cancelSearchComparablesPolygonDrawing = () => {
    this.loggerService.logDebug('cancelling polygon search area');
    this.cancelPolygonDrawing();
    this.closeSearchComparablesStartPolygonInstructions();
    this.closeSearchComparablesInProgressPolygonInstructions();
    this.toggleSearchComparablesDrawPolygonMode(false);
  }

  isUserDrawingPolygon = () => {
    return !this.isPolygonDrawingCancelled() && this.searchComparablesDrawingManager?.getDrawingMode() == google.maps.drawing.OverlayType.POLYGON;
  }

  isPolygonDrawingCancelled = () => {
    return this.cmaCancelPolygonDrawing && this.searchComparablesDrawingManager?.getDrawingMode() == null;
  }

  continuePolygonDrawing = () => {
    this.setCancelPolygonDrawing(false);
    this.startSearchComparablesPolygonDrawingManager();
  }

  private cancelPolygonDrawing = () => {
    this.setCancelPolygonDrawing(true);
  }

  private setCancelPolygonDrawing = (cancel: boolean) => {
    this.cmaCancelPolygonDrawing = cancel;
  }

  private startSearchComparablesPolygonDrawingManager = () => {
    this.searchComparablesDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        fillColor: this.COMPARABLE_SALE_POLYGON_FILL_COLOR,
        fillOpacity: this.COMPARABLE_SALE_POLYGON_FILL_OPACITY,
        strokeWeight: this.COMPARABLE_SALE_POLYGON_STROKE_WEIGHT,
        strokeColor: this.COMPARABLE_SALE_POLYGON_STROKE_COLOR,
        clickable: false,
        draggable: false,
        editable: true,
        paths: [],
        zIndex: 1,
        geodesic: true
      },
      map: this.getMap()
    });

    google.maps.event.addListener(this.searchComparablesDrawingManager, "overlaycomplete", (event : any) => {
      let overlay: any = event.overlay;
      overlay.custom_id = new Date().getTime(); //set a custom handle to this overlay

      if (!this.isPolygonDrawingCancelled()) {
        //add the new polygon overlay created by the drawing manager
        this.getUserDrawnPolygons().push(overlay);
      }

    });

    google.maps.event.addListener(this.searchComparablesDrawingManager, "polygoncomplete", (overlay : any) => {

      //there is currently no way to determine which polygon is being resized when multi polygon search is supported
      //i.e, the event call to 'insert_at' does not contain the unique polygon id ("polygonId") in the path array
      overlay.draggable = false;
      overlay.editable = false;

      if (this.isPolygonDrawingCancelled()) {
        this.setCancelPolygonDrawing(false);
        overlay.setMap(null); // the api auto-closes the polygon when it is cancelled, so manually remove it
        this.toggleSearchComparablesDrawPolygonMode(false);
        return;
      }

      var path = overlay.getPath();
      var pathList = path.getArray();
      this.postCustomPolygonDrawnExtraSteps(pathList, overlay.custom_id);

      google.maps.event.addListener(path, 'insert_at', () => {
        var temp = path.getArray();
        this.postCustomPolygonDrawnExtraSteps(temp, overlay.custom_id);
      });

      google.maps.event.addListener(path, 'set_at', () => {
        var temp = path.getArray();
        this.postCustomPolygonDrawnExtraSteps(temp, overlay.custom_id);
      });

      this.toggleSearchComparablesDrawPolygonMode(false);
    });

  }

  //Additional things that need to be done after the drawing manager renders the polygon.
  postCustomPolygonDrawnExtraSteps(pathList: any, polygonId: number) {

		let llBounds = new google.maps.LatLngBounds();
		let polygonPoints = [];

		let uniquePolygonId = polygonId;
		for (let i: number = 0; i < pathList.length; i++) {
			let item: any = {};

			item["polygonId"] = uniquePolygonId;
			item["latitude"] = pathList[i].lat();
			item["longitude"] = pathList[i].lng();

			if (i == 0) {
        let markerIcon = {
          url: 'assets/img/svg/map/drawing_map_x_hover.svg',
          scaledSize: new google.maps.Size(20, 20),
          anchor: new google.maps.Point(16, 20)
        }

        let polygonCloseMarker = new google.maps.Marker({
          map: this.gMap,
          icon: markerIcon,
          title:"Remove polygon search area",
          position: new google.maps.LatLng(item.latitude, item.longitude)
        });
				item["icon"] = polygonCloseMarker;

        polygonCloseMarker.addListener("click", () => {
          this.removeSearchComparablesPolygon(uniquePolygonId);
        });

				this.getPolygonCloseMarkers().push(item);
			}

			var latlng = new google.maps.LatLng(pathList[i].lat(), pathList[i].lng());
			llBounds.extend(latlng);

			var polygonPoint: any = {};
			if (i == 0) {
				polygonPoint = {
					"polygonId": uniquePolygonId,
					"latitude": pathList[i].lat(),
					"longitude": pathList[i].lng()
				};

			} else {
				polygonPoint = {
					"latitude": pathList[i].lat(),
					"longitude": pathList[i].lng()
				};
			}
			polygonPoints.push(polygonPoint);
		}

    let polygon: any = {};
    polygon["coordinates"] = polygonPoints;

    //save the new polygon coordinates
		this.getUserDrawnPolygonsCoordinates().push(polygon);

    //zoom out to make the new polygon visible
		this.getUserDrawnPolygonBounds().union(llBounds);

    //prompt to add more polygons
    if (this.userAccessControls.comparableSalesMultiPolygonSearch) {
      if (this.getRenderedPolygonsCount() < this.maximumSearchPolygonsAllowed) {
        setTimeout(() => {
          this.searchComparablesFormService.promptAddPolygonSearchArea(true);
        }, 100);
      } else {
        this.searchComparablesFormService.promptAddPolygonSearchArea(false);
        setTimeout(() => {
          this.setFocusOnUserDrawnPolygons();
        }, 100);

      }
    } else {
      setTimeout(() => {
        this.setFocusOnUserDrawnPolygons();
      }, 100);
    }

	}

  renderComparableSalesSnapshotPolygons = (polygonPaths: google.maps.LatLng[], polygonId: number) => {
    setTimeout(() => {
      const polygon = new google.maps.Polygon({
        paths: polygonPaths,
        strokeColor: this.COMPARABLE_SALE_POLYGON_STROKE_COLOR,
        strokeWeight: this.COMPARABLE_SALE_POLYGON_STROKE_WEIGHT,
        fillColor: this.COMPARABLE_SALE_POLYGON_FILL_COLOR,
        fillOpacity: this.COMPARABLE_SALE_POLYGON_FILL_OPACITY,
        clickable: false
      });

      polygon.set('polygonId', polygonId);
      polygon.setMap(this.gMap);
      this.cmaMultiSnapshotPolygons.push(polygon);
    }, 200);
  }

  setUserDrawnPolygonBounds = (bounds: google.maps.LatLngBounds) => {
    this.cmaMultiPolygonBounds = bounds;
  }

  getUserDrawnPolygonBounds = () => {
    return this.cmaMultiPolygonBounds;
  }

  getSelectedMunicipalityBounds = () => {
    return this.cmaMultiMunicipalityBounds;
  }

  setFocusOnUserDrawnPolygons = () => {
		this.getMap().fitBounds(this.getUserDrawnPolygonBounds());
		this.getMap().panTo(this.getUserDrawnPolygonBounds().getCenter());
  }

  private removeSearchComparablesPolygon(polygonId: number) {

    this.loggerService.logDebug(`removing polygon with id ${polygonId}`);
    
    //if the polygon was rendered by the drawing manager, remove it
    let overlayPolygonIndexToRemove: number = this.getUserDrawnPolygons().findIndex(overlay => overlay.custom_id === polygonId);
    if (overlayPolygonIndexToRemove > -1) {
      this.getUserDrawnPolygons()[overlayPolygonIndexToRemove].setMap(null);
      this.getUserDrawnPolygons().splice(overlayPolygonIndexToRemove, 1);
    }

    //if the polygon was rendered from the snapshot, remove it
    let snapshotPolygonIndexToRemove: number = this.getComparableSalesSnapshotPolygons().findIndex(polygon => polygon.get('polygonId') === polygonId);
    if (snapshotPolygonIndexToRemove > -1) {
      this.getComparableSalesSnapshotPolygons()[snapshotPolygonIndexToRemove].setMap(null);
      this.getComparableSalesSnapshotPolygons().splice(snapshotPolygonIndexToRemove, 1);
    }
    
    //remove the polygon's close button
    let markerIndex: number = this.getPolygonCloseMarkers().findIndex(polygonMarker => polygonMarker.polygonId === polygonId);
    if (markerIndex > -1) {
      this.getPolygonCloseMarkers()[markerIndex].icon.setMap(null);
    }
    this.getPolygonCloseMarkers().splice(markerIndex, 1);

    //remove the polygon's saved coordinates
    let polyInstanceToRemove: number = this.getUserDrawnPolygonsCoordinates().findIndex(polygon => polygon.coordinates[0].polygonId === polygonId);
    if (polyInstanceToRemove > -1) {
      this.getUserDrawnPolygonsCoordinates().splice(polyInstanceToRemove, 1);
    }

    //re-adjust the map after the polygon has been removed
    if (this.getRenderedPolygonsCount() > 0) {
      this.cmaMultiPolygonBounds = new google.maps.LatLngBounds();
      for (let i = 0; i < this.getRenderedPolygonsCount(); i++) {
        let polygon = this.getUserDrawnPolygonsCoordinates()[i];
        let polygonPoints = polygon.coordinates;
        for (let j = 0; j < polygonPoints.length; j++) {
          let latlng = new google.maps.LatLng(polygonPoints[j].latitude, polygonPoints[j].longitude);
          this.getUserDrawnPolygonBounds().extend(latlng);
        }
      }

      this.getMap().fitBounds(this.getUserDrawnPolygonBounds());
      this.getMap().panTo(this.getUserDrawnPolygonBounds().getCenter());
    }

    this.toggleSearchComparablesDrawPolygonMode(false);
  }

  renderStreetViewMapMarker = (map: google.maps.Map, position: google.maps.LatLng) => {
    let markerIcon = {
      url: this.searchResultSelectedMarkerIcon,
      scaledSize: this.streetViewMarkerSize
    }

    if (this.streetViewMapMarker) this.panoramaMarker.setMap(null);

    let streetViewMapMarker = new google.maps.Marker({
      position: position,
      map: map,
      icon: markerIcon
    });
  }

  renderStreetViewPanoramaMarker = (panorama: google.maps.StreetViewPanorama, position: google.maps.LatLng) => {
    let markerIcon = {
      url: this.searchResultSelectedMarkerIcon,
      scaledSize: this.streetViewMarkerSize
    }

    if (this.panoramaMarker) this.panoramaMarker.setMap(null);

    let panoramaMarker = new google.maps.Marker({
      position: position,
      map: panorama,
      icon: markerIcon
    });

    //seems to be a valid workaround to make the marker visible on the streetview panorama
    // panorama.setZoom(1);
    // panorama.setVisible(true);
  }

  /**
   * This 2G implementation which is being re-implemented in 3G is quite poor in that it only supports
   * searching one condo building within the search area.
   * TODO: A nice improvement would be to allow the user to select from a list of all condo buildings within the circular search area.
   *
   * @returns
   */
  getCondoWithinCircularSearchArea = (): google.maps.Marker | undefined => {
    let condoMarker: google.maps.Marker | undefined;

    let circularSearchArea = this.getCircularSearchAreaBufferStorage()?.find(obj => {
      //@ts-ignore
      return obj.circularSearchArea;
    })

    if (circularSearchArea) {
      //find the first condo (which means a random condo since there could be more than one)
      //within the circular search area
      //TODO: we could improve the UI to display a list of condos within the circular search area for the user to choose from.
      condoMarker = this.getRenderedMarkers()?.find(marker => {
        if (this.isCondoMarker(marker)) {
          if (circularSearchArea.getBounds().contains(marker.getPosition())) {
            //note: in the background google still uses a rectangle, so everything inside the rectangular bounding box,
            //but outside the circle will be recognized as inside the latLng bounds.
            //so it is possible a condo marker gets picked up as being within the circle even when it is not.
            return marker;
          }
        }
        return undefined;
      })
    }

    this.loggerService.logDebug(`condo found in search area?`, condoMarker);

    return (condoMarker);
  }

  adjustMapZoomLevelForRadiusSetting = (radius: string) => {
    let radiusInMeters: number;
    let currentRadius = radius;

    var zoomLevel = 16;
    if (currentRadius != null && currentRadius.indexOf("km") > 0) {
      //@ts-ignore
      radiusInMeters = currentRadius.substr(0, currentRadius.indexOf("km")) * 1000;
      if (radiusInMeters <= 1000) {
        zoomLevel = 15;
      } else if (radiusInMeters > 1000 && radiusInMeters <= 2500) {
        zoomLevel = 13;
      } else if (radiusInMeters > 2500 && radiusInMeters <= 5000) {
        zoomLevel = 12;
      } else if (radiusInMeters > 5000) {
        zoomLevel = 11;
      }
    } else if (currentRadius != null && currentRadius.indexOf("m") > 0) {
      //@ts-ignore
      radiusInMeters = currentRadius.substr(0, currentRadius.indexOf("m"));
      if (radiusInMeters <= 250) {
        zoomLevel = 16;
      } else {
        zoomLevel = 15;
      }
    } else {
      zoomLevel = 16;
    }

    this.setMapZoomLevel(zoomLevel);
  }

  centerSubjectPropertyOnMap = () => {
    let propertyDetail: PropertyDetail = this.propertyReportService.getSubjectProperty();
    if (propertyDetail?.pii?.pinXy?.centroid) {
      this.gaService.featureClicked(GA_Feature.CENTER_PROPERTY_ON_MAP, 'PIN', propertyDetail.pii.pin);
      let centroid: Centroid = propertyDetail?.pii?.pinXy?.centroid;
      let latLng = new google.maps.LatLng(centroid.latitude, centroid.longitude);
      this.getMap().panTo(latLng);
    }
  }

  handleMapClickedForSearchComparables = (coordinates: google.maps.LatLng) => {
    this._mainMapClickedForSearchComparables.next(coordinates);
  }

  isCondoMarker = (marker: google.maps.Marker | undefined) => {
    return marker?.get('isCondo');
  }

  setMapOption(option: string, value: boolean) {
    this.getMap()?.set(option, value);
  }

  setUserDrawnPolygons = (overlays: any[]) => {
    this.cmaPolygonOverlays = overlays;
  }

  getUserDrawnPolygons = () => {
    return this.cmaPolygonOverlays;
  }

  getComparableSalesSnapshotPolygons = () => {
    return this.cmaMultiSnapshotPolygons;
  }

  getPolygonCloseMarkers = () => {
    return this.cmaMultiPolygonCloseMarkers;
  }

  getUserDrawnPolygonsCoordinates = () => {
    return this.cmaMultiPolygonCoordinates;
  }

  getRenderedPolygonsCount = () => {
    return this.getUserDrawnPolygonsCoordinates()?.length;
  }

  getRenderedMunicipalityPolygons = () => {
    return this.cmaMunicipalityPolygons;
  }

  isMaximumSearchPolygonsReached = () => {
    return (this.getRenderedPolygonsCount() == this.maximumSearchPolygonsAllowed);
  }

  isMarkersExist = () => {
    return this.isRenderedMarkersExist() || this.isClickedMarkersExist();
  }

  isRenderedMarkersExist = () => {
    return !_.isEmpty(this.getRenderedMarkers());
  }

  isClickedMarkersExist = () => {
    return !_.isEmpty(this.getClickedMarkers());
  }

  isMarkerVisible = (marker: google.maps.Marker) => {
    return marker?.getMap() != null;
  }

  hideMarker = (marker: google.maps.Marker) => {
    marker?.setMap(null);
  }

  showMarker = (marker: google.maps.Marker) => {
    marker?.setMap(this.gMap);
  }

  getSearchComparableMarkers = () => {
    return this.searchComparableMarkers;
  }

  getRenderedMarkers = () => {
    return this.renderedMarkers;
  }

  getRenderedMarkersCount = () => {
    return this.getRenderedMarkers().length;
  }

  getClickedMarkers = () => {
    return this.clickMarkers;
  }

  getClickedMarkersCount = () => {
    return this.getClickedMarkers().length;
  }

  clearLroPolygon = () => {
    if (_.isEmpty(this.renderedLroPolygons)) return;

    this.renderedLroPolygons.forEach(polygon => {
      polygon.setMap(null);
    });

    while (this.renderedLroPolygons.length > 0) {
      this.renderedLroPolygons.pop();
    }
  }

  clearOmnibarSearchBlockPolygons = () => {
    if (_.isEmpty(this.renderedOmnibarSearchBlockPolygons)) return;

    this.renderedOmnibarSearchBlockPolygons.forEach(polygon => {
      polygon.setMap(null);
    });

    while (this.renderedOmnibarSearchBlockPolygons.length > 0) {
      this.renderedOmnibarSearchBlockPolygons.pop();
    }
  }

  clearRenderedMarkers = () => {
    if (_.isEmpty(this.getRenderedMarkers())) return;

    this.getRenderedMarkers().forEach(marker => {
      marker.setMap(null);
    });

    while (this.getRenderedMarkers().length > 0) {
      this.getRenderedMarkers().pop();
    }
  }

  clearSearchComparableMarkers = () => {
    if (_.isEmpty(this.getSearchComparableMarkers())) return;

    this.getSearchComparableMarkers().forEach(marker => {
      marker.setMap(null);
    });

    while (this.getSearchComparableMarkers().length > 0) {
      this.getSearchComparableMarkers().pop();
    }
  }

  clearClickMarkers = () => {
    if (_.isEmpty(this.clickMarkers)) return;

    this.clickMarkers.forEach(marker => {
      marker.setMap(null);
    });

    while (this.clickMarkers.length > 0) {
      this.clickMarkers.pop();
    }
  }

  isClickPolygonsExist = () => {
    return !_.isEmpty(this.clickPolygons);
  }

  clearClickPolygons = () => {
    if (!this.isClickPolygonsExist()) return;

    this.clickPolygons.forEach(polygon => {
      polygon.setMap(null);
    });

    while (this.clickPolygons.length > 0) {
      this.clickPolygons.pop();
    }
  }

  clearRenderedMarkerInfoBubbles = () => {
    for (const pinBubble of this.renderedMarkerInfoBubbles.values()) {
      pinBubble.bubble.close();
      pinBubble.bubble.setPosition(null);
    }
    this.renderedMarkerInfoBubbles.clear();
    if (this.currentMarkerInfoBubble?.bubble) this.currentMarkerInfoBubble.bubble.close();
  }

  isOmnibarSearchResultsPolygonsExist = () => {
    return !_.isEmpty(this.renderedOmnibarSearchResultsPolygons);
  }

  clearOmnibarSearchResultsPolygons = () => {
    if (!this.isOmnibarSearchResultsPolygonsExist()) return;

    this.renderedOmnibarSearchResultsPolygons.forEach(polygon => {
      polygon.setMap(null);
    });

    while (this.renderedOmnibarSearchResultsPolygons.length > 0) {
      this.renderedOmnibarSearchResultsPolygons.pop();
    }
  }

  clearTownshipLotPolygons = () => {
    if (_.isEmpty(this.renderedTownshipLotPolygons)) return;

    this.renderedTownshipLotPolygons.forEach(polygon => {
      polygon.setMap(null);
    });

    while (this.renderedTownshipLotPolygons.length > 0) {
      this.renderedTownshipLotPolygons.pop();
    }
  }

  clearSearchComparablesObjects = () => {
    this.clearSearchComparablesCircleObjects();
    this.clearSearchComparablesPolygonObjects();
    this.clearSearchComparablesMunicipalityObjects();
  }

  setCircularSearchAreaBufferStorage = (storage: any[]) => {
    this.cmaCircularBufferObjects = storage;
  }

  getCircularSearchAreaBufferStorage = () => {
    return this.cmaCircularBufferObjects;
  }

  clearSearchComparablesPIIPolygonObjects = () => {
    this.getCircularSearchAreaBufferStorage()?.forEach(object => {
      if (object instanceof google.maps.Polygon) {
        object.setMap(null);
      }
    });
  }

  clearSearchComparablesCircleObjects = () => {

    this.getCircularSearchAreaBufferStorage()?.forEach(object => {
      object.setMap(null);
    });

    while (this.getCircularSearchAreaBufferStorage()?.length > 0) {
      this.getCircularSearchAreaBufferStorage().pop();
    }
  }

  clearSearchComparablesPolygonObjects = () => {
    this.toggleSearchComparablesDrawPolygonMode(false);

    //remove the polygons rendered by the drawing manager
    if (!_.isEmpty(this.getUserDrawnPolygons())) {
      this.getUserDrawnPolygons().forEach(overlay => {
        overlay.setMap(null);
      })
    }

    //remove the polygons rendered from the snapshot
    if (!_.isEmpty(this.getComparableSalesSnapshotPolygons())) {
      this.getComparableSalesSnapshotPolygons().forEach(polygon => {
        polygon.setMap(null);
      })
    }

    //remove the polygon close buttons
    this.getPolygonCloseMarkers().forEach(item => {
      item.icon.setMap(null);
    })

    this.cmaPolygonOverlays = [];
    this.cmaMultiPolygonCoordinates = [];
    this.cmaMultiSnapshotPolygons = [];
    this.cmaMultiPolygonCloseMarkers = [];
    this.cmaMultiPolygonBounds = new google.maps.LatLngBounds();
  }

  clearSearchComparablesMunicipalityObjects = () => {
    this.getRenderedMunicipalityPolygons().forEach(object => {
      object.setMap(null);
    });

    while (this.getRenderedMunicipalityPolygons().length > 0) {
      this.getRenderedMunicipalityPolygons().pop();
    }

    this.cmaMultiMunicipalityBounds = new google.maps.LatLngBounds();
  }

  clearAllRenderedMapObjects = () => {
    this.clearRenderedMarkers();
    this.clearSearchComparableMarkers();
    this.clearClickMarkers();
    this.clearClickPolygons();
    this.clearRenderedMarkerInfoBubbles();
    this.clearOmnibarSearchResultsPolygons();
    this.clearTownshipLotPolygons();
    this.clearSearchComparablesObjects();
    this.clearAllHeatmapBlocks();
    this.clearAllHeatmapNhood();
    this.clearOmnibarSearchBlockPolygons();
  }

  /**
   * Clears all rendered map objects except for the object types still required by the specified <code>ScreenNameEnum</code> screen.
   *
   * @param screenNameEnum
   * @param identifier
   */
  clearAllExceptRenderedMapObjectsForScreen = (screenNameEnum: ScreenNameEnum, identifier?: string) => {
    switch (screenNameEnum) {
      case ScreenNameEnum.PROPERTY_REPORT:
        this.clearRenderedMarkers();

        let pin: string | undefined = identifier;
        if (pin) {
          if (!this.searchComparablesResultService.isPinOpenedFromSearchResults(pin)) {
            this.clearSearchComparableMarkers();  //clear the result markers only when this pin was not part of the most recent search results
          }
        }

        this.clearClickMarkers();
        this.clearClickPolygons();
        this.clearRenderedMarkerInfoBubbles();
        this.clearOmnibarSearchResultsPolygons();
        this.clearTownshipLotPolygons();
        this.clearSearchComparablesObjects();
        this.clearAllHeatmapBlocks();
        this.clearAllHeatmapNhood();
        this.clearOmnibarSearchBlockPolygons();
        break;
    }
  }

  calculateOffsets(heading: number) {
    var pixelOffset = new google.maps.Size(-5, -10);
    var angleOffset = 90;

    if (heading > 90 && heading < 135) {
      pixelOffset = new google.maps.Size(-10, 5);
      angleOffset = 90;
    } else if (heading > 135 && heading < 180) {
      pixelOffset = new google.maps.Size(-10, 5);
      angleOffset = 90;
    } else if (heading > 0 && heading < 45) {
      pixelOffset = new google.maps.Size(-10, -20);
      angleOffset = 90;
    } else if (heading > 45 && heading < 90) {
      pixelOffset = new google.maps.Size(-5, -20);
      angleOffset = 90;
    } else if (heading > -90 && heading < -45) {
      pixelOffset = new google.maps.Size(-10, 5);
      angleOffset = 270;
    } else if (heading > -45 && heading < 0) {
      pixelOffset = new google.maps.Size(-10, 5);
      angleOffset = 270;
    } else if (heading > -135 && heading < -90) {
      pixelOffset = new google.maps.Size(-5, -20);
      angleOffset = 270;
    } else if (heading > -180 && heading < -135) {
      pixelOffset = new google.maps.Size(-10, -20);
      angleOffset = 270;
    }
    return {pixelOffset, angleOffset};
  }

  initializeUser = () => {
    this.userService.userObservable
    .pipe(takeUntil(this.destroyed$))
    .subscribe(value => {
      this.user = value;
      this.maximumSearchPolygonsAllowed = this.user.comparableSalesMultiplePolygonSearchMaxCount;
    });
  }

  private getMarkerByPin(pin: string) : google.maps.Marker[] {
    return this.getRenderedMarkers()
      .filter(marker => {
        let markerPin = marker.get('pin');
        if (this.isCondoMarker(marker)) {
          return markerPin.substring(0, 5) == pin.substring(0, 5);
        }
        return markerPin == pin;
      });
  }

  private changeMarkerIcon(pin? : string, newIcon?: any) {
    if (pin) {
      this.getMarkerByPin(pin)
        .forEach(marker => {
          marker.setIcon(newIcon);
        });
    }
  }

  setMarkerSelected(pin?: string) {
    this.changeMarkerIcon(pin, this.selectedMarkerIcon);
  }

  setMarkerDefault(pin?: string) {
    this.changeMarkerIcon(pin, this.defaultMarkerIcon);
  }

  /**
   * @method This method retrieves Environics data for the rental percentage for a dissemination area block (neighbourhood).
   */

  async renderNhoodHeatmapLayer(nhoodType: HeatmapNhoodTypeEnum, mapBounds: any, zoomLevel: number) {
    if (!this.hasAccessToHeatmapNHood(nhoodType)) return;

    if (zoomLevel >= DataService.HEATMAP_NHOOD_MIN_ZOOM_LEVEL && zoomLevel <= DataService.HEATMAP_MAX_ZOOM_LEVEL) {
      var nelat = mapBounds.getNorthEast().lat();
      var nelng = mapBounds.getNorthEast().lng();
      var swlat = mapBounds.getSouthWest().lat();
      var swlng = mapBounds.getSouthWest().lng();

      var loadingNhood = 'Loading heatmap for Rental Percentage...';
      let heatMapLoadingMarker = new google.maps.Marker({
        position: new google.maps.LatLng(this.getMapCenter().lat(), this.getMapCenter().lng()),
        map: this.getMap(),
        icon: this.loadingMarkerIcon(loadingNhood, zoomLevel)
      });

      if (this.isChangesInHeatmapNhoodRequestDetected(nelat, nelng, swlat, swlng)) {

        let heatmapResponse: any = await lastValueFrom(this.heatmapSearchService.getHeatmapNhood(nelat, nelng, swlat, swlng));

        heatMapLoadingMarker.setMap(null);

        if (heatmapResponse && heatmapResponse.heatmapNhoodAreas && heatmapResponse.heatmapNhoodAreas.length > 0) {
          this.loggerService.logDebug('Heatmap data available for Rental Percentage.');
          this.lastHeatmapNhoodRentalPercentageData = heatmapResponse.heatmapNhoodAreas;
          this.renderNhoodPolygonsAndData(nhoodType, heatmapResponse.heatmapNhoodAreas);
        } else {
          this.openSnackBarError('No heatmap data available for Rental Percentage.');
          this.clearAllHeatmapNhood();
          this.lastHeatmapNhoodRentalPercentageData = null;
        }

      } else {
        heatMapLoadingMarker.setMap(null);
        this.renderNhoodPolygonsAndData(nhoodType, this.lastHeatmapNhoodRentalPercentageData);
      }

    } else {
      this.clearAllHeatmapNhood();
    }
  }

}
