import { ChangeDetectionStrategy, Component, ElementRef, HostListener, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FusionChartDataSource } from "../../../../../../core/model/fusion-chart/fusion-chart-data-source";
import { DemographicsChartsUtility } from "../../../../../../shared/utility/fusion-charts/demographics-charts.utility";
import { DemographicsPopulation } from "../../../../../../core/model/demographics/demographics-population";
import { DataService } from "../../../../../../shared/service/data.service";
import { BaseChartComponent } from "../../../../../../core/component/base-chart/base-chart.component";
import { LoggerService } from "../../../../../../shared/service/log/logger.service";
import { FusionChartsModule } from "angular-fusioncharts";

@Component({
  selector: 'gema3g-gender-status',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './gender-status.component.html',
  imports: [
    FusionChartsModule
  ],
  styleUrls: ['./gender-status.component.scss']
})
export class GenderStatusComponent extends BaseChartComponent {
  @Input() population: DemographicsPopulation;
  showChart: boolean = false;

  constructor() {
    super();
  }

  private loggerService: LoggerService = inject(LoggerService);

  override loadChartData() {
    if(!this.pinOrArn || this.pinOrArn != this.population?.pinOrArn) {
      this.loggerService.logDebug(`First attempt to load gender status chart data for ${this.population?.pinOrArn}`);
      this.nbhDataSource = DemographicsChartsUtility.genderDataSource(DataService.DEFAULT_CHART_FONT, 'NBH', this.population?.PRCDDA, window.innerWidth);
      this.comDataSource = DemographicsChartsUtility.genderDataSource(DataService.DEFAULT_CHART_FONT, 'COM', this.population?.FSA, window.innerWidth);
      this.cityDataSource = (this.population?.PRCDCSD?.totalPopulation) ?
        DemographicsChartsUtility.genderDataSource(DataService.DEFAULT_CHART_FONT, 'CITY', this.population?.PRCDCSD, window.innerWidth) :
        new FusionChartDataSource();
      this.pinOrArn = this.population?.pinOrArn;
    } else {
      //this.loggerService.logDebug(`Attempt to reload gender status chart data for ${this.pinOrArn}`);
    }
  }

  override setHeightWidth() {
    //this.defaultWidth = "500";
    this.defaultHeight = "38"
    this.minWidth = 230;
    this.minHeight = 38;
  }
}
