import { inject, Pipe, PipeTransform } from '@angular/core';
import { DateUtilityService } from "../utility/date.utility";

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor() {
  }

  private dateUtilityService: DateUtilityService = inject(DateUtilityService);

  transform(value: any, ignoreTimeStamp?: boolean): any {
    return this.dateUtilityService.customFormatDate(value, 'MMM DD', ignoreTimeStamp);
  }
}
