import * as _ from 'lodash';
import { AfterViewInit, ChangeDetectorRef, Component, effect, ElementRef, EventEmitter, HostListener, inject, Inject, input, Input, OnChanges, OnDestroy, output, Output, signal, SimpleChanges, ViewChild, WritableSignal } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Centroid } from '../../../core/model/spatial/centroid';
import { MainMapState } from '../../../core/model/spatial/main-map-state';
import { MapLayerVisibility } from '../../../core/model/interface/map-layer-visibility';
import { MainMapService } from './main-map.service';
import { PropertyReportService } from '../../../shared/service/property-report.service';
import { OmnibarStateService } from '../../../shared/service/search/omnibar-state.service';
import { LroPolygonsService } from '../../../shared/service/lro-polygons.service';

import { lastValueFrom, skip, takeUntil } from "rxjs";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";

import { DataService } from '../../../shared/service/data.service';
import { HeatmapLayerState } from '../../../core/model/interface/heatmap-layer-state';
import { LocationService } from '../../../shared/service/location.service';
import { MapTileEnum } from "../../../core/enum/map-tile-enum";
import { StreetViewPoint } from "../../../core/model/spatial/street-view-point";
import { StreetViewUtility } from "../../../shared/utility/street-view.utility";
import { MapUtility } from '../../../shared/utility/map.utility';
import { defaultErrorMatSnackBarConfig, LocalStorageKey } from "../../../shared/constant/constants";
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { LoadingSpinnerService } from '../../../shared/service/loading-spinner.service';
import { StreetViewService } from '../../../shared/service/google-maps/street-view.service';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { MatSidenav } from '@angular/material/sidenav';
import { GenericPreference } from '../../../core/model/user/preference/generic-preference';
import { UserService } from '../../../shared/service/user.service';
import { GenericPreferenceLayerType } from '../../../account/preference/preference/preference-mapping';
import { Preference } from '../../../core/model/user/preference/preference';
import { SpatialSearchService } from '../../../shared/service/search/spatial-search.service';
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { ScreenOrientation } from '../../../core/enum/screen-orientation-enum';
import { Router } from '@angular/router';
import { FirstBaseSolutionService } from '../../../shared/service/first-base-solution.service';
import { ErrorUtil } from "../../../shared/service/error.util";
import { Pii } from '../../../core/model/property/pii';
import { OmnibarSearchService } from '../../../shared/service/search/omnibar-search.service';
import { SearchResult } from '../../../core/model/search-result/search-result';
import { HeatmapNhoodLayerState } from "../../../core/model/interface/heatmap-nhood-layer-state";
import { PropertyDetailSectionEnum } from '../../../core/enum/property-detail-section-enum';
import { PointOfView } from "../../../core/model/spatial/point-of-view";
import { GeoAddressMapOverlayComponent } from "../geo-address-map-overlay/geo-address-map-overlay.component";
import { BrowserDetectorService } from '../../../shared/service/browser-detector.service';

@Component({
    selector: 'gema3g-main-map',
    templateUrl: './main-map.component.html',
    styleUrls: ['./main-map.component.scss']
})
export class MainMapComponent extends BaseUnsubscribe implements AfterViewInit {

    constructor() {
        super();

        effect(() => {
            this.isMapControlsMoved = this.isMapControlsMovedInput();
        });
    }

    private userService = inject(UserService);
    private elementRef = inject(ElementRef);
    private mainMapService = inject(MainMapService);
    private propertyReportService = inject(PropertyReportService);
    private omnibarSearchService = inject(OmnibarSearchService);
    private omnibarStateService = inject(OmnibarStateService);
    private lroPolygonsService = inject(LroPolygonsService);
    private dataService = inject(DataService);
    private locationService = inject(LocationService);
    private _snackBar = inject(MatSnackBar);
    private router = inject(Router);
    private loggerService = inject(LoggerService);
    private loadingSpinnerService = inject(LoadingSpinnerService);
    private streetViewService = inject(StreetViewService);
    private screenManager = inject(ScreenManager);
    private spatialServiceService = inject(SpatialSearchService);
    private searchComparablesResultService = inject(SearchComparablesResultService);
    private fbsService = inject(FirstBaseSolutionService);
    private browserDetectionService = inject(BrowserDetectorService);
    private changeDetectorRef = inject(ChangeDetectorRef);

    @ViewChild('mainSideNavContainer', { static: false }) mainSideNavContainer: ElementRef;
    @ViewChild('mainMapComponentContainer', { static: false }) mapComponentContainer: ElementRef;
    @ViewChild('mainMapContainer', { static: false }) gMapContainer: ElementRef;
    @ViewChild('propertyStreetViewContainer', { static: false }) propertyStreetViewContainer: ElementRef;
    @ViewChild('matSideNav') matSideNav: MatSidenav;
    @ViewChild('geoAddressMapOverlayComponent') geoAddressMapOverlayComponent : GeoAddressMapOverlayComponent;
    @Output() mobileFullScreen = new EventEmitter<boolean>();
    isMapControlsMovedInput = input.required<boolean>({alias: 'isMapControlsMovedInput'});

    propertyReportSectionScrolled: PropertyDetailSectionEnum;    
    isMapControlsMoved: boolean;
    hoodQMapOpened = output<MainMapState>();

    gMap: google.maps.Map;
    gMapStreetViewService: google.maps.StreetViewService = new google.maps.StreetViewService();
    mapLayerVisibility = <MapLayerVisibility>{};
    propertyReportVisible: WritableSignal<boolean> = signal(false);
    searchComparablesResultVisible: boolean;
    omnibarSearchResultsFound: boolean;
    omnibarSearchInitiated: WritableSignal<boolean> = signal(false);
    propertyStreetViewEnabled: boolean = false;
    propertyStreetViewMap: google.maps.Map;
    streetViewPosition: google.maps.LatLng;
    addressQueryResult: any = {};
    addressUpdating: boolean;
    orientation = ScreenOrientation;
    mapScreenOrientation = ScreenOrientation.HORIZONTAL;
    lastMapZoomLevel: number = 0;
    panorama: google.maps.StreetViewPanorama;
    panoramaVisibleChangedListener: google.maps.MapsEventListener;
    panoramaPositionChangedListener: google.maps.MapsEventListener;
    panoramaPovChangedListener: google.maps.MapsEventListener;
    requestedPointOfViews : PointOfView[] = [];

    initializeMap = () => {
        try {
            let mapOptions = {
                streetViewControl: false,
                overviewMapControl: true,
                panControl: false,
                maxZoom: DataService.MAIN_MAP_MAX_ZOOM_LEVEL,
                minZoom: DataService.MAIN_MAP_MIN_ZOOM_LEVEL,
                disableDefaultUI: true,
                visualEnabled: true,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: true,

                panControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                }
            }
            this.gMap = new google.maps.Map(this.gMapContainer.nativeElement, mapOptions);
            this.gMap.setMapTypeId(this.mainMapService.mainMapTypeId);

            //todo: this is wip to add map controls to the main map when the search comparables results screen is open
            // let mapControls = this.addFullScreenMapControls();
            // // @ts-ignore
            // this.gMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(mapControls);

            this.registerMapEventListeners();
        } catch (e) {
            this.loggerService.logError(`error initializing map`, e);
        } finally {
            //this.loadingSpinnerService.stop(this.dataService.mapLoadingSpinnerDialogRef);
        }
    }

    postMapIdle = () => {
        try {
            if (this.gMap.overlayMapTypes.getLength() == 0) {
                this.setDefaultMapSettings();
            }

            let mapCenter: google.maps.LatLng = this.mainMapService.getMapCenter();
            if (mapCenter) {
                let mapCenterLroId: string | undefined = this.lroPolygonsService.getLroIdBySpatialPoint(mapCenter);
                if (mapCenterLroId !== undefined) {
                    let currentLro = this.lroPolygonsService.getCurrentLro();
                    this.loggerService.logDebug(`center of map is within lro ${mapCenterLroId}, current lro is ${currentLro}`);

                    if (mapCenterLroId != currentLro) {
                        if (currentLro !== '01' && currentLro !== '03' && currentLro !== '48') {
                            if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
                                this.lroPolygonsService.setCurrentLro(mapCenterLroId);
                                setTimeout(() => {
                                    this.mainMapService.renderLroPolygon(mapCenterLroId!, false);
                                }, 100);
                            }
                        }
                    }

                } else {
                    this.loggerService.logWarning('cannot determine in which lro the map center is located');
                }
            }

            // for rendering block sales heatmap data
            if (this.gMap?.getZoom()! >= DataService.HEATMAP_MIN_ZOOM_LEVEL && this.gMap?.getZoom()! <= DataService.HEATMAP_MAX_ZOOM_LEVEL) {
                let activeHeatmapLayerState: HeatmapLayerState | undefined = this.dataService.getActiveHeatmapLayerState();
                if (activeHeatmapLayerState) {
                    this.mainMapService.renderHeatmapLayer(activeHeatmapLayerState.layer, activeHeatmapLayerState.dateRangeInMonths, activeHeatmapLayerState.isCondo, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);
                }
            } else {
                this.mainMapService.clearAllHeatmapBlocks();
            }

            // for rendering rental heatmap data
            if (this.gMap?.getZoom()! >= DataService.HEATMAP_NHOOD_MIN_ZOOM_LEVEL && this.gMap?.getZoom()! <= DataService.HEATMAP_MAX_ZOOM_LEVEL) {
                let activeNhoodHeatmapLayerState: HeatmapNhoodLayerState | undefined = this.dataService.getActiveNhoodHeatmapLayerState();
                if (activeNhoodHeatmapLayerState) {
                    this.mainMapService.renderNhoodHeatmapLayer(activeNhoodHeatmapLayerState.layer, this.mainMapService.getMapBounds(), this.mainMapService.getMapZoomLevel()!);
                }
            } else {
                this.mainMapService.clearAllHeatmapNhood();
            }

            //initialize the custom tile layers once the map has settled down;
            //this eliminates unnecessary tiles fetching and should help make the UI more responsive.
            if (this.lastMapZoomLevel != this.mainMapService.getMapZoomLevel()) {
                setTimeout(() => {
                    this.mainMapService.initializeMapLayers(this.mapLayerVisibility);
                    this.lastMapZoomLevel = this.mainMapService.getMapZoomLevel()!;
                }, 200);
            }

            this.gMap.data.set('isMapIdled', true);

            this.loggerService.logDebug(`map idle: zoom level ${this.gMap?.getZoom()?.toString()}`);

            //force the template to update immediately
            this.changeDetectorRef.detectChanges();

        } catch (e) {
            this.loggerService.logError(`error occurred after map went idle`, e);
        } finally {
            this.loadingSpinnerService.stop(this.dataService.mapLoadingSpinnerDialogRef);

            //for the omnibar search keyboard shortcut to work, set the focus on the page (setting the focus on the map will do)
            this.gMapContainer.nativeElement.focus();
        }
    }

    registerMapEventListeners = () => {

        // register the one-time event listeners
        let me = this;
        google.maps.event.addListenerOnce(this.gMap, 'tilesloaded', function() {
            //uncomment if we want the loading indicatior to be visible while loading the custom tile layers
            //me.loadingSpinnerService.stop(me.dataService.mapLoadingSpinnerDialogRef);
        });

        // register map idle event listener
        this.gMap.addListener("idle", () => {
            setTimeout(() => {
                this.postMapIdle();
            }, 100);
        });

        // register map click event listener
        this.gMap.addListener("click", (event: google.maps.MapMouseEvent) => {
            this.loggerService.logDebug(`map clicked at ${event.latLng!.lat()}, ${event.latLng!.lng()}`);

            if (!this.streetViewService.isMainMapStreetViewInitiated() && this.gMap.getZoom() && this.gMap.getZoom()! >= 14) {
                this.mainMapService.displayPropertyInfoBubbleByClickLatLng(event.latLng!);
            }

            if (this.streetViewService.isMainMapStreetViewInitiated()) {
                setTimeout(() => {
                    this.spatialServiceService.getPINByLatLng(event?.latLng?.lat()!, event?.latLng?.lng()!).then(pin => {
                        this.mainMapService.openStreetViewForPin(pin);
                    });
                }, 100);

                this.streetViewService.cancelMainMapStreetView(); //turn off the streetview mode cursor
            }
        });

        // register map mousemove event listener
        /*
        this.gMap.addListener("mousemove", (event: google.maps.MapMouseEvent) => {
        });
        */
    }

    getMapCenter = (): Centroid => {
        let mapCenter: Centroid = new Centroid(this.gMap.getCenter()?.lat(), this.gMap.getCenter()?.lng());
        return mapCenter;
    }

    onHoodQMapOpened = () => {
        //open hoodq map and center it where the main map is centered
        let mapState = new MainMapState();
        mapState.centroid = new Centroid(this.getMapCenter().latitude, this.getMapCenter().longitude);
        mapState.zoomLevel = this.gMap.getZoom()!;
        this.hoodQMapOpened.emit(mapState);
    }

    onMapLayerToggled = (event: MatSlideToggleChange) => {
        this.mainMapService.refreshMapLayer(event.source.name, event.checked);
    }

    updateStreetViewPegman = (initiated: boolean) => {
        this.loggerService.logDebug('streetview initiated', initiated);

        if (initiated) {
            this.gMap?.setOptions({
                draggableCursor: 'url(assets/img/svg/map/icon_map_pegman_onmap.svg), crosshair'
            });
        } else {
            this.gMap?.setOptions({
                draggableCursor: ''
            });
        }
    }

    onMainMapZoomIn = () => {
        this.gMap.setZoom(this.gMap.getZoom()! + 1);
    }

    onMainMapZoomOut = () => {
        this.gMap.setZoom(this.gMap.getZoom()! - 1);
    }

    onThematicLayerOpacityChanged = (event: number) => {
        this.loggerService.logDebug(`Thematic layer opacity changed to ${event}`);
        this.mainMapService.thematicLayerOpacityValue = event;
        this.mainMapService.refreshMapLayer(MapTileEnum.THEMATIC_TILE, true);
    }

    initializeMapLayerVisibilityControls = () => {
        this.mapLayerVisibility.ownership = this.getPreferredLayerVisibility(GenericPreferenceLayerType.OWNERSHIP_PARCEL.toString());
        this.mapLayerVisibility.assessment = this.getPreferredLayerVisibility(GenericPreferenceLayerType.ASSESSMENT_PARCELS.toString());
        this.mapLayerVisibility.address = this.getPreferredLayerVisibility(GenericPreferenceLayerType.STREET_NUMBERS.toString());
        this.mapLayerVisibility.thematic = this.getPreferredLayerVisibility(GenericPreferenceLayerType.THEMATIC_LAYERS.toString());
        this.mapLayerVisibility.lotConcession = this.getPreferredLayerVisibility(GenericPreferenceLayerType.LOT_CONCESSIONS.toString());
        this.mapLayerVisibility.municipalityLro = this.getPreferredLayerVisibility(GenericPreferenceLayerType.LRO_MUNICIPALITY.toString());
        this.mapLayerVisibility.firstBaseSolution = this.fbsService.isFBSMapSelected() && this.userService.getUserAccessControl().FBSAccess;
    }

    initializeHeatmapLayerControls = () => {
        this.mapLayerVisibility.heatMapAverageSales = false;
        this.mapLayerVisibility.heatMapAverageSalesPropertyType = 'freehold';
        this.mapLayerVisibility.heatMapAverageSalesPropertyTypeIsCondo = 'N';
        this.mapLayerVisibility.heatMapAverageSalesDateRange = '1yr';
        this.mapLayerVisibility.heatMapAverageSalesDateRangeInMonths = 12;
        this.mapLayerVisibility.heatMapGrowthRate = false;
        this.mapLayerVisibility.heatMapGrowthRatePropertyType = 'freehold';
        this.mapLayerVisibility.heatMapGrowthRatePropertyTypeIsCondo = 'N';
        this.mapLayerVisibility.heatMapGrowthRateDateRange = '1yr';
        this.mapLayerVisibility.heatMapGrowthRateDateRangeInMonths = 12;
        this.mapLayerVisibility.heatMapMarketTurnover = false;
        this.mapLayerVisibility.heatMapMarketTurnoverPropertyType = 'freehold';
        this.mapLayerVisibility.heatMapMarketTurnoverPropertyTypeIsCondo = 'N';
        this.mapLayerVisibility.heatMapMarketTurnoverDateRange = '1yr';
        this.mapLayerVisibility.heatMapMarketTurnoverDateRangeInMonths = 12;

        this.dataService.setMapLayerVisibility(this.mapLayerVisibility);
    }

    getPreferredLayerVisibility = (layerKey: string) => {
        let visible: boolean = false;

        let genericPreference: GenericPreference = this.userService.getUserPreferencesFromLocalStorage()?.genericPreference;
        let layerPreference: Preference | undefined = genericPreference?.layers?.find((preference) => {return preference.key == layerKey});

        if (layerPreference) {
            visible = layerPreference?.booleanValue;
        }

        return visible;
    }

    private setDefaultMapSettings = () => {

        this.initializeMapLayerVisibilityControls();
        this.initializeHeatmapLayerControls();
        this.mainMapService.setMap(this.gMap);  //TODO: better to set the map at the time the service is instantiated

        //add gesture support for mobile devices by default
        if (this.browserDetectionService.isMobile()) {
            this.mainMapService.setMapTwoFingerTouchGesture(this.gMap);
        } else {
            this.mainMapService.setMapOneFingerTouchGesture(this.gMap)
        }

        let lroId: string | null = this.lroPolygonsService.getCurrentLroState()?.lroId;

        if (!this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
            this.lroPolygonsService.setCurrentLro(lroId!);
        } else {
            //the subject property's lro would have been set at this point
        }

        this.mainMapService.renderLroPolygon(lroId!, true);
    }

    ngOnInit(): void {
        let msg: string = 'Loading map...';
        this.loggerService.logDebug(msg);
        this.dataService.mapLoadingSpinnerDialogRef = this.loadingSpinnerService.start(msg);

        try {
            this.locationService.position$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((position: any) => {
                    if (position && position != -1) {
                        this.mainMapService.renderUserLocationMarker(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
                    } else {
                        if (position == DataService.USER_LOCATION_PROMPT_DENIED) {
                            this.openSnackBarError(DataService.USER_LOCATION_ERROR);
                            this.locationService.clearPosition();
                        }
                    }
                });
        } finally {
            this.loggerService.logDebug('Map loaded');
        }

        try {
            this.streetViewService.mainMapStreetViewInitiated$
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((initiated: any) => {
                    this.updateStreetViewPegman(initiated);
                });
        } finally {
        }

    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initializeMap();

            this.omnibarStateService.omnibarState
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((state) => {
                    this.omnibarSearchInitiated.set(state.searchInitiated);
                    this.omnibarSearchResultsFound = state.searchResultsFound;

                    if (this.omnibarSearchInitiated()) {
                        this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
                    }

                });

            /**
             * Show the property report when a new one is provided.
             */
            this.propertyReportService.subjectProperty$
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe((newPropertyDetail) => {
                    if (newPropertyDetail.pii !== undefined) {
                        this.loggerService.logDebug(`new subject property pin ${newPropertyDetail.pii?.pin}`);
                        this.screenManager.showScreen(ScreenNameEnum.PROPERTY_REPORT);
                    }
                });


            /**
             * Reduce the main map height so that all the rendered map objects are fully visible when the property report is opened.
             * Restore the main map height to full height when the property report is closed.
             */
            this.screenManager.getObservableScreen(ScreenNameEnum.PROPERTY_REPORT)!
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(visible => {
                    if (visible) {
                        this.gMapContainer.nativeElement.style.height = '60vh';
                        this.mainMapService.setMapTwoFingerTouchGesture(this.gMap);

                    } else {
                        this.mainMapService.setMapOneFingerTouchGesture(this.gMap);

                        if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
                            this.gMapContainer.nativeElement.style.height = '100%';
                        }

                        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM) || this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
                            //do not clear the markers when the search comparables form or results screen is open since those markers are still needed
                        } else {
                            this.mainMapService.clearAllRenderedMapObjects();
                        }
                    }

                    this.propertyReportVisible.set(visible);
                });

            /**
             * Reduce the main map height so that all the rendered map objects are fully visible when the search comparables result is opened.
             * Restore the main map height to full height when the search comparables result is closed.
             */
            this.screenManager.getObservableScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)!
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe(visible => {
                    if (visible) {
                        this.gMapContainer.nativeElement.style.height = '40vh';
                        this.mainMapService.setMapTwoFingerTouchGesture(this.gMap);

                    } else {
                        localStorage.setItem(LocalStorageKey.comparablesSalesAbortRenderMarkers, 'true');

                        this.mainMapService.setMapOneFingerTouchGesture(this.gMap);
                        this.mainMapService.setAutoPanMapForSearchComparablesResultMarkers(false);
                        
                        this.gMapContainer.nativeElement.style.height = '100%';
                        this.gMapContainer.nativeElement.style.width = '100%';
                        this.searchComparablesResultService.resetScreenOrientation();
                        this.searchComparablesResultService.resetScreenDisplay();
                       
                        this.screenManager.hideScreen(ScreenNameEnum.MAP_LAYER_POPUP);
                        this.screenManager.hideScreen(ScreenNameEnum.MAP_TYPE_POPUP);

                        this.mainMapService.clearSearchComparablesObjects();
                        this.mainMapService.clearRenderedMarkers();
                    }

                    this.searchComparablesResultVisible = visible;
                });

            /**
             * Make the search comparables form fully visible when it is opened by first making the map visible,
             * since the search comparables form is a child element of the map container
             */
            this.screenManager.getObservableScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM)!
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe(visible => {
                    if (visible && this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
                        var map = this.elementRef.nativeElement.querySelector('#mainMapContainer');
                        map.scrollIntoView({behavior: "smooth"});
                    }
                });

            /**
             * Checks where streetview was opened from.
             */
            this.propertyReportService.propertyReportStreetView$
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe((flag) => {
                    if (this.propertyReportService.isStreetViewOpenedFromPropertyReport()) {
                        this.loggerService.logDebug(`streetview opened from property report pin`);
                        this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
                    }
                });

            this.propertyReportService.propertyStreetViewRequest$
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                //.pipe(debounceTime(200))
                .subscribe(streetViewPoint => {
                    if (streetViewPoint) {
                        if (streetViewPoint.centroid?.latitude) {
                            this.propertyStreetViewEnabled = true;
                            this.loadPropertyStreetView(streetViewPoint);

                            if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
                                this.propertyReportService.setStreetViewOpenedFromPropertyReport(true);
                            }

                        } else {
                            this.propertyStreetViewEnabled = false;
                            this.propertyStreetViewMap?.setStreetView(null);
                            this.screenManager.hideScreen(ScreenNameEnum.MAIN_MAP_STREETVIEW);  //keep track of the streetview screen state

                            if (!this.screenManager.isScreenVisible(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS) &&
                                !this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT) &&
                                !this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {

                                //return to the home/main screen and restore the last viewed property report
                                setTimeout(() => {
                                    try {
                                        if (this.propertyReportService.getSubjectPropertyPin()) {
                                            this.router.navigateByUrl('/home?pin=' + this.propertyReportService.getSubjectPropertyPin());
                                        }
                                    } catch (e) {
                                        this.loggerService.logError(`unable to restore previous property report from streetview`, e);
                                    }
                                }, 100);
                            }
                        }
                    }
                });

            this.screenManager.getObservableScreen(ScreenNameEnum.MAIN_MAP_STREETVIEW)!
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe(visible => {
                    if (!visible) {
                        this.propertyStreetViewEnabled = false;
                    }
                });

            //switch the map styling when the screen orientation changes
            this.searchComparablesResultService.screenOrientation$
                .pipe(skip(1), takeUntil(this.ngUnsubscribe))
                .subscribe(orientation => {
                    switch (orientation) {
                        case null:
                            this.mapScreenOrientation = ScreenOrientation.HORIZONTAL;  //set default orientation
                            break;

                        case ScreenOrientation.HORIZONTAL:
                            this.mapScreenOrientation = ScreenOrientation.HORIZONTAL;
                            break;

                        case ScreenOrientation.VERTICAL:
                            this.mapScreenOrientation = ScreenOrientation.VERTICAL;
                            break;
                    }
                });

        }, 1);
    }

    toggleSearchComparablesForm = (visible: boolean) => {
        visible? this.matSideNav?.open() : this.matSideNav?.close();
        this.isMapControlsMoved = visible;
    }

    scrollToTop() {
        this.gMapContainer.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    openSnackBarError(msg: string) {
        this._snackBar.open(msg, 'Close', defaultErrorMatSnackBarConfig);
    }

    positionStreetView = (latLng: google.maps.LatLng) => {
        let streetViewPoint: StreetViewPoint = new StreetViewPoint();
        streetViewPoint.centroid = new Centroid(latLng.lat(), latLng.lng());
        streetViewPoint.projection = streetViewPoint.centroid;

        this.loadPropertyStreetView(streetViewPoint);
    }

    loadPropertyStreetView = async(streetViewPoint : StreetViewPoint) => {

        let centroid = streetViewPoint?.projection;
        if (centroid) {
            let coordinates = new google.maps.LatLng(centroid.latitude, centroid.longitude);

            let mapOptions: google.maps.MapOptions = {
                center: coordinates,
                zoom: 15,
                disableDefaultUI: true
            };

            this.propertyStreetViewMap = new google.maps.Map(this.propertyStreetViewContainer?.nativeElement, mapOptions);
            this.panorama = this.propertyStreetViewMap.getStreetView();

            if (this.panorama) {
                var me = this;
                this.gMapStreetViewService.getPanorama( {location : coordinates}, function (data, status) {
                        me.loggerService.logDebug('streetview service status', status);

                        switch(status) {
                            case google.maps.StreetViewStatus.OK:
                                me.panorama.setPosition(coordinates);
                                me.panorama.set('zoom', 0.2);
                                me.panorama.set('cacheHeading', false);
                                me.panorama.set('addressControl', false);
                                me.panorama.set('showControls', false);
                                me.panorama.set('panControl', false);
                                me.panorama.set('zoomControl', false);
                                me.panorama.set('enableCloseButton', false);
                                me.panorama.set('imageDateControl', false);
                                me.panorama.set('navigationControl', false);
                                me.panorama.set('linksControl', false);
                                me.panorama.set('pitch', 0);
                                me.panorama.set('homePageGSVOverlayEnabled', true);

                                me.panorama.setPov({
                                    heading: StreetViewUtility.calculateHeading(data?.location?.latLng?.lat(), data?.location?.latLng?.lng(), streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude ),
                                    pitch: 0,
                                });

                                me.panorama.setVisible(true);
                                me.screenManager.showScreen(ScreenNameEnum.MAIN_MAP_STREETVIEW);  //keep track of the streetview screen state
                                me.screenManager.closeScreensWhenThisOpened(ScreenNameEnum.MAIN_MAP_STREETVIEW);
                                setTimeout(() => {
                                    me.streetViewService.updateGeoAdressMapOverlayCoordinates(coordinates);
                                });


                                break;

                            case google.maps.StreetViewStatus.ZERO_RESULTS:
                                me.panorama.setVisible(false);
                                me.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
                                break;

                            case google.maps.StreetViewStatus.UNKNOWN_ERROR:
                                me.panorama.setVisible(false);
                                me.openSnackBarError(ErrorUtil.STREETVIEW_ERROR);
                                break;
                        }
                    }
                );

                if (this.panoramaPositionChangedListener) {
                    google.maps.event.removeListener(this.panoramaPositionChangedListener);
                }

                let posDebounceTimeout: number | undefined;
                this.panoramaPositionChangedListener = this.panorama.addListener('position_changed', () => {
                  clearTimeout(posDebounceTimeout);
                  this.streetViewPosition = this.panorama.getPosition()!;
                  this.loggerService.logDebug(`streetview position changed to `, this.streetViewPosition);
                  posDebounceTimeout = setTimeout(() => {
                    this.handlePostStreetViewChange(this.panorama, true);
                  }, 500);
                });

                if (this.panoramaPovChangedListener) {
                    google.maps.event.removeListener(this.panoramaPovChangedListener);
                }

                let povDebounceTimeout: number | undefined;
                this.panoramaPovChangedListener = this.panorama.addListener('pov_changed', () => {
                  clearTimeout(povDebounceTimeout);
                  this.streetViewPosition = this.panorama.getPosition()!;
                  this.loggerService.logDebug(`streetview pov changed to `, this.panorama.getPov());
                  povDebounceTimeout = setTimeout(() => {
                    this.handlePostStreetViewChange(this.panorama, false);
                  }, 500);
                });

                if (this.panoramaVisibleChangedListener) {
                    google.maps.event.removeListener(this.panoramaVisibleChangedListener);
                }

                this.panoramaVisibleChangedListener = google.maps.event.addListener(this.panorama, 'visible_changed', () => {
                    if (this.panorama.getVisible()) {
                        this.screenManager.showScreen(ScreenNameEnum.MAIN_MAP_STREETVIEW);  //keep track of the streetview screen state
                    }
                });
            }

        } else {
            this.openSnackBarError(DataService.STREETVIEW_NOT_AVAILABLE);
        }
    }

    handlePostStreetViewChange = async (panorama: google.maps.StreetViewPanorama, isHeadingChange: boolean) => {
        //let pov: google.maps.StreetViewPov;
        let pov: any;
        let heading: number = 0.0;
        let headingStart: number = 0;
        let headingMinimum: number = 8;
        let pitch: number | undefined = DataService.DEFAULT_STREETVIEW_PITCH;
        let zoom: number;
        let calculatedPov = new PointOfView();


        try {
            if (panorama) {
                pov = panorama.getPov();
                if (pov && pov.heading) {
                    if (pov.heading < 0) {
                        pov.heading = pov.heading + 360;
                    } else if (pov.heading >= 360) {
                        pov.heading = pov.heading - 360;
                    }
                    if (isHeadingChange && Math.abs(pov.heading - headingStart) < headingMinimum) {
                        return;
                    }

                    headingStart = pov.heading;
                    calculatedPov.heading = pov.heading;
                    pitch = pov.pitch;

                    //zoom = Math.round(pov.zoom);
                    //panorama.setPov(pov);

                    setTimeout(() => {
                        //to make the streetview marker visible, add it to both the map and the panorama
                        //this.mainMapService.renderStreetViewMapMarker(this.propertyStreetViewMap, panorama.getPosition());
                        //this.mainMapService.renderStreetViewPanoramaMarker(panorama, panorama.getPosition());
                    });

                    calculatedPov.latitude = panorama.getPosition()?.lat()!;
                    calculatedPov.longitude = panorama.getPosition()?.lng()!;

                    if(this.requestedPointOfViews.some(pov => pov.latitude == calculatedPov.latitude && pov.longitude == calculatedPov.longitude && pov.heading == calculatedPov.heading)) {
                        // a request already in progress
                        return;
                    }
                    try {
                        this.addressUpdating = true;
                        this.requestedPointOfViews.push(calculatedPov);
                        this.addressQueryResult = await lastValueFrom(this.streetViewService.getAddressByStreetViewPOV(calculatedPov), {defaultValue: {}});
                        if(this.geoAddressMapOverlayComponent){
                            this.geoAddressMapOverlayComponent.updateAddress(this.addressQueryResult);
                        }
                    }
                    finally {
                        this.addressUpdating = false;
                        this.requestedPointOfViews = this.requestedPointOfViews.filter(pov => pov.latitude == calculatedPov.latitude && pov.longitude == calculatedPov.longitude && pov.heading == calculatedPov.heading);
                    }


                    /* todo: this needs to be investigated as to why the pin returned by the lat/lng query is different from the pin of the address returned by the streetview query.
                    let pii: Pii = await lastValueFrom(this.spatialServiceService.getPIIByLatLng(latitude, longitude), {defaultValue: new Pii()});
                    if (pii) {
                      this.addressQueryResult.pin = pii.pin;
                    } else {
                      this.addressQueryResult.pin = null;
                    }
                    */

                    let address = this.addressQueryResult.address?.message;
                    let error = this.addressQueryResult.address?.errorMessage;

                    if (!_.isEmpty(address) && _.isEmpty(error)) {
                        let lroId: string | undefined = this.lroPolygonsService.getLroIdBySpatialPoint(panorama.getPosition()!);
                        if (lroId !== undefined) {
                            let results = await lastValueFrom(this.omnibarSearchService.searchPropertiesByWildcard(address, lroId, 0, true), { defaultValue: new SearchResult() });
                            if (results && results.searchResult && results.searchResult.length > 0) {
                                let pii: Pii = results.searchResult[0];
                                if (pii) {
                                    this.addressQueryResult.pin = pii.pin;
                                } else {
                                    this.addressQueryResult.pin = null;
                                }
                            }
                        }
                    }
                }
            }
        } catch (e) {
            this.loggerService.logError(`error determining geowarehouse address from streetview`, e);
            this.addressQueryResult = {};

        } finally {
            this.addressUpdating = false;
        }
    };

    private getScreenPointLatLng = (event: any): google.maps.LatLng | undefined => {
        const offset = event.target.getClientRects()[0];
        if (offset == undefined) return;

        let x: number = event.clientX - offset.left;
        let y: number = event.clientY - offset.top;

        // let x: number = event.x;
        // let y: number = event.y;

        let latLng: google.maps.LatLng = MapUtility.screenPointToLatLng(x, y, this.gMap);
        return latLng;
    }

    /**
     * Captures the LatLng where the user clicked on the map by converting the screen point to LatLng.
     * Useful when the map api does not directly expose the LatLng on click events, such as when drawing the paths of a polygon on the map.
     * I.e, this method is a workaround to capture the map click event that would normally be handled by the map click event listener.
     *
     * @param event
     */
    onMapContainerClicked = (event: any) => {
        let latLng: google.maps.LatLng | undefined = this.getScreenPointLatLng(event);

        if (latLng) {
            this.loggerService.logDebug(`map container clicked at ${latLng.lat()}, ${latLng.lng()}`);

            if (this.mainMapService.isSearchComparablesInPolygonDrawingMode()) {
                this.mainMapService.closeSearchComparablesStartPolygonInstructions();

                if (!this.mainMapService.isSearchComparablesPolygonStartPathSet()) {
                    this.mainMapService.setSearchComparablesPolygonStartPath(latLng);
                } else {
                    //keep the instruction infobox visible at all times while drawing the polygon
                    //this.mainMapService.closeSearchComparablesInProgressPolygonInstructions();
                }
            }
        }
    }

    /**
     * Captures the LatLng where the user moved the mouse on the map by converting the screen point to LatLng.
     * Useful when the map api does not directly expose the LatLng on mousemove events, such as when drawing the paths of a polygon on the map.
     * I.e, this method is a workaround to capture the map mousemove event that would normally be handled by the map mousemove event listener.
     *
     * @param event
     */
    onMapContainerMouseMoved = (event: any) => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM) && this.mainMapService.isSearchComparablesInPolygonDrawingMode() && !this.mainMapService.isSearchComparablesPolygonStartPathSet()) {
            let latLng: google.maps.LatLng | undefined = this.getScreenPointLatLng(event);
            if (latLng) {
                this.loggerService.logDebug(`map container mouse moved at ${latLng.lat()}, ${latLng.lng()}`);

                if (this.mainMapService.isSearchComparablesStartPolygonInstructionsVisible()) {
                    this.mainMapService.closeSearchComparablesStartPolygonInstructions();
                }
                this.mainMapService.showSearchComparablesStartPolygonInstructions(latLng);
            }
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        switch (event.key) {
            case "Escape":
                this.loggerService.logDebug(`esc key pressed ${event}`);

                // cancel drawing of current polygon if applicable
                this.mainMapService.cancelSearchComparablesPolygonDrawing();

                // cancel streetview mode if it was initiated from the main map controls
                if (this.streetViewService.isMainMapStreetViewInitiated()) {
                    this.streetViewService.cancelMainMapStreetView();
                }

                break;
        }
    }

    addFullScreenMapControls(): HTMLDivElement {
        const mainDiv = document.createElement('div');
        mainDiv.id = `fullscreen-controls`;
        mainDiv.className = 'map-top-right-controls';

        const zoomInDiv = document.createElement('div');
        zoomInDiv.id = `fullscreen-zoom-in`;
        zoomInDiv.className = 'control control-off zoom-in-btn';
        zoomInDiv.addEventListener('click', () => { this.onMainMapZoomIn(); });
        const zoomInImg = document.createElement('img');
        zoomInImg.src = `/assets/img/svg/map/icon_map_zoom+.svg`;
        zoomInDiv.appendChild(zoomInImg);
        mainDiv.appendChild(zoomInDiv);

        const zoomOutDiv = document.createElement('div');
        zoomOutDiv.id = `fullscreen-zoom-out`;
        zoomOutDiv.className = 'control control-off zoom-out-btn';
        zoomOutDiv.addEventListener('click', () => { this.onMainMapZoomOut(); });
        const zoomOutImg = document.createElement('img');
        zoomOutImg.src = `/assets/img/svg/map/icon_map_zoom-.svg`;
        zoomOutDiv.appendChild(zoomOutImg);
        mainDiv.appendChild(zoomOutDiv);

        return mainDiv;
    }

    mobileFullScreenMode(result: boolean) {
        this.mobileFullScreen.emit(result);
    }

    isPropertyReportPanelVisible(panelId: string) {
        try {
            var rect = document.getElementById(panelId)!.getBoundingClientRect();
            var elemmentTop = rect.top;
            var elementBottom = rect.bottom;

            //only completely visible elements return true:
            //var isVisible = (elemmentTop >= 0) && (elementBottom <= window.innerHeight);

            //partially visible elements return true:
            var isVisible = elemmentTop < window.innerHeight && elementBottom >= 0;

            return isVisible;

        } catch (e) {
            return false;
        }
    }

    onScroll = ($event: Event) => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
            //panel ids must match the ids defined in property report <mat-expansion-panel>
            if (this.isPropertyReportPanelVisible('registry-panel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.PROPERTY_DETAILS;
            }
            if (this.isPropertyReportPanelVisible('condo-details-container')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.CONDO_DETAILS;
            }
            if (this.isPropertyReportPanelVisible('siteStructurePanel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.SITE_STRUCTURE;
            }
            if (this.isPropertyReportPanelVisible('valuation-sales-panel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.VALUATION_SALES;
            }
            if (this.isPropertyReportPanelVisible('pr-expansion-div-panel-ps')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS;
            }
            if (this.isPropertyReportPanelVisible('residential-relocation-panel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.RESIDENTIAL_RELOCATION;
            }
            if (this.isPropertyReportPanelVisible('hoodq-panel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.HOODQ;
            }
            if (this.isPropertyReportPanelVisible('demographics-panel')) {
                this.propertyReportSectionScrolled = PropertyDetailSectionEnum.DEMOGRAPHICS;
            }
        }
    }

}
