import { Component, inject } from '@angular/core';
import { EstoreService } from "../../../shared/service/estore.service";
import { LandingPageSettings } from "../../../core/model/store-front/landing-page/landing-page-settings";
import { lastValueFrom } from "rxjs";
import { Router } from "@angular/router";
import { EstoreProductCategoryEnum } from "../../../core/enum/estore-product-category-enum";

@Component({
  selector: 'gema3g-store-front-main',
  templateUrl: './store-front-main.component.html',
  styleUrls: ['./store-front-main.component.scss']
})
export class StoreFrontMainComponent {

  constructor() {
    this.init();
  }

  private eStoreService = inject(EstoreService);
  private router = inject(Router);

  landingPageSettings: LandingPageSettings;
  estoreProductCategoryEnum: typeof EstoreProductCategoryEnum = EstoreProductCategoryEnum;

  async init() {
    this.landingPageSettings = await lastValueFrom(this.eStoreService.getEstoreLandingPageSettings());
  }

  navigateHome() {
    this.router.navigate(['../home']);
  }

  goToCatalogue(category: EstoreProductCategoryEnum) {
    this.eStoreService.goToCatalogueCategory(category);
  }
}
