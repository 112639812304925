import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { PropertyDetail } from '../../../../core/model/property/property-detail';
import { PropertyReportSectionEnum } from '../../../../core/enum/property-report-section-enum';
import { PropertyReportComponentBase } from '../property-report-component-base';
import { UserAccessControl } from '../../../../core/model/user/user-access-control';
import { EStoreProduct } from "../../../../core/model/product/e-store/e-store-product";
import { defaultResponsiveOptions } from "../../../../shared/constant/constants";
import { SelectedProperty } from "../../../../core/model/property/selected-property";
import { CarouselService } from "../../../../shared/service/carousel.service";
import { LayoutComponent } from "../layout/layout.component";
import { MatDividerModule } from "@angular/material/divider";
import { CarouselModule } from "primeng/carousel";
import { CoreModule } from "../../../../core/core.module";
import { takeUntil } from "rxjs";
import { DataService } from "../../../../shared/service/data.service";
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { GA_Button } from '../../../../shared/constant/google-analytics-constants';

declare var HQ:any;

@Component({
  selector: 'gema3g-property-report-hoodq',
  standalone: true,
  templateUrl: './hoodq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LayoutComponent,
    MatDividerModule,
    CarouselModule,
    CoreModule
  ],
  styleUrls: ['./hoodq.component.scss']
})
export class HoodqComponent extends PropertyReportComponentBase implements OnInit {

  constructor() {
    super();
  }

  private carouselService = inject(CarouselService);
  private gaService = inject(GoogleAnalyticsService);

  subjectProperty: PropertyDetail;
  @Input() userAccessControls: UserAccessControl;
  @Input() carouselProducts: EStoreProduct[] | undefined;
  @Input() selectedProperty: SelectedProperty;
  responsiveOptions = defaultResponsiveOptions;
  propertyReportSection = PropertyReportSectionEnum.HOODQ;

  initializeSubjectPropertyListener = () => {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPropertyDetail) => {
        this.subjectProperty = newPropertyDetail;
        setTimeout( () => {
          this.loadHoodQData();
        },1000);
      });
  }

  ngOnInit(): void {
    this.initializeSubjectPropertyListener();
  }

  async loadHoodQData(){
    // Wait for the HQ.NeighbourhoodHighlights constructor to become available.
    // @ts-ignore
    //await HQ.waitFor('neighbourhoodHighlights');

    // @ts-ignore
    HQ.NeighbourhoodHighlights({
      el: 'neighbourhoodHighlights',
      address: this.subjectProperty?.pii?.address?.fullAddress,
    });
  }

  get showNavigators(): boolean{
    return this.carouselService.showNavigators(this.carouselProducts?.length);
  }

  gaHoodQ(){
    this.gaService?.buttonClicked(GA_Button.HOODQ_SCHOOLS, GA_Button.HOODQ_SCHOOLS_LABEL);
  }

}
