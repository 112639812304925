<div class="estore-header hidden md:flex relative">
  <div class="estore-header-left"></div>
  <div class="estore-header-center">
    <div class="flex flex-col justify-center justify-content-center">
      <div class="flex justify-center justify-content-center"><h1 id="estore-h1-retail-title">GEOWAREHOUSE STORE</h1></div>
      <div class="flex justify-center justify-content-center"><h3 id="estore-h3-retail-subtitle">Access authoritative data. Be the expert.</h3></div>
    </div>
  </div>
  <div class="estore-header-right"></div>
  @if (!userService.userHasAccessToEStoreOnly) {
    <div class="absolute right-0 top-[1px]">
      <!--<a id="estore-link-retail-close" (click)="navigateHome()" class="retail-close-btn">Close X</a>-->
    </div>
  }
</div>

<div class="bg-[#5e5e5e]">
  <div class="geo-container mx-auto">

    <!-- small menu-->
    <div [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents" class="md:hidden">
      <button id="estore-btn-retail-sm" [matMenuTriggerFor]="smallMenu" mat-menu-item>
        <mat-icon id="estore-icon-retail">menu</mat-icon>
      </button>
      <mat-menu id="estore-dd-retail-sm" #smallMenu="matMenu" yPosition="below">
        @for (menu of menuDefinition; track menu) {
          <button id="estore-btn-retail-sm-item-{{menu.type}}" (click)="menuClicked(menu)" mat-menu-item>
            <span id="estore-retail-sm-item-label-{{menu.type}}" [innerHtml]="menu.title"></span>
          </button>
        }
      </mat-menu>

    </div>

    <!-- large menu-->
    <div class="hidden md:block">
      <mat-nav-list [style.pointer-events]="userAccessControls?.restrictedAccessPointerEvents" class="geo-mat-menu-horizontal ">

        @for (menu of menuDefinition; track menu) {
          <mat-list-item id="estore-list-item-retail-lm-{{menu.type}}" (click)="menuClicked(menu)" [ngClass]="{'menu-selected-item' : menu.selected}" class="menu-item">
            <span class="menu-title">
              @if (menu.isNew) {
                <span id="estore-retail-lm-item-new-{{menu.type}}" class="menu-title-orange">New! </span>
              }
              <span id="estore-retail-lm-item-label-{{menu.type}}" [innerHtml]="menu.title"></span>
            </span>
          </mat-list-item>
        }
      </mat-nav-list>
    </div>

  </div>
</div>
<div class="lg:flex lg:justify-center">
  <div class="gema3g-ss-container">
    <div class="py-9">
      <h2 id="estore-h2-retail-sp" class="text-center">Selected Property for Report Purchase</h2>
    </div>

    <div class="gema3g-ss-container__inner">
      <div class="gema3g-ss-container__inner-grid-2-column">
        <div class="l-column">
          <div class="">
            <div class="">
              @if (selectedPropertyLoading) {
                <div class="selected-property-loading">
                  <img id="estore-retail-refresh-icon" src="assets/img/icon-refresh.gif">
                </div>
              } @else {
                <div class="flex flex-row">
                  <div class="w-full" [ngClass]="selectedProperty.isSelected? 'selected-property' : 'no-property'">
                    @if (!selectedProperty.isSelected) {
                      <div class="flex flex-col h-full">
                        <div class="flex justify-content-center align-middle h-full w-full items-end">
                          <h3 id="estore-h3-retail-no-property" class="text-center">No Property Selected</h3>
                        </div>
                        <div class="flex justify-content-center align-middle h-full w-full items-center">
                          <p id="estore-retail-no-property-msg" class="text-center">This property will be the subject of the reports and records you view in the store.</p>
                        </div>
                        <div class="flex justify-content-center align-middle h-full w-full items-start">
                          @if (userService.userHasAccessToEStoreOnly) {
                            <a id="estore-link-retail-search-realm" (click)="searchREALM()" class="anchor">Search for a property<img id="estore-retail-arrow-icon-realm" alt="" class="arrow-img" src="/assets/img/arrow.png"/></a>
                          } @else {
                            <a id="estore-link-retail-search-geo" (click)="navigateHome()" class="anchor">Search for a property in GeoWarehouse <img id="estore-retail-arrow-icon" alt="" class="arrow-img" src="/assets/img/arrow.png"/></a>
                          }
                        </div>
                      </div>
                    }
                    @if (selectedProperty?.isSelected) {
                      <div class="flex w-full h-full">
                        <div class="flex hidden md:block h-full justify-content-center align-middle">
                          <div [ngClass]="{'flex flex-col min-w-[200px] h-full justify-center p-2' : true}">
                            <div [ngClass]="{'google-sv' : displayStreetView}" #storeRetailStreetViewMapContainer id="store-retail-street-view-map-container"></div>
                            <div [ngClass]="{'flex flex-row justify-center' : !displayStreetView}">
                              <img id="estore-img-retail-no-streetview" [ngClass]="{'hidden' : displayStreetView}" src="{{noPropertyStreetViewImgUrl}}">
                            </div>
                            <div [ngClass]="{'flex flex-row justify-center text-xs' : !displayStreetView}">
                              <p id="estore-retail-no-streetview-msg" [ngClass]="{'hidden' : displayStreetView}">{{ noPropertyStreetViewText }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="flex w-full h-full px-2">
                          <div class="flex flex-col h-full w-full">
                            <div class="flex h-full pt-4">
                              <h4 id="estore-h4-retail-addr">{{ selectedProperty.address }}
                                @if (isDisplayPinCode()) {
                                  {{ selectedProperty.pincode }}
                                }
                              </h4>
                            </div>
                            <div class="flex h-full pt-4">
                              <p id="estore-retail-legal-desc">{{ selectedProperty.legaldescription | cutText:70 }}</p>
                            </div>
                            <div class="flex h-full pt-2">
                              <ul>
                                <li id="estore-retail-pin"><span id="estore-retail-pin-label" class="text-os-bold">PIN:</span> {{ selectedProperty.pin }}</li>
                                @if (selectedProperty.isSubjectPropertyMultipleARN) {
                                  <li id="estore-retail-arn">
                                    <span id="estore-retail-arn-label" class="text-os-bold">ARN:</span>
                                    Multiple ARNS
                                  </li>
                                }
                                @if (!selectedProperty.isSubjectPropertyMultipleARN) {
                                  <li id="estore-retail-arn">
                                    <span id="estore-retail-arn-label" class="text-os-bold">ARN:</span>
                                    {{ selectedProperty.arn }}
                                  </li>
                                }
                              </ul>
                            </div>
                            <div class="flex h-full pt-2">
                              @if (!userService.userHasAccessToEStoreOnly) {
                                @if (userAccessControls.propertyReportAccess) {
                                  <a id="estore-link-retail-pr" (click)="openPropertyReport(selectedProperty.pin);" class="anchor">Property Report
                                    <img id="estore-retail-pr-icon" alt="" class="property-img" src="/assets/img/estore-pr-icon.png">
                                  </a>
                                } @else {
                                  <span id="estore-retail-no-pr-access">Property Report Not Available</span>
                                }
                              }
                            </div>
                            <div class="flex h-full pt-2">
                              <p id="estore-retail-legal-msg" class="legal">This property will be the subject of your purchased reports.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
            @if (!userAccessControls.GWHRSubscriptionExpired) {
              <div class="flex flex-row">
                @if (userService.userHasAccessToEStoreOnly) {
                  <h5 id="estore-h6-retail-callout-realm" class="callout">Want to purchase reports for another property? <a id="estore-link-retail-callout-realm" (click)="searchREALM()" class="anchor">Search for a property.</a></h5>
                } @else {
                  <h5 id="estore-h6-retail-callout" class="callout">Want to purchase reports for another property? <a id="estore-link-retail-callout" (click)="navigateHome()" class="anchor">Search for a property in GeoWarehouse.</a></h5>
                }
              </div>
            }
          </div>
        </div>

        <div class="r-column">
          <div>
            <h5 id="estore-h5-retail-use-sp">Use the subject property</h5>
            <div class="r-column__child-grid">
              <div class="l-column" [ngClass]="{'active' : isPropertyOpenedInMainScreenSelected() }">
                @if (!propertyOpenedInMainScreen) {
                  <span class="gray-bg">
                    <p id="estore-retail-use-sp-none">No Subject Property Selected</p>
                  </span>
                } @else {
                  <a id="estore-link-retail-use-sp" (click)="highlightSubjectPropertyByDefault()">
                    @if (propertyOpenedInMainScreen.isAddressNotAvailable && propertyOpenedInMainScreen.pin != 'N/A') {
                      <p id="estore-retail-use-sp-pin">Address Not Available<br/>PIN : {{ propertyOpenedInMainScreen.pin }}</p>
                    }
                    @if (!propertyOpenedInMainScreen.isAddressNotAvailable) {
                      <p id="estore-retail-use-sp-addr">{{ propertyOpenedInMainScreen.address }}</p>
                    }
                    @if (propertyOpenedInMainScreen.address == 'Address Not Available' && propertyOpenedInMainScreen.arn != 'N/A' && propertyOpenedInMainScreen.pin == 'N/A') {
                      <p id="estore-retail-use-sp-arn">Address Not Available<br/>ARN : {{ propertyOpenedInMainScreen.arn }}</p>
                    }
                  </a>
                }
              </div>
            </div>
          </div>
          <div>
            <h5 id="estore-h5-retail-use-rvp">Use a recently viewed property</h5>
            <div class="r-column__child-grid">
              @if (recentProperties.length > 0 && userAccessControls.propertyReportAccess) {
                @for (pResult of recentProperties | slice:0:4; track pResult; let i = $index) {
                  <div class="l-column" [ngClass]="{'active' : selectedProperty?.isFromRecent && (selectedProperty?.pin === pResult.pin || selectedProperty?.arn === pResult.text )}">
                    <a id="estore-link-retail-use-rvp-{{i}}" (click)="setSubjectProperty(pResult.pin, pResult.text, true)">
                      @if (pResult.pin == null && pResult.text.length === 15) {
                        <p id="estore-retail-use-rvp-arn-{{i}}">Address Not Available<br/>ARN : {{ pResult.text }}</p>
                      }
                      @if (pResult.pin != null &&  pResult.text!='') {
                        <p id="estore-retail-use-rvp-text-{{i}}">{{ pResult.text }}</p>
                      }
                      @if (pResult.pin != null &&  pResult.text=='') {
                        <p id="estore-retail-use-rvp-pin-{{i}}">Address Not Available<br/>PIN : {{ pResult.pin }}</p>
                      }
                    </a>
                  </div>
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #productListAnchor></div>

    <div class="sort-tabs">
      <span id="estore-retail-sort-label" class="pl-7 text-os-bold">Sort By Price:</span>
      <select id="estore-dd-retail-sort" (ngModelChange)="sortOrderChanged($event)" [(ngModel)]="products.selectedOrder" class="sort-price-drp-dwn">
        <option id="estore-dd-option-retail-undefined" disabled selected value="undefined">Choose</option>
        @for (type of orderTypes; track type; let i = $index) {
          <option id="estore-dd-option-retail-{{type.value}}" [value]="type.value">{{ type.display }}</option>
        }
      </select>
    </div>

    <div>
      <div>
        @if (userAccessControls.propertyLineAccess && currentCategory.includes('catMPAC') && !noSelectedProducts) {
          <div class="text-center">
            <div id="estore-retail-add-reports">Looking for additional MPAC reports? Click here to find more reports on
              <a id="estore-link-retail-add-reports" (click)="openEstorePropertyLine(selectedProperty?.arn, $event)" class="anchor" title="MPAC-PropertyLine"> Propertyline</a>!
            </div>
          </div>
        }
      </div>

      @if (noSelectedProducts) {
        <div class="no-products w-full pb-10 pt-10 bg-slate-50">
          <div class="justify-content-center align-middle h-full w-full">

            @if (!currentCategory.includes('catMPAC')) {
              <h3 id="estore-retail-no-reports" class="text-center">{{ DataService.ESTORE_NO_REPORTS_AVAILABLE }}</h3>
              <div id="estore-retail-no-reports-msg" class="flex flex-col gap-2 text-center">
                <a id="estore-link-retail-no-reports" href="mailto:{{DataService.SUPPORT_EMAIL}}?subject=GeoWarehouse Store" class="anchor">{{ DataService.SUPPORT_EMAIL }}</a>
                <div>{{ DataService.ESTORE_SPECIFIC_PRODUCT }}</div>
                <div id="estore-link-retail-no-reports-phone">Phone: {{ DataService.SUPPORT_PHONE }}</div>
                <div id="estore-link-retail-no-reports-toll-free">Toll Free: {{ DataService.SUPPORT_TOLL_FREE }}</div>
              </div>
            }

            @if (currentCategory.includes('catMPAC')) {
              <h3 id="estore-mpac-retail-no-reports" class="text-center">{{ DataService.MPAC_NO_REPORTS_AVAILABLE }}</h3>
              <div id="estore-retail-no-mpac-reports-msg" class="flex flex-col gap-2 text-center">
                <p id="estore-retail-no-mpac-msg">Please go to <a id="estore-link-retail-no-mpac" class="anchor" href="{{propertyLineUrl}}" style="display:inline" target="_blank">www.PropertyLine.ca</a> to purchase reports directly from
              MPAC.</p>
            </div>
          }

        </div>
      </div>
    }

    <div class="gema3g-ss-container__inner">
      <div class="gema3g-ss-container__inner-grid-multi">
        @for (product of products.selectedProducts; track $index) {
          @if (Array.isArray(product)) {

            <gema3g-multi-catalogue-product
              class="l-column-for-card"
              [products]="product"
              [recentProperties]="recentProperties"
              [selectedProperty]="selectedProperty"
              >
            </gema3g-multi-catalogue-product>
          } @else {

            <gema3g-single-catalogue-product
              class="l-column-for-card"
              [product]="product"
              [recentProperties]="recentProperties"
              [selectedProperty]="selectedProperty"
              >
            </gema3g-single-catalogue-product>
          }
        }
      </div>
    </div>
  </div>

</div>
</div>
