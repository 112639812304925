import { inject, Injectable } from "@angular/core";
import { LoggerService } from "./log/logger.service";
import { environment } from "../../../environments/environment";
import { UserService } from "./user.service";

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
    if (environment.isGoogleAnalyticsEnabled) {
      this.loggerService.logInfo('Google Analytics is enabled. Events will be sent to Google Analytics');
    } else {
      this.loggerService.logInfo('Google Analytics is disabled. No events will be sent to Google Analytics');
    }
  }

  private loggerService: LoggerService = inject(LoggerService);
  private userService = inject(UserService);

  // ToDo investigate if this event can be tracked easier from app.component with something like this.router.events....
  public navigate(category: string, label?: string, value: string = '') {
    this.sendEvent('Navigate', category, label, value);
  }

  public openModal(category: string, label?: string, value: string = '') {
    this.sendEvent('OpenModal', category, label, value);
  }

  public openPage(category: string, label?: string, value: string = '') {
    this.sendEvent('OpenPage', category, label, value);
  }

  public openSection(category: string, label?: string, value: string = '') {
    this.sendEvent('OpenSection', category, label, value);
  }

  public buttonClicked(category: string, label?: string, value: string = '') {
    this.loggerService.logDebug('Button clicked', category, label, value);
    this.sendEvent('ButtonClicked', category, label, value);
  }

  public featureClicked(category: string, label?: string, value: string = '') {
    this.sendEvent('FeatureClicked', category, label, value);
  }

  public sendEvent(action: string, category: string, label?: string, value: string = '') {
    if (environment.isGoogleAnalyticsEnabled) {
      try {
        gtag('event', action, {
          'event_category': category,
          'event_label': label,
          'value': value,
          'gema3g': [category, label, value, this.userService.user.businessEntityId].join('-')
        });
      } catch (ex) {
        this.loggerService.logError("Could not send event to GoogleAnalytics", ex);
      }
    }
  }
}
