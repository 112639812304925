import {Component, inject, OnInit} from '@angular/core';
import {DataService} from '../../../../shared/service/data.service';
import {ZendeskService} from "../../../../shared/service/zendesk.service";
import {environment} from "../../../../../environments/environment";
import {lastValueFrom} from "rxjs";
import {UrlService} from "../../../../shared/service/url.service";
import {LoggerService} from "../../../../shared/service/log/logger.service";
import {ZendeskConnectionParam} from "../../../model/zendesk/zendesk-connection-param";

@Component({
  selector: 'gema3g-need-assistance',
  templateUrl: './need-assistance.component.html',
  styleUrls: ['./need-assistance.component.scss']
})
export class NeedAssistanceComponent implements OnInit {
  ngOnInit(): void {
    //this.loadZendesk();
  }

  private zendeskService = inject(ZendeskService);
  private loggerService = inject(LoggerService);

  jwtToken: string;
  returnUrl: string;
  zendeskAction: string;

  get isProduction(): boolean {
    //return environment.text === 'PROD';
    return environment.text === 'PROD' || environment.text === 'UAT';
  };

  async redirectToZendesk() {
    if (this.isProduction) {
      try {
        await this.loadZendesk(true);
      } catch (e) {
        this.loggerService.logError(e);
        window.open(UrlService.DEFAULT_ZENDESK_URL, '_blank');
      }
    } else {
      window.open(UrlService.DEFAULT_ZENDESK_URL, '_blank');
    }
  }

  get DataService() {
    return DataService;
  }

  resetForm(zendeskConnectionParam : ZendeskConnectionParam) {
    // @ts-ignore
    document.getElementById("jwtForm").reset();
    // @ts-ignore
    document.getElementById('jwtInput').value = zendeskConnectionParam.jwtToken;
    // @ts-ignore
    document.getElementById('returnInput').value = zendeskConnectionParam.returnTo;
  }
  async loadZendesk(openForm: boolean = false) {
    const zendeskConnectionParam = await lastValueFrom(this.zendeskService.getZendeskConnectionParams());
    if (zendeskConnectionParam) {
      this.loggerService.logDebug(zendeskConnectionParam.jwtToken);
      this.resetForm(zendeskConnectionParam);
      this.zendeskAction = zendeskConnectionParam.url;
      if (openForm) {
        setTimeout(() => {
          // @ts-ignore
          document.forms["jwtForm"].action = this.zendeskAction;
          // @ts-ignore
          document.forms["jwtForm"].submit();
        }, 200);
      }
    }
  }
}
