import { HttpClient } from "@angular/common/http";
import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, catchError, lastValueFrom, map, Observable, throwError} from "rxjs";
import * as _ from "lodash";
import { LroPolygonMap } from "../../core/model/map/lro-polygon-map";
import { LroState } from "../../core/model/map/lro-state";
import { LoggerService } from "./log/logger.service";
import { LroDefaultPolygon, LroPolygon } from "../../core/model/map/lro-default-polygon";
import { LocalStorageKey } from "../constant/constants";

@Injectable({
    providedIn: 'root'
})
export class LroPolygonsService {
    private allLros: LroDefaultPolygon[];
    private lroKeyValuePairs: any[] = [];
    private _lroPolygonsJsonUrl = '../../../../assets/json/lro-polygons.json';
    private polygonsCache: LroPolygonMap[] = [];

    private _lroState = new BehaviorSubject<LroState>(new LroState(null, false));
    lroState$ = this._lroState.asObservable();

    private _ssoLroState = new BehaviorSubject<LroState>(new LroState(null, false));
    ssoLroState$ = this._ssoLroState.asObservable();

    private https = inject(HttpClient);
    private loggerService = inject(LoggerService);

    loadLroPolygons = async () => {
      this.allLros = await lastValueFrom(this.getLroPolygonsJson());
    }

    private getLroPolygonsJson = (): Observable<LroDefaultPolygon[]> => {
        return this.https.get(this._lroPolygonsJsonUrl)
          .pipe(
            map(response => {
              if (!_.isEmpty(response) && Array.isArray(response)) {
                return response.map(value => {
                  return new LroDefaultPolygon(value);
                })

              } else {
                this.loggerService.logError('Unexpected empty file '+this._lroPolygonsJsonUrl);
                return [];
              }
            }),
            catchError((err) => {
              this.loggerService.logError('Could not load file'+this._lroPolygonsJsonUrl);
              return throwError(err);
            })
          );
    }

    getAllLros = () => {
        return this.allLros;
    }

    getLroById = (lroId: string): LroPolygon[] => {
        let lroPolygons: any[] = [];
        this.getAllLros()?.forEach((lro: { id: string; list: any[]; }) => {
            if (lro.id == lroId) {
                lroPolygons = lro.list;
            }
        });

        return lroPolygons;
    }

    getLrosCount = (): number => {
        return this.getAllLros()?.length;
    }

    getCurrentLroState = () => {
        return this._lroState.getValue();
    }

    getCurrentLro = (): string | null => {
        return this.getCurrentLroState().lroId;
    }

    setCurrentLroState = (lroState: LroState) => {
        this._lroState.next(lroState);
        localStorage.setItem(LocalStorageKey.LRO, lroState.lroId!);
    }

    // retained for backwards compatibility
    setCurrentLro = (lroId: string) => {
        this.setCurrentLroState(new LroState(lroId, false));
    }

    setSSOLroState = (lroState: LroState) => {
        this._ssoLroState.next(lroState);
    }

    getCurrentSSOLroState = () => {
        return this._ssoLroState.getValue();
    }

    cacheLroPolygons = () => {
        this.getAllLros()?.forEach((lro: { id: string; list: any[]; }) => {

            let lroPolygons: any[] = this.getLroById(lro.id);

            lroPolygons.forEach(lroPolygon => {

                const nonUIPolygon = new google.maps.Polygon({
                    paths: lroPolygon.polygon
                });

                this.polygonsCache.push(new LroPolygonMap(lro.id, nonUIPolygon));
            })
        });

        this.loggerService.logInfo(`${this.polygonsCache.length} polygons cached.`);
    }

    getLroIdBySpatialPoint = (spatialPoint: google.maps.LatLng): string | undefined => {
        if (_.isEmpty(this.polygonsCache)) {
            this.cacheLroPolygons();
        }

        let lroId: string | undefined;
        this.polygonsCache.find(lroPolygonMap => {
            lroId = lroPolygonMap.lroId;
            return (google.maps.geometry.poly.containsLocation(spatialPoint, lroPolygonMap.polygon));
        });

        return lroId;
    }

    getLroKeyValuePairs = () => {
        if (_.isEmpty(this.lroKeyValuePairs)) {
            this.getAllLros()?.forEach((lro: { id: string; list: any[]; }) => {
                let allLroData: any[] = lro.list;
                let lroData = allLroData[0];
                this.lroKeyValuePairs.push({"id": lro.id, "name": lroData.name});
            });

            this.lroKeyValuePairs.sort((a, b) => (a.name > b.name) ? 1 : -1);
        }
        return this.lroKeyValuePairs;
    }

    getNameById = (lroId: string): string | null => {
        let lroName = null;

        this.getLroKeyValuePairs()?.filter(lro => {
            if (_.isEqual(lro.id, lroId)) {
                lroName = lro.name;
            }
        })

        return lroName;
    }
}
