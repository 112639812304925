import {PdfReportMyPropertyReportConfiguration} from "./input-param/pdf-report-my-property-report-configuration";
import {PdfReportClientReportConfiguration} from "./input-param/pdf-report-client-report-configuration";
import {UserAccessControl} from "../user/user-access-control";
import {PdfReportRequestReportConfig} from "./pdf-report-request-report-config";

export class PdfReportRequestUtil {

  static loadPropertyInformationSection(reportConfig: PdfReportRequestReportConfig, reportParam: PdfReportMyPropertyReportConfiguration | PdfReportClientReportConfiguration, userAccessControls: UserAccessControl, pin?: string, arn?: string) {
    reportConfig.reportSections.push('RealtorCard');
    if (reportParam.cover) {
      reportConfig.reportSections.push('Cover');
    }
    if (reportParam.propertyInfo.propertyDetail.enabled) {
      reportConfig.reportSections.push('PropertyDetails');
    }
    if (reportParam.propertyInfo.ownership) {
      reportConfig.reportSections.push('LegalOwnership');
    }
    if (reportParam.propertyInfo.propertyHasLegalDescription && reportParam.propertyInfo.legalDescription) {
      reportConfig.reportSections.push('LegalDescription');
    }
    if (reportParam.propertyInfo.propertyIsCondo && reportParam.propertyInfo.condo) {
      reportConfig.reportSections.push('CondoDetails');
    }
    if (reportParam.propertyInfo.hasLotSize && reportParam.propertyInfo.lotSize) {
      reportConfig.reportSections.push('SiteStructure');
      reportConfig.reportImages?.push('terrainView');

    }
    if (reportParam.propertyInfo.canDisplayAssessmentInfo && reportParam.propertyInfo.assessmentInfo && userAccessControls.propertyReportAccess) {
      reportConfig.reportSections.push('AssessmentInformation');
    }
    if (reportParam.propertyInfo.hasSalesHistory && reportParam.propertyInfo.salesHistory) {
      reportConfig.reportSections.push('SalesHistory');
    }

    if (reportParam.propertyInfo.canDisplayDemographic && reportParam.propertyInfo.demographic) {
      reportConfig.reportSections.push("Demographics");
      reportConfig.reportSections.push("Households");
      reportConfig.reportSections.push("SocioEconomic");
      if (reportParam.propertyInfo.dominantMarketGroup) {
        reportConfig.reportSections.push("DominantMarketGroupA");
        reportConfig.reportSections.push("DominantMarketGroupB");
        reportConfig.reportSections.push("DominantMarketGroupC");
      }
    }
    reportConfig.reportSections.push("LastPage");

    if (pin) {
      if (reportParam.propertyInfo.propertyDetail.enabled) {
        if (reportParam.propertyInfo.propertyDetail.aerialMapView && reportParam.propertyInfo.propertyDetail.propertyHasAerialMapView) {
          reportConfig.reportSections.push("PropertyDetailsGoogleImg");
          reportConfig.reportImages?.push('googleView');
        }
        if (reportParam.propertyInfo.propertyDetail.propertyHasStreetView && reportParam.propertyInfo.propertyDetail.streetView) {
          reportConfig.reportImages?.push('streetView');
        }
      }
    } else if (arn) {
      reportConfig.reportImages = [];
    }
  }

  static setComparableSalesReportConfig(reportConfig: PdfReportRequestReportConfig, reportParam: PdfReportClientReportConfiguration) {
    if (reportConfig) {
      reportConfig.reportName = 'ComparableSalesReport';
      reportConfig.reportImages = ['statisticsImage'];
      reportConfig.reportSections.push("RealtorCard");
      if (reportParam.cover) {
        reportConfig.reportSections.push('ComparablesCover');
      }
      if (reportParam.areaSalesInfo.mapResults) {
        reportConfig.reportSections.push("ComparableSalesImage");
        reportConfig.reportSections.push("SearchResults");
        // in 2g this is only sent for a property report and not for a comparable search !!!
        //reportConfig.reportSections.push("SearchCriteria");
        reportConfig.reportImages.push("comparableSalesImages");

        if (reportParam.areaSalesInfo.salesStatistics) {
          reportConfig.reportSections.push("SalesStatistics");
        }
      }
      reportConfig.reportSections.push("LastPage");
    }
  }
}
