import { Component, inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { TransactionHistory } from "../../../core/model/transaction/transaction-history";
import { lastValueFrom } from "rxjs";
import { TrackingRecord } from "../../../core/model/user/tracking-record";
import { UserService } from "../../../shared/service/user.service";
import { MatPaginator } from "@angular/material/paginator";
import { maxPaymentHistoryItems } from "../../../shared/service/system";
import { EstoreService } from "../../../shared/service/estore.service";
import { UserPaymentMethod } from "../../../core/model/user/user-payment-method";
import { ConfirmDialogData } from "../../../core/component/modal/confirm-dialog/confirm-dialog-data";
import { ConfirmDialogComponent } from "../../../core/component/modal/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { DialogReturnTypeEnum } from "../../../core/enum/dialog-return-type-enum";
import { ErrorUtil } from "../../../shared/service/error.util";
import { SnackBarService } from "../../../shared/service/snack-bar.service";
import { GA_Modal } from '../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-billing-payment',
  templateUrl: './billing-payment.component.html',
  styleUrls: ['./billing-payment.component.scss']
})
export class BillingPaymentComponent {
  isLoadingTransactions: boolean = true;
  isLoadingVaultedCards: boolean = true;
  displayedColumns: string[] = ['date', 'price', 'numberOfProducts', 'productDetails', 'transactionId'];
  dataSource = new MatTableDataSource<TransactionHistory>();
  items: TransactionHistory[];
  pageEvent: Promise<void>;
  pageIndex: number;
  pageSize: number;
  length: number = 0;
  pageSizes = [5, 10, 20];
  userPaymentMethod: UserPaymentMethod;

  constructor() {
    setTimeout(() => {
      this.getReport('', null);
    }, 100);
    this.getVaultedCreditCards();
  }

  private snackBarService = inject(SnackBarService);
  private userService = inject(UserService);
  private eStoreService = inject(EstoreService);
  private dialog = inject(MatDialog);
  private loggerService = inject(LoggerService);
  private gaService = inject(GoogleAnalyticsService);

  async getReport(source: string, $event: any) {
    this.loggerService.logInfo($event);

    this.isLoadingTransactions = true;
    try {
      const transactionHistoryResult = await lastValueFrom(this.userService.getTransactionReportHistory(0, maxPaymentHistoryItems, 'creationdate', 'desc'));
      this.items = [];
      this.items.push(...transactionHistoryResult.transactionHistory.map(value => {
        return new TransactionHistory(value);
      }));
      this.dataSource = new MatTableDataSource(this.items);
      this.length = transactionHistoryResult.totalItems;
    } finally {
      this.isLoadingTransactions = false;
    }
  }

  async redirectToGWHInvoicePage(transactionId: string | undefined) {
    await this.eStoreService.redirectToGWHInvoicePage(transactionId);
  }

  async getVaultedCreditCards() {
    this.isLoadingVaultedCards = true;
    try {
      this.userPaymentMethod = await lastValueFrom(this.userService.getVaultedCreditCards());
    } finally {
      this.isLoadingVaultedCards = false;
    }
  }

  deleteCreditCard(externalPaymentId: string) {
    const msg = ['Are you sure you want to delete this credit card?'];

    this.gaService.openModal(GA_Modal.CONFIRM_CARD_REMOVAL);
    const dialogData = new ConfirmDialogData('Confirm Card Removal', msg, 'Yes', 'No',);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData})
      .afterClosed()
      .subscribe(async (resp) => {
          if (resp == DialogReturnTypeEnum.FIRST_BUTTON) {
            this.loggerService.logInfo("Starting credit card removal process");
            const deleted = await lastValueFrom(this.userService.deleteVaultedCreditCard(externalPaymentId));
            if (deleted){
              this.snackBarService.displaySnackBarMessage(ErrorUtil.VAULTED_CARD_DELETION_SUCCESS);
            } else {
              this.snackBarService.displaySnackBarError(ErrorUtil.VAULTED_CARD_DELETION_ERROR);
            }

            await this.getVaultedCreditCards();
          } else {
            this.loggerService.logInfo("User cancelled credit card removal process");
          }
        }
      );
  }
}
