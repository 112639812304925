import {signal, WritableSignal} from "@angular/core";

export class SliderParam {

  constructor(step: number) {
    this.step = step;
  }

  thumbStartValue: WritableSignal<number> = signal(0);
  thumbEndValue: WritableSignal<number> = signal(0);
  startValue: number = 0;
  endValue: number = 0;
  step: number = 1;
}
