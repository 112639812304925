<gema3g-property-report-layout [propertyReportSection]="propertyReportSection">

  @if (staticStreetViewUrl !== undefined) {
    <div class="property-street-view">
      <img id="reg-streetview-img" class="mb-9 w-full" (click)="showPropertyStreetView()" src="{{ staticStreetViewUrl }}"/>
      <div class="streetview-link-container">
        <img id="reg-streetview-icon" class="icon" src="assets/img/svg/map/icon_map_bubble_pegman-orange.jpg">
        <a id="reg-link-streetview" (click)="showPropertyStreetView()" class="anchor">Street View</a>
      </div>
    </div>
  }

  @if (sectionLoading) {
    <div id="reg-streetview-loading" class="pr-no-data-gray-background text-center mb-9 lg:hidden">
      {{ geowarehouseStreetViewPhotoLoading }}
    </div>
  }

  @if (!sectionLoading && staticStreetViewUrl == undefined) {
    <div id="reg-streetview-na-large" class="pr-no-data-gray-background text-center mb-9 lg:hidden">
      {{ geowarehouseStreetViewPhotoNotAvailable }}
    </div>
  }

  <div class="flex flex-col sm:flex-row gap-2">
    <div class="pr-vertical-label-data-container text-sm 2xl:text-lg">
      <div class="flex flex-col mb-8">
        <h4 id="reg-h4-gw-address">GeoWarehouse Address</h4>
        <div id="reg-gw-address" style="text-transform:uppercase;">{{ geowarehouseAddress }}{{ pinCode }}</div>
      </div>
      <div class="flex-grow flex flex-col mb-8">
        <h4 id="reg-h4-lro">Land Registry Office</h4>
        <div id="reg-lro">{{ lro }}</div>
      </div>
      <div class="flex-grow flex flex-col mb-8" id="registry-owner-names">
        <h4 id="reg-h4-on">Owner Names</h4>
        <div id="reg-on">{{ ownerNames }}</div>
      </div>
    </div>

    <div class="pr-vertical-label-data-container text-sm 2xl:text-lg">
      <div class="flex-grow flex flex-col mb-8">
        <h4 id="reg-h4-ot">Ownership Type</h4>
        <div id="reg-ot">{{ subjectProperty?.pii?.ownershipType }}</div>
      </div>
      <div class="flex-grow flex flex-col mb-8">
        <h4 id="reg-h4-lrs">Land Registry Status</h4>
        <div id="reg-lrs">{{ subjectProperty?.pii?.registryStatus | na }}</div>
      </div>
      <div class="flex-grow flex flex-col mb-8">
        @if (userAccessControls.PurviewPropertyTypeAccess) {
          <h4 id="reg-h4-pt">Property Type</h4>
          <div id="reg-pt">{{ subjectProperty?.pii?.propertyType | na }}</div>
        }
      </div>
    </div>

    <div class="pr-vertical-label-data-container text-sm 2xl:text-lg">
      <div class="flex-grow flex flex-col mb-8">
        <h4 id="reg-h4-rt">Registration Type</h4>
        <div id="reg-rt">{{ subjectProperty?.pii?.registryType | na }}</div>
      </div>
      <div class="flex-grow flex flex-col mb-8">
        <h4 id="reg-h4-pin">PIN</h4>
        <div id="reg-pin">
          <div class="pin">{{pin}}</div>
          @if (pin) {
            <div class="icon" [satPopoverAnchor]="copyPinPopOver" (click)="copyPin()">
              <mat-icon id="reg-icon-copy-pin" class="" aria-hidden="false" aria-label="Copy pin" fontIcon="content_copy">
              </mat-icon>
            </div>
          }
        </div>
        <sat-popover #copyPinPopOver [autoFocus]="false" [restoreFocus]="false" horizontalAlign="after" openTransition="500ms ease-out" closeTransition="100ms ease-in">
          <gema3g-tooltip [content]="copiedPin"></gema3g-tooltip>
        </sat-popover>
      </div>
      <div class="flex-grow flex flex-col mb-8">
        <h4>&nbsp;</h4>
        <div>&nbsp;</div>
      </div>
    </div>

  </div>

  <div class="flex flex-col sm:flex-row mt-3 sm:mt-6 gap-4 text-sm 2xl:text-lg">
    @if (hasAccessToLotMaps) {
    <!-- lot measurement map -->
    <div class="w-[100%] sm:w-[50%] flex flex-col gap-4 h-[300px]">
      <gema3g-map-measurement [subjectProperty]="subjectProperty">
      </gema3g-map-measurement>
    </div>

    <!-- parcel map -->
    <div class="w-[100%] sm:w-[50%] h-[300px] max-h-[300px]" id="parcelMapContainer">
      <gema3g-map-parcel [subjectProperty]="subjectProperty">
      </gema3g-map-parcel>
    </div>
    }
  </div>


  <!-- lot size -->
  @if (hasAccessToLotMaps) {
  <div class="flex flex-row divide-x divide-solid my-9 text-sm 2xl:text-lg">
    <h4 id="reg-label-ls" class="flex-25-percent-column">Lot Size</h4>

    <div class="flex-75-percent-column flex flex-col gap-1 pl-2">
      <div class="flex flex-col md:flex-row">
        @if (subjectProperty?.hasCondoDetail() && subjectProperty?.isCondoUnitLockerOrParking()) {
          <div class="lot-size-data">
            <h4 id="reg-label-ls-area-condo">Area:</h4>
            <span id="reg-ls-area-condo">{{ null | na }}</span>
          </div>
          <div class="lot-size-data">
            <h4 id="reg-label-ls-perimeter-condo">Perimeter:</h4>
            <span id="reg-ls-perimeter-condo">{{ null | na }}</span>
          </div>
        } @else {
        <div class="lot-size-data">
          <h4 id="reg-label-ls-area">Area:</h4>
          <span id="reg-ls-area"> {{ area }}</span>
            @if (areaAdditional) {
        <span>{{ areaAdditional }}</span>
        }
          </div>
          <div class="lot-size-data">
            <h4 id="reg-label-ls-perimeter">Perimeter:</h4>
            <span id="reg-ls-perimeter"> {{ perimeter }}</span>
          </div>
        }
      </div>
      <div class="lot-size-data">
        <h4 id="reg-label-ls-measurements">Measurements:</h4>
        @if (subjectProperty?.hasCondoDetail() && subjectProperty?.isCondoUnitLockerOrParking()) {
          <span id="reg-ls-measurements-condo">{{ null | na }}</span>
        } @else {
          <span id="reg-ls-measurements">
            {{ measurements | na }}
            <img id="reg-ls-measurements-copy" (click)="copyMeasurementsToClipboard()" class="legal-img" src="/assets/img/icon_legal.png"/>
          </span>
        }
      </div>
      <div id="lotMeasurementAccuracy" class="lot-size-data">
        <h4 id="reg-label-ls-lma">Lot Measurement Accuracy:</h4>
        <span id="reg-ls-lma" [ngClass]="{'text-red-300' : subjectProperty?.pii?.lotMeasurementAccuracy == 'LOW',
                          'text-green-300' : subjectProperty?.pii?.lotMeasurementAccuracy == 'HIGH',
                          'text-gray-300' : subjectProperty?.pii?.lotMeasurementAccuracy != 'HIGH' && subjectProperty?.pii?.lotMeasurementAccuracy != 'LOW' }">
          {{ subjectProperty?.pii?.lotMeasurementAccuracy | na }}
          <fa-icon
            id="reg-ls-lma-tooltip"
            class="fa-sm text-[#428BCA]"
            [icon]="faCircleInfo"
            [satPopoverAnchor]="measurementAccuracyPopover"
            (mouseover)="measurementAccuracyPopover.open()"
            (mouseleave)="delayedMouseOut(this.measurementAccuracyPopover)">
          </fa-icon>
          <sat-popover #measurementAccuracyPopover scrollStrategy="close" [autoFocus]="false" [restoreFocus]="false" verticalAlign="above" openTransition="500ms ease-out" closeTransition="100ms ease-in">
            <gema3g-tooltip [content]="accuracyToolTip"></gema3g-tooltip>
          </sat-popover>
        </span>
      </div>
    </div>
  </div>
  }

  @if (showCondoDetails) {
    <div id="condo-details-container" class="flex flex-col mt-9 text-sm 2xl:text-lg">
      <h2 id="reg-h2-cd">Condominium Details</h2>
      <div class="flex flex-col md:flex-row gap-4">
        <div class="flex-1">

          <div class="flex flex-col md:flex-col gap-1 h-full">

            <div class="pr-vertical-label-data-container mb-5">
              <div class="flex flex-col">
                <h4 id="reg-h4-cd-cc">Condominium Corporation</h4>
                <div id="reg-cd-cc">{{ subjectProperty?.condoDetail?.corporationName | na }}</div>
              </div>
            </div>

            <div class="pr-vertical-label-data-container-row mb-5">
              <div class="flex-grow flex flex-col">
                <h4 id="reg-h4-cd-cn">Common Name</h4>
                <div id="reg-cd-cn">{{ subjectProperty?.condoDetail?.commonName | na }}</div>
              </div>
            </div>

            <div class="pr-vertical-label-data-container-row mb-5">
              <div class="flex flex-col">
                <h4 id="reg-h4-cd-cdrd">Condo Declaration Registration Date</h4>
                <div id="reg-cd-cdrd">{{ subjectProperty?.condoDetail?.registrationDate | mediumDate:true }}</div>
              </div>
            </div>

            <div class="pr-vertical-label-data-container mb-5">
              <div class="flex-grow flex flex-col">
                <h4 id="reg-h4-cd-suite">Suite #</h4>
                <div id="reg-cd-suite">{{ subjectProperty.pii?.address?.unitNumber | na }}</div>
              </div>
            </div>

          </div>
        </div>

        <data class="flex-1 dense-2 mb-4">
          <table id="reg-tbl-cd" [dataSource]="condoDetailDataSource" class="h-[100%]" class="mat-elevation-z2 standard-white-table" mat-table matSort>

            <ng-container matColumnDef="condoTypeVal">
              <th id="reg-tbl-hdr-cd-type" *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
              <td id="reg-tbl-data-cd-type-{{element.pin}}" *matCellDef="let element" mat-cell>{{ element.condoTypeVal }}</td>
            </ng-container>

            <ng-container matColumnDef="level">
              <th id="reg-tbl-hdr-cd-level" *matHeaderCellDef mat-header-cell mat-sort-header>Level</th>
              <td id="reg-tbl-data-cd-level-{{element.pin}}" *matCellDef="let element" mat-cell>{{ element.level }}</td>
            </ng-container>

            <ng-container matColumnDef="unit">
              <th id="reg-tbl-hdr-cd-unit" *matHeaderCellDef mat-header-cell mat-sort-header>Unit</th>
              <td id="reg-tbl-data-cd-unit-{{element.pin}}" *matCellDef="let element" mat-cell>{{ element.unit }}</td>
            </ng-container>

            <ng-container matColumnDef="pin">
              <th id="reg-tbl-hdr-cd-pin" *matHeaderCellDef mat-header-cell mat-sort-header>PIN</th>
              <td *matCellDef="let element" mat-cell>
                <a id="reg-tbl-link-cd-pin-{{element.pin}}" (click)="openPropertyReport(element.pin)" class="anchor">{{ element.pin }}</a>
              </td>
            </ng-container>

            <tr id="reg-tbl-row-cd-hdr" *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr id="reg-tbl-row-cd-{{row.pin}}" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'mat-table-highlighted-row': isCurrentPin(row) }"
                mat-row
            >
            </tr>
          </table>
        </data>
      </div>
      <mat-divider></mat-divider>
    </div>
  }

  <h2 id="reg-reports-title" class="mt-7 no-print">{{productsForPurchaseLabel}}</h2>

  @if (carouselProducts && carouselProducts.length == 0) {
  <div class="carousel-products-loading">
    <div id="reg-no-reports-label">{{noProductsForPurchaseLabel}}</div>
  </div>
  }

  @if (carouselProductsLoading) {
    <div class="carousel-products-loading">
      <img id="reg-img-refresh" src="assets/img/icon-refresh.gif">
    </div>
  }

  @if (!carouselProductsLoading && carouselProducts && carouselProducts.length > 0) {
    <div class="carousel-container no-print" [ngClass]="{'flex': carouselProducts.length < 3}">
      <p-carousel id="reg-reports-carousel" [circular]="false" [numScroll]="1" [numVisible]="3" [responsiveOptions]="responsiveOptions" [value]="carouselProducts" [showIndicators]="false" [showNavigators]="showNavigators">
        <ng-template let-product pTemplate="item">
          <div class="carousel-card" [ngClass]="{'mr-5': carouselProducts.length === 2}">
            <gema3g-single-catalogue-product
              class="carousel-card__block"
              [product]="product"
              [selectedProperty]="selectedProperty">
            </gema3g-single-catalogue-product>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  }

  <!-- <div class="h-[500px] md:hidden"></div> -->
</gema3g-property-report-layout>
