import { Injectable, Inject, inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../../../shared/service/authentication.service";
import { UserService } from "../../../shared/service/user.service";

@Injectable({ providedIn: 'root' })
export class NotForEStoreOnlyAccessGuard {

  private authService = inject(AuthenticationService);
  private userService = inject(UserService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn) {
      if(this.authService.isSSOLogin){
        return !this.userService.userHasAccessToEStoreOnly;
      }
      return true;
    }

    return false;
  }
}
