import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { EstoreService } from "../../../shared/service/estore.service";
import { EStoreProduct } from "../../../core/model/product/e-store/e-store-product";
import { lastValueFrom, takeUntil } from "rxjs";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { UserService } from "../../../shared/service/user.service";
import { StoreRetailMenuDefinition } from "./menu/store-retail-menu-definition";
import { ActivatedRoute, Router } from "@angular/router";
import { UserActivity } from "../../../core/model/user/user-activity";
import { PropertyReportSearchService } from "../../../shared/service/search/property-report-search.service";
import { EStoreGroupedProduct } from "../../../core/model/product/e-store/e-store-grouped-product";
import { SelectedProperty } from "../../../core/model/property/selected-property";
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { EstoreProductCategoryEnum } from "../../../core/enum/estore-product-category-enum";
import { RouteMappingUtility } from "../../../shared/utility/route-mapping-utility";
import { PropertyDetail } from "../../../core/model/property/property-detail";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";
import { LoggerService } from '../../../shared/service/log/logger.service';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { SSODeeplinkObservablesService } from '../../../shared/service/sso-deeplink-observables.service';
import { DataService } from "../../../shared/service/data.service";
import { UrlService } from "../../../shared/service/url.service";
import { AuthenticationService } from "../../../shared/service/authentication.service";
import { StreetViewUtility } from "../../../shared/utility/street-view.utility";

@Component({
  selector: 'gema3g-store-retail-main',
  templateUrl: './store-retail-main.component.html',
  styleUrls: ['./store-retail-main.component.scss']
})
export class StoreRetailMainComponent extends BaseUnsubscribe implements OnInit {

  @ViewChild('productListAnchor', {static: false}) productListAnchor: ElementRef;

  products: EStoreGroupedProduct = new EStoreGroupedProduct();
  userAccessControls: UserAccessControl;
  menuDefinition: StoreRetailMenuDefinition[];
  currentCategory: string[] = [];
  orderTypes = [
    {value: "ASC", display: 'Low to High'},
    {value: "DSC", display: 'High to Low'}
  ];
  recentProperties: UserActivity[] = [];
  propertyOpenedInMainScreen: SelectedProperty;
  selectedProperty: SelectedProperty;
  displayStreetView: boolean = false;
  @ViewChild('storeRetailStreetViewMapContainer', {static: false}) gMapElementRef: ElementRef;
  streetViewMap: google.maps.Map;
  streetViewService = new google.maps.StreetViewService();
  selectedPropertyLoading: boolean = false;
  noPropertyStreetViewImgUrl: string = '/assets/img/mygeo_overview_grey.png';
  noPropertyStreetViewText: string = 'No Street View Available';
  isSSORequest: boolean = false;
  ssoSubjectPropertyPin: string;
  propertyLineUrl: string = UrlService.PROPERTY_LINE_URL;

  constructor() {
    super();
    this.init();
  }

  private eStoreService: EstoreService = inject(EstoreService);
  public userService: UserService = inject(UserService);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private dataService: DataService = inject(DataService);
  private propertyReportSearchService: PropertyReportSearchService = inject(PropertyReportSearchService);
  private propertyReportService: PropertyReportService = inject(PropertyReportService);
  private ssoDeeplinkObservablesService: SSODeeplinkObservablesService = inject(SSODeeplinkObservablesService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private loggerService: LoggerService = inject(LoggerService);
  private screenManager: ScreenManager = inject(ScreenManager);
  protected authService = inject(AuthenticationService);

  ngOnInit(): void {
    this.propertyReportService.subjectProperty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((propDetail) => {
        if (!this.selectedProperty){
          this.selectedProperty = new SelectedProperty();
        }
        if (propDetail) {
          this.selectedProperty.loadFromPropertyDetail(propDetail, true);
          if (propDetail?.pii) {
            this.selectedProperty.isSelected = true;
          }
        }
      }
    );

    this.ssoDeeplinkObservablesService.getSSOSubjectPropertyPinObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pin) => {
        if (pin) {
          this.loggerService.logDebug(`Setting sso deeplink new subject property pin ${pin}`);
          this.isSSORequest = true;
          this.ssoSubjectPropertyPin = pin;
        }
    });

    this.eStoreService.getCatalogueProductListAnchorObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((scroll) => {
        if (scroll) {
          setTimeout(() => {
            this.scrollToAnchor(this.productListAnchor);
          }, 100);
        }
    });

    /*
    this.eStoreService.getEstoreUrlObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((estoreUrl) => {
        if (estoreUrl) {
          this.loggerService.logInfo(`Opening estore url ${estoreUrl}`);
          this.navigateCart();
        }
    });
    */

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async (params) => {
          const pin = params['pin'];
          const arn = params['arn'];
          if ((pin && this.dataService.isPin(pin)) || (arn && this.dataService.isArn(arn))) {
            await this.setSubjectProperty(pin, arn, true, true);
          }
        }
      );
  }

  private filterBasedOnRoute() {
    const filterFor = this.activatedRoute.snapshot.paramMap.get("type");
    if (filterFor) {
      const category = RouteMappingUtility.eStoreRouteToCategory(filterFor);
      if (category && category.length > 0) {
        this.filterProducts(category);
        // mark menu as selected
        this.menuDefinition.forEach(menu => {
          menu.selected = StoreRetailMenuDefinition.associatedProducts(menu.type).some( epce =>  category.includes(epce));
        });
        this.eStoreService.scrollToCatalogueProductListAnchor(true);
      }
    }
  }

  protected highlightSubjectPropertyByDefault = () => {
    this.setSubjectProperty(this.propertyOpenedInMainScreen.pin, this.propertyOpenedInMainScreen.arn, false);
  }

  async init() {
    this.userAccessControls = this.userService.getUserAccessControl();
    this.menuDefinition = StoreRetailMenuDefinition.definitions(this.userAccessControls);
    try {
      this.recentProperties = await lastValueFrom(this.userService.getUserActivity());
      if (this.screenManager.isScreenVisible(ScreenNameEnum.PROPERTY_REPORT)) {
        this.propertyOpenedInMainScreen = this.selectedProperty;
        this.propertyOpenedInMainScreen.loadFromPropertyOpenedInMainScreen(this.propertyReportService.getSubjectProperty());
        this.highlightSubjectPropertyByDefault();

      } else if (this.isSSORequest && this.ssoSubjectPropertyPin) {
        //set up the subject property as though it is visible
        this.loggerService.logDebug(`setting new sso subject property pin ${this.ssoSubjectPropertyPin}`);
        this.propertyOpenedInMainScreen = this.selectedProperty;
        let propertyDetail: PropertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(this.ssoSubjectPropertyPin));
        if (propertyDetail) {
          this.propertyReportService.updateSubjectProperty(propertyDetail);
          this.propertyOpenedInMainScreen.loadFromPropertyOpenedInMainScreen(this.propertyReportService.getSubjectProperty());
          this.highlightSubjectPropertyByDefault();
          this.selectedProperty.isFromRecent = false;
        }
      }
    } catch (e) {
      this.loggerService.logInfo('could not load recent properties');
    }
    try{
      const eStoreProdPage = await lastValueFrom(this.eStoreService.getEStoreProducts(this.propertyOpenedInMainScreen?.pin));
      this.products.loadProducts(eStoreProdPage.products);
      this.filterBasedOnRoute();
    }
    catch (e) {
      this.loggerService.logInfo('could not load products');
    }
  }


  get noProducts(): boolean {
    return this.products.noProducts;
  }

  get noSelectedProducts(): boolean {
    return this.products.noSelectedProducts;
  }

  menuClicked(menu: StoreRetailMenuDefinition) {
    //this.loggerService.logInfo(menu);

    if(this.userAccessControls.GWHRSubscriptionExpired){
      return;
    }
    this.menuDefinition.forEach(value => {
      value.selected = value.type == menu.type
    });
    if (menu?.type) {
      switch (menu.type) {
        case "ALL_REPORTS_RECORDS_PROPERTY":
          this.products.reloadSelectedProducts(true);
          break;
        default:
          this.filterProducts(StoreRetailMenuDefinition.associatedProducts(menu.type));
          break;
      }

      this.eStoreService.scrollToCatalogueProductListAnchor(true);
    }
  }

  filterProducts(category: EstoreProductCategoryEnum[]) {
    if (category && category.length > 0) {
      this.currentCategory = [...category];
      this.products.filterProducts(category);
    }
  }

  navigateHome() {
    this.router.navigate(['../home']).then(() => {
      this.screenManager.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
    });
  }

  sortOrderChanged($event: any) {
    this.products.sortOrderChanged($event);
  }

  async searchREALM() {
    const url = await lastValueFrom(this.eStoreService.getSearchURL());
    if (url) {
      window.location.replace(url.URL);
    }
  }

  async setSubjectProperty(pin: string, arn: string = "", reloadFromBackend: boolean, forceSelected: boolean = false) {
    try {
      this.selectedPropertyLoading = true;

      if (reloadFromBackend) {
        if (!this.selectedProperty) {
          this.selectedProperty = new SelectedProperty();
        }

        let propertyDetail: PropertyDetail;
        if (pin && pin != "N/A") {
          this.loggerService.logDebug(`Setting new subject property pin ${pin}`);
          propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(pin));

          let titleCheckIndicators = await lastValueFrom(this.eStoreService.getTitleCheckIndicators(pin));
          this.products.updateTitleCheckIndicators(titleCheckIndicators);

        } else {
          this.loggerService.logDebug(`Setting new subject property arn ${arn}`);
          propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByArn(arn));
          // when only one pin associated with current arn, use pin's propertyDetails as it has more info
          if(propertyDetail && Array.isArray(propertyDetail.assessments) && propertyDetail.assessments.length > 0 && Array.isArray(propertyDetail.assessments[0].pins) && propertyDetail.assessments[0].pins.length == 1) {
            let associatedPin = propertyDetail.assessments[0].pins[0].xrefId?.pin;
            if (associatedPin) {
              propertyDetail = await lastValueFrom(this.propertyReportSearchService.getPropertyDetailByPin(associatedPin));
            }
          }
        }
        if(propertyDetail.pii && forceSelected) {
          this.propertyOpenedInMainScreen = new SelectedProperty();
          this.propertyOpenedInMainScreen.loadFromPropertyOpenedInMainScreen(propertyDetail);
          this.selectedProperty.isSelected = true;
        }
        // since we have a subscriber on this component that listens to updates on subject property
        this.propertyReportService.updateSubjectProperty(propertyDetail);

      } else {
        this.selectedProperty = new SelectedProperty(this.propertyOpenedInMainScreen);
        this.selectedProperty.isSelected = true;
      }

      setTimeout(() => {
        this.displayStreetViewForSelectedProperty();
      });

    } catch (e) {
      this.loggerService.logError(`error setting subject property ${pin}`, e);
    } finally {
      this.selectedPropertyLoading = false;
    }

  }

  isPropertyOpenedInMainScreenSelected(): boolean {
    let propertyOpenedInMainScreen = this.propertyOpenedInMainScreen;
    let selectedProperty = this.selectedProperty;
    let isFromRecent = this.selectedProperty.isFromRecent;
    let isSelected = this.selectedProperty.isSelected;

    return (propertyOpenedInMainScreen && selectedProperty && propertyOpenedInMainScreen.pin === selectedProperty.pin);
  }

  isDisplayPinCode = () => {
    return !this.selectedProperty.address.includes(this.selectedProperty.pincode);
  }

  async openEstorePropertyLine(arn: string | undefined, $event: MouseEvent) {
    await this.eStoreService.openPropertyLine(arn);
  }

  async openPropertyReport(pin: string) {
    await this.router.navigate(['/home'], {
      queryParams: {
        pin: pin
      }
    });
  }

  scrollToAnchor(panelRef: ElementRef, scrollToEnd: boolean = false) {
    if (panelRef && panelRef.nativeElement) {
      panelRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: (scrollToEnd) ? 'end' : 'start'
      });
      panelRef.nativeElement.scrollBy(0, 0);
    }
  }

  async displayStreetViewForSelectedProperty() {
    this.displayStreetView = false;
    let streetViewResult: google.maps.StreetViewStatus | null = null;
    if (this.selectedProperty.pin) {
      const streetViewPoint = await lastValueFrom(this.propertyReportService.getStreetViewPoint(this.selectedProperty.pin));
      if (streetViewPoint) {
        let coordinates = new google.maps.LatLng(streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude);

        let gMapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 15,
          disableDefaultUI: true
        };

        this.streetViewMap = new google.maps.Map(this.gMapElementRef.nativeElement, gMapOptions);
        const panorama: google.maps.StreetViewPanorama = this.streetViewMap.getStreetView();

        this.streetViewService.getPanorama({location: coordinates}, (data, status) => {
            this.loggerService.logDebug(`selected catalogue property streetview status ${status}`);
            setTimeout(() => {
              this.displayStreetView = streetViewResult == google.maps.StreetViewStatus.OK;
            });
            streetViewResult = status;
            panorama.setPosition(coordinates);
            panorama.set('disableDefaultUI', true);
            panorama.set('fullscreenControl', true);
            panorama.set('enableCloseButton', false);
            panorama.setPov({
              heading: StreetViewUtility.calculateHeading(data?.location?.latLng?.lat(), data?.location?.latLng?.lng(), streetViewPoint.centroid.latitude, streetViewPoint.centroid.longitude ),
              pitch: 0
            });
            panorama.setVisible(true);
          }
        );
      }
    }
  }

  public get DataService() {
    return DataService;
  }

  protected readonly Array = Array;
}
