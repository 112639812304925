import { BaseModel } from "../base-model";
import { DataService } from "../../../shared/service/data.service";

export class ComparableSalesResponse extends BaseModel {

  constructor(csr?: ComparableSalesResponse) {
    super(csr);
    if (csr) {
      if (csr.centroid) {
        this.centroid = new Centroid(csr.centroid);
      }
      if (csr.sales) {
        this.sales = [];
        csr.sales.forEach(sale => {
          this.sales.push(new ComparableSale(sale));
        });
      }
    } else {
      this.amountLeft = -1; // will force the counter comparable-search.service#comparableSearchCounter to not get updated if not set on the server
    }
  }

  areaDownsized: boolean;
  averagePrice: number;
  blockNumber: null;
  centroid: Centroid;
  downsizedAreaRadius: null;
  maxPrice: number;
  medianPrice: number;
  minPrice: number;
  sales: ComparableSale[];
  salesCount: number;
  salesTruncated: boolean;
  standardDiv: number;
  totalSalesCount: number;
  amountLeft: number;
  amountUsed: number;
}

export class Centroid extends BaseModel {
  latitude: number;
  longitude: number;
}

export class ComparableSale extends BaseModel {

  constructor(cs?: ComparableSale) {
    super(cs);
    if (cs?.centroid) {
      this.centroid = new Centroid(cs.centroid);
    }
  }


  area: number;
  centroid: Centroid;
  condo: Condo;
  considerationAmount: number;
  distance: number;
  pin: string;
  registrationDate: string;
  yearBuilt: null;
  propertyCode: null;
  rollNumber: string | null;
  streetViewUrl: null;
  teranetPropertyType: null;
  areaInAcres: number;
  id: number;
  lro: string;
  useAcres: boolean;
  saleAddressStr: string;
  pricePerArea: number;
  areaInFeet: number;
  pricePerAreaInFeet: number;
  calculatedAreaInAcres: number;
  calculatedPricePerAreaInAcres: number;

  //UI only
  selected: boolean = true;
  displayArea: string = DataService.NOT_APPLICABLE;
  displayPricePerArea: string = DataService.NOT_APPLICABLE;

  get arn(): string {
    return this.rollNumber ?? '';
  }
}

export enum Condo {
  N = "N",
  Y = "Y",
}
