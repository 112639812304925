import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SearchComparablesResultSnapshot } from "../../../core/model/search-result/comparables-result-snapshot";

@Injectable({
    providedIn: 'root'
})
export class SearchComparablesFormService {

    private _multiPolygonSearch = new BehaviorSubject<boolean>(false);
    private multiPolygonSearch$ = this._multiPolygonSearch.asObservable();

    private _customSearchRadius = new BehaviorSubject<string | null>(null);
    private customSearchRadius$ = this._customSearchRadius.asObservable();

    private _searchFormSnapshotRecovery = new BehaviorSubject<SearchComparablesResultSnapshot | null>(null);
    private _searchFormSnapshotRecovery$ = this._searchFormSnapshotRecovery.asObservable();

    getMultiPolygonSearchObservable = () => {
        return this.multiPolygonSearch$;
    }

    promptAddPolygonSearchArea = (add: boolean) => {
        this._multiPolygonSearch.next(add);
    }

    setNewCustomSearchRadius = (value: string) => {
        this._customSearchRadius.next(value);
    }

    getSearchFormSnapshotRecoveryObservable = () => {
        return this._searchFormSnapshotRecovery$;
    }

    private setResultSnapshotForSearchCriteria = (resultsSnapshot: SearchComparablesResultSnapshot | null) => {
        this._searchFormSnapshotRecovery.next(resultsSnapshot);
    }

    startSearchFormRecoveryFromSnapshot = (resultsSnapshot: SearchComparablesResultSnapshot) => {
        this.setResultSnapshotForSearchCriteria(resultsSnapshot);
    }

    endSearchFormRecoveryFromSnapshot = () => {
        this.setResultSnapshotForSearchCriteria(null);
    }
}