import { Component, inject, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from "../../shared/service/authentication.service";
import { UserService } from "../../shared/service/user.service";
import { filter, lastValueFrom, Subject, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../shared/service/data.service";
import { LoginResponse } from "../../core/model/user/login-response";
import { HttpErrorResponse } from "@angular/common/http";
import { LoggerService } from '../../shared/service/log/logger.service';
import { ThemeService } from '../../shared/service/theme.service';
import { LoginError } from "./login-error";
import { SSODeeplinkService } from '../../shared/service/sso-deeplink.service';
import { ZendeskService } from '../../shared/service/zendesk.service';
import { DOCUMENT } from '@angular/common';
import { VersioningService } from "../../shared/service/versioning-service";
import { environment } from "../../../environments/environment";
import { LocalStorageKey } from "../../shared/constant/constants";
import { AuthService } from "@auth0/auth0-angular";
import { UrlService } from "../../shared/service/url.service";
import { TimerService } from "../../shared/service/timer/timer.service";
import { ErrorUtil } from "../../shared/service/error.util";
import { SnackBarService } from "../../shared/service/snack-bar.service";
import _ from "lodash";
import { BrowserDetectorService } from "../../shared/service/browser-detector.service";

@Component({
  selector: 'gema3g-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.fromDataService = this.dataService.someImportantData;
  }

  private authService = inject(AuthenticationService);
  private userService = inject(UserService);
  private dataService = inject(DataService);
  private loggerService = inject(LoggerService);
  protected themeService = inject(ThemeService);
  private activatedRoute = inject(ActivatedRoute);
  private zendeskService = inject(ZendeskService);
  private versioningService = inject(VersioningService);
  private route = inject(ActivatedRoute);
  private snackBarService = inject(SnackBarService);
  private ssoDeeplinkService = inject(SSODeeplinkService);

  auth0Service = inject(AuthService);
  timerService = inject(TimerService);
  router = inject(Router);

  private _loginSubject = new Subject();
  private activeThemeSub = Subscription.EMPTY;
  userName: string = '';
  password: string = '';
  isLoading: boolean = false;
  showPassword: boolean = false;
  logo: string = 'assets/img/geowarehouse-logo1.png';
  lightLogo: string = 'assets/img/footer-left-logo.png';
  loginError: LoginError = new LoginError();
  private ssoLogin: boolean;
  isOktaLogin: boolean = false;

  fromDataService: string;
  loginAttempts: number = 0;
  maxLoginAttempts: number = 3;

  ssoLoginText: string;
  ssoLoginError: boolean;

  privacyStatementUrl: string = environment.url.PRIVACY_STATEMENT_URL;
  termsOfUseUrl: string = environment.url.TERMS_OF_USE_URL;
  securityStatementUrl: string = UrlService.SECURITY_STATEMENT_URL;

  ngOnInit() {
    this.ssoLogin = (this.activatedRoute.snapshot.url[0].path == 'login-by-token') ? true : false;

    try {
      this.zendeskService.hideMessagingWidget();
    } catch (e) {
      this.loggerService.logError('error hiding zendesk messaging widget', e);
    }

    // Dark/Light Theme
    const theme = localStorage.getItem(LocalStorageKey.activeTheme);
    if (theme && this.themeService.themeNames.includes(theme)) {
      this.themeService.set(theme);
    } else {
      this.themeService.set('light');
    }

    this.activeThemeSub = this.themeService.activeTheme$
      .pipe(filter(Boolean))
      .subscribe((themeName) => localStorage.setItem(LocalStorageKey.activeTheme, themeName));

    if (this.isSSOLogin) {
      this.ssoLoginText = DataService.SSO_DEEPLINK_SIGN_IN;
      this.ssoLoginError = false;

      setTimeout(() => {
        this.verifyAuthenticatedSSOUser();
      }, 1000);
    }

    this.route.queryParams
      //.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
          const code = params['code'];
          const state = params['state'];
          const jumpToOktaLogin = params['okta'];
          //this.loggerService.logDebug('code=' + code);
          //this.loggerService.logDebug('state=' + state);
          if (code && state) {
            this.continueOktaLoggingIn();
          } else if (jumpToOktaLogin === 'y') {
            this.oktaLogin()
          }
        }
      );

    this.auth0Service.error$.subscribe(error => {

      let auth0LoginAttemptStr = localStorage.getItem(LocalStorageKey.auth0LoginAttempt);
      let auth0LoginAttempt = 0;
      if (_.isNumber(auth0LoginAttemptStr)) {
        auth0LoginAttempt = parseInt(auth0LoginAttemptStr);
      }

      if (auth0LoginAttempt >= 2) {
        this.loginError.genericLoginError = true;
        this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_OKTA_LOGIN + error.message);
        return;
      } else {
        auth0LoginAttempt++;
        localStorage.setItem(LocalStorageKey.auth0LoginAttempt, auth0LoginAttempt.toString());
        setTimeout(() => {
          this.authService.loginWithOkta();
        })
      }
    });
  }


  continueOktaLoggingIn() {
    this.isOktaLogin = true;
    this.auth0Service.isAuthenticated$.subscribe(
      async (isAuthenticated) => {
        if (isAuthenticated) {
          const token = await lastValueFrom(this.auth0Service.getAccessTokenSilently({
            detailedResponse: true
          }));
          if (token) {
            this.authService.registerOktaSessionParams(token);
            this.timerService.startCheckingForAuth0TokenExpiration();
            await this.authService.continueLoggingIn(true, false);
          }
        } else {
          this.loggerService.logError('there was an error on OKTA login, it looks like authentication failed');
          this.loginError.genericLoginError = true;
        }
      },
      error1 => {
        this.loggerService.logError('there was an error on OKTA login', error1);
        this.loginError.genericLoginError = true;
        this.isOktaLogin = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.resetSubscribers();
  }

  async onLoginClicked() {
    this.loginAttempts = 0;
    await this.login();
  }

  public async login() {
    this.versioningService.checkIfNewVersionIsAvailable()
      .then(async restartNeeded => {
        if (restartNeeded) {
          this.loggerService.logInfo(`Restarting app to load the new version ${this.versioningService.currentVersion()}`);
          await this.versioningService.processLocalVersionOutOfDate();
        }
      });

    this.userService.clearUser();

    if (this.loginAttempts >= this.maxLoginAttempts) {
      this.loginAttempts = 0;
      return;
    }
    this.isLoading = true;
    this.loginError.resetErrors();

    try {
      const loginSuccess = await lastValueFrom(this.authService.login(this.userName, this.password), {defaultValue: new LoginResponse(false)});
      // backend sends {} on a successful login
      if (loginSuccess) {
        await this.continueLoggingIn();
      }
    } catch (e) {
      if (e instanceof HttpErrorResponse && (<HttpErrorResponse>e).status == 401 || (<HttpErrorResponse>e).status == 503) {
        this.loginError.badCredentials = (<HttpErrorResponse>e).status == 401;
        this.loginError.backendNotResponding = (<HttpErrorResponse>e).status == 503;
        this.isLoading = false;
      } else {
        this.loginError.genericLoginError = true;
        this.loggerService.logError(e);
      }
      this.isLoading = false;
    }
  }

  async continueLoggingIn() {
    try {
      this.loginAttempts = 0;
      let continueLoggingIn: boolean = true;

      if (this.isSSOLogin || this.userService.user.userProfile?.parentREB) {
        const licenseCounters = await lastValueFrom(this.userService.getUserLicenseCounters());
        if (licenseCounters?.propertyReportCounter) {
          this.loggerService.logDebug(`user ${this.userService.user.businessEntityId} property report counter is ${licenseCounters.propertyReportCounter.licenseRemaining}`);

          if (licenseCounters.propertyReportCounter.licenseRemaining <= 0) {
            continueLoggingIn = false;
            this.isLoading = false;
            this.ssoLoginError = true;  //re-use this flag for this error
            this.ssoLoginText = ErrorUtil.SSO_DEEPLINK_REQUEST_FAILED;
            
            await this.ssoDeeplinkService.showSSOUserReportWarning();
          }
        }
      }

      if (continueLoggingIn) {
        await this.authService.continueLoggingIn(false, this.isSSOLogin);
      }
    } catch (e) {
      this.loggerService.logError(e);

      if (this.isSSOLogin) {
        this.ssoLoginError = true;
        this.ssoLoginText = ErrorUtil.SSO_DEEPLINK_SIGN_IN_ERROR;
      } else {
        this.loginError.genericLoginError = true;
      }

      this.zendeskService.hideMessagingWidget();
    } finally {
      this.isLoading = false;
    }
  }

  /**
   *
   */
  verifyAuthenticatedSSOUser = async () => {
    await this.continueLoggingIn();
  }

  public get isSSOLogin(): boolean {
    return this.ssoLogin;
  }


  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  get loginButtonText(): string {
    if (this.isLoading || this.isSSOLogin) {
      return "Signing in...";
    }
    return "Sign in";
  }

  resetSubscribers = () => {
    this._loginSubject.next(null);
    this.activeThemeSub.unsubscribe();
  }

  async oktaLogin() {
    if(BrowserDetectorService.isSafari()) {
      await this.authService.loginWithOkta();
    } else {
      await this.router.navigate(['/login-with-okta']);
    }
  }
}
