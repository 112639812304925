import { inject, Injectable } from "@angular/core";
import { LoggerService } from "../log/logger.service";
import { GoogleAnalyticsService } from "../google-analytics.service";
import { NgxPrintService, PrintOptions } from "ngx-print";
import { PropertyDetailSectionEnum } from "../../../core/enum/property-detail-section-enum";
import { PropertyReportService } from "../property-report.service";
import { ProgressBarWithTimeoutDialogData } from "../../../core/component/modal/progress-bar-with-timeout-dialog/progress-bar-with-timeout-data";
import { ProgressBarWithTimeoutDialogComponent } from "../../../core/component/modal/progress-bar-with-timeout-dialog/progress-bar-with-timeout-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import dayjs from "dayjs";
import { GA_Event } from "../../constant/google-analytics-constants";

@Injectable({
  providedIn: 'root'
})
export class CustomPrintService {

  private loggerService = inject(LoggerService);
  gaService = inject(GoogleAnalyticsService);
  private printService = inject(NgxPrintService);
  private propertyReportService = inject(PropertyReportService);
  dialog = inject(MatDialog);

  static propertyReportPrintableArea = 'property-report-container';

  propertyReportHasPrintableArea(): boolean {
    return !!document.getElementById(CustomPrintService.propertyReportPrintableArea);
  }

  customPrintPropertyReport() {
    if (this.propertyReportHasPrintableArea()) {
      this.showProgressBar();
      this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PROPERTY_DETAILS_MAPS);
      setTimeout(() => {
        this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS);
        setTimeout(() => {
          this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PROPERTY_DETAILS);
          this.propertyReportService.scrolltoSectionPanel(null);
          this.gaService.sendEvent(GA_Event.EVENT, 'CustomPrint', 'Print', '1');
          this.ngxPrint(CustomPrintService.propertyReportPrintableArea);
          //this.customPrint(CustomPrintService.propertyReportPrintableArea);
        }, 4000);
      }, 7000);
    }
  }

  showProgressBar() {
    let modalConfig = {
      data: new ProgressBarWithTimeoutDialogData('Please wait while we are building report details...', 10000),
      minWidth: 400,
      maxWidth: 900,
    };
    this.dialog.open(ProgressBarWithTimeoutDialogComponent, modalConfig)
      .afterClosed()
      .subscribe(async (resp: boolean) => {
        this.loggerService.logDebug("Done.")
      });
  }

  private ngxPrint(printableArea: string) {
    let printTitle = `GeoWarehouse_${this.propertyReportService?.getSubjectProperty()?.pii?.getPinOrArn()}_${dayjs().format('YYYYMMMDD_HH_mm')}`;

    const customPrintOptions: PrintOptions = new PrintOptions({
      printSectionId: printableArea,
      printTitle: printTitle,
      useExistingCss: true,
      //openNewTab: true,

    });
    this.printService.print(customPrintOptions)
  }

  private customPrint(printableArea: string) {
    let printContents = document.getElementById(printableArea)!.innerHTML;
    if (printContents) {
      this.gaService.sendEvent(GA_Event.EVENT, 'CustomPrint', 'Print', '1');
      const winPrint = window.open('', '', 'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0');
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((cssRule) => cssRule.cssText)
              .join('');
          } catch (e) {
            console.error(e);
            return '';
          }
        })
        .join('');

      winPrint!.document.write(`<html>
                                    <head><title>GeoWarehouse Print</title></head>
                                    <style>${styles}</style>
                                    <body>${printContents}</body>
                                </html>`);

      setTimeout(() => {
        winPrint!.document.close();
        winPrint!.focus();
        winPrint!.print();
        setTimeout(() => {
          winPrint!.close();
        }, 500);
      }, 5000);

    } else {
      this.loggerService.logError('No printable area found. Searched for: ' + printableArea);
    }
  }
}
