import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { Assessment } from "../../../../../core/model/assessment/assessment";
import { EstoreService } from "../../../../../shared/service/estore.service";
import { UserAccessControl } from "../../../../../core/model/user/user-access-control";
import { MainMapService } from "../../../main-map/main-map.service";
import { PropertyReportService } from '../../../../../shared/service/property-report.service';
import { GA_Feature } from '../../../../../shared/constant/google-analytics-constants';
import { GoogleAnalyticsService } from '../../../../../shared/service/google-analytics.service';

@Component({
  selector: 'gema3g-site-structure-assessment-number',
  templateUrl: './site-structure-assessment-number.component.html',
  styleUrls: ['./site-structure-assessment-number.component.scss']
})
export class SiteStructureAssessmentNumberComponent {

  constructor() {
  }
  
  private eStoreService: EstoreService = inject(EstoreService);
  private element: ElementRef = inject(ElementRef);
  private mainMapService: MainMapService = inject(MainMapService);
  private propertyReportService: PropertyReportService = inject(PropertyReportService);
  private gaService = inject(GoogleAnalyticsService);
  
  @Input() assessment: Assessment = new Assessment();
  @Input() userAccessControls: UserAccessControl;
  @Input() assessmentIndex: number = 0;
  @Input() assessmentTotalNumber: number = 1;
  @Output() scrollToAssessment = new EventEmitter<number>();
  @ViewChild("copyArnPopOver") copyArnPopOver: any;
  copiedArn: string = "Copied";

  openPropertyLine(rollNumber: string) {
    this.eStoreService.openPropertyLine(rollNumber);
  }

  drawAssessmentPolygon(assessment: Assessment) {
    this.propertyReportService.requestSiteStructureMapIt(true);
    this.mainMapService.addNewDrawAssessmentRequest(assessment.rollNumber);
  }

  goToNextAssessment() {
    this.scrollToAssessment.emit(this.assessmentIndex + 1);
  }

  goToPreviousAssessment() {
    this.scrollToAssessment.emit(this.assessmentIndex - 1);
  }

  copyArn = (rollNumber: string) => {
    navigator?.clipboard?.
    writeText(rollNumber)
      .then(() => {
        this.copiedArn = "Copied";
        this.copyArnPopOver.open();
        setTimeout(() => {
          this.copyArnPopOver.close();
          this.gaService.featureClicked(GA_Feature.COPY_ARN, 'ARN', rollNumber);
        }, 4000);
      });
  }

}
