import { Component, inject, OnInit } from '@angular/core';
import { ComparableSalesReportItem } from "../../../core/model/comparables/report/comparable-sales-report-item";
import { lastValueFrom, takeUntil } from "rxjs";
import { ComparablesReportService } from "../../../shared/service/comparables-report.service";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { UserService } from "../../../shared/service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../../shared/service/data.service";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { ComparableSalesReportDetail } from "../../../core/model/comparables/report/comparable-sales-report-detail";
import dayjs from "dayjs";
import { SnackBarService } from "../../../shared/service/snack-bar.service";
import { GoogleAnalyticsService } from "../../../shared/service/google-analytics.service";
import { MatDialog } from "@angular/material/dialog";
import { LoggerService } from "../../../shared/service/log/logger.service";
import { ComparablesReportSectionsPrint } from "../../../core/component/modal/comparables-report-sections-print/comparables-report-sections-print";
import { ComparablesSalesReportPrintParam } from "../../../core/model/comparables/report/print/comparables-sales-report-print-param";
import { ProgressBarDialogData } from "../../../core/component/modal/progress-bar-dialog/progress-bar-data";
import { ProgressBarDialogComponent } from "../../../core/component/modal/progress-bar-dialog/progress-bar-dialog.component";
import { PinListStaticStreetView } from "../../../core/model/map/pinlist-static-streetview";
import { ComparablesService } from "../../../shared/service/comparables.service";
import { ComparablesReportAdd } from "../../../core/component/modal/comparables-report-add/comparables-report-add";
import { ComparableSalesReportCreationParam } from "../../../core/model/comparables/report/comparable-sales-report-creation-param";
import { ComparableSalesReportCreationRequest } from "../../../core/model/comparables/report/comparable-sales-report-creation-request";
import { PinOrArn } from "../../../core/model/property/pin-or-arn";
import { PropertyReportService } from "../../../shared/service/property-report.service";
import { WarningService } from "../../../shared/service/warning.service";
import { WarningDialogData } from "../../../core/component/modal/warning-dialog/warning-dialog-data";
import { ErrorUtil } from "../../../shared/service/error.util";
import { BaseUnsubscribe } from "../../../core/component/base-unsubscribe/base-unsubscribe";
import { ScreenNameEnum } from "../../../core/enum/screen-name.enum";
import { ScreenManager } from "../../../shared/service/screen-manager.service";
import { GA_Modal } from '../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-comparables-main',
  templateUrl: './comparables-main.component.html',
  styleUrls: ['./comparables-main.component.scss']
})
export class ComparablesMainComponent extends BaseUnsubscribe implements OnInit {

  comparableSaleReportService = inject(ComparablesReportService);
  userService = inject(UserService);
  router = inject(Router);
  dataService = inject(DataService);
  snackBarService = inject(SnackBarService);
  gaService = inject(GoogleAnalyticsService);
  dialog = inject(MatDialog);
  loggerService = inject(LoggerService);
  comparablesService = inject(ComparablesService);
  propertyReportService = inject(PropertyReportService);
  warningService = inject(WarningService);
  route = inject(ActivatedRoute);
  screenManager = inject(ScreenManager);

  userAccessControls: UserAccessControl;
  isLoading: boolean = true;
  reports: ComparableSalesReportItem[] = [];
  displayedColumns: string[] = ['reportName', 'subjectPin', 'address', 'clientName', 'jobNumber', 'modifiedDate', 'comments', 'addReport'];

  selectedReport: ComparableSalesReportDetail | null;
  propertyImages: PinListStaticStreetView;

  async ngOnInit(): Promise<void> {
    this.userAccessControls = this.userService.getUserAccessControl();
    this.reports = await lastValueFrom(this.comparableSaleReportService.getExistingReports());
    this.isLoading = false;

    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
          const reportId = params['reportId'];
          if (reportId) {
            setTimeout(() => {
              this.openReport(reportId);
            }, 1);
          }
        }
      );

    this.screenManager.getObservableScreen(ScreenNameEnum.COMPARABLES_REPORT)!
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(visible => {
        // when opening the menu again while a report is showing, close the report and display the list of available reports
        if (visible && this.selectedReport) {
          this.selectedReport = null;
        }
      });
  }

  async goToPin(subjectPin: string) {
    let qp = {};
    if (this.dataService.isPin(subjectPin)) {
      await this.router.navigate(['/home'], {
        queryParams: {pin: subjectPin}
      });
    }
  }

  async openReport(reportId: number) {
    this.isLoading = true;
    try {
      this.selectedReport = await lastValueFrom(this.comparableSaleReportService.getReport(reportId));
      const allPins: string[] = [this.selectedReport.subjectArnVO.pii.pin, ...this.selectedReport.compsArnVO.map(comps => comps.pii.pin)];
      this.propertyImages = await lastValueFrom(this.comparablesService.getStaticStreetViewForMultiplePins(allPins, 120, 90, 'STREET_VIEW', 60, DataService.NO_STREETVIEW_IMAGE));
    } finally {
      this.isLoading = false;
    }
  }

  get isSelectedReportAvailable(): boolean {
    return !!(this.selectedReport && this.propertyImages) && !this.isLoading;
  }

  protected readonly faCircleXmark = faCircleXmark;

  async closeForm() {
    await this.router.navigate(['/home'],);
  }


  async openPdfReport(param: ComparablesSalesReportPrintParam) {
    let modalConfig = {
      data: new ProgressBarDialogData('PDF generation is in progress...', this.requestPdfReport.bind(this), param),
      minWidth: 400,
      maxWidth: 900,
    };
    const dialogRef = await this.dialog.open(ProgressBarDialogComponent, modalConfig)
      .afterClosed()
      .subscribe(async (resp: boolean) => {
        this.loggerService.logDebug("Done.")
      });
  }

  async requestPdfReport(param: ComparablesSalesReportPrintParam) {
    if (param && this.selectedReport) {
      const fileName = this.getReportFileName('pdf');
      const pdfDownloaded = await lastValueFrom(this.comparableSaleReportService.getPdfReport(this.selectedReport?.reportId, fileName, param));
      if (pdfDownloaded) {
        this.snackBarService.displaySnackBarMessage(`File "${fileName}" downloaded successfully`);
      } else {
        this.snackBarService.displaySnackBarError(`There was an error downloading file "${fileName}". Please try again later.`);
      }
    }
  }

  async printPDFReport() {
    this.gaService.openModal(GA_Modal.PRINT_PDF_COMPARABLES_REPORT);
    const dialogRef = await this.dialog.open(ComparablesReportSectionsPrint, {data: this.selectedReport})
      .afterClosed()
      .subscribe(async (param: ComparablesSalesReportPrintParam) => {
        this.openPdfReport(param);
      });
  }

  getReportFileName(extension: string) {
    return `ComparablesReport-${this.selectedReport?.subjectArnVO?.pii?.pin}-${dayjs().format('DD-MMM-YYYY_HH-MM')}.${extension}`;
  }

  async getCsvReport() {
    if (this.selectedReport) {
      this.gaService.openModal(GA_Modal.PRINT_CSV_COMPARABLES_REPORT);
      const fileName = this.getReportFileName('csv');
      const csvDownloaded = await lastValueFrom(this.comparableSaleReportService.getCsvReport(this.selectedReport?.reportId, fileName));
      if (csvDownloaded) {
        this.snackBarService.displaySnackBarMessage(`File "${fileName}" downloaded successfully`);
      } else {
        this.snackBarService.displaySnackBarError(`There was an error downloading file "${fileName}". Please try again later.`);
      }
    }
  }


  propertyRemoved($event: number) {
    if (this.selectedReport) {
      this.openReport(this.selectedReport.reportId);
    }
  }

  async removeReport(reportId: number) {
    const reportDeleted = await lastValueFrom(this.comparableSaleReportService.removeReport(reportId));
    if (reportDeleted?.businessError == 0) {
      this.reports = await lastValueFrom(this.comparableSaleReportService.getExistingReports());
    }
  }

  async addReport() {
    this.gaService.openModal(GA_Modal.ADD_NEW_COMPARABLES_REPORT);
    const subjectProperty = this.propertyReportService.getSubjectProperty();

    if (!subjectProperty || subjectProperty.isEmpty) {
      const content = [DataService.MISSING_SUBJECT_PROPERTY];
      const dialogData = new WarningDialogData(DataService.MISSING_SUBJECT_PROPERTY_HEADER, content, '', 'Ok');
      this.warningService.showWarning(dialogData, false, 560);
    } else {

      const dialogRef = await this.dialog.open(ComparablesReportAdd, {data: this.selectedReport})
        .afterClosed()
        .subscribe(async (param: ComparableSalesReportCreationParam) => {
            if (param && param.reportName) {
              const reportParam: ComparableSalesReportCreationRequest = new ComparableSalesReportCreationRequest();
              reportParam.reportParam = new ComparableSalesReportCreationParam(param);
              reportParam.subjectPin = new PinOrArn(subjectProperty?.pii?.pin, subjectProperty?.pii?.arn);
              const reportCreated = await lastValueFrom(this.comparableSaleReportService.createReport(reportParam));
              if (reportCreated?.businessError == 0) {
                this.reports = await lastValueFrom(this.comparableSaleReportService.getExistingReports());
              } else {
                this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_CREATING_REPORT);
              }
            }
          }
        );
    }
  }

}
