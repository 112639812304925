import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SearchComparablesFormBase } from '../search-comparables-form/search-comparables-form-base';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { takeUntil } from 'rxjs';
import { ComparableSalesResultPayload } from '../../../core/model/comparables/comparable-sales-result-payload';
import { CompactNumberPipe } from '../../../shared/pipe/compact-number.pipe';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { ComparableSale } from '../../../core/model/comparables/comparable-sales-response';

@Component({
  selector: 'gema3g-search-comparables-result-price-chart',
  templateUrl: './search-comparables-result-price-chart.component.html',
  styleUrls: ['./search-comparables-result-price-chart.component.scss'],
  providers: [CompactNumberPipe]
})
export class SearchComparablesResultPriceChartComponent extends SearchComparablesFormBase implements OnInit {

  constructor() {
    super();
  }

  private loggerService = inject(LoggerService);
  private compactNumberPipe = inject(CompactNumberPipe);
  private searchComparablesResultService = inject(SearchComparablesResultService);

  @Input() reInitializeChart: boolean = false;

  data: any;
  options: any;

  searchResultsPayload: ComparableSalesResultPayload;
  median: number;
  average: number;
  standardDeviation: number;

  private initializePriceChart = () => {
    this.loggerService.logDebug(`initializing search comparables result price chart`);

    Chart.register(ChartDataLabels);

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['Sales ($)'],
      datasets: [
        {
          label: 'Min Price',
          backgroundColor: documentStyle.getPropertyValue('--orange-500'),
          borderColor: documentStyle.getPropertyValue('--orange-500'),
          data: [this.calculatedMinPrice()]
        },
        {
          label: 'Max Price',
          backgroundColor: documentStyle.getPropertyValue('--red-500'),
          borderColor: documentStyle.getPropertyValue('--red-500'),
          data: [this.calculatedMaxPrice()]
        },
        {
          label: 'Median',
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          data: [this.calculatedMedianPrice()]
        },
        {
          label: 'Average',
          backgroundColor: documentStyle.getPropertyValue('--green-500'),
          borderColor: documentStyle.getPropertyValue('--green-500'),
          data: [this.calculatedAveragePrice()]
        },
        {
          label: 'Standard Deviation',
          backgroundColor: documentStyle.getPropertyValue('--yellow-500'),
          borderColor: documentStyle.getPropertyValue('--yellow-500'),
          data: [this.calculatedStandardDeviation()]
        }
      ]
    };

    let me = this;
    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.8,
      width: 800,
      barThickness: 50,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        },
        datalabels: {
          anchor: 'center',
          align: 'center',
          font: {
            weight: 'bold'
          },
          //formatter: this.compactNumberPipe.transform(Math.round),
          formatter: (v: number) => this.compactNumberPipe.transform(v, 1)
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              var xLabel = context.dataset.label;
              var yLabel = '$' + context.formattedValue;
              return xLabel + ': ' + yLabel;
            },
            footer: function (context: any) {
              if (context[0].dataset.label == 'Standard Deviation') {
                return 'The smaller the number, the more similar\nthe sales results are in the area.\nThe larger the number, the more widely spread\nthe sales results are in the area.';
              } else {
                return '';
              }
            }
          }
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary,
            callback: function (value: any, index: any, values: any) {
              if (parseInt(value) >= 1000) {
                //return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return me.compactNumberPipe.transform(value, 1);
              } else {
                return value;
              }
            }
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }

      }
    };
  }

  private get selectedSales(): ComparableSale[] {
    return this.searchResultsPayload.response.sales.filter(sale => sale.selected);
  }

  private calculatedMinPrice(): number {
    return this.selectedSales?.length ? this.selectedSales
        .reduce((min, sale) => sale.considerationAmount < min ? sale.considerationAmount : min, this.selectedSales[0].considerationAmount)
      : 0;
  }

  private calculatedMaxPrice(): number {
    return this.selectedSales?.length ?
      this.selectedSales
        .reduce((max, sale) => sale.considerationAmount > max ? sale.considerationAmount : max, this.selectedSales[0].considerationAmount)
      : 0;
  }

  private calculatedAveragePrice(): number {
    return this.selectedSales?.length ?
      this.selectedSales
        .reduce((sum, sale) => sum + sale.considerationAmount, 0) / this.selectedSales.length
      : 0;
  }

  private calculatedMedianPrice(): number {
    if (this.selectedSales?.length) {
      let sorted = this.selectedSales.sort((a, b) => a.considerationAmount - b.considerationAmount);
      let med = Math.floor(sorted.length / 2);
      if (sorted.length % 2) {
        return sorted[med].considerationAmount;
      } else {
        return (sorted[med - 1].considerationAmount + sorted[med].considerationAmount) / 2;
      }
    }
    return 0;
  }

  private calculatedStandardDeviation(): number {
    if (this.selectedSales?.length && this.selectedSales?.length > 1) {
      const average = this.calculatedAveragePrice();
      return Math.sqrt(
        this.selectedSales
          .reduce((sum, sale) =>
            sum + Math.pow(sale.considerationAmount - average, 2), 0) / (this.selectedSales.length - 1)
      );
    }
    return 0;
  }

  ngOnInit() {
    this.searchComparablesResultService.getSearchResultsSnapshotObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(snapshot => {
        if (snapshot) {
          this.searchResultsPayload = snapshot.results;
          this.initializePriceChart();
        } else {
          this.loggerService.logDebug('no search comparables price chart to display');
        }

      })
  }

  updatePriceChart(): void {
    this.loggerService.logDebug(`re-initializing search comparables results price chart`);
    this.initializePriceChart();
  }
}
