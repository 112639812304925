import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CoreModule } from "../../../../core/core.module";
import { Pii } from "../../../../core/model/property/pii";
import { MainMapService } from "../../main-map/main-map.service";
import { BaseSearchResults } from "../base-search-results";
import { CommonModule } from "@angular/common";
import { LoggerService } from "../../../../shared/service/log/logger.service";
import { CondoSummary } from "../../../../core/model/property/condo-summary";
import { SvgIconComponent } from "../../../../core/component/svg-icon/svg-icon.component";
import { UserService } from '../../../../shared/service/user.service';

@Component({
  selector: 'gema3g-pii-search-results',
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SvgIconComponent
  ],
  templateUrl: './pii-search-results.component.html',
  styleUrl: './pii-search-results.component.scss'
})
export class PiiSearchResultsComponent extends BaseSearchResults {

  constructor() {
    super();
  }
  
  userService: UserService = inject(UserService);
  mainMapService: MainMapService = inject(MainMapService);
  private loggerService: LoggerService = inject(LoggerService);

  @Input('searchResult') searchResult: Pii[] = [];
  @Input() isMobileFullScreen: boolean = false;
  @Output() onCondoClick = new EventEmitter<CondoSummary>();

  onMouseOut(pii: Pii) {
    this.mainMapService.setMarkerDefault(pii?.pin);
    pii.showExpansionImages = false;
  }

  onMouseOver(pii: Pii) {
    this.mainMapService.setMarkerSelected(pii?.pin);
    pii.showExpansionImages = true;
  }

  openCondoView(result: Pii) {
    if (result) {
      this.onCondoClick.emit(new CondoSummary(result.pin, result.fullAddress));
    } else {
      this.loggerService.logError('Could not open code view with an empty result.');
    }
  }

  get propertyReportAccess() {
    return this.userService.getUserAccessControl().propertyReportAccess;
  }
}
