import { inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { SSODeeplinkRequestParameter } from "../../core/enum/sso-deeplink-request-parameter.enum";
import { SSODeeplinkTarget } from "../../core/enum/sso-deeplink-target.enum";
import { EnumUtility } from "../utility/enum.utility";
import { DataService } from "./data.service";
import { LoggerService } from "./log/logger.service";
import { PropertyReportService } from "./property-report.service";
import { UserService } from "./user.service";
import { defaultErrorMatSnackBarConfig, defaultIndefiniteErrorMatSnackBarConfig, defaultMatSnackBarConfig } from '.././../shared/constant/constants';
import { EstoreService } from "./estore.service";
import { EstoreProductTypeEnum } from "../../core/enum/estore-product-type.enum";
import { EstoreDisplayModeEnum } from "../../core/enum/estore-display-mode.enum";
import { EStoreParam } from "../../core/model/product/e-store/e-store-param";
import { BehaviorSubject, lastValueFrom, Observable, skip, Subject, takeUntil } from "rxjs";
import * as _ from 'lodash';
import { SSODeeplinkObservablesService } from "./sso-deeplink-observables.service";
import { PropertyDetailSectionEnum } from "../../core/enum/property-detail-section-enum";
import { AdBannerService } from "./ad-banner.service";
import { AdBannerAction } from "../../core/model/banner/ad-banner-action";
import { AdBannerActionEnum } from "../../core/enum/ad-banner-action-enum";
import { MpacAssessmentService } from "./mpac-assessment.service";
import { Assessment } from "../../core/model/assessment/assessment";
import { OmnibarSearchService } from "./search/omnibar-search.service";
import { LroState } from "../../core/model/map/lro-state";
import { LroPolygonsService } from "./lro-polygons.service";
import { ErrorUtil } from "./error.util";
import { v4 as uuidv4 } from 'uuid';
import { PinRollNumberXref } from "../../core/model/mpac/pin-rollnumber-xref";
import { WarningDialogData } from "../../core/component/modal/warning-dialog/warning-dialog-data";
import { WarningService } from "./warning.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class SSODeeplinkService {
    constructor() {
        this.userService.ssoRequestRoute$
            .pipe(skip(1))
            .subscribe(route => {
                if (route) {
                    this.dispatchRequest(route);
                    this.userService.userHasAccessToEStoreOnly = this.estoreOnly;
                }
            });
    }

    private adBannerService = inject(AdBannerService);
    private loggerService = inject(LoggerService);
    private router = inject(Router);
    private dataService = inject(DataService);
    private userService = inject(UserService);
    private eStoreService = inject(EstoreService);
    private propertyReportService = inject(PropertyReportService);
    private ssoDeeplinkObservablesService = inject(SSODeeplinkObservablesService);
    private mpacAssessmentService = inject(MpacAssessmentService);
    private omnibarSearchService = inject(OmnibarSearchService);
    private lroPolygonsService = inject(LroPolygonsService);
    private warningService = inject(WarningService);
    private authService = inject(AuthenticationService);
    private _snackBar = inject(MatSnackBar);

    private _deeplinkServiceSubject = new Subject();
    activatedRoute: ActivatedRoute;
    deepLinkTarget: SSODeeplinkTarget | undefined;
    deepLinkSearchType: string;
    searchValue: string;
    address: string;
    registeredPlan: string;
    lro: string;
    lot: string;
    concession: string;
    instrumentNumber: string;
    deepLinkProductCode: string;
    pybToken: string;
    estoreOnly: boolean = false;
    callback: string;
    requestedFeature: string;
    referer: string;

    public dispatchRequest = (activatedRoute: ActivatedRoute) => {
        this.loggerService.logDebug(`dispatching sso request ${window.location.href}`);
        this.activatedRoute = activatedRoute;
        this.parseRequestParameters();
        this.dispatchRequestToDeeplinkHandlers();
    }

    private dispatchRequestToDeeplinkHandlers = () => {
        switch (this.deepLinkTarget) {
            case null:
                this.dispatchSSOOnlyRequest();
                break;

            case SSODeeplinkTarget.PROPERTY_REPORT.toUpperCase():
                this.dispatchPropertyReportSearch();
                break;

            case SSODeeplinkTarget.PROPERTY_SEARCH.toUpperCase():
                if (!_.isEmpty(this.deepLinkSearchType) && this.deepLinkSearchType == 'lro') {
                    this.dispatchLroSearch();
                } else {
                    this.dispatchPropertySearch();
                }
                break;

            case SSODeeplinkTarget.ESTORE_CATALOGUE.toUpperCase():
                this.dispatchEstoreCatalogueRequest();
                break;

            case SSODeeplinkTarget.ESTORE_PRODUCT.toUpperCase():
                this.dispatchEstoreProductRequest();
                break;
        }
    }

    private dispatchPropertyReportSearch = async () => {
        try {
            if (this.dataService.isPin(this.searchValue)) {
                if (this.address && this.address != 'null') {
                    //this may not execute as address search returning a single property is translated to search by pin on the backend
                    this.loggerService.logDebug(`sso deeplink address search`, this.userService.user.businessEntityId, this.address);
                } else {
                    //this may in fact be an address search as address search returning a single property is translated to search by pin on the backend
                    this.loggerService.logDebug(`sso deeplink address/pin search`, this.userService.user.businessEntityId, this.searchValue);
                }

                await this.router.navigate(['/home'], {
                    queryParams: {
                        pin: this.searchValue
                    }
                }).then(() => {
                });
                
            } else if (this.dataService.isArn(this.searchValue)) {
                //this may in fact be an arn search as arn search returning a single property is translated to search by pin on the backend
                this.loggerService.logDebug(`sso deeplink arn/pin search`, this.userService.user.businessEntityId, this.searchValue);

                await this.router.navigate(['/home'], {
                    queryParams: {
                        arn: this.searchValue
                    }
                }).then(() => {
                });

            } else {
                //TODO review this catch all scenario
                this.dispatchUnhandledRequest(SSODeeplinkTarget.PROPERTY_REPORT);
            }
        } catch (error) {
            this.logGeneralError();
        }
    }

    private dispatchPropertySearch = async () => {
        try {
            this.loggerService.logDebug(`forwarding user ${this.userService.user.businessEntityId} to sso deeplink by property search`, this.searchValue);
            await this.router.navigate(['/home']).then(() => {
                this.omnibarSearchService.nextWildCardSearch(this.searchValue);
            });
        } catch (error) {
            this.logGeneralError();
        }
    }

    private dispatchLroSearch = async () => {
        try {
            if (_.isEmpty(this.searchValue)) throw new Error('missing lro in sso lro deeplink');
            this.loggerService.logDebug(`forwarding user ${this.userService.user.businessEntityId} to sso deeplink search by lro ${this.searchValue}`);
            await this.router.navigate(['/home']).then(() => {
                let ssoLro: string = this.searchValue;
                setTimeout(() => {
                    this.lroPolygonsService.setSSOLroState(new LroState(ssoLro, true));
                }, 200);
            });
        } catch (error) {
            this.logGeneralError();
        }
    }

    private dispatchEstoreCatalogueRequest = async () => {
        try {
            if (this.dataService.isPin(this.searchValue)) {
                this.loggerService.logDebug(`sso deeplink to estore catalogue pin`, this.userService.user.businessEntityId, this.searchValue);
                await this.router.navigate(['/catalogue'], {
                    queryParams: {
                        pin: this.searchValue
                    }
                }).then(() => {
                });
            } else if (this.dataService.isArn(this.searchValue)) {
                this.loggerService.logDebug(`sso deeplink to estore catalogue arn`, this.userService.user.businessEntityId, this.searchValue);
                await this.router.navigate(['/catalogue'], {
                    queryParams: {
                        arn: this.searchValue
                    }
                }).then(() => {
                });
            } else if (this.estoreOnly) {
                this.loggerService.logDebug(`sso deeplink to estore only`, this.userService.user.businessEntityId);
                this.router.navigate(['/catalogue']);
            } else {
                //TODO review this catch all scenario
                this.dispatchUnhandledRequest(SSODeeplinkTarget.ESTORE_CATALOGUE);
            }
        } catch (error) {
            this.logGeneralError();
        }
    }

    private dispatchEstoreProductRequest = async () => {
        try {
            switch (this.deepLinkProductCode) {
              case EstoreProductTypeEnum.PARCEL_REGISTER:
                    var storeParam: EStoreParam = new EStoreParam(EstoreProductTypeEnum.PARCEL_REGISTER, this.searchValue);
                    await this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    break;
                    
              case EstoreProductTypeEnum.PARCEL_REGISTER_TITLE_CHECK:
                    var storeParam: EStoreParam = new EStoreParam(EstoreProductTypeEnum.PARCEL_REGISTER_TITLE_CHECK, this.searchValue);
                    await this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    break;

                case EstoreProductTypeEnum.PLAN_IMAGE:
                    await this.router.navigate(['/home'], {
                        queryParams: {
                          pin: this.searchValue
                        }
                    }).then(() => {
                        this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS);
                    });

                    break;

                case EstoreProductTypeEnum.INSTRUMENT_IMAGE:
                    var storeParam: EStoreParam = new EStoreParam(EstoreProductTypeEnum.INSTRUMENT_IMAGE_DOCUMENT, undefined, undefined, this.lro, undefined, this.instrumentNumber);
                    await this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    break;

                case EstoreProductTypeEnum.PYB_SURVEY:
                    var storeParam: EStoreParam = new EStoreParam(EstoreProductTypeEnum.PYB_SURVEY, this.searchValue);
                    storeParam.pybToken = this.pybToken;
                    await this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    break;

                case EstoreProductTypeEnum.PYB_BUYER_REPORT:
                case EstoreProductTypeEnum.PYB_SELLER_REPORT:
                case EstoreProductTypeEnum.PYB_EASEMENT_REPORT:
                    var storeParam: EStoreParam = await this.getEstoreParamForPYB(this.deepLinkProductCode, this.searchValue);
                    await this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    break;

                case EstoreProductTypeEnum.HOODQ_ADDRESS_REPORT:
                case EstoreProductTypeEnum.HOODQ_DETAILED_REPORT:
                case EstoreProductTypeEnum.HOODQ_SCHOOL_REPORT:
                    let rollNumber = await this.getAssessmentRollNumber(this.searchValue);
                    var storeParam: EStoreParam = new EStoreParam(this.deepLinkProductCode, this.searchValue, rollNumber);
                    //TODO: should this dispatch to dispatchCatalogueProductTypeRequest to set the subject property in the catalogue?
                    await this.router.navigate(['/home']).then(() => {
                        this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    });
                    break;

                case EstoreProductTypeEnum.CONDO_STATUS_CERTIFICATE:
                    if (this.dataService.isPin(this.searchValue)) {
                        await this.router.navigate(['/home']).then(() => {
                            this.propertyReportService.showPropertyReportByPinDeferData(this.searchValue);
                            this.propertyReportService.propertyPropertyRendered$
                                .pipe(takeUntil(this._deeplinkServiceSubject))
                                .subscribe((rendered) => {
                                    if (rendered) {
                                        if (this.propertyReportService?.getSubjectProperty()?.hasCondoDetail()) {
                                            if (this.userService.hasAccessToProduct(EstoreProductTypeEnum.CONDO_STATUS_CERTIFICATE)) {
                                                this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.CONDO_DETAILS);
                                                this.adBannerService.invokeAction(new AdBannerAction(AdBannerActionEnum.OPEN_CONDUIT));
                                                this.resetSubscribers();    //reset immediately otherwise this also fires for non-sso requests
                                            } else {
                                                this.loggerService.logWarning(`SSO deeplink to pin ${this.propertyReportService.getSubjectPropertyPin()} aborted. No product access to ${EstoreProductTypeEnum.CONDO_STATUS_CERTIFICATE}.`);
                                            }
                                        } else {
                                            this.loggerService.logWarning(`SSO deeplink to pin ${this.propertyReportService.getSubjectPropertyPin()} aborted. No condo details found.`);
                                        }
                                    }
                                });
                        });

                    } else {
                        this.dispatchUnhandledRequest(SSODeeplinkTarget.ESTORE_PRODUCT);
                    }
                    break;

                case EstoreProductTypeEnum.CONDO_SUITE_SQUARE_FOOTAGE_REPORT:
                    break;

                case EstoreProductTypeEnum.AVM_RANGE_REPORT:
                case EstoreProductTypeEnum.PREMIUM_AVM_COMPARABLE_REPORT:
                    this.dispatchCatalogueProductTypeRequest(this.deepLinkProductCode);
                    break;

                case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_LRO:
                case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_FSA:
                case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_MUNICIPALITY:
                    this.dispatchCatalogueProductTypeRequest(this.deepLinkProductCode);
                    break;

                case EstoreProductTypeEnum.AERIAL_IMAGERY_REPORT:
                    await this.router.navigate(['/home'], {
                        queryParams: {
                          pin: this.searchValue
                        }
                    }).then(() => {
                        this.propertyReportService.scrolltoSectionPanel(PropertyDetailSectionEnum.PLANS_SURVEYS_EASEMENTS);
                        setTimeout(() => {
                            this.adBannerService.invokeAction(new AdBannerAction(AdBannerActionEnum.OPEN_ZOOM2_IT));
                        }, 100);
                    });

                    break;

                case EstoreProductTypeEnum.MPAC_AVM_RANGE_REPORT:
                case EstoreProductTypeEnum.MPAC_PREMIUM_AVM_COMPARABLE_REPORT:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_1:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2:
                case EstoreProductTypeEnum.MPAC_AVM_BASIC_RESIDENTIAL_REPORT:
                case EstoreProductTypeEnum.MPAC_AVM_ENHANCED_RESIDENTIAL_REPORT:
                case EstoreProductTypeEnum.MPAC_COMMERCIAL_DETAILED_REPORT:
                case EstoreProductTypeEnum.MPAC_INDUSTRIAL_DETAILED_REPORT:
                case EstoreProductTypeEnum.MPAC_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT:
                case EstoreProductTypeEnum.MPAC_REB_REPORT:
                case EstoreProductTypeEnum.MPAC_CORPORATE_NAME_ADDRESS_REPORT:
                case EstoreProductTypeEnum.MPAC_AVM_COMPARABLE_REPORT:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT:
                case EstoreProductTypeEnum.MPAC_FOUR_YEAR_PHASED_IN_REPORT:
                case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAILED_REPORT:
                case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_RANGE:
                case EstoreProductTypeEnum.MPAC_AVM_MARKET_SALES_REPORT:
                case EstoreProductTypeEnum.MPAC_DISCOUNTED_ICI_COMMERCIAL_INDUSTRIAL_DETAILED_REPORT:
                case EstoreProductTypeEnum.MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT:
                case EstoreProductTypeEnum.MPAC_NEW_RESIDENTIAL_FLOOR_AREA_REPORT:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_RANGE_REPORT:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_DETAILED_REPORT_LEVEL_2_RANGE:
                    var storeParam: EStoreParam = new EStoreParam(this.deepLinkProductCode, this.searchValue);
                    //TODO: should this dispatch to dispatchCatalogueProductTypeRequest to set the subject property in the catalogue?
                    await this.router.navigate(['/home']).then(() => {
                        this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    });
                    break;

                //MPAC bundle products
                case EstoreProductTypeEnum.MPAC_AVM_RESIDENTIAL_COMPARABLE_REPORT_BUNDLE_10:
                case EstoreProductTypeEnum.MPAC_AVM_MARKET_SALES_REPORT_BUNDLE:
                case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_1:
                case EstoreProductTypeEnum.MPAC_REB_RESIDENTIAL_DETAIL_REPORT_BUNDLE_2:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_1:
                case EstoreProductTypeEnum.MPAC_RESIDENTIAL_FLOOR_AREA_REPORT_BUNDLE_2:
                    var storeParam: EStoreParam = new EStoreParam(this.deepLinkProductCode, this.searchValue);
                    //TODO: should this dispatch to dispatchCatalogueProductTypeRequest to set the subject property in the catalogue?
                    await this.router.navigate(['/home']).then(() => {
                        this.eStoreService.openShoppingCartWithStoreProductTypeForSSO(storeParam, EstoreDisplayModeEnum.BROWSER_TAB);
                    });
                    break;

                default:
                    //TODO review this catch all scenario
                    this.dispatchUnhandledRequest(SSODeeplinkTarget.ESTORE_PRODUCT);
            }

        } catch (error) {
            this.logGeneralError();
        }
    }

    private dispatchUnhandledRequest = (deepLinkTarget: SSODeeplinkTarget) => {
        this.loggerService.logWarning(`An unhandled ${deepLinkTarget} search request was encountered.`);
        this._snackBar.open(DataService.SSO_DEEPLINK_UNHANDLED_REQUEST, 'Close', defaultErrorMatSnackBarConfig);
        this.dispatchSSOOnlyRequest();
    }

    private dispatchSSOOnlyRequest = () => {
        this.loggerService.logDebug(`forwarding deeplink request to landing page only`);
        this.router.navigate(['/home']);
    }

    private dispatchCatalogueProductTypeRequest = async (productType: EstoreProductTypeEnum) => {
        this.loggerService.logDebug(`forwarding deeplink request to catalogue product type`);

        switch (productType) {
            case EstoreProductTypeEnum.AVM_RANGE_REPORT:
                await this.router.navigate(['/catalogue/ti']).then(() => {
                    this.ssoDeeplinkObservablesService.updateSSOSubjectPropertyPin(this.searchValue); //note: check if AVM search by ARN gets translated to PIN on the backend
                    setTimeout(() => {
                        this.eStoreService.scrollToCatalogueProductListAnchor(true);
                        this.eStoreService.startPropertyInsightsReportRunner(170);
                    }, 200);
                });
                break;

            case EstoreProductTypeEnum.PREMIUM_AVM_COMPARABLE_REPORT:
                await this.router.navigate(['/catalogue/ti']).then(() => {
                    this.ssoDeeplinkObservablesService.updateSSOSubjectPropertyPin(this.searchValue); //note: check if AVM search by ARN gets translated to PIN on the backend
                    setTimeout(() => {
                        this.eStoreService.scrollToCatalogueProductListAnchor(true);
                        this.eStoreService.startPropertyInsightsReportRunner(171);
                    }, 200);
                });
                break;

            case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_LRO:
                await this.router.navigate(['/catalogue/rr']).then(() => {
                    this.ssoDeeplinkObservablesService.updateSSOSubjectPropertyPin(this.searchValue); //note: RRR search by ARN gets translated to PIN on the backend
                    setTimeout(() => {
                        this.eStoreService.scrollToCatalogueProductListAnchor(true);
                        this.eStoreService.triggerResidentialRelocationReportTimeFramePrompt(1119);
                    }, 200);
                });
                break;

            case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_FSA:
                await this.router.navigate(['/catalogue/rr']).then(() => {
                    this.ssoDeeplinkObservablesService.updateSSOSubjectPropertyPin(this.searchValue); //note: RRR search by ARN gets translated to PIN on the backend
                    setTimeout(() => {
                        this.eStoreService.scrollToCatalogueProductListAnchor(true);
                        this.eStoreService.triggerResidentialRelocationReportTimeFramePrompt(1121);
                    }, 200);
                });
                break;

            case EstoreProductTypeEnum.RESIDENTIAL_RELOCATION_REPORT_MUNICIPALITY:
                await this.router.navigate(['/catalogue/rr']).then(() => {
                    this.ssoDeeplinkObservablesService.updateSSOSubjectPropertyPin(this.searchValue); //note: RRR search by ARN gets translated to PIN on the backend
                    setTimeout(() => {
                        this.eStoreService.scrollToCatalogueProductListAnchor(true);
                        this.eStoreService.triggerResidentialRelocationReportTimeFramePrompt(1120);
                    }, 200);
                });
                break;
        }
    }

    private getEstoreParamForPYB = async (deepLinkProductCode: string, searchValue: string): Promise<EStoreParam> => {
        var storeParam: EStoreParam = new EStoreParam(deepLinkProductCode);
        storeParam.uuid = uuidv4();

        if (this.dataService.isPin(searchValue)) {
            storeParam.pin = searchValue;
            storeParam.arn = await this.getAssessmentRollNumber(searchValue);

        } else if (this.dataService.isArn(searchValue)) {
            storeParam.arn = searchValue;
            storeParam.pin = '';

            let xrefArray: PinRollNumberXref[] = await lastValueFrom(this.mpacAssessmentService.getAssessmentXRefByArn(searchValue));
            if (!_.isEmpty(xrefArray)) {
              let xref: PinRollNumberXref = xrefArray[0];
              storeParam.pin = xref.xrefId?.pin;
            }
        }

        return storeParam;
    }

    private getAssessmentRollNumber = async (pin: string) => {
        let rollNumber: string = '';
        try {
            let assessments: Assessment[] = await lastValueFrom(this.mpacAssessmentService.getFullAssessmentsByPin(pin));
            if (!_.isEmpty(assessments)) {
                rollNumber = assessments[0].rollNumber;
            }
        } catch (e) {
            this.loggerService.logError(`error getting assessment rollnumber for pin ${pin}`);
        }

        return rollNumber;
    }

    private logGeneralError = () => {
        this.loggerService.logError(ErrorUtil.SSO_DEEPLINK_GENERAL_ERROR_OCCURRED);
        this._snackBar.open(ErrorUtil.SSO_DEEPLINK_GENERAL_ERROR_OCCURRED, 'Close', defaultErrorMatSnackBarConfig);
    }

    private parseRequestParameters = () => {
        let parameterValue: string;

        parameterValue = this.getParameterValue(SSODeeplinkRequestParameter.DEEP_LINK_TARGET);
        this.deepLinkTarget = EnumUtility.getEnumKeyByEnumValue(SSODeeplinkTarget, parameterValue) as SSODeeplinkTarget;

        this.deepLinkSearchType = this.getParameterValue(SSODeeplinkRequestParameter.DEEP_LINK_SEARCH_TYPE);
        this.searchValue = this.getParameterValue(SSODeeplinkRequestParameter.SEARCH_VALUE);
        this.address = this.getParameterValue(SSODeeplinkRequestParameter.ADDRESS);
        this.registeredPlan = this.getParameterValue(SSODeeplinkRequestParameter.REGISTERED_PLAN);
        this.lro = this.getParameterValue(SSODeeplinkRequestParameter.LRO);
        this.lot = this.getParameterValue(SSODeeplinkRequestParameter.LOT);
        this.concession = this.getParameterValue(SSODeeplinkRequestParameter.CONCESSION);
        this.instrumentNumber = this.getParameterValue(SSODeeplinkRequestParameter.INSTRUMENT_NUMBER);
        this.deepLinkProductCode = this.getParameterValue(SSODeeplinkRequestParameter.PRODUCT_CODE);
        this.pybToken = this.getParameterValue(SSODeeplinkRequestParameter.PYB_TOKEN);
        this.estoreOnly = this.getParameterValue(SSODeeplinkRequestParameter.ESTORE_ONLY)?.toUpperCase() === 'TRUE';
        this.callback = this.getParameterValue(SSODeeplinkRequestParameter.CALLBACK);
        this.requestedFeature = this.getParameterValue(SSODeeplinkRequestParameter.REQUESTED_FEATURE);
        this.referer = this.getParameterValue(SSODeeplinkRequestParameter.REFERER);
    }

    private getParameterValue = (param: string): string => {
        let paramMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;
        return paramMap.get(param)? paramMap.get(param)! : '';
    }

    async showSSOUserReportWarning(): Promise<void> {
        const dialogData = new WarningDialogData(DataService.PROPERTY_REPORT_SSO_NO_BALANCE_WARNING_HEADER, [DataService.PROPERTY_REPORT_SSO_NO_BALANCE_WARNING], '', 'Log Out', '', false);
        this.warningService.showWarning(dialogData, true, 600, () => {}, () => {
          this.authService.logoutFromApp();
        });
    };
    
    resetSubscribers = () => {
        this._deeplinkServiceSubject.next(null);
        this.userService.setSSORequestRoute(null);
        this.userService.releaseSSORequestRouteObservable();
    }

}
