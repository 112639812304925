import { Component, EventEmitter, inject, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SearchComparablesResultService } from '../../../shared/service/search/search-comparables-result.service';
import { ComparableSale } from '../../../core/model/comparables/comparable-sales-response';
import { lastValueFrom, takeUntil } from "rxjs";
import { BaseUnsubscribe } from '../../../core/component/base-unsubscribe/base-unsubscribe';
import { MainMapService } from '../main-map/main-map.service';
import { ComparableSalesResultPayload } from '../../../core/model/comparables/comparable-sales-result-payload';
import { SearchComparablesFormService } from '../../../shared/service/search/search-comparables-form.service';
import { ScreenManager } from '../../../shared/service/screen-manager.service';
import { ScreenNameEnum } from '../../../core/enum/screen-name.enum';
import { MatSort, SortDirection } from '@angular/material/sort';
import { PropertyReportService } from '../../../shared/service/property-report.service';
import { DataService } from '../../../shared/service/data.service';
import { comparablesLotSizeValues, defaultErrorMatSnackBarConfig, defaultMatSnackBarConfig, LocalStorageKey } from "../../../shared/constant/constants";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScreenOrientation } from '../../../core/enum/screen-orientation-enum';
import { MeasurementUnitService } from "../../../shared/service/measurement-unit.service";
import { MatRadioChange } from '@angular/material/radio';
import { SearchComparablesResultPriceChartComponent } from "../search-comparables-result-price-chart/search-comparables-result-price-chart.component";
import { SearchComparablesCriteriaCrumb } from '../../../core/model/comparables/search-comparables-criteria-crumb';
import { SearchComparablesResultSnapshot } from '../../../core/model/search-result/comparables-result-snapshot';
import { LoggerService } from '../../../shared/service/log/logger.service';
import { faCircleInfo, faCircleXmark, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { SearchComparablesEnum } from "../../../core/enum/search-comparables.enum";
import { ScreenDisplay } from '../../../core/enum/screen-display-enum';
import { SearchComparablesFormWrapper } from '../../../core/model/comparables/search-comparables-form-wrapper';
import { PIIService } from '../../../shared/service/pii.service';
import { UserService } from '../../../shared/service/user.service';
import { PropertyReportSearchService } from "../../../shared/service/search/property-report-search.service";
import { SnackBarService } from "../../../shared/service/snack-bar.service";
import { MatDialog } from "@angular/material/dialog";
import { ComparablesReportSelectReport } from "../../../core/component/modal/comparables-report-select-report/comparables-report-select-report";
import { WarningService } from "../../../shared/service/warning.service";
import { WarningDialogData } from "../../../core/component/modal/warning-dialog/warning-dialog-data";
import _ from "lodash";
import { ComparablesReportAdd } from "../../../core/component/modal/comparables-report-add/comparables-report-add";
import { ComparableSalesReportCreationParam } from "../../../core/model/comparables/report/comparable-sales-report-creation-param";
import { ComparableSalesReportCreationRequest } from "../../../core/model/comparables/report/comparable-sales-report-creation-request";
import { PinOrArn } from "../../../core/model/property/pin-or-arn";
import { ErrorUtil } from "../../../shared/service/error.util";
import { PropertyDetail } from "../../../core/model/property/property-detail";
import { ComparablesReportService } from "../../../shared/service/comparables-report.service";
import { ComparableSalesReportResponse } from "../../../core/model/comparables/report/comparable-sales-report-select-report-from-modal";
import { Router } from "@angular/router";
import { UserAccessControl } from "../../../core/model/user/user-access-control";
import { SortColumn } from '../../../core/model/search-result/sort-column';
import { formatNumber } from '@angular/common';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GoogleAnalyticsService } from '../../../shared/service/google-analytics.service';
import { GA_Feature } from '../../../shared/constant/google-analytics-constants';

type PayloadType = "regular" | "snapshot";
declare function scrollToElementById0(elementId: string): void;
declare var $: any;

@Component({
  selector: 'gema3g-search-comparables-result',
  templateUrl: './search-comparables-result.component.html',
  styleUrls: ['./search-comparables-result.component.scss']
})
export class SearchComparablesResultComponent extends BaseUnsubscribe implements OnInit {

  constructor() {
    super();
  }

  protected propertyReportSearchService: PropertyReportSearchService = inject(PropertyReportSearchService);
  private loggerService = inject(LoggerService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  private searchComparablesFormService = inject(SearchComparablesFormService);
  private mainMapService = inject(MainMapService);
  private screenManager = inject(ScreenManager);
  private propertyReportService = inject(PropertyReportService);
  private _snackBar = inject(MatSnackBar);
  private dataService = inject(DataService);
  private piiService = inject(PIIService);
  private userService = inject(UserService);
  private measurementUnitService = inject(MeasurementUnitService);
  private gaService = inject(GoogleAnalyticsService);

  @Output() scrollToTop = new EventEmitter();
  @ViewChild(SearchComparablesResultPriceChartComponent) chart: SearchComparablesResultPriceChartComponent;
  searchResultsPayload: ComparableSalesResultPayload;
  searchResultsCount: number = 0;
  searchedBy: SearchComparablesEnum = SearchComparablesEnum.SEARCH_BY_RADIUS;
  searchResultsCountMaxAllowed: number = DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED;
  displayedColumns: string[];
  dataSource = new MatTableDataSource<ComparableSale>();
  sort: MatSort;
  orientation = ScreenOrientation;
  screenOrientation: ScreenOrientation = ScreenOrientation.HORIZONTAL;
  flipResultsVertically: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_VERTICAL_TOOLTIP_TEXT;
  flipResultsHorizontally: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_HORIZONTAL_TOOLTIP_TEXT;
  maximizeDisplay: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_MAXIMIZE_TOOLTIP_TEXT;
  minimizeDisplay: string = DataService.SEARCH_COMPARABLES_RESULT_ORIENTATION_MINIMIZE_TOOLTIP_TEXT;
  resultsCountTooltipText: string = DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_DISPLAYED;
  openPropertyReportTooltipText: string = DataService.SEARCH_COMPARABLES_RESULT_OPEN_PROPERTY_REPORT_TOOLTIP_TEXT;
  display = ScreenDisplay;
  screenDisplay: ScreenDisplay = ScreenDisplay.NORMAL;
  normalizeDisplay: string = this.minimizeDisplay;  //for now, minimized size is the normal size
  chartTabIndex: number = 0;
  listTabIndex: number = 1;
  activeTabIndex = this.listTabIndex;
  priceChartInput: any = {};
  ANA: string = DataService.ADDRESS_NOT_AVAILABLE;
  NA: string = DataService.NOT_APPLICABLE;
  propertyCodeTooltipDescription: string;
  selectedDisplay: string = 'List';
  displayOptions: string[] = ['Chart', 'List'];
  isMpsRequest: boolean;
  searchCriteriaCrumbs: SearchComparablesCriteriaCrumb[] = [];
  snapshot: SearchComparablesResultSnapshot;
  payloadType: PayloadType;
  faCircleXmark = faCircleXmark;
  faCircleInfo = faCircleInfo;
  faMinimize = faDownLeftAndUpRightToCenter;
  faMaximize = faUpRightAndDownLeftFromCenter;
  lotSizeHeader: WritableSignal<string> = signal('');
  lotSizePriceHeader: WritableSignal<string> = signal('');
  userAccessControls: UserAccessControl;
  autoPanMapTooltipText: string = DataService.SEARCH_COMPARABLES_RESULT_AUTOPAN_MAP_TOOLTIP_TEXT;

  snackBarService = inject(SnackBarService);
  dialog = inject(MatDialog);
  warningService = inject(WarningService);
  comparableSaleReportService = inject(ComparablesReportService);
  router = inject(Router);

  //This setter will fire once matSort in the view changes.
  //I.e. when it is defined the first time. It will not fire when you change the sorting by clicking on the arrows.
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.sort = sort;
  }

  setDataSort = (columnName: string, sortDirection: SortDirection) => {
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.sort.sort({
        id: columnName,
        start: sortDirection,
        disableClear: true
      });
      this.sort.disableClear = true;
      this.loggerService.logDebug(`set sort column ${this.sort.active} to ${this.sort.direction}`);
    }

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'registrationDate':
          return new Date(item.registrationDate).getTime();
        default: // @ts-ignore
          return item[property];
      }
    }
  }

  private initializeSalesTableColumns = () => {
    this.displayedColumns = [];
    this.displayedColumns.push('selected');
    this.displayedColumns.push('saleAddressStr');
    this.displayedColumns.push('registrationDate');
    this.displayedColumns.push('considerationAmount');
    this.displayedColumns.push('area');
    this.displayedColumns.push('pricePerArea');
    this.displayedColumns.push('distance');
    this.displayedColumns.push('pin');
    if (this.isMpsRequest) {
      this.displayedColumns.push('yearBuilt');
      this.displayedColumns.push('propertyCode');
      this.displayedColumns.push('rollNumber');
    }
  }

  get useImperial(): boolean {
    return (!this.measurementUnitService.isUomInMeters);
  }

  get useAcres(): boolean {
    return (this.useImperial) && this.searchResultsPayload?.request?.usesAcres;
  }

  private populateSalesTable = () => {
    if (this.useImperial) {
      if (this.useAcres) {
        this.lotSizeHeader.set('Lot Size (acres)');
        this.lotSizePriceHeader.set('$/ac');
      } else {
        this.lotSizeHeader.set('Lot Size (ft²)');
        this.lotSizePriceHeader.set('$/ft²');
      }
    } else {
      this.lotSizeHeader.set('Lot Size (m²)');
      this.lotSizePriceHeader.set('$/m²');
    }

    this.computePricePerArea(this.measurementUnitService.rateSquareMetersToSquareFeet,
      this.measurementUnitService.rate_squareMetersToAcres,
      this.useImperial, this.useAcres);

    this.dataSource = new MatTableDataSource(this.searchResultsPayload?.response?.sales);
  }

  modifySearchCriteria = () => {
    if (!this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_FORM)) {
      this.screenManager.showScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
      setTimeout(() => {
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_MODIFY_SEARCH_CRITERIA, 'Count', this.searchResultsCount.toString());
      }, 200);
    }
  }

  onRowMouseOver = (sale: ComparableSale, uniqueRowId: string) => {
    if (this.isMpsRequest) {
      if (this.dataService.propertyCodes != null) {
        let propertyCode: any = this.dataService.propertyCodes.find((propertyCode: any) => {
          return propertyCode.code == sale.propertyCode;
        })
        this.propertyCodeTooltipDescription = propertyCode.description;
      } else {
        this.propertyCodeTooltipDescription = sale.propertyCode!;
      }
    }

    setTimeout(() => {
      this.mainMapService.highlightSearchComparableMarker(this.searchComparablesResultService.getSaleMarkerPin(sale), uniqueRowId);
    }, 100);
  }

  onRowMouseOut = (sale: ComparableSale) => {
    setTimeout(() => {
      this.mainMapService.unhighlightSearchComparableMarker(this.searchComparablesResultService.getSaleMarkerPin(sale));
    }, 100);
  }

  goBack() {
    this.scrollToTop.emit();
  }

  closeResultsScreen = () => {
    this.mainMapService.clearAllRenderedMapObjects();
    this.screenManager.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);

    localStorage.removeItem(LocalStorageKey.comparablesSalesSnapshot);
    this.searchComparablesResultService.clearSearchResultsSnapshot();
  }

  onDisplayOptionChanged = (event: MatRadioChange) => {
    switch (event.value) {
      case 'Chart':
        this.chart?.updatePriceChart();
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_CHART_VIEW, 'Count', this.searchResultsCount.toString());
        break;

      case 'List':
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_LIST_VIEW, 'Count', this.searchResultsCount.toString());
    }
  }

  adjustMapBounds = (formWrapper: SearchComparablesFormWrapper | undefined) => {
    try {
      if (formWrapper) {
        switch (formWrapper.selectedSearchBy) {
          case SearchComparablesEnum.SEARCH_BY_RADIUS:
            this.loggerService.logDebug(`adjusting search circle map bounds for screen orientation ${this.screenOrientation}`);
            if (formWrapper.sameCondoSearch) {
              setTimeout(() => {
                this.mainMapService.setMapCenter(formWrapper.sameCondoLocation);
                this.mainMapService.setMapZoomLevel(DataService.SEARCH_COMPARABLES_SINGLE_SEARCH_RESULT_DEFAULT_ZOOM_LEVEL);
              }, 100);
            } else {
              this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchCircleBounds);
            }
            break;

          case SearchComparablesEnum.SEARCH_BY_POLYGON:
            this.loggerService.logDebug(`adjusting search polygon map bounds for screen orientation ${this.screenOrientation}`);
            this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchPolygonBounds);
            break;

          case SearchComparablesEnum.SEARCH_BY_MUNICIPALITY:
            this.loggerService.logDebug(`adjusting search municipality map bounds for screen orientation ${this.screenOrientation}`);
            this.mainMapService.getMap().fitBounds(formWrapper.searchShapeBounds.searchMunicipalityBounds);
            break;
        }
      }

    } catch (e) {
      this.loggerService.logError(`error adjusting search results map bounds in current payload type ${this.payloadType}`, e);
    }
  }

  computePricePerArea = (rateSquareMetersToSquareFeet: number, rateSquareMetersToAcres: number, useImperial: boolean, useAcres: boolean) => {
    this.searchResultsPayload.response.sales.forEach((sale) => {
      sale.pricePerArea = sale.considerationAmount / sale.area;
      sale.areaInFeet = Math.round(sale.area * rateSquareMetersToSquareFeet);
      sale.pricePerAreaInFeet = sale.considerationAmount / sale.areaInFeet;
      sale.calculatedAreaInAcres = Math.round(sale.area * rateSquareMetersToAcres * 100) / 100;
      sale.calculatedPricePerAreaInAcres = sale.considerationAmount / sale.calculatedAreaInAcres;

      if (sale.condo !== 'Y') {
        if (useImperial) {
          if (useAcres) {
            sale.displayArea = formatNumber(sale.calculatedAreaInAcres, 'en-US', '1.2-2');
            if (sale.calculatedPricePerAreaInAcres !== 0) {
              sale.displayPricePerArea = formatNumber(sale.calculatedPricePerAreaInAcres, 'en-US', '1.0-0');
            }
          } else {
            sale.displayArea = formatNumber(sale.areaInFeet, 'en-US', '2.0-0');
            if (sale.pricePerAreaInFeet !== 0) {
              sale.displayPricePerArea = formatNumber(sale.pricePerAreaInFeet, 'en-US', '1.0-0');
            }
          }
        } else {
          sale.displayArea = formatNumber(sale.area, 'en-US', '2.0-0');
          if (sale.pricePerArea !== 0) {
            sale.displayPricePerArea = formatNumber(sale.pricePerArea, 'en-US', '1.0-0');
          }
        }
      }
    })
  }
  
  openPropertyReport = (pin: string, pinRowElementId: string, event: any) => {
    if (pin) {
      //update the snapshot with these changes
      this.snapshot.pin = pin;
      this.snapshot.pinElementId = event.currentTarget.id;
      this.snapshot.pinRowElementId = pinRowElementId;
      
      if (!this.snapshot.originalSubjectProperty) {
        this.snapshot.originalSubjectProperty = this.propertyReportService.getSubjectProperty();
        this.loggerService.logDebug(`updated search comparables snapshot with original subject property pin ${this.snapshot.originalSubjectProperty?.pii?.pin}`);
      }

      this.updateSnapshotInStorage();
      this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_OPEN_PROPERTY_REPORT, 'PIN', pin);
      
      this.propertyReportService.showPropertyReportByPinDeferData(pin);
    }
  }

  get wasLastSearchByMunicipality(): boolean {
    return this.searchedBy === SearchComparablesEnum.SEARCH_BY_MUNICIPALITY;
  }

  ngOnInit(): void {

    this.userAccessControls = this.userService.getUserAccessControl();
   
    //display results from the last results payload snapshot
    this.searchComparablesResultService.getSearchResultsSnapshotObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((snapshot) => {
        if (snapshot) {
          this.snapshot = snapshot;
          this.loggerService.logDebug(`restoring the search comparables result from snapshot timestamp ${snapshot.timestamp}`); 

          let results: ComparableSalesResultPayload | null = snapshot.results;
          if (results) {
            this.isMpsRequest = results.request.mps;
            this.searchCriteriaCrumbs = results.formWrapper.searchCriteriaCrumbs;
            this.initializeSalesTableColumns();
            this.searchResultsPayload = results;
            this.searchedBy = snapshot.results.formWrapper?.selectedSearchBy;
            this.searchResultsCount = this.searchResultsPayload?.response?.sales?.length;

            //todo: shouldn't this be saved in the snapshot as well?
            this.searchResultsPayload.request.usesAcres = comparablesLotSizeValues
              .some(lotSize => lotSize.value == this.searchResultsPayload.request.minArea && lotSize.imperialDisplayValue.includes('acre'));

            setTimeout(() => {
              this.populateSalesTable();

              if (this.searchResultsCount >= DataService.SEARCH_COMPARABLES_MAXIMUM_SALES_ALLOWED && this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
                defaultErrorMatSnackBarConfig['duration'] = 8000;
                this._snackBar.open(DataService.SEARCH_COMPARABLES_TOP_MAXIMUM_SALES_DISPLAYED, 'Close', defaultErrorMatSnackBarConfig);
              }

              //re-apply the sort state
              setTimeout(() => {
                if (snapshot.sortColumn) {
                  this.setDataSort(snapshot.sortColumn.columnName, snapshot.sortColumn.sortOrder);
                } else {
                  this.setDataSort('saleAddressStr', 'asc');
                }
              }, 200);

              //re-create the auto pan map state only when it was enabled
              if (snapshot.autoPanMapForPropertyMarkers) {
                this.mainMapService.setAutoPanMapForSearchComparablesResultMarkers(true);
              }

              if (snapshot.new) {
                //select all properties by default to match with the visible property markers
                setTimeout(() => {
                  this.selectAll();
                }, 200);
                
              } else {
                //re-select the properties that were previously selected
                if (!_.isEmpty(snapshot.selectedProperties)) {
                  this.snapshot.results.response.sales.map(sale => {
                    sale.selected = snapshot.selectedProperties!.includes(sale.pin);
                  });
                }
              }

              //scroll back to the same result pin and highlight the row if it is the same pin as the last opened property report
              if (snapshot.pinElementId && snapshot.pin && this.propertyReportService.getSubjectPropertyPin() && snapshot.pin == this.propertyReportService.getSubjectPropertyPin()) {
                setTimeout(() => {
                  this.loggerService.logDebug(`scrolling to the last opened property report pin ${snapshot.pin}`);
                  scrollToElementById0(snapshot.pinElementId!);
                  this.highlightPinRow(snapshot.pinRowElementId!);
                }, 200);
              } else {
                setTimeout(() => {
                  scrollToElementById0('scr-tbl-row-hdr');
                }, 200);
              }

            }, 100);

            setTimeout(async () => {
              await this.mainMapService.renderComparableSalesMarkers(this.searchResultsPayload?.response?.sales, new google.maps.LatLng(snapshot.results.request.searchCenter.latitude, snapshot.results.request.searchCenter.longitude));
              this.searchComparablesResultService.adjustSearchResultsMapBounds(snapshot.results.formWrapper);

            }, 100);

            setTimeout(() => {
              this.searchComparablesFormService.startSearchFormRecoveryFromSnapshot(snapshot);
            }, 100);

          } else {
            this.loggerService.logDebug('no search comparables result to display from snapshot');
            //this._snackBar.open(DataService.SEARCH_COMPARABLES_RESULT_EMPTY, 'Close', defaultErrorMatSnackBarConfig);
          }

        } else {
          this.loggerService.logDebug('no search comparables result to display from snapshot');
          // this._snackBar.open(DataService.SEARCH_COMPARABLES_RESULT_EMPTY, 'Close', defaultErrorMatSnackBarConfig);
        }
      });

    //switch the results styling and adjust the results map boundaries when the screen orientation changes
    this.searchComparablesResultService.screenOrientation$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(orientation => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          switch (orientation) {
            case null:
              this.screenOrientation = ScreenOrientation.HORIZONTAL;  //set default orientation
              break;

            case ScreenOrientation.HORIZONTAL:
              this.screenOrientation = ScreenOrientation.HORIZONTAL;
              break;

            case ScreenOrientation.VERTICAL:
              this.screenOrientation = ScreenOrientation.VERTICAL;
              break;
          }

          if (this.searchResultsPayload?.formWrapper.searchShapeBounds) {
            setTimeout(() => {
              this.searchComparablesResultService.adjustSearchResultsMapBounds(this.searchResultsPayload.formWrapper);
            }, 100);
          }
        }
      });

    this.searchComparablesResultService.screenDisplay$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(display => {
        if (this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
          switch (display) {
            case null:
              this.screenDisplay = ScreenDisplay.NORMAL;  //set default size
              break;

            case ScreenDisplay.NORMAL:
              this.screenDisplay = ScreenDisplay.NORMAL;
              break;

            case ScreenDisplay.MAXIMIZED:
              this.screenDisplay = ScreenDisplay.MAXIMIZED;
              break;
          }
        }
      });

    this.searchComparablesResultService.getSearchResultsMapBoundsObservable()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(formWrapper => {
        if (formWrapper) {
          this.adjustMapBounds(formWrapper);
        }
      });

      this.mainMapService.markerMouseOverEvent$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(marker => {
          if (marker && !marker.get('isMouseOverRequestFromSearchResults') && this.screenManager.isScreenVisible(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS)) {
            marker.set('isMouseOverRequestFromSearchResults', false);  //reset
            if (marker.get('uniqueSearchResultRowId')) {
              scrollToElementById0(marker.get('uniqueSearchResultRowId'));
            }
          }
        });
  }

  private updateSnapshotInStorage = () => {
    let serializedSnapshot = localStorage.getItem(LocalStorageKey.comparablesSalesSnapshot);

    if (serializedSnapshot) {
      let snapshot = (<SearchComparablesResultSnapshot>JSON.parse(serializedSnapshot));

      //snapshot becomes old the moment we update it in storage
      snapshot.new = false;

      //save the current table sort state
      snapshot.sortColumn = new SortColumn(this.sort.active, this.sort.direction);
      
      //save the currently selected properties
      snapshot.selectedProperties = [];
      snapshot.results.response.sales = this.dataSource.data;
      snapshot.selectedProperties = this.snapshot.results?.response.sales.filter(sale => sale.selected).map(sale => sale.pin);

      //save the current orientation of the screen
      snapshot.screenOrientation = this.screenOrientation;

      //save the current display size of the screen
      snapshot.screenDisplay = this.screenDisplay;

      //save the current auto pan map state
      snapshot.autoPanMapForPropertyMarkers = this.snapshot.autoPanMapForPropertyMarkers;

      //save all other updates to the snapshot
      snapshot.pin = this.snapshot.pin;
      snapshot.pinElementId = this.snapshot.pinElementId;
      snapshot.pinRowElementId = this.snapshot.pinRowElementId;
      snapshot.originalSubjectProperty = this.snapshot.originalSubjectProperty;

      //save the updated snapshot
      let serializedUpdatedSnapshot: string = JSON.stringify(snapshot);
      localStorage.setItem(LocalStorageKey.comparablesSalesSnapshot, serializedUpdatedSnapshot);
      this.loggerService.logDebug(`updated search results snapshot in storage`);
    }
  }

  toggleScreenSize = () => {
    switch (this.screenDisplay) {
      case ScreenDisplay.MINIMIZED:
        //not implemented at this time
        break;

      case ScreenDisplay.MAXIMIZED:
        this.searchComparablesResultService.changeScreenDisplay(ScreenDisplay.NORMAL);
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_SCREEN_SIZE_NORMAL, 'Count', this.searchResultsCount.toString());
        this.updateSnapshotInStorage();
        break;

      case ScreenDisplay.NORMAL:
        this.searchComparablesResultService.changeScreenDisplay(ScreenDisplay.MAXIMIZED);
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_SCREEN_SIZE_MAXIMIZED, 'Count', this.searchResultsCount.toString());
        this.updateSnapshotInStorage();
        break;
    }
  }

  toggleScreenOrientation = () => {
    switch (this.screenOrientation) {
      case ScreenOrientation.HORIZONTAL:
        this.searchComparablesResultService.changeScreenOrientation(ScreenOrientation.VERTICAL);
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_ORIENTATION_VERTICAL, 'Count', this.searchResultsCount.toString());
        this.updateSnapshotInStorage();
        break;

      case ScreenOrientation.VERTICAL:
        this.searchComparablesResultService.changeScreenOrientation(ScreenOrientation.HORIZONTAL);
        this.updateSnapshotInStorage();
        this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_ORIENTATION_HORIZONTAL, 'Count', this.searchResultsCount.toString());
        break;
    }
  }

  get containerClassName() {
    let className: string = 'flip-horizontal-normal'; //default

    if (this.screenOrientation == ScreenOrientation.HORIZONTAL && this.screenDisplay == ScreenDisplay.NORMAL) {
      className = 'flip-horizontal-normal';
    }

    if (this.screenOrientation == ScreenOrientation.HORIZONTAL && this.screenDisplay == ScreenDisplay.MAXIMIZED) {
      className = 'maximized';
    }

    if (this.screenOrientation == ScreenOrientation.VERTICAL && this.screenDisplay == ScreenDisplay.NORMAL) {
      className = 'flip-vertical-normal';
    }

    if (this.screenOrientation == ScreenOrientation.VERTICAL && this.screenDisplay == ScreenDisplay.MAXIMIZED) {
      className = 'maximized';
    }

    return className;
  }

  printPDFReports = async () => {
    // the last property details will have the value of the original pin the search was performed for
    const propertyDetail = this.snapshot.originalSubjectProperty ? this.snapshot.originalSubjectProperty : undefined;
    await this.propertyReportService.printPDFReports(true, this.searchResultsPayload, propertyDetail);
  }

  areAllSelected() {
    return this.dataSource.data.every(value => value.selected);
  }

  areSomeSelected() {
    return this.dataSource.data.some(value => value.selected);
  }

  areNoneSelected() {
    return this.dataSource.data.every(value => !value.selected);
  }

  selectAll() {
    this.dataSource.data.forEach(value => value.selected = true);
    this.updateSnapshotInStorage();
  }

  unselectAll() {
    this.dataSource.data.forEach(value => value.selected = false);
    this.updateSnapshotInStorage();
  }

  toggleAll() {
    let newSelection = !this.areAllSelected();
    this.dataSource.data.forEach(value => value.selected = newSelection);
    this.updateSnapshotInStorage();

    if (this.areAllSelected()) {
      setTimeout(() => {
        this.mainMapService.showSearchComparableMarkers();
      }, 100);
    }

    if (this.areNoneSelected()) {
      setTimeout(() => {
        this.mainMapService.hideSearchComparableMarkers();
      }, 100);
    }
  }

  toggle(row: ComparableSale) {
    row.selected = !row.selected;
    this.updateSnapshotInStorage();

    setTimeout(() => {
      this.mainMapService.toggleSearchComparableMarker(this.searchComparablesResultService.getSaleMarkerPin(row), row.selected);
    }, 100);
  }

  onAutoPanMapChange(event: MatCheckboxChange) {
    setTimeout(() => {
      this.snapshot.autoPanMapForPropertyMarkers = event.checked;
      this.mainMapService.setAutoPanMapForSearchComparablesResultMarkers(event.checked);
      this.updateSnapshotInStorage();
      this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_RESULTS_AUTOPAN_MAP, 'Enabled', event.checked.toString());
    }, 200);
  }

  get selectedPropertiesCount(): number {
    return this.dataSource.data.filter(value => value.selected).length;
  }

  onTableSortChanged = (event: any) => {
    this.updateSnapshotInStorage();
    this.gaService.featureClicked(GA_Feature.SEARCH_COMPARABLES_SORT_RESULTS, this.sort.active, this.sort.direction);
  }

  highlightPinRow = (elementToHighlight: string) => {
    //$("#" + elementToHighlight).addClass("highlight-row");
    document.getElementById(elementToHighlight)?.classList.add("highlight-row");

    setTimeout(() => {
      document.getElementById(elementToHighlight)?.classList.remove("highlight-row");
    }, 3000);
  }

  get propertyReportAccess() {
    return this.userService.getUserAccessControl().propertyReportAccess;
  }

  async addToReport() {
    if (this.searchResultsPayload.response.sales.filter(sale => sale.selected).length > 20) {
      this.snackBarService.displaySnackBarError('Maximum allowable properties in a report is 20.');

    } else {
      const subjectProperty = this.propertyReportService.getSubjectProperty();
      if (!subjectProperty || subjectProperty.isEmpty) {
        const content = [DataService.MISSING_SUBJECT_PROPERTY];
        const dialogData = new WarningDialogData(DataService.MISSING_SUBJECT_PROPERTY_HEADER, content, '', 'Ok');
        this.warningService.showWarning(dialogData, false, 560);
      } else {
        const dialogRef = await this.dialog.open(ComparablesReportSelectReport, {data: subjectProperty})
          .afterClosed()
          .subscribe(async (reportSelection: ComparableSalesReportResponse) => {
            if (_.isNumber(reportSelection?.reportId)) {
              if (reportSelection?.reportId == -1) {
                // new report requested
                this.createNewReport(subjectProperty, reportSelection.openReport);
              } else {
                this.addToExistingReport(reportSelection.openReport, reportSelection.reportId);
              }
            }
          });
      }
    }
  }

  async createNewReport(subjectProperty: PropertyDetail, openReport: boolean) {
    // create new report
    const dialogRef = await this.dialog.open(ComparablesReportAdd,)
      .afterClosed()
      .subscribe(async (param: ComparableSalesReportCreationParam) => {
          if (param && param.reportName) {
            const reportParam: ComparableSalesReportCreationRequest = new ComparableSalesReportCreationRequest();
            reportParam.reportParam = new ComparableSalesReportCreationParam(param);
            reportParam.subjectPin = new PinOrArn(subjectProperty?.pii?.pin, subjectProperty?.pii?.arn);
            reportParam.comparablePins = this.searchResultsPayload.response.sales.filter(sale => sale.selected).map(sale => new PinOrArn(sale.pin, sale.arn));
            const reportCreated = await lastValueFrom(this.comparableSaleReportService.createReport(reportParam));
            if (reportCreated?.businessError == 0) {
              if (reportCreated.reportId && openReport) {
                this.router.navigate(["/comparables-report"], {
                  queryParams: {
                    reportId: reportCreated.reportId
                  }
                });
              } else {
                this.router.navigate(["/comparables-report"]);
              }
            } else {
              this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_CREATING_REPORT);
            }
          }
        }
      );
  }

  async addToExistingReport(openReport: boolean, reportId: number) {
    const newPins = this.searchResultsPayload.response.sales.filter(sale => sale.selected).map(sale => new PinOrArn(sale.pin, sale.arn))
    const reportUpdated = await lastValueFrom(this.comparableSaleReportService.addPinsToReport(reportId, newPins));
    if (reportUpdated?.businessError == 0) {
      if (openReport) {
        this.router.navigate(["/comparables-report"], {
          queryParams: {
            reportId: reportId
          }
        });
      } else {
        this.router.navigate(["/comparables-report"]);
      }
    } else {
      this.snackBarService.displaySnackBarError(ErrorUtil.ERROR_ON_AADDING_PINS_TO_REPORT);
    }
  }
}
