import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CoreModule } from "../../../../core/core.module";
import { CommonModule } from "@angular/common";
import { BaseSearchResults } from "../base-search-results";
import { OwnerToPropertyMap } from "../../../../core/model/search-result/owner-to-property-map";
import { Pii } from "../../../../core/model/property/pii";
import { MainMapService } from "../../main-map/main-map.service";
import { SvgIconComponent } from "../../../../core/component/svg-icon/svg-icon.component";
import { UserAccessControl } from "../../../../core/model/user/user-access-control";
import { UserService } from "../../../../shared/service/user.service";

@Component({
  selector: 'gema3g-owner-to-property-map-search-results',
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SvgIconComponent
  ],
  templateUrl: './owner-to-property-map-search-results.component.html',
  styleUrl: './owner-to-property-map-search-results.component.scss'
})
export class OwnerToPropertyMapSearchResultsComponent extends BaseSearchResults{

  constructor() {
    super();
  }

  userService = inject(UserService);
  private mainMapService: MainMapService = inject(MainMapService);

  @Output() onOwnerClick = new EventEmitter<OwnerToPropertyMap>();
  @Input() ownerToPropertyMap:OwnerToPropertyMap[] = [];
  @Input() isMobileFullScreen: boolean = false;
  userAccessControls: UserAccessControl = this.userService.getUserAccessControl();

  openOwnerView(result: OwnerToPropertyMap) {
    this.onOwnerClick.emit(result);
  }

  onMouseOut(result: OwnerToPropertyMap) {
    if(result.pin) {
      this.mainMapService.setMarkerDefault(result.pin);
    }
    result.showExpansionImages = false;
  }

  onMouseOver(result: OwnerToPropertyMap) {
    if(result.pin) {
      this.mainMapService.setMarkerSelected(result.pin);
    }
    result.showExpansionImages = true;
  }


}
