import { Component, inject, OnInit } from '@angular/core';
import { UserAccessControl } from "../../../model/user/user-access-control";
import { Router } from "@angular/router";
import { lastValueFrom, Observable, takeUntil, filter, Subscription } from "rxjs";
import { AuthenticationService } from "../../../../shared/service/authentication.service";
import { UserService } from "../../../../shared/service/user.service";
import { MatDialog } from "@angular/material/dialog";
import { BaseUnsubscribe } from "../../base-unsubscribe/base-unsubscribe";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { EstoreService } from '../../../../shared/service/estore.service';
import { ZendeskService } from '../../../../shared/service/zendesk.service';
import { ThemeService } from '../../../../shared/service/theme.service';
import { User } from "../../../model/user/user";
import { AboutDialogComponent } from '../../about/about-dialog.component';
import { AboutDialogData } from '../../about/about-dialog-data';
import { UrlService } from '../../../../shared/service/url.service';
import { faArrowRightFromBracket, faBookOpenReader, faCalendarDays, faListUl, faAddressCard, faCommentDots, faReply, faInfo, faCircleInfo, faHandHoldingHand, faPersonChalkboard, faBellConcierge, faTowerBroadcast, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { GoogleAnalyticsService } from "../../../../shared/service/google-analytics.service";
import { VersioningService } from "../../../../shared/service/versioning-service";
import { LocalStorageKey } from "../../../../shared/constant/constants";
import { environment } from "../../../../../environments/environment";
import { DataService } from "../../../../shared/service/data.service";
import { ZendeskConnectionParam } from "../../../model/zendesk/zendesk-connection-param";
import { ScreenNameEnum } from "../../../enum/screen-name.enum";
import { ScreenManager } from "../../../../shared/service/screen-manager.service";
import { CartCounter } from '../../../../core/model/estore/cart-counter';
import { GA_Button, GA_Modal, GA_Navigate } from '../../../../shared/constant/google-analytics-constants';

@Component({
  selector: 'gema3g-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseUnsubscribe implements OnInit {
  private activeThemeSub = Subscription.EMPTY;

  constructor() {
    super();
  }

  private router = inject(Router);
  protected authService = inject(AuthenticationService);
  public userService = inject(UserService);
  private dialog = inject(MatDialog);
  private loggerService = inject(LoggerService);
  private eStoreService = inject(EstoreService);
  private zendeskService = inject(ZendeskService);
  private urlService = inject(UrlService);
  protected themeService = inject(ThemeService);
  private gaService = inject(GoogleAnalyticsService);
  private versioningService = inject(VersioningService);
  screenManager = inject(ScreenManager);

  estoreCartCount: Observable<number>;
  estoreSingleCartCount: CartCounter = new CartCounter();
  userAccessControls: UserAccessControl;
  user: User;

  faReply = faReply;
  faInfo = faInfo;
  faCircleInfo = faCircleInfo;
  faAddressCard = faAddressCard;
  faHandHoldingHand = faHandHoldingHand;
  faCommentDots = faCommentDots;
  faPersonChalkboard = faPersonChalkboard;
  faBellConcierge = faBellConcierge;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faListUl = faListUl;
  faCalendarDays = faCalendarDays;
  faBookOpenReader = faBookOpenReader;
  faTowerBroadcast = faTowerBroadcast;
  faEnvelopeOpenText = faEnvelopeOpenText;
  jwtToken: string;
  returnUrl: string;
  zendeskAction: string;
  get isProduction() :boolean {
    //return environment.text === 'PROD';
    return environment.text === 'PROD' || environment.text === 'UAT';
  };

  async ngOnInit(): Promise<void> {
    this.userAccessControls = this.userService.getUserAccessControl();

    /* polling for cart counter
    this.eStoreService.getCartCount();
    this.estoreCartCount = this.eStoreService.polledApi$;
    this.estoreCartCount.subscribe((count: number) => {
      this.cartCount = count;
    });
    */

    this.estoreSingleCartCount = await lastValueFrom(this.eStoreService.getEstoreCartCount(), {defaultValue: new CartCounter()});

    this.userService.userObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        this.user = value;
      });

    // Dark/Light Theme
    const theme = localStorage.getItem(LocalStorageKey.activeTheme);
    if (theme && this.themeService.themeNames.includes(theme)) {
      this.themeService.set(theme);
    } else {
      this.themeService.set('light');
    }

    this.activeThemeSub = this.themeService.activeTheme$
      .pipe(filter(Boolean))
      .subscribe((themeName) => localStorage.setItem(LocalStorageKey.activeTheme, themeName));

    //this.initZendesk();
  }

  // async initZendesk() {
  //   const zendeskConnectionParam = await lastValueFrom(this.zendeskService.getZendeskConnectionParams());
  //   if (zendeskConnectionParam) {
  //     this.jwtToken = zendeskConnectionParam.jwtToken;
  //     this.returnUrl = zendeskConnectionParam.returnTo;
  //     this.zendeskAction = zendeskConnectionParam.url;
  //   }
  // }

  redirectToTrainingCalendar() {
    this.gaService.navigate(GA_Navigate.TO_TRAINING);
    window.open(UrlService.REDIRECT_TO_TRAINING_CALENDAR);
  }

  resetForm(zendeskConnectionParam : ZendeskConnectionParam) {
    // @ts-ignore
    document.getElementById("jwtForm").reset();
    // @ts-ignore
    document.getElementById('jwtInput').value = zendeskConnectionParam.jwtToken;
    // @ts-ignore
    document.getElementById('returnInput').value = zendeskConnectionParam.returnTo;
  }

  async redirectToZendeskSupport() {
    if(this.isProduction) {
      try {
        const zendeskConnectionParam = await lastValueFrom(this.zendeskService.getZendeskConnectionParams());
        if (zendeskConnectionParam) {
          this.resetForm(zendeskConnectionParam);
          this.zendeskAction = zendeskConnectionParam.url;
          setTimeout( () => {
            // @ts-ignore
            document.forms["jwtForm"].submit();
          }, 100);
        }
      }catch (e) {
        this.loggerService.logError(e);
        window.open(UrlService.DEFAULT_ZENDESK_URL, '_blank');
      }
    } else {
      window.open(UrlService.DEFAULT_ZENDESK_URL, '_blank');
    }
  }

  redirectToOverview() {
    this.gaService.navigate(GA_Navigate.TO_OVERVIEW);
    window.open(UrlService.REDIRECT_To_OVERVIEW);
  }

  redirectToHowToLibrary() {
    this.gaService.navigate(GA_Navigate.TO_HOW_TO_LIBRARY);
    window.open(UrlService.REDIRECT_TO_HOW_TO_LIBRARY);
  }

  redirectToContactUs() {
    this.gaService.navigate(GA_Navigate.TO_CONTACT_US);
    window.open(UrlService.REDIRECT_To_CONTACT_US);
  }

  redirectToInsighter() {
    this.gaService.navigate(GA_Navigate.TO_INSIGHTER);
    window.open(UrlService.REDIRECT_TO_INSIGHTER);
  }

  async redirectToHelpAndSupport() {
    if(this.isProduction){
      await this.redirectToZendeskSupport();
    } else {
      this.gaService.navigate(GA_Navigate.TO_HELP_AND_SUPPORT);
      window.open(UrlService.REDIRECT_TO_HELP_AND_SUPPORT);
    }
  }

  openRATA() {
    this.gaService?.buttonClicked(GA_Button.RATA_BUTTON, GA_Button.RATA_BUTTON_LABEL);
    this.router.navigate(["/rata"]);
  }

  openGwhMenu(){
    this.gaService?.buttonClicked(GA_Button.GEO_ACADEMY_BUTTON, GA_Button.GEO_ACADEMY_BUTTON_LABEL);
  }

  openSupportMenu(){
    this.gaService?.buttonClicked(GA_Button.SUPPORT_BUTTON, GA_Button.SUPPORT_BUTTON_LABEL);
  }

  toggleHelpMenu() {
  }

  get showRataLink(): boolean {
    return this.userAccessControls.registrationTrackerAccess;
  }

  get mpsComparablesReportsAccess(): boolean {
    return this.userAccessControls.mpsComparablesReportsAccess;
  }

  get disableCartCountRenewal(): boolean {
    return this.userAccessControls.GWHRSubscriptionExpired && this.estoreSingleCartCount.counter === 0;
  }

  openCompsReportList() {
    this.router.navigate(["/comparables-report"]);
    this.screenManager.showScreen(ScreenNameEnum.COMPARABLES_REPORT);
  }

  openEstore() {
    this.urlService.goToEstorePage();
  }

  async openCart() {
    //this.router.navigate(["/cart"]);
    await this.eStoreService.openShoppingCart();
  }

  toggleProfileMenu() {

  }

  logout() {
    this.authService.logoutFromApp();
  }

  myAccount() {
    this.userService.getUser(true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.router.navigate(["/account/overview"]);
      });
  }

  myMessages() {
    this.router.navigate(["/messages"]);
  }

  async openAboutWindow() {
    const buildVersion = await lastValueFrom(this.versioningService.getLocalBuildVersion());
    const msg = [`GeoWarehouse Version ${buildVersion?.version}`];
    //const dialogData = new ConfirmDialogData('About', msg, '', 'OK', false);
    //const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData}).afterClosed();
    this.gaService.openModal(GA_Modal.ABOUT);
    const dialogData = new AboutDialogData('About GeoWarehouse', buildVersion, 'OK');
    const dialogRef = this.dialog.open(AboutDialogComponent, { data: dialogData }).afterClosed();
  }
}
