
<div class="header">
  <div class="left">
    <div id="rata-header-label" class="label">Registration Tracking Reports</div>
  </div>

  <div class="right">
    <!--
    <span id="rata-btn-close" class="flex flex-row items-center" (click)="closeRata()">
      <fa-icon class="fa-lg text-white" [icon]="faCircleXmark"></fa-icon>
    </span>
    -->
  </div>
</div>

<div class="rata-body-container">
  <div class="controls get-dense-3">
    <form id="rata-form-rdd" [formGroup]="reportDropDownForm">
      <div class="dropdowns">
        <div class="dd-container">
          <mat-form-field id="rata-ff-rt" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="dd" (mouseover)="reportTypePopover.open()" (mouseout)="reportTypePopover.close()" [satPopoverAnchor]="reportTypePopover">
            <mat-label id="rata-label-rt">Report Type</mat-label>
            <mat-select id="rata-select-rt" formControlName="{{RRDDKE.REPORT_TYPE}}" [disabled]="reportRunning">
              @for (reportType of reportTypes?.reportTypes; track reportType; let i = $index) {
                <mat-option id="rata-option-rt-{{reportType.description}}" [value]="reportType.name" (onSelectionChange)="onReportTypeSelected($event, reportType)">
                  {{reportType.description}}
                </mat-option>
              }
            </mat-select>
            <sat-popover #reportTypePopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
              <gema3g-tooltip [content]="selectedReportTypeToolTip"></gema3g-tooltip>
            </sat-popover>
          </mat-form-field>
        </div>

        <div class="dd-container">
          <mat-form-field id="rata-ff-mn" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="dd" (mouseover)="reportModelPopover.open()" (mouseout)="reportModelPopover.close()" [satPopoverAnchor]="reportModelPopover">
            <mat-label id="rata-label-mn">Model Name</mat-label>
            <mat-select id="rata-select-mn" formControlName="{{RRDDKE.REPORT_MODEL}}" [disabled]="reportRunning">
              @for (reportModel of reportModels?.models; track reportModel; let j = $index) {
                <mat-option id="rata-option-mn-{{reportModel.name}}" [value]="reportModel.modelId" (onSelectionChange)="onReportModelSelected($event, reportModel)">
                  {{reportModel.name}}
                </mat-option>
              }
            </mat-select>
            <sat-popover #reportModelPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
              <gema3g-tooltip [content]="selectedReportModelToolTip"></gema3g-tooltip>
            </sat-popover>
          </mat-form-field>
        </div>

        <div class="dd-container">
          <mat-form-field id="rata-ff-rp" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="dd" (mouseover)="reportPeriodPopover.open()" (mouseout)="reportPeriodPopover.close()" [satPopoverAnchor]="reportPeriodPopover">
            <mat-label id="rata-label-rp">Reporting Period</mat-label>
            <mat-select id="rata-select-rp" formControlName="{{RRDDKE.REPORT_PERIOD}}" [(ngModel)]="selectedProcessId" [disabled]="reportRunning">
              @for (reportPeriod of reportPeriods?.reportingPeriods; track reportPeriod; let k = $index) {
                <mat-option id="rata-option-rp-{{reportPeriod.processId}}" [value]="reportPeriod.processId" (onSelectionChange)="onReportPeriodSelected($event, reportPeriod)">
                  {{reportPeriod.reportingPeriodStart | mediumDate}} to {{reportPeriod.reportingPeriodEnd | mediumDate}}
                </mat-option>
              }
            </mat-select>
            <sat-popover #reportPeriodPopover [autoFocus]="false" [restoreFocus]="false" verticalAlign="below" openTransition="500ms ease-out" closeTransition="100ms ease-in">
              <gema3g-tooltip [content]="selectedReportPeriodToolTip"></gema3g-tooltip>
            </sat-popover>
          </mat-form-field>
        </div>

        <div class="dd-container">
          <button id="rata-btn-rr" mat-raised-button color="primary" (click)="search(true, false, false, false)" [disabled]="reportRunning || !showRunReport">
            <div class="run">
              <span id="rata-rr-label">Run Report</span><fa-icon id="rata-run-icon" class="fa-sm text-white" [icon]="faFileImport"></fa-icon>
            </div>
          </button>
        </div>
      </div>
    </form>
    <div class="options">
      <div class="filter">
        <mat-slide-toggle id="rata-toggle-filter" [disabled]="reportRunning || !(showSearchResults || showFilter)" [(ngModel)]="showFilter" (change)="showFilterCriteria($event)" color="primary">Filter</mat-slide-toggle>
      </div>

      <button id="rata-save-records" mat-raised-button color="primary" [matMenuTriggerFor]="menu" [disabled]="reportRunning || !showSearchResults">
        <div class="save">
          <span id="rata-save-label">Save</span><fa-icon id="rata-save-icon" class="fa-sm text-white" [icon]="faFileArrowDown"></fa-icon>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadSelectedReportActivities()" title="Save selected records">Selected</button>
        <button mat-menu-item (click)="downloadAllReportActivities()" title="Save all records">All</button>
      </mat-menu>
    </div>
  </div>

  @if (showDescription) {
    <div class="description">
      <div id="rata-desc">
        The Registration Activity Tracker (REGISTRATION TRACKING) is an application that tracks and reports on registration activity which has occurred within a report period. The major features of this application are:
      </div>
      <div class="items">
        <ul class="list-disc">
          <li id="rata-desc-list-1">REGISTRATION TRACKING searches the GeoWarehouse database for activity based on custom model criteria on a weekly basis</li>
          <li id="rata-desc-list-1">Reports can be viewed / filtered and sorted to quickly access the information</li>
          <li id="rata-desc-list-1">Detailed information can be viewed within the application</li>
          <li id="rata-desc-list-1">Documents can be retrieved and viewed to get detailed information</li>
        </ul>
      </div>
    </div>
  }

  @if (showFilter) {
    <div class="filter-container">
      <form id="rata-form-filter" [formGroup]="filterForm" (ngSubmit)="onFilterFormSubmitted()">
        <div class="filter get-dense-3">

          @if (isUIElementVisible(RFKE.LRO)) {
            <mat-form-field id="rata-ff-lro" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-lro">LRO</mat-label>
              <mat-select id="rata-select-lro" formControlName="{{RFKE.LRO}}" [disabled]="reportRunning">
                @for (lro of filterCriteria.lros; track lro; let i = $index) {
                  <mat-option id="rata-option-lro-{{lro.lroNumber}}" [value]="lro.lroNumber" (onSelectionChange)="onLroFilterChanged($event, lro)">
                    {{lro.lroOfficeName}} ({{lro.lroNumber}})
                  </mat-option>
                }
              </mat-select>
              <button id="rata-btn-lro" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterDropdownSelection($event, RFKE.LRO)">
                <mat-icon id="rata-icon-close">close</mat-icon>
              </button>
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.INSTRUMENT_TYPE)) {
            <mat-form-field id="rata-ff-it" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-it">Instrument Type</mat-label>
              <mat-select id="rata-select-it" formControlName="{{RFKE.INSTRUMENT_TYPE}}" [disabled]="reportRunning">
                @for (instrument of filterCriteria.instrumentTypes; track instrument; let i = $index) {
                  <mat-option id="rata-option-it-{{instrument.instrumentSubTypeCode}}" [value]="instrument.instrumentSubTypeCode" (onSelectionChange)="onInstrumentFilterChanged($event, instrument)">
                    {{instrument.instrumentDescription}} ({{instrument.instrumentSubTypeCode}})
                  </mat-option>
                }
              </mat-select>
              <button id="rata-btn-it" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterDropdownSelection($event, RFKE.INSTRUMENT_TYPE)">
                <mat-icon id="rata-icon-close">close</mat-icon>
              </button>
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.CHARGE_INSTRUMENT_TYPE)) {
            <mat-form-field id="rata-ff-ct" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-ct">Charge Type</mat-label>
              <mat-select id="rata-select-ct" formControlName="{{RFKE.CHARGE_INSTRUMENT_TYPE}}" [disabled]="reportRunning">
                @for (instrument of filterCriteria.chargeInstrumentTypes; track instrument; let i = $index) {
                  <mat-option id="rata-option-ct-{{instrument.instrumentSubTypeCode}}" [value]="instrument.instrumentSubTypeCode" (onSelectionChange)="onChargeInstrumentFilterChanged($event, instrument)">
                    {{instrument.instrumentDescription}} ({{instrument.instrumentSubTypeCode}})
                  </mat-option>
                }
              </mat-select>
              <button id="rata-btn-ct" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterDropdownSelection($event, RFKE.CHARGE_INSTRUMENT_TYPE)">
                <mat-icon id="rata-icon-close">close</mat-icon>
              </button>
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.TRANSFER_INSTRUMENT_TYPE)) {
            <mat-form-field id="rata-ff-tt" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-tt">Transfer Type</mat-label>
              <mat-select id="rata-select-tt" formControlName="{{RFKE.TRANSFER_INSTRUMENT_TYPE}}" [disabled]="reportRunning">
                @for (instrument of filterCriteria.transferInstrumentTypes; track instrument; let i = $index) {
                  <mat-option id="rata-option-tt-{{instrument.instrumentSubTypeCode}}" [value]="instrument.instrumentSubTypeCode" (onSelectionChange)="onTransferInstrumentFilterChanged($event, instrument)">
                    {{instrument.instrumentDescription}} ({{instrument.instrumentSubTypeCode}})
                  </mat-option>
                }
              </mat-select>
              <button id="rata-btn-tt" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilterDropdownSelection($event, RFKE.TRANSFER_INSTRUMENT_TYPE)">
                <mat-icon id="rata-icon-close">close</mat-icon>
              </button>
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.PIN)) {
            <mat-form-field id="rata-ff-pin" appearance="outline" floatLabel="always" subscriptSizing="dynamic" floatLabel="always">
              <mat-label id="rata-label-pin">PIN</mat-label>
              <input id="rata-tf-pin" matInput formControlName="{{RFKE.PIN}}" placeholder="9-digit PIN"
                [errorStateMatcher]="filterErrorStateMatcher" [disabled]="reportRunning">
                @if (filterForm.get(RFKE.PIN)?.errors?.['pinInvalid']) {
                  <mat-error id="rata-error-pin">
                    Enter 9-digit numeric
                  </mat-error>
                }
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.PARTY_FROM)) {
            <mat-form-field id="rata-ff-pf" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-pf">Party From</mat-label>
              <input id="rata-tf-pf" matInput formControlName="{{RFKE.PARTY_FROM}}" placeholder=""
                [errorStateMatcher]="filterErrorStateMatcher" [disabled]="reportRunning">
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.PARTY_TO)) {
            <mat-form-field id="rata-ff-pt" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
              <mat-label id="rata-label-pt">Party To</mat-label>
              <input id="rata-tf-pt" matInput formControlName="{{RFKE.PARTY_TO}}" placeholder=""
                [errorStateMatcher]="filterErrorStateMatcher" [disabled]="reportRunning">
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.REGISTRATION_DATE)) {
            <mat-form-field id="rata-ff-rd" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="calendar">
              <mat-label id="rata-label-rd">Registration Date</mat-label>
              <input id="rata-tf-rd" matInput [matDatepicker]="chargeDatePicker"
                formControlName="{{RFKE.REGISTRATION_DATE}}" [max]="maxCalendarDate" placeholder="MM/DD/YYYY"
                (dateChange)="onRegistrationDateChanged($event)" [disabled]="reportRunning">
                <mat-datepicker-toggle id="rata-datepicker-toggle-rd" matIconSuffix [for]="chargeDatePicker"></mat-datepicker-toggle>
                <mat-datepicker id="rata-datepicker-rd" #chargeDatePicker></mat-datepicker>
                @if (filterForm.get(RFKE.REGISTRATION_DATE)?.errors) {
                  <mat-error id="rata-error-rd">
                    Invalid {{FILTER_DATE_FORMAT}} value
                  </mat-error>
                }
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.CHARGE_REGISTRATION_DATE)) {
            <mat-form-field id="rata-ff-cd" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="calendar">
              <mat-label id="rata-label-cd">Charge Date</mat-label>
              <input id="rata-tf-cd" matInput [matDatepicker]="chargeDatePicker"
                formControlName="{{RFKE.CHARGE_REGISTRATION_DATE}}" [max]="maxCalendarDate" placeholder="MM/DD/YYYY"
                (dateChange)="onChargeRegistrationDateChanged($event)" [disabled]="reportRunning">
                <mat-datepicker-toggle id="rata-datepicker-toggle-cd" matIconSuffix [for]="chargeDatePicker"></mat-datepicker-toggle>
                <mat-datepicker id="rata-datepicker-cd" #chargeDatePicker></mat-datepicker>
                @if (filterForm.get(RFKE.CHARGE_REGISTRATION_DATE)?.errors) {
                  <mat-error id="rata-error-cd">
                    Invalid {{FILTER_DATE_FORMAT}} value
                  </mat-error>
                }
            </mat-form-field>
          }

          @if (isUIElementVisible(RFKE.TRANSFER_REGISTRATION_DATE)) {
            <mat-form-field id="rata-ff-td" appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="calendar">
              <mat-label id="rata-label-td">Transfer Date</mat-label>
              <input id="rata-tf-td" matInput [matDatepicker]="transferDatePicker"
                formControlName="{{RFKE.TRANSFER_REGISTRATION_DATE}}" [max]="maxCalendarDate" placeholder="MM/DD/YYYY"
                (dateChange)="onTransferRegistrationDateChanged($event)" [disabled]="reportRunning">
                <mat-datepicker-toggle id="rata-datepicker-toggle-td" matIconSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
                <mat-datepicker id="rata-datepicker-td" #transferDatePicker></mat-datepicker>
                @if (filterForm.get(RFKE.TRANSFER_REGISTRATION_DATE)?.errors) {
                  <mat-error id="rata-error-td">
                    Invalid {{FILTER_DATE_FORMAT}} value
                  </mat-error>
                }
            </mat-form-field>
          }

          <button id="rata-btn-filter-apply" type="submit" mat-raised-button color="primary" [disabled]="filterForm.invalid || reportRunning">
            <div class="apply">
              <span id="rata-apply-label">Apply</span><fa-icon id="rata-filter-icon" class="fa-sm text-white" [icon]="faFilter"></fa-icon>
            </div>
          </button>
        </div>
      </form>
    </div>
  }

  @if (showSearchResults || reportRunning) {
    <div class="paginator get-dense-3">
      <div class="control">
        <!-- pageIndex is zero-based -->
        <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
          [pageSize]="rowsPerPage" [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="currentPageNumber - 1"
          aria-label="Select page">
        </mat-paginator>

        <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="page-number-list">
          <mat-label>Page</mat-label>
          <mat-select id="rata-page-numbers" [(ngModel)]="currentPageNumber">
            @for (page of pageNumbers; track page; let i = $index) {
              <mat-option id="rata-page-number-{{page}}" [value]="page" (onSelectionChange)="onPageChangedEvent($event)">
                {{page}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

      </div>

      @if (showFilter && filterSummary) {
        <div id="rata-filter-summary" class="filter-summary">
          Results filtered by: {{filterSummary}}
        </div>
      }

      @if (hasRecordsSelected) {
        <div id="rata-selected-records" class="selected-records">
          {{selectedRecordsCount}} {{(selectedRecordsCount > 1? 'records' : 'record')}} selected

          <a id="rata-clear-selected-records" (click)="clearSelectedRecords()" class="anchor">Clear selection</a>
        </div>
      }

    </div>
  }

  @if (reportRunning) {
    <div class="busy">
      <img id="rata-refresh-icon" src="assets/img/icon-refresh.gif">
    </div>
  }

  @if (!reportRunning && showSearchResults) {
    <div class="data">
      <mat-sidenav-container #rataSideNavContainer class="sidenav-container" [hasBackdrop]="false">
        <mat-sidenav-content #rataSideNavContentContainer class="sidenav-data-container">
          <div class="sidenav-data-content">
            <table id="rata-tbl" class="mat-elevation-z2 standard-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChanged($event)">

              @if (isUIElementVisible(RSKE.CHECKBOXES)) {
                <ng-container matColumnDef="{{RSKE.CHECKBOXES}}">
                  <th id="rata-tbl-hdr-sel" mat-header-cell *matHeaderCellDef mat-sort-header></th>
                  <td mat-cell *matCellDef="let row; let i = index" class="centered">
                  <input id="rata-tbl-chkbx-sel-{{row.recordId}}" type="checkbox" [checked]="isRecordSelected(row.recordId)" (click)="$event.stopPropagation();" (change)="onRecordSelected(row.recordId, $event)" /></td>
                  <!-- This number is the index of each row -->
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.LRO_OFFICE_NAME)) {
                <ng-container matColumnDef="{{RSKE.LRO_OFFICE_NAME}}">
                  <th id="rata-tbl-hdr-lro" mat-header-cell *matHeaderCellDef mat-sort-header>LRO NAME</th>
                  <td id="rata-tbl-data-lro-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered"> {{row.lroOfficeName}} {{row.lroNumber}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.PIN)) {
                <ng-container matColumnDef="{{RSKE.PIN}}">
                  <th id="rata-tbl-hdr-pin" mat-header-cell *matHeaderCellDef mat-sort-header>PIN</th>
                  <td id="rata-tbl-data-pin-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.pin}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.ARN)) {
                <ng-container matColumnDef="{{RSKE.ARN}}">
                  <th id="rata-tbl-hdr-arn" mat-header-cell *matHeaderCellDef mat-sort-header>ARN</th>
                  <td id="rata-tbl-data-arn-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.arn}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.INSTRUMENT_TYPE)) {
                <ng-container matColumnDef="{{RSKE.INSTRUMENT_TYPE}}">
                  <th id="rata-tbl-hdr-type" mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
                  <td id="rata-tbl-data-type-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.instrumentSubTypeCode}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.CHARGE_TYPE)) {
                <ng-container matColumnDef="{{RSKE.CHARGE_TYPE}}">
                  <th id="rata-tbl-hdr-ct" mat-header-cell *matHeaderCellDef mat-sort-header>CHARGE TYPE</th>
                  <td id="rata-tbl-data-ct-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.chargeInstrumentSubTypeCode}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.TRANSFER_TYPE)) {
                <ng-container matColumnDef="{{RSKE.TRANSFER_TYPE}}">
                  <th id="rata-tbl-hdr-tt" mat-header-cell *matHeaderCellDef mat-sort-header>TRANSFER TYPE</th>
                  <td id="rata-tbl-data-tt-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.transferInstrumentSubTypeCode}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.REGISTRATION_DATE)) {
                <ng-container matColumnDef="{{RSKE.REGISTRATION_DATE}}">
                  <th id="rata-tbl-hdr-rd" mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
                  <td id="rata-tbl-data-rd-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.registrationDate | date : 'mediumDate'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.CHARGE_DATE)) {
                <ng-container matColumnDef="{{RSKE.CHARGE_DATE}}">
                  <th id="rata-tbl-hdr-cd" mat-header-cell *matHeaderCellDef mat-sort-header>CHARGE DATE</th>
                  <td id="rata-tbl-data-cd-{{row.recordId | date : 'mediumDate'}}" mat-cell *matCellDef="let row" class="centered">{{row.chargeRegistrationDate | date : 'mediumDate'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.TRANSFER_DATE)) {
                <ng-container matColumnDef="{{RSKE.TRANSFER_DATE}}">
                  <th id="rata-tbl-hdr-td" mat-header-cell *matHeaderCellDef mat-sort-header>TRANSFER DATE</th>
                  <td id="rata-tbl-data-td-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.transferRegistrationDate | date : 'mediumDate'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.CONSIDERATION_AMOUNT)) {
                <ng-container matColumnDef="{{RSKE.CONSIDERATION_AMOUNT}}">
                  <th id="rata-tbl-hdr-amount" mat-header-cell *matHeaderCellDef mat-sort-header>AMOUNT</th>
                  <td id="rata-tbl-data-amount-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.considerationAmount | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.SUM_AMOUNT)) {
                <ng-container matColumnDef="{{RSKE.SUM_AMOUNT}}">
                  <th id="rata-tbl-hdr-sa" mat-header-cell *matHeaderCellDef mat-sort-header>SUM</th>
                  <td id="rata-tbl-data-sa-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.sumConsideration | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.CHARGE_AMOUNT)) {
                <ng-container matColumnDef="{{RSKE.CHARGE_AMOUNT}}">
                  <th id="rata-tbl-hdr-ca" mat-header-cell *matHeaderCellDef mat-sort-header>CHARGE AMOUNT</th>
                  <td id="rata-tbl-data-ca-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.chargeConsiderationAmount | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.TRANSFER_AMOUNT)) {
                <ng-container matColumnDef="{{RSKE.TRANSFER_AMOUNT}}">
                  <th id="rata-tbl-hdr-ta" mat-header-cell *matHeaderCellDef mat-sort-header>TRANSFER AMOUNT</th>
                  <td id="rata-tbl-data-ta-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.transferConsiderationAmount | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.PARTY_FROM)) {
                <ng-container matColumnDef="{{RSKE.PARTY_FROM}}">
                  <th id="rata-tbl-hdr-pf" mat-header-cell *matHeaderCellDef mat-sort-header>PARTY FROM</th>
                  <td id="rata-tbl-data-pf-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.partyFrom}}</td>
                </ng-container>
              }

              @if (isUIElementVisible(RSKE.PARTY_TO)) {
                <ng-container matColumnDef="{{RSKE.PARTY_TO}}">
                  <th id="rata-tbl-hdr-pt" mat-header-cell *matHeaderCellDef mat-sort-header>PARTY TO</th>
                  <td id="rata-tbl-data-pt-{{row.recordId}}" mat-cell *matCellDef="let row" class="centered">{{row.partyTo}}</td>
                </ng-container>
              }

              <tr id="rata-tbl-row-hdr" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr id="rata-tbl-row-{{row.recordId}}" mat-row *matRowDef="let row; columns: displayedColumns;" (click)="displayPII($event, row)" (mouseover)="onMouseOver(row)" (mouseout)="onMouseOut(row)"></tr>

            </table>
          </div>
        </mat-sidenav-content>
        <mat-sidenav #rataSideNavPIIContainer class="sidenav-pii-container" position="end" opened="{{showPii}}" disableClose="false">
          <div class="sidenav-pii-content">
            <gema3g-registration-tracker-pii (piiOverlapMode)="togglePIISideDisplayMode($event)" (piiClosed)="displayPIISidebar(false)" [piiBusy]="piiBusy">
            </gema3g-registration-tracker-pii>
          </div>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  }

</div>