import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, inject } from '@angular/core';
import { BaseSearchResults } from "../base-search-results";
import { SearchResultOrder } from "../search-results-container/search-result-order";
import * as _ from "lodash";
import { OwnerToPropertyMap } from "../../../../core/model/search-result/owner-to-property-map";
import { Pii } from "../../../../core/model/property/pii";
import { BasePropertyEventEmitter } from "../base-property-event-emitter";
import { LoggerService } from "../../../../shared/service/log/logger.service";
import { CondoSummary } from "../../../../core/model/property/condo-summary";

@Component({
  selector: 'gema3g-omnibar-search-results',
  templateUrl: './main-search-results.component.html',
  styleUrls: ['./main-search-results.component.scss']
})
export class MainSearchResultsComponent extends BasePropertyEventEmitter implements OnChanges {
  @Input() ownerToPropertyMap:OwnerToPropertyMap[] = [];
  @Input('searchResult') searchResult: Pii[] = [];
  @Input() isMobileFullScreen: boolean = false;
  @Output() onOwnerClick = new EventEmitter<OwnerToPropertyMap>();
  @Output() onCondoClick = new EventEmitter<CondoSummary>();
  @Output() onDivScroll = new EventEmitter<Event>();

  constructor() {
    super();
  }

  private loggerService: LoggerService = inject(LoggerService);
  searchResultOrder: SearchResultOrder;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['ownerToPropertyMap'] || changes['searchResult']) && this.searchResultOrder) {
      this.applySorting(this.searchResultOrder);
    }
  }

  openOwnerView(ownerToPropertyMap: OwnerToPropertyMap) {
    //this.loggerService.logDebug('calling parent with', ownerToPropertyMap);
    this.onOwnerClick.emit(ownerToPropertyMap);
  }

  applySorting($event: SearchResultOrder) {
    var sorting = $event.sorting;
    var orders = [$event.isReverse ? 'desc' : 'asc'];
    // Owner sort
    if (this.ownerToPropertyMap && this.ownerToPropertyMap.length > 0) {
      // PIN sort
      if ($event.firstPredicate == 'pin' && $event.secondPredicate == '') {
        sorting = ['hasMultipleItems', $event.firstPredicate];
        orders = ['asc', $event.isReverse ? 'desc' : 'asc'];
      // Address (A-Z/Z-A) sort
      } else if ($event.firstPredicate == 'streetName' && $event.secondPredicate == '') {
        sorting = ['hasMultipleItems', $event.firstPredicate, 'streetNumber', 'unitNumber'];
        orders = $event.isReverse ? ['asc', 'desc', 'desc', 'desc'] : ['asc', 'asc', 'asc', 'asc'];
      // Address (0-9/9-0) sort
      } else if ($event.firstPredicate != '' && $event.secondPredicate != '') {
        sorting = ['hasMultipleItems', 'streetNumberInt'];
        orders = $event.isReverse ? ['asc', 'desc'] : ['asc', 'asc'];
      }
      // @ts-ignore
      this.ownerToPropertyMap = _.orderBy(this.ownerToPropertyMap, sorting, orders);
    // PII sort
    } else if (this.searchResult && this.searchResult.length > 1){
      if ($event.firstPredicate == 'streetName' && $event.secondPredicate == '') {
        sorting = [$event.firstPredicate, 'streetDesignation', 'streetDirection', 'streetNumLength', 'streetNumber', 'unitNumLength', 'unitNumber', 'city'];
        orders = $event.isReverse ? ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc'] : ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'];
      } else if ($event.firstPredicate != '' && $event.secondPredicate != '') {
        sorting = ['streetNumLength', $event.firstPredicate, 'streetName', 'streetDesignation', 'streetDirection', 'unitNumLength', $event.secondPredicate, 'city'];
        orders = $event.isReverse ? ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc'] : ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'asc'];
      }
      // @ts-ignore
      this.searchResult = _.orderBy(this.searchResult, sorting, orders);
    }
    this.searchResultOrder = $event;
  }

  goToCondo(condoSummary : CondoSummary){
    this.onCondoClick?.emit(condoSummary);
  }

  onScroll($event: Event) {
    this.onDivScroll.emit($event);
  }
}
