import { Component, inject } from '@angular/core';
import { EstoreService } from "../../../../shared/service/estore.service";
import { ProductCategory } from "../../../../core/model/store-front/product-category/product-category";
import { lastValueFrom } from "rxjs";
import { LoggerService } from '../../../../shared/service/log/logger.service';
import { UserAccessControl } from "../../../../core/model/user/user-access-control";
import { UserService } from "../../../../shared/service/user.service";
import { ProductCategoryItem } from "../../../../core/model/store-front/product-category/product-category-item";

@Component({
  selector: 'gema3g-store-front-product-categories',
  templateUrl: './store-front-product-categories.component.html',
  styleUrls: ['./store-front-product-categories.component.scss']
})
export class StoreFrontProductCategoriesComponent {
  constructor() {
    this.init();
  }

  private eStoreService: EstoreService = inject(EstoreService);
  private loggerService: LoggerService = inject(LoggerService);

  productCategory: ProductCategory = new ProductCategory();
  allItems: ProductCategoryItem[] = [];
  userAccessControls: UserAccessControl;

  userService = inject(UserService);

  async init() {
    this.userAccessControls = this.userService.getUserAccessControl();
    this.allItems = await lastValueFrom(this.eStoreService.getEstoreProductCategories());

    if (!this.userAccessControls?.GWHRSubscriptionAccess) {
      this.allItems = this.allItems.filter(item => item.linkParamType !== 'SUBSCRIPTION');
    }

    this.productCategory.groups = [];
    for (let i = 0; i < this.allItems.length; i += 3) {
      const groupItems = this.allItems.slice(i, i + 3);
      this.productCategory.addGroup(groupItems[0], groupItems[1], groupItems[2]);
    }

    this.loggerService.logInfo(this.productCategory);
  }
}
