<div class="sidebar-container no-print">
  @for (adBanner of newAdBanners; track $index) {
    @if (adBanner.imageSource.startsWith('assets/img/store-front/parcelregister') && adBanner.imageSource.endsWith('.mp4')) {
      <video id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" class="hide-mobile" [src]="adBanner.imageSource" autoplay muted loop (click)="invokeAction(adBanner.action, $index)">
        Your browser does not support the video tag.
      </video>
    } @else {
      <img id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" [src]="adBanner.imageSource" (click)="invokeAction(adBanner.action, $index)">
    }
  }

  @if (isPropertySummary) {
    <img id="right-sidebar-img-title-reports" src="assets/img/estore/images/catalogue/View_other_title_reports.jpg" (click)="invokeAction(viewStatReportsAction, -1)">
    @if (condoBanner) {
      <img id="right-sidebar-{{condoBanner.adType}}-{{condoBanner.imageSource}}" class="mt-auto" [ngClass]="{'lg:mb-[282px]': newBottomAdBanners.length == 0}" src="{{condoBanner?.imageSource}}" (click)="invokeAction(condoBanner.action, -1)">
    }
  }

  @for (adBanner of newBottomAdBanners; track $index) {
    @if (adBanner.imageSource.endsWith('.mp4')) {
      <video id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" class="hide-mobile" [ngClass]="{'mt-auto': !condoBanner && $index == 0}" [src]="adBanner.imageSource" autoplay muted loop (click)="invokeAction(adBanner.action, $index)">
        Your browser does not support the video tag.
      </video>
    } @else {
      <img id="right-sidebar-{{adBanner.adType}}-{{adBanner.imageSource}}" class="hide-mobile" [ngClass]="{'mt-auto': !condoBanner && $index == 0}" [src]="adBanner.imageSource" (click)="invokeAction(adBanner.action, $index)">
    }
  }
</div>