import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from "./login/login.module";
import { HomeModule } from './home/home.module';
import { AccountModule } from './account/account.module';
import { CartModule } from './cart/cart.module';
import { StoreModule } from './store/store.module';
import { ReportModule } from './report/report.module';
import { ModifiedFormGuard } from "./core/modified-form/modified-form.guard";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { httpInterceptorProviders } from "./shared/service/interceptors/interceptors-registration";
import { errorHandlerProviders } from "./shared/service/error-handler/global-error-handler.service";
import { APP_BASE_HREF, CurrencyPipe } from "@angular/common";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ReloadStateService } from "./shared/service/reload-state.service";
import { PrimeNGConfig } from 'primeng/api';
import { SSOModule } from './sso/sso.module';
import { AuthModule, provideAuth0 } from "@auth0/auth0-angular";
import { environment } from "../environments/environment";
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';

export function initApp(reloadStateService: ReloadStateService) {
  return () => reloadStateService.reload();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    HomeModule,
    AccountModule,
    CartModule,
    StoreModule,
    ReportModule,
    SSOModule,
    NgxDaterangepickerMd.forRoot(),
    MatSnackBarModule
  ],
  providers: [
    provideAuth0({
      domain: environment.auth0.DOMAIN,
      clientId: environment.auth0.CLIENT_ID,

      authorizationParams: {
        redirect_uri: environment.auth0.LOGIN_REDIRECT_URI,
        audience: environment.auth0.AUDIENCE,
        scope: environment.auth0.SCOPE,
        response_type: environment.auth0.RESPONSE_TYPE,
        logoutUrl: environment.auth0.LOGOUT_URL,

      }
    }),
    ModifiedFormGuard,
    httpInterceptorProviders,
    errorHandlerProviders,
    { provide: APP_BASE_HREF, useValue: '/ui/' },
    CurrencyPipe,
    ReloadStateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [ReloadStateService, PrimeNGConfig],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

