import { DataService } from "./data.service";

export class ErrorUtil {

  static BAD_REQUEST_ERROR_MESSAGE = "Bad Request - Please check the input parameters";
  static DEFAULT_ERROR_MESSAGE = 'Sorry, but there is an issue with your request.  Please try again later.';

  static OMNIBAR_RECENTLY_SEARCHED_ERROR = "An error occurred while retrieving the recent search activities.";

  static SEARCH_AREA_TOO_LARGE = 'Sorry, but there is an issue with your request.  Please refine your search criteria to include fewer municipalities, or to capture a smaller area on the map.';

  static SEARCH_COMPARABLES_SUBMIT_FORM_ERROR: string = 'An error occurred while submitting the form.';

  static SEARCH_COMPARABLES_RESULT_MARKERS_ERROR: string = 'An error occurred while displaying the comparable properties on the map.';

  static FORM_ERRORS_FOUND = 'The search form has errors. Please correct and submit again.';

  static STREETVIEW_ERROR = 'There was an error displaying the streetview.';

  static RATA_GENERAL_ERROR_OCCURRED: string = 'An error has occurred. Please contact Teranet customer service at ' + DataService.SUPPORT_PHONE + ' or ' + DataService.SUPPORT_TOLL_FREE + ' (toll free).';

  static SSO_DEEPLINK_GENERAL_ERROR_OCCURRED: string = 'A single sign-on error has occurred.';
  static SSO_DEEPLINK_SIGN_IN_ERROR: string = 'SSO user authentication failed.';
  static SSO_DEEPLINK_NO_ACCESS_ERROR: string = 'The user does not have SSO access permissions.';
  static SSO_DEEPLINK_REQUEST_FAILED: string = 'SSO request failed.';

  static VOICE_SEARCH_RECOGNITION_START_ERROR: string = "An error occurred when starting voice search recognition.";
  static VOICE_SEARCH_RECOGNITION_STOP_ERROR: string = "An error occurred when stopping voice search recognition.";

  static PDF_REPORT_VIEWER_NO_REPORT_KEY: string = 'There was an error on retrieving report key. Please try again later.';
  static PDF_REPORT_VIEWER_NO_REPORT_RETURNED: string = 'There was an error on retrieving PDF report. Please try again later.';

  static PROPERTY_VIEWED_INSIGHTS_NOT_AVAILABLE: string = 'The property viewed insights for this property is not available at this time. Please try again later.';

  static ERROR_ON_OKTA_LOGIN: string = 'There was an error while trying to process your request. OKTA reported: ';
  static BACKEND_ERROR_HTTP_CALL: string = 'We are unable to service the request at this moment. Please try again.';

  static VAULTED_CARD_DELETION_ERROR: string = 'We are unable to remove vaulted card. Please try again.';
  static VAULTED_CARD_DELETION_SUCCESS: string = 'Vaulted card has been removed successfully.';
  static ERROR_ON_CREATING_REPORT: string = 'There was an error creating the report. Please try again later.';
  static ERROR_ON_AADDING_PINS_TO_REPORT: string = 'There was an error adding new pins to existing the report. Please try again later.';

  static COULD_NOT_SAVE_PREFS = `Your preference could not be saved.`;

  static PROPERTY_REPORT_NOT_FOUND_HEADER = 'Property report';
  static PROPERTY_REPORT_NOT_FOUND = 'Property report not found, please try again.';
}
