import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ScreenNameEnum } from "../../core/enum/screen-name.enum";
import { ScreenMatrix } from "../../core/model/screen/screen-matrix";
import { LoggerService } from "./log/logger.service";
import { ScreenManagerHelper } from "./screen-manager-helper";
import { SearchComparablesResultService } from "./search/search-comparables-result.service";
import { ScreenOrientation } from '../../core/enum/screen-orientation-enum';
import { ScreenDisplay } from "../../core/enum/screen-display-enum";

@Injectable({
  providedIn: 'root'
})
export class ScreenManager {
  constructor() {
  }

  private loggerService = inject(LoggerService);
  private searchComparablesResultService = inject(SearchComparablesResultService);
  
  private screenMatrix: ScreenMatrix;

  private screenManagerHelper: ScreenManagerHelper = new ScreenManagerHelper();

  public getObservableScreen = (screen: ScreenNameEnum): Observable<boolean> | null => {

    let observable = this.screenManagerHelper?.screenManagers?.get(screen)?.asObservable();
    return observable ? observable : null;
  }

  public hideScreen = (screen: ScreenNameEnum) => {
    let observable = this.screenManagerHelper?.screenManagers?.get(screen);
    observable?.next(false);
  }

  public showScreen = (screen: ScreenNameEnum) => {
    let observable = this.screenManagerHelper?.screenManagers?.get(screen);
    observable?.next(true);
  }

  public isScreenVisible = (screen: ScreenNameEnum): boolean => {
    let observable = this.screenManagerHelper?.screenManagers?.get(screen);
    return observable?.getValue() || false;
  }

  /**
   * Lists which screens should close when a particular screen is opened.
   *
   */
  private initializeScreenMatrix = () => {
    this.screenMatrix = new ScreenMatrix();

    this.screenMatrix.map.set(ScreenNameEnum.PROPERTY_REPORT, [
      ScreenNameEnum.OMNIBAR_SEARCH_RESULTS,
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.SEARCH_COMPARABLES_RESULTS,
      ScreenNameEnum.MAIN_MAP_STREETVIEW,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.OMNIBAR_SEARCH, [
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.SEARCH_COMPARABLES_RESULTS,
      ScreenNameEnum.MAIN_MAP_STREETVIEW,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS, [
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.SEARCH_COMPARABLES_RESULTS,
      ScreenNameEnum.MAIN_MAP_STREETVIEW,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.SEARCH_COMPARABLES_FORM, [
      ScreenNameEnum.OMNIBAR_SEARCH_RESULTS,
      ScreenNameEnum.PROPERTY_REPORT,
      ScreenNameEnum.MAIN_MAP_STREETVIEW,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS, [
      ScreenNameEnum.PROPERTY_REPORT,
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.MAIN_MAP_STREETVIEW, [
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.HOODQ, [
      ScreenNameEnum.MAIN_MAP_STREETVIEW,
      ScreenNameEnum.MAP_LAYER_POPUP,
      ScreenNameEnum.MAP_TYPE_POPUP]);

    this.screenMatrix.map.set(ScreenNameEnum.MY_ACCOUNT, [
      ScreenNameEnum.SEARCH_COMPARABLES_FORM,
      ScreenNameEnum.SEARCH_COMPARABLES_RESULTS]);
  }

  /**
   * Close all the screens in the matrix of screens tied to the specified screen to open.
   * 
   * @param screenOpened
   */
  public closeScreensWhenThisOpened = (screenOpened: ScreenNameEnum) => {
    if (!this.screenMatrix) {
      this.initializeScreenMatrix();
    }

    let screenName = ScreenNameEnum[screenOpened];
    let screensToClose = this.screenMatrix.map.get(screenOpened);
    this.loggerService.logInfo(`closing screens for ${screenName}`, screensToClose);

    screensToClose!.forEach((screen: ScreenNameEnum) => {
      this.hideScreen(screen);
    });
  }

  public setDefaultSearchComparablesScreenOrientation = (screenOrientation: ScreenOrientation | null) => {
    if (screenOrientation) {
      this.searchComparablesResultService.changeScreenOrientation(screenOrientation);
    } else {
      this.searchComparablesResultService.changeScreenOrientation(ScreenOrientation.HORIZONTAL);
    }
  }

  public setDefaultSearchComparablesScreenDisplay = (screenDisplay: ScreenDisplay | null) => {
    if (screenDisplay) {
      this.searchComparablesResultService.changeScreenDisplay(screenDisplay);
    } else {
      this.searchComparablesResultService.changeScreenDisplay(ScreenDisplay.NORMAL);
    }
  }

  public closeAllScreens = () => {
    this.hideScreen(ScreenNameEnum.PROPERTY_REPORT);
    this.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_FORM);
    this.hideScreen(ScreenNameEnum.SEARCH_COMPARABLES_RESULTS);
    this.hideScreen(ScreenNameEnum.MAIN_MAP_STREETVIEW);
    this.hideScreen(ScreenNameEnum.HOODQ);
    this.hideScreen(ScreenNameEnum.MAP_LAYER_POPUP);
    this.hideScreen(ScreenNameEnum.MAP_TYPE_POPUP);
    this.hideScreen(ScreenNameEnum.MY_ACCOUNT);
    this.hideScreen(ScreenNameEnum.OMNIBAR_SEARCH_RESULTS);
  }
}
