<section class="my-account-container">
  <gema3g-header></gema3g-header>
  <gema3g-omnibar-search [searchEnabled]='false'></gema3g-omnibar-search>
  <div class="my-account">
    <mat-drawer-container class="drawer-container">
      <mat-drawer #drawer class="drawer" mode="side">
        <!-- <p>Auto-resizing sidenav</p>
        <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p>
        <button (click)="showFiller = !showFiller" mat-raised-button>
          Toggle extra text
        </button> -->
        <div class="left-nav">
          <div class="name-header">
            <div class="img-contain">
              @if (user) {
                <div>
                  @if (user.profilePhotoUrl) {
                    <img id="account-img-user" src="{{user.profilePhotoUrlRandomized}}" width="40"/>
                  }
                  @if (!user.profilePhotoUrl) {
                    <img id="account-img-user-default" src="../../../../assets/img/default-userprofile-photo.png" width="40"/>
                  }
                </div>
              } @else {
                <img id="account-img-default" class='account-main-profile-image' src="../../../../assets/img/mygeo-icons/icon_profile.png" width="40"/>
              }
            </div>
            @if (user) {
              <div class="pl-2">
                <span id="account-user-fullname">{{ user.fullName }}</span>
              </div>
            }
          </div>
          <div class="nav-items" style="padding-right: 20px;">
            <ul>
              @for (accMenu of accountMenu; track accMenu) {
                <li>
                  <a (click)="menuItemClicked(accMenu)" id="account-{{accMenu.id}}">
                    <div id="account-label-{{accMenu.id}}" class="text-nav-icon">
                      <img id="account-icon-{{accMenu.id}}" alt="" src="{{accMenu.img}}"/>{{ accMenu.title }} &nbsp;
                      @if (accMenu.children.length) {
                        <span id="account-caret-{{accMenu.id}}" class="caret caret-margin"></span>
                      }
                    </div>
                  </a>
                  <ul [ngClass]="{'collapse' : accMenu.collapsed}">
                    @for (subMenu of accMenu.children; track subMenu) {
                      <li class="sub-menu-icon-text">
                        <a (click)="subMenuItemClicked(subMenu)" id="account-{{subMenu.id}}">
                          <div id="account-label-{{subMenu.id}}" class="text-nav-icon">
                            <img id="account-icon-{{subMenu.id}}" alt="" class="left-nav-img" src="{{subMenu?.img}}"/>
                            {{ subMenu.title }}
                          </div>
                        </a>
                      </li>
                    }
                  </ul>
                </li>
              }
            </ul>
          </div>
        </div>
      </mat-drawer>


      <div class="drawer-content">
        <button id="account-btn-apps" type="button" mat-button (click)="drawer.toggle()">
          <mat-icon id="account-icon-apps" matSuffix>apps icon</mat-icon>
        </button>

        <div>
          <div class="gema3g-ss-container">
            <div class="right-content">
              <!-- header -->
              <div class="account-header display-flex mb-8">
                <div class="flex-1 account-header-left">
                  <img id="account-img-selected-menu" alt="" src="{{selectedMenu?.imgGrey}}"/>
                  <h3 id="account-h3-first-sub-menu" class="no-icon account-h3">{{ secondSubMenu ? secondSubMenu : firstSubMenu }}</h3>
                </div>
                <div class="flex-1 justify-content-center hidden md:block">
                  @if (breadcrumbsEnabled) {
                    <a id="account-link-my-account" class="anchor" routerLink="overview">My Account</a>
                    @if (firstSubMenuLink) {
                      >
                      <a id="account-link-first-sub-menu" class="anchor" routerLink="{{firstSubMenuLink}}">{{ firstSubMenu }}</a>
                      @if (secondSubMenuLink) {
                        >
                        <a id="account-link-second-sub-menu" class="anchor" routerLink="{{secondSubMenuLink}}">{{ secondSubMenu }}</a>
                      }
                    }
                  }
                </div>
                <div class="flex-1">
                  <!--<a id="account-link-close" (click)="navigateHome()" class="close-btn">Close X</a>-->
                </div>
              </div>

              <!-- <div class="header">
              <h3 class="mb-0"><img alt="" src="{{selectedMenu?.imgGrey}}"/> {{secondSubMenu ? secondSubMenu : firstSubMenu}}</h3>

              <ng-container *ngIf="breadcrumbsEnabled">
                <a class="anchor" routerLink="overview">My Account</a>
                <ng-container *ngIf="firstSubMenuLink"> >
                  <a class="anchor" routerLink="{{firstSubMenuLink}}">{{firstSubMenu}}</a>
                  <ng-container *ngIf="secondSubMenuLink"> >
                    <a class="anchor" routerLink="{{secondSubMenuLink}}">{{secondSubMenu}}</a>
                  </ng-container>
                </ng-container>
              </ng-container>

              <a (click)="navigateHome()" class="ml-auto">
                <mat-icon matSuffix>close</mat-icon>
              </a>
            </div> -->

              <div class="main-sub-component">
                <router-outlet></router-outlet>
              </div>
            </div>

          </div>
        </div>
      </div>
    </mat-drawer-container>
  </div>
</section>
